import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Link from 'next/link'
import { getParentCategories } from 'SRC/modules/categories/selectors'

const CategoriesColumns = ({ parentCategories }) => {
  if (!Array.isArray(parentCategories) || !parentCategories.length) return null

  const categoryColumns = []

  for (let i = 0; i < parentCategories.length; i += 12) {
    categoryColumns.push(parentCategories.slice(i, i + 12))
  }

  if (!categoryColumns.length) return null

  return [
    <span
      key='footer-categories-title'
      className='footer-column-title category-column-title'
    >
        Kategorije
    </span>,
    categoryColumns.map((items, index) => {
      return (
        <div key={`categories-columns-${index}`} className='category-column'>
          <ul>
            {items.map(item => {
              return (
                <li key={`category-${item.seo}`}>
                  <Link
                    href={`/category?parentCategory=${item.seo}`}
                    as={`/${item.seo}`}
                  >
                    <a>{item.title}</a>
                  </Link>
                </li>
              )
            })}
          </ul>
        </div>
      )
    })
  ]
}

CategoriesColumns.propTypes = {
  parentCategories: PropTypes.array.isRequired
}

const mapStateToProps = state => {
  return {
    parentCategories: getParentCategories(state)
  }
}

export default connect(mapStateToProps)(CategoriesColumns)
