import PropTypes from 'prop-types'
import React from 'react'
// import { Message } from '../Message'
// import { Email } from '../Email'
// import { Comment } from '../Comment'
import { SaveAd } from '../SaveAd'
import { SaveProfile } from '../SaveProfile'
import { ReportButton, ReportForm, ReportSuccess } from '../Report'
import { getCurrentAd, getAdsReportTypes } from 'SRC/modules/ads/detail/selectors'
import { fetchAdsReportTypes } from 'SRC/modules/ads/detail/actions'
import { getInfo as getUserInfo } from 'src/modules/users/detail/selectors'
import { connect } from 'react-redux'
import Modal from 'react-responsive-modal'
import { getIsAuthorized } from 'SRC/modules/users/Auth/selectors'
import { CookiesHelper } from 'Core/cookiesHelper/CookiesHelper'
import { AdsDetailApi } from 'SRC/modules/ads/detail/api'
import { Preloader } from 'SRC/ui/Preloader'
import { ErrorNotification } from 'SRC/ui'
// import { emailValidator } from 'Core/validators'
import Router from 'next/router'

export class Container extends React.PureComponent {
  state = {showModal: false, isLoading: false, error: null, reportAdded: false}
  cookies = new CookiesHelper()

  onReportClick = () => {
    const { reportTypes, fetchAdsReportTypes } = this.props

    if (this.props.isAuthorized) {
      if (!Array.isArray(reportTypes) || !reportTypes.length) fetchAdsReportTypes()

      this.setState({...this.state, showModal: true})
    } else {
      Router.push({ pathname: '/profile' }, { pathname: 'profil' })
    }
  }

  onCloseModal = () => {
    this.setState({...this.state, showModal: false})
  }

  onCloseErrorNotification = () => {
    this.setState({...this.state, error: null})
  }

  onExited = () => {
    this.setState({...this.state, reportAdded: false})
  }

  onSubmit = async values => {
    const {adId, isAuthorized} = this.props
    const {type, email, comment} = values
    if (type && adId) {
      this.setState({...this.state, isLoading: true})
      const request = {type: Number(type), email, adId}
      if (comment) {
        request.comment = comment
      }
      const token = this.cookies.get('token')
      const userId = this.cookies.get('userId')
      if (isAuthorized && token && userId) {
        request.userId = userId
        request.token = token
      }
      const api = new AdsDetailApi()
      const {error} = await api.addReport(request)
      if (error) {
        this.setState({...this.state, error: 'Došlo je do greške. Prijava nije uspjela.', isLoading: false})
      } else {
        this.setState({...this.state, reportAdded: true, isLoading: false})
      }
    }
  }

  render () {
    const {isAdPage, reportTypes, adId, adStatus, user} = this.props

    const {showModal, isLoading, error, reportAdded} = this.state

    const userInfo = { id: user.id,
      type: isAdPage
        ? user.type.id : user.type.seo,
      username: user.username }
    return (
      <div className='sidebar-user-akcije'>
        {/* <Message />
        <Email />
        {isAdPage ? <Comment /> : null} */}
        {isAdPage ? <SaveAd adId={adId} adStatus={adStatus} /> : null}
        <SaveProfile userInfo={userInfo} style={this.props.isAdPage ? {} : {width: '100%'}} />
        {isAdPage ? <ReportButton onReportClick={this.onReportClick} /> : null}
        <Modal
          open={showModal}
          onClose={this.onCloseModal}
          center
          closeOnEsc
          closeOnOverlayClick
          showCloseIcon={false}
          onExited={this.onExited}
        >
          {
            reportAdded
              ? <ReportSuccess onClose={this.onCloseModal} />
              : (
                <ReportForm
                  onClose={this.onCloseModal}
                  reportTypes={reportTypes}
                  onSubmit={this.onSubmit}
                  isLoading={isLoading}
                />
              )
          }
          {isLoading ? <Preloader /> : null}
        </Modal>
        <ErrorNotification hideNotification={this.onCloseErrorNotification} error={error} />
      </div>
    )
  }
}

Container.displayName = 'Buttons'

const mapStateToProps = state => {
  const ad = getCurrentAd(state)
  const isAdPage = Boolean(ad)
  const user = getUserInfo(state)

  return {
    isAdPage,
    reportTypes: getAdsReportTypes(state),
    adId: isAdPage && ad.id,
    adStatus: isAdPage && ad.status,
    user: isAdPage ? ad.user : user,
    isAuthorized: getIsAuthorized(state)
  }
}

Container.propTypes = {
  isAdPage: PropTypes.bool.isRequired,
  reportTypes: PropTypes.array.isRequired,
  adId: PropTypes.number,
  adStatus: PropTypes.string,
  user: PropTypes.object.isRequired,
  isAuthorized: PropTypes.bool.isRequired,
  fetchAdsReportTypes: PropTypes.func.isRequired
}

Container.defaultProps = {
  isAdPage: false,
  reportTypes: [],
  isAuthorized: false
}

export default connect(mapStateToProps, { fetchAdsReportTypes })(Container)
