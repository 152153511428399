import { CitiesApi } from '../../api'
import { getCitiesByCountryWithinRegions } from '../../selectors'
import { setCitiesLoading, setCitiesByCountryWithinRegions } from '../../actions'

export const fetchCitiesByCountryWithinRegions = (country) => {
  return async (dispatch, getState) => {
    if (!country) {
      dispatch(setCitiesByCountryWithinRegions([]))
    } else {
      dispatch(setCitiesLoading(true))
      const state = getState()
      const cities = getCitiesByCountryWithinRegions(state, country)[country]

      if (!cities || !Array.isArray(cities) || !cities.length) {
        const api = new CitiesApi()
        const result = await api.getCitiesByCountryWithinRegions(country)
        dispatch(setCitiesByCountryWithinRegions(country, result))
      } else {
        dispatch(setCitiesByCountryWithinRegions(country, cities))
      }
      dispatch(setCitiesLoading(false))
    }
  }
}
