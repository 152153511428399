import initialState from 'src/modules/redux/initialState'
import actionTypes from 'src/modules/redux/actionTypes'

export const profileReducer = (state = initialState.user.profile, action) => {
  switch (action.type) {
    case actionTypes.SET_PROFILE_PAGE:
      return {
        ...state,
        currentPage: action.payload
      }
    case actionTypes.SET_PROFILE_NOTIFICATIONS:
      return {
        ...state,
        notifications: action.payload
      }
    case actionTypes.SET_USER_INFO:
      return {
        ...state,
        info: action.payload
      }
    case actionTypes.SET_NEW_LOGO:
      return {...state, newLogo: action.payload}
    case actionTypes.SET_NEW_BANNER:
      return {...state, newBanner: action.payload}
    case actionTypes.SET_PROFILE_UPDATE_ERROR:
      return {...state, error: action.payload}
    case actionTypes.SET_PROFILE_LOADING:
      return {...state, isLoading: action.payload}
    case actionTypes.SAVE_SERVICE_PROVIDER_PHOTO:
      const photos = [...state.photos]
      photos.push(action.payload)
      return {...state, photos}
    case actionTypes.DELETE_SERVICE_PROVIDER_PHOTO:
      const filteredPhotos = state.photos.filter(photo => photo.tmpId !== action.payload)
      return {...state, photos: filteredPhotos}
    case actionTypes.UPDATE_SERVICE_PROVIDER_PHOTO_BY_ID:
      if (state.photos.filter(photo => photo.id === action.payload.id).length) {
        const newPhotos = state.photos.map(item => item.id === action.payload.id ? action.payload.photo : item)
        return {...state, photos: newPhotos}
      }
      return state
    case actionTypes.UPDATE_SERVICE_PROVIDER_PHOTO_BY_TMP_ID:
      if (state.photos.filter(photo => photo.tmpId === action.payload.tmpId).length) {
        const newPhotos = state.photos.map(item => item.tmpId === action.payload.tmpId ? action.payload.photo : item)
        return {...state, photos: newPhotos}
      }
      return state
    case actionTypes.SET_SERVICE_PROVIDER_PHOTOS:
      return {...state, photos: action.payload}
    case actionTypes.SET_PROFILE_ADS_QUERY:
      return {...state, ads: {...state.ads, query: action.payload}}
    case actionTypes.SET_PROFILE_ADS_ERROR:
      return {...state, ads: {...state.ads, error: action.payload}}
    case actionTypes.RESET_PROFILE_INFO:
      return initialState.user.profile
    case actionTypes.SET_AD_FOR_PROMO:
      return {...state, promo: {...state.promo, ad: action.payload}}
    case actionTypes.SET_ACTIVATE_PROMO_ERROR:
      return {...state, promo: {...state.promo, error: action.payload}}
    case actionTypes.SET_PROFILE_FAVOURITE_ADS:
      return {
        ...state,
        favouriteAds: {
          ...state.favouriteAds,
          [action.payload.status]: {
            ...state.favouriteAds[action.payload.status],
            elements: action.payload.ads
          }
        }
      }
    case actionTypes.SET_PROFILE_FAVOURITE_ADS_LOADING:
      return {
        ...state,
        favouriteAds: {
          ...state.favouriteAds,
          [action.payload.status]: {
            ...state.favouriteAds[action.payload.status],
            isLoading: action.payload.isLoading
          }
        }
      }
    case actionTypes.SET_SAVED_FILTERS_LOADING:
      return {
        ...state,
        savedFilters: {
          ...state.savedFilters,
          isLoading: action.payload.isLoading
        }
      }
    case actionTypes.SET_SAVED_FILTERS:
      return {
        ...state,
        savedFilters: {
          ...state.savedFilters,
          elements: action.payload.savedFilters
        }
      }
    case actionTypes.SET_PROFILE_PAGINATION:
      return {
        ...state,
        pagination: {
          ...state.pagination,
          params: action.payload
        }
      }
    case actionTypes.SET_ADDONS:
      const activeAddons = (state.info?.activeAddons || []).map(activeAddon => activeAddon.id)
      const pendingAddons = (state.info?.pendingAddons || []).map(pendingAddon => pendingAddon.id)
      return {
        ...state,
        addons: {
          ...state.addons,
          elements: action.payload.map(addon => ({
            ...addon,
            status: activeAddons.includes(addon.id) ? 'active' : (pendingAddons.includes(addon.id) ? 'pending' : null)
          }))
        }
      }
    case actionTypes.SET_ADDONS_LOADING:
      return {
        ...state,
        addons: {
          ...state.addons,
          isLoading: action.payload
        }
      }
    case actionTypes.SET_FAVOURITE_PROFILES:
      return {
        ...state,
        favouriteProfiles: {
          ...state.favouriteProfiles,
          [action.payload.type]: {
            elements: action.payload.profiles,
            count: action.payload.count
          }
        }

      }
    case actionTypes.SET_FAVOURITE_PROFILES_LOADING:
      return {
        ...state,
        favouriteProfiles: {
          ...state.favouriteProfiles,
          isLoading: action.payload
        }
      }
    case actionTypes.SET_CURRENT_STATUS_FOR_ALL_PROFILE_ADS:
      return {
        ...state,
        ads: {
          ...state.ads,
          currentStatusForAll: action.payload
        }
      }
    case actionTypes.SET_PAGE_FOR_ALL_PROFILE_ADS:
      return {
        ...state,
        ads: {
          ...state.ads,
          pageForAll: action.payload
        }
      }
    case actionTypes.SET_AGENTS:
      return {
        ...state,
        agents: {
          ...state.agents,
          elements: action.payload
        }
      }
    case actionTypes.SET_AGENTS_LOADING:
      return {
        ...state,
        agents: {
          ...state.agents,
          isLoading: action.payload
        }
      }
    case actionTypes.SET_USER_PACKAGE_INACTIVE_MESSAGE:
      return {
        ...state,
        packageInactiveMessage: action.payload
      }
    default:
      return state
  }
}
