import { combineReducers } from 'redux'
import { regionsReducer } from './regions/reducer'
import { countriesReducer } from './countries/reducer'
import { citiesReducer } from './cities/reducer'
import { locationsReducer } from './locations/reducer'
import { closerLocationsReducer } from './closerLocations/reducer'

export const geoObjectsReducer = combineReducers({
  countries: countriesReducer,
  regions: regionsReducer,
  cities: citiesReducer,
  locations: locationsReducer,
  closerLocations: closerLocationsReducer
})
