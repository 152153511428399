import React from 'react'
import PropTypes from 'prop-types'

export const Facebook = ({ baseUrl, asPath, adLink }) => {
  const link = baseUrl ? baseUrl + '/' + adLink : adLink

  return (
    <div className='fb-share-button' data-href={`https://oglasi.me${asPath}`} data-layout='button_count'>
      <div className='kontakt-icon kontakt-facebook'>
        <a
          rel='noreferrer'
          target='_blank'
          href={adLink
            ? `https://www.facebook.com/sharer/sharer.php?u=${link}%2F&amp;src=sdkpreparse`
            : `https://www.facebook.com/sharer/sharer.php?u=${baseUrl}${asPath}%2F&amp;src=sdkpreparse`
          }
          className='fb-xfbml-parse-ignore'
        >
          <i className='ico-share-facebook ico' />
        </a>
      </div>
    </div>
  )
}

Facebook.propTypes = {
  baseUrl: PropTypes.string.isRequired,
  asPath: PropTypes.string.isRequred,
  adLink: PropTypes.string.isRequred
}
