import React from 'react'
import PropTypes from 'prop-types'

export const SectionHeader = ({ title }) => (
  <header>
    <p>{title}</p>
  </header>
)

SectionHeader.propTypes = {
  title: PropTypes.string.isRequired
}

SectionHeader.defaultProps = {
  title: 'SVE KATEGORIJE'
}
