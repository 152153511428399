
import React from 'react'
import { connect } from 'react-redux'
import Link from 'next/link'
import { withRouter } from 'next/router'
import { compose } from 'recompose'
import { get404, getUserTypes } from 'SRC/modules/common/selectors'
import PropTypes from 'prop-types'
import { slide as BurgerMenu } from 'react-burger-menu'
import config from 'SRC/config/config.yaml'
import { getIsAuthorized } from 'SRC/modules/users/Auth/selectors'
import { itemsBeforeUserTypes, itemsAfterUserTypes, itemsBottom } from '../../Menu/data/burgerMenuItems'
import { getUserTypeItems } from '../../Menu/Menu'
import { ItemTop } from '../ItemTop'
import { ItemBottom } from '../ItemBottom'
import { Auth } from '../Auth'

const getIsItemActive = ({ item, asPath }) => {
	const linkPathname = item && item.link && typeof item.link === 'object' && item.link.pathname
		? item.link.pathname.replace('/', '')
		: (item.link.replace('/', '') || '')

	const itemAsReplaced = item && item.as && item.as.replace('/', '')
	const asPathReplaced = asPath && asPath.replace('/', '')

	if (item.as === '/' || item.as === '/mobilna-pretraga' || item.as === '/mobilna-pretraga/tekstualna-pretraga') {
		return item.as === asPath
	}

	return (itemAsReplaced && asPathReplaced && asPathReplaced.startsWith(itemAsReplaced)) ||
		(linkPathname && asPathReplaced && linkPathname === asPathReplaced)
}

const Menu = ({ userTypes, is404, asPath }) => {
	const [isMenuOpen, setIsMenuOpen] = React.useState(false)
	const prevAsPath = React.useRef(asPath)

	if (prevAsPath.current && prevAsPath.current !== asPath) {
		// setIsMenuOpen(false)
		prevAsPath.current = asPath
	}

	const handleStateChange = (state) => {
    setIsMenuOpen(state.isOpen)
  }

	const closeIcon = () => setIsMenuOpen(false)

	const burgerMenuTopItems = [...itemsBeforeUserTypes]
  const userTypesItems = getUserTypeItems(userTypes || [], { isMobile: true })

  burgerMenuTopItems.push(...userTypesItems)
  burgerMenuTopItems.push(...itemsAfterUserTypes)

	return (
		<div className='burger-menu-wrapper'>
			<BurgerMenu
				isOpen={isMenuOpen}
				onStateChange={handleStateChange}
				right
				width='90%'
			>
				{burgerMenuTopItems.map(item => (
					<ItemTop
						key={item.id}
						{...item}
						is404={is404}
						isActive={getIsItemActive({ item, asPath })}
						closeIcon={closeIcon}
					/>
				))}

				{Array.isArray(itemsBottom) ? itemsBottom.map(item => (
					<ItemBottom
						key={item.id}
						{...item}
						is404={is404}
						isActive={getIsItemActive({ item, asPath })}
						closeIcon={closeIcon}
					/>
				)) : null}
				<Auth key='burger-menu-auth' is404={is404} closeIcon={closeIcon} asPath={asPath} />
			</BurgerMenu>
		</div>
	)
}

const mapStateToProps = state => {
	return {
		userTypes: getUserTypes(state),
		is404: get404(state),
		isAuthorized: getIsAuthorized(state)
	}
}

export default compose(
  withRouter,
	connect(mapStateToProps)
)(Menu)

