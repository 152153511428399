import PropTypes from 'prop-types'
import React from 'react'
import { connect as formikConnect } from 'formik'
import { adFiltersLabels } from 'src/core/constants'
import { AdFilterField, SelectDropdown } from 'SRC/ui/FormElementsNew'

export class Condition extends React.Component {
  getConditionField = () => {
    const { formik } = this.props

    return {
      id: 'condition',
      title: adFiltersLabels['condition'],
      options: [
        {
          id: 'condition-new',
          label: 'Novo',
          value: 'new'
        },
        {
          id: 'condition-used',
          label: 'Polovno',
          value: 'used'
        }
      ],
      onChange: this.onConditionChange,
      value: formik.values.condition
    }
  }

  onConditionChange = value => {
    const { formik } = this.props

    if (Number(value) === -1) formik.setFieldValue('condition', -1)
    else formik.setFieldValue('condition', value)
  }

  render () {
    return <AdFilterField>
      <SelectDropdown {...this.getConditionField()} />
    </AdFilterField>
  }
}

Condition.propTypes = {
  formik: PropTypes.shape({
    values: PropTypes.object,
    setFieldValue: PropTypes.func
  }).isRequired
}

export default formikConnect(Condition)
