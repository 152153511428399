export const extractSimilarWords = categories => {
  let similarWords = []

  const categoriesWithSimilarWords = categories.filter(c => c.similarWords && c.similarWords.length)

  for (let category of categoriesWithSimilarWords) {
    const categorySimilarWords = Array.isArray(category.similarWords)
      ? category.similarWords
      : JSON.parse(category.similarWords)
    categorySimilarWords.push(category.title)

    similarWords = similarWords.concat(categorySimilarWords.map(word => ([word, category.id])))
  }

  return similarWords
}

export const getSpecificationVisibilityCondition = specification => {
  const visibilityCondition = {
    show: null,
    hide: null
  }

  let specDependents = specification && Array.isArray(specification.dependents) ? specification.dependents : []
  if (specDependents.length) {
    specDependents = specDependents.filter(specDependent => specDependent.dependent)

    if (specDependents.length) {
      specDependents.forEach(specDependent => {
        if (['hide', 'show'].includes(specDependent.dependentType) && Array.isArray(specDependent.dependentValues)) {
          if (!visibilityCondition[specDependent.dependentType]) {
            visibilityCondition[specDependent.dependentType] = {
              [specDependent.dependent]: specDependent.dependentValues
            }
          } else {
            visibilityCondition[specDependent.dependentType][specDependent.dependent] = specDependent.dependentValues
          }
        }
      })
    }
  }

  return visibilityCondition
}

export const getIsSelfConainedCategory = parentCategory => {
  if (parentCategory && Array.isArray(parentCategory.groups) && parentCategory.groups.length === 1) {
    const group = parentCategory.groups[0]

    if (group && Array.isArray(group.categories) && group.categories.length === 1) {
      const category = group.categories[0]

      if (category && parentCategory.seo === group.seo && group.seo === category.seo) return true
    }
  }

  return false
}
