import React from 'react'
import PropTypes from 'prop-types'
import { connect as formikConnect } from 'formik'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import { ServiceTypesApi } from 'SRC/modules/ads/serviceTypes/api'
import { getCurrentSubCategory } from 'SRC/modules/categories/selectors'
import { AdFilterField, SelectDropdown } from 'SRC/ui/FormElementsNew'
import { adFiltersLabels } from 'SRC/core/constants'

class Services extends React.Component {
  constructor (props) {
    super(props)

    this.serviceTypesApi = new ServiceTypesApi()

    this.state = {
      serviceTypes: {},
      isServiceTypesLoading: false
    }
  }

  /**
   * Services
   */
  getServiceField = () => {
    const { formik, services } = this.props

    return {
      id: 'service',
      isDisabled: !services || !services.length,
      title: adFiltersLabels.service,
      options: this.getServiceOptions(),
      value: formik.values.service,
      onChange: this.onServiceChange
    }
  }

  getServiceOptions = () => {
    return this.props.services.map(service => ({
      id: service.id,
      label: service.name,
      value: service.id
    }))
  }

  onServiceChange = value => {
    const { formik, services } = this.props

    const service = Number(value)

    const findService = (services || []).find(sr => sr.id === service)

    if (findService) {
      formik.setFieldValue('serviceText', findService.name)
      formik.setFieldValue('serviceBc', { seo: findService.seo, text:findService.name })
    } else {
      formik.setFieldValue('serviceText', '')
      formik.setFieldValue('serviceBc', {})
    }

    formik.setFieldValue('service', service)
    formik.setFieldValue('serviceType', -1)
  }

  /**
   * Services Types
   */
  getServiceTypeField = () => {
    const { formik } = this.props
    const { serviceTypes, isServiceTypesLoading } = this.state

    const service = formik.values.service

    if (service !== -1 && !serviceTypes[service] && !isServiceTypesLoading) {
      this.getServiceTypes(service)
    }

    const isDisabled = service === -1 || !serviceTypes[service] || !serviceTypes[service].length || isServiceTypesLoading

    return {
      id: 'serviceType',
      isDisabled,
      title: adFiltersLabels.serviceType,
      options: this.getServiceTypesOptions(),
      value: formik.values.serviceType,
      onChange: this.onServiceTypeChange
    }
  }

  getServiceTypes = async service => {
    await this.setState({ isServiceTypesLoading: true })

    if (!this.state.serviceTypes[service]) {
      const serviceTypes = await this.serviceTypesApi.fetchServiceTypesByService(Number(service))

      this.setState({
        serviceTypes: {
          ...this.state.serviceTypes,
          [service]: serviceTypes && serviceTypes.length ? serviceTypes : []
        }
      })
    }

    this.setState({ isServiceTypesLoading: false })
  }

  getServiceTypesOptions = () => {
    const { formik } = this.props
    const { serviceTypes } = this.state

    const service = formik.values.service

    if (service && serviceTypes[service]) {
      return serviceTypes[service].map(serviceType => ({
        id: serviceType.id,
        label: serviceType.name,
        value: serviceType.id
      }))
    }
    return []
  }

  onServiceTypeChange = (value) => {
    const { formik } = this.props
    const { serviceTypes } = this.state

    const serviceType = Number(value)

    const findServiceType = ((serviceTypes && serviceTypes[formik.values.service]) || []).find(srt => srt.id === serviceType)

    if (findServiceType) {
      formik.setFieldValue('serviceTypeText', findServiceType.name)
      formik.setFieldValue('serviceTypeBc', { seo: findServiceType.seo, text: findServiceType.name })
    } else {
      formik.setFieldValue('serviceTypeText', '')
      formik.setFieldValue('serviceTypeBc', {})
    }

    formik.setFieldValue('serviceType', serviceType)
  }

  render () {
    const serviceField = this.getServiceField()
    const serviceTypeField = this.getServiceTypeField()

    return <React.Fragment>
      <AdFilterField>
        <SelectDropdown {...serviceField} />
      </AdFilterField>

      <AdFilterField>
        <SelectDropdown {...serviceTypeField} />
      </AdFilterField>
    </React.Fragment>
  }
}

Services.propTypes = {
  category: PropTypes.shape({
    id: PropTypes.number
  }).isRequired,
  services: PropTypes.array,
  formik: PropTypes.shape({
    values: PropTypes.object,
    setFieldValue: PropTypes.func
  }).isRequired
}

const mapStateToProps = state => {
  const currentSubCategory = getCurrentSubCategory(state)

  return {
    services: currentSubCategory && Array.isArray(currentSubCategory.services) ? currentSubCategory.services : []
  }
}

const enhance = compose(
  connect(mapStateToProps),
  formikConnect
)

Services.defaultProps = {
  services: []
}

export default enhance(Services)
