import { CategoriesApi } from '../../api'
import { setCategoriesWithGroups } from '../setCategoriesWithGroups'

export const fetchCategoriesWithGroups = () => {
  return async (dispatch, getState) => {
    let categories = getState().categories.withGroups

    if (!categories || !categories.length) {
      const api = new CategoriesApi()
      categories = await api.fetchCategoriesWithGroups()
    }

    dispatch(setCategoriesWithGroups(categories))
  }
}
