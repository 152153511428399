import { CategoriesApi } from '../../api'
import { setCategories } from '../setCategories'

export const fetchCategories = () => {
  return async (dispatch, getState) => {
    let categories = getState().categories.elements
    if (!categories || !categories.length) {
      const api = new CategoriesApi()
      categories = await api.fetchCategories()
    }

    dispatch(setCategories(categories.map(category => {
      const categoryData = {
        id: category.id,
        title: category.title,
        iconSvg: category.iconSvg,
        weight: category.weight,
        seo: category.seo
      }

      if (Array.isArray(category.children)) {
        if (category.children.length === 1 && category.seo === category.children[0].seo) {
          categoryData.isSelfContainedParentCategory = true
        } else {
          categoryData.isParentCategory = true
        }

        categoryData.children = category.children.map(subCategory => ({
          id: subCategory.id,
          title: subCategory.title,
          iconSvg: subCategory.iconSvg,
          weight: subCategory.weight,
          seo: subCategory.seo,
          requiredAdOptions: subCategory.requiredAdOptions,
          similarWords: subCategory.similarWords || [],
          searchSpecifications: subCategory.searchSpecifications || [],
          swappPresence: subCategory.swappPresence,
          swappSpecification1: subCategory.swappSpecification1,
				  swappSpecification2: subCategory.swappSpecification2
        }))
      }

      return categoryData
    })))
  }
}
