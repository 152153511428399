import PropTypes from 'prop-types'
import React from 'react'
import { LinksCommon } from '../../../components'
import { withRouter } from 'next/router'

export class TopLinks extends React.Component {
  render () {
    return (
      <div className='top-nav'>
        <LinksCommon router={this.props.router} />
      </div>
    )
  }
}

TopLinks.propTypes = {
  router: PropTypes.shape()
}

export default withRouter(TopLinks)
