import React from 'react'
import PropTypes from 'prop-types'
import Link from 'next/link'
import { SectionHeader } from '../SectionHeader'
import config from 'SRC/config/config.yaml'

export const SubCategories = ({
  items,
  parentCategoryTitle,
  parentCategorySeo,
  baseAs,
  baseHref,
  onBackBtnClick,
  isHidden
}) => {
  return (
    <section className='all-categories-with-letters-section' style={{ display: isHidden ? 'none' : 'block' }}>
      <SectionHeader title={parentCategoryTitle} />
      <button onClick={onBackBtnClick}>
        Nazad na glavne kategorije
        <i className='ico-nazad ico' style={{ fontSize: 20, marginLeft: 12 }} />
      </button>
      <section className='subcategories-items'>
        {items.map(item => {
          let hrefUrl = { pathname: baseHref }
          let asUrl = baseAs

          if (parentCategorySeo && baseHref !== '/addAd') {
            let isSelfContainedParentCategory = false

            if (item.seo && item.seo === parentCategorySeo) isSelfContainedParentCategory = true

            if (!isSelfContainedParentCategory) {
              hrefUrl.query = { category: item.seo, parentCategory: parentCategorySeo }
              asUrl += `${parentCategorySeo}/${item.seo}`
            } else {
              hrefUrl.query = { parentCategory: parentCategorySeo }

              asUrl += `${parentCategorySeo}`
            }
          } else {
            hrefUrl.query = { category: item.seo }

            asUrl += `${item.seo}`
          }

          console.log('ItemhrefUrl', hrefUrl)
          console.log('ItemasUrl', asUrl)

          return (
            <Link
              key={item.seo}
              href={hrefUrl}
              as={asUrl}
            >
              <a className='subcategories-item'>
                <img src={`${config.mediaCDN}${item.iconSvg}`} alt={item.title} />
                <p>{item.title}</p>
              </a>
            </Link>
          )
        })}
      </section>
    </section>
  )
}

SubCategories.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    seo: PropTypes.string,
    title: PropTypes.string,
    iconSvg: PropTypes
  })).isRequired,
  parentCategoryTitle: PropTypes.string.isRequired,
  parentCategorySeo: PropTypes.string.isRequired,
  baseAs: PropTypes.string,
  baseHref: PropTypes.string,
  isHidden: PropTypes.bool.isRequired,
  onBackBtnClick: PropTypes.func.isRequired
}

SubCategories.defaultProps = {
  items: [],
  isHidden: false
}
