import { setSavedFilters, setSavedFiltersLoading } from '../../actions'
import { setProfilePaginationParams } from '../../pagination/actions'
import { ProfileApi } from '../../api'
import { globalOptions } from 'SRC/core/constants'

export const fetchSavedFilters = (userId, token, page, paginationParams = {}) => {
  return async (dispatch, getState) => {
    dispatch(setSavedFiltersLoading(true))
    const api = new ProfileApi()
    const {savedFilters, count} = await api.getSavedFilters(userId, token, page, globalOptions.savedFiltersCountPerPage)

    const categories = getState().categories.elements

    if (Array.isArray(savedFilters)) {
      dispatch(setSavedFilters(savedFilters.map(savedFilter => {
        if (savedFilter.parentCategory) {
          const parentCategory = Array.isArray(categories) ? categories.find(c => c.id === Number(savedFilter.parentCategory)) : null
          if (parentCategory) {
            return {
              ...savedFilter,
              parentCategory
            }
          }
        }

        return savedFilter
      })))
    } else {
      dispatch(setSavedFilters([]))
    }

    const pagination = {...paginationParams, currentPage: page, count}
    dispatch(setProfilePaginationParams(pagination))
    dispatch(setSavedFiltersLoading(false))
  }
}
