/* eslint-disable react/no-did-update-set-state */
import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import {
  getFavouritePublishedAds,
  getFavouriteExpiredAds,
  getFavouriteSoldAds
} from '../../../../../users/Profile/selectors/getFavouriteAds'
import { updateFavouriteAds } from '../../../../../users/Profile/actions'
import { CookiesHelper } from 'Core/cookiesHelper/CookiesHelper'
import { showPopupLogin } from 'src/modules/common/actions/setPopupLoginVisibility'
import { fetchFavouriteAds } from 'SRC/modules/users/Profile/actions'

export class AddToFavorites extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      isFavourite: false
    }
  }

  componentDidMount () {
    let favouriteAdsIds = []
    switch (this.props.adStatus) {
      case 'Published':
        favouriteAdsIds = this.props.favouritePublishedAds.map(ad => ad.id)
        break
      case 'Expired':
        favouriteAdsIds = this.props.favouriteExpiredAds.map(ad => ad.id)
        break
      case 'Sold':
        favouriteAdsIds = this.props.favouriteSoldAds.map(ad => ad.id)
        break
      default: break
    }
    if (favouriteAdsIds.includes(this.props.id)) this.setState({ isFavourite: true })
  }

  componentDidUpdate (prevProps) {
    // Update isFavourite for published ads
    if (!prevProps.favouritePublishedAds.length && this.props.favouritePublishedAds.length) {
      let favouriteAdsIds = this.props.favouritePublishedAds.map(ad => ad.id)
      if (favouriteAdsIds.includes(this.props.id)) this.setState({ isFavourite: true })
    }
  }

  handleClick = async () => {
    const cookie = new CookiesHelper()
    const userId = cookie.get('userId')
    const token = cookie.get('token')
    if (userId && token) {
      const add = !this.state.isFavourite
      const result = await this.props.updateFavouriteAds(this.props.id, userId, token, add, this.props.adStatus)
      if (result && result.status === 0) {
        this.setState({ isFavourite: !this.state.isFavourite })
        this.props.fetchFavouriteAds(userId, token, 'Published', 1, 100)
      }
    } else {
      this.props.showPopupLogin()
    }
  }

  render () {
    return (
      <div className='akcija-sacuvaj'>
        <a
          href='javascript:void(0)'
          className={classNames({'active': this.state.isFavourite})}
          onClick={this.handleClick}
        >
          <span className={classNames(['heart', this.state.isFavourite && 'active'])}>
            <i className='ico-sacuvaj-oglas ico' />
          </span>
          {this.state.isFavourite ? 'Sačuvan' : 'Sačuvaj'}
        </a>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    favouritePublishedAds: getFavouritePublishedAds(state),
    favouriteExpiredAds: getFavouriteExpiredAds(state),
    favouriteSoldAds: getFavouriteSoldAds(state)
  }
}

AddToFavorites.propTypes = {
  id: PropTypes.number.isRequired,
  favouritePublishedAds: PropTypes.array,
  favouriteExpiredAds: PropTypes.array,
  favouriteSoldAds: PropTypes.array,
  updateFavouriteAds: PropTypes.func,
  fetchFavouriteAds: PropTypes.func.isRequired,
  adStatus: PropTypes.string,
  showPopupLogin: PropTypes.func.isRequired
}

export default connect(mapStateToProps, { updateFavouriteAds, showPopupLogin, fetchFavouriteAds })(AddToFavorites)
