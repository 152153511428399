import PropTypes from 'prop-types'
import Link from 'next/link'
import React from 'react'
import { isObjectContainedAnotherObject } from 'SRC/utils/Utils'

export const Item = ({id, icoClass, text, children, className, link, targetBlank, as, pages, query, isActive, is404}) => {
  const linkHtml = <a className='main-menu-btn' target={targetBlank ? '_blank' : '_self'}>
    {icoClass !== '' ? <i className={`${icoClass} ico`} /> : ''}
    {text}
    {children ? <i className='ico-gavni-meni-dropdown-strelica ico ico-sm' /> : ''}
  </a>
  const activeClass = !is404 && isActive ? `active` : ``

  const itemClassName = `${className}${activeClass ? ` ${activeClass}` : ''}${id ? ` ${id}` : ''}`

  return (
    <li className={itemClassName.trim()}>
      {
        (link && link !== '' && link !== '#')
          ? <Link href={link} as={as}>
            {linkHtml}
          </Link>
          : linkHtml
      }
      {children}
    </li>
  )
}

Item.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  icoClass: PropTypes.string,
  link: PropTypes.string,
  as: PropTypes.string,
  pages: PropTypes.any,
  text: PropTypes.string,
  isActive: PropTypes.bool,
  query: PropTypes.shape(),
  is404: PropTypes.bool.isRequired,
  targetBlank: PropTypes.bool
}

Item.defaultProps = {
  is404: false
}
