import { BaseApi } from 'Core/api/BaseApi'

export class Api extends BaseApi {
  updateBoughtItem = async payload => {
    const { userId, token, item, status } = payload

    if (userId && token && item && status) {
      try {
        const { body } = await Api.post('/boughtitems/update', payload)
        const error = body.error
        if (error || !body.item) {
          return {status: Api.responseTypes.ERROR, error}
        } else {
          return { status: Api.responseTypes.OK, item: body.item }
        }
      } catch (e) {
        const result = {
          status: Api.responseTypes.ERROR
        }

        return result
      }
    }
  }

  getBoughtItems = async payload => {
    const defaultResult = []

    try {
      const { body } = await Api.post('/boughtitems/list', payload)

      const result = body

      return body.error ? defaultResult : result
    } catch (e) {
      return defaultResult
    }
  }
}
