import React from 'react'
import PropTypes from 'prop-types'
import Link from 'next/link'
import { getIsSelfConainedCategory } from 'SRC/modules/categories/functions'
import { SectionHeader } from '../SectionHeader'
import config from 'SRC/config/config.yaml'

export const ParentCategories = ({ items, onClick, isHidden, baseAs, baseHref }) => (
  <section className='all-categories-with-letters-section' style={{ display: isHidden ? 'none' : 'block' }}>
    <SectionHeader title='GLAVNE KATEGORIJE' />
    <section className='parent-categories-items'>
      {items.map(item => {
        const isSelfContainedParentCategory = getIsSelfConainedCategory(item)

        if (!isSelfContainedParentCategory) {
          return (
            <div key={item.seo} className='parent-categories-item' onClick={() => onClick(item)}>
              <img src={`${config.mediaCDN}${item.iconSvg}`} alt={item.title} />
              <p>{item.title}</p>
            </div>
          )
        }

        let hrefUrl = { pathname: baseHref }
        let asUrl = baseAs

        hrefUrl.query = { category: item.seo }
        asUrl += `${item.seo}`

        return (
          <Link
            key={item.seo}
            href={hrefUrl}
            as={asUrl}
          >
            <a className='parent-categories-item'>
              <img src={`${config.mediaCDN}${item.iconSvg}`} alt={item.title} />
              <p>{item.title}</p>
            </a>
          </Link>
        )
      })}
    </section>
  </section>
)

ParentCategories.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    seo: PropTypes.string,
    title: PropTypes.string,
    iconSvg: PropTypes
  })).isRequired,
  baseAs: PropTypes.string,
  baseHref: PropTypes.string,
  isHidden: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired
}

ParentCategories.defaultProps = {
  items: [],
  isHidden: false
}
