import React from 'react'
import PropTypes from 'prop-types'

export const Twitter = ({ baseUrl, asPath, adLink }) => {
  const link = baseUrl ? baseUrl + '/' + adLink : adLink

  return (
    <div className='kontakt-icon kontakt-twitter'>
      <a
        rel='noreferrer'
        target='_blank'
        href={
          adLink ? `https://twitter.com/intent/tweet?text=${link}`
            : `https://twitter.com/intent/tweet?text=${baseUrl}${asPath}`}>
        <i className='ico-share-twitter ico' />
      </a>
    </div>
  )
}

Twitter.propTypes = {
  baseUrl: PropTypes.string.isRequired,
  asPath: PropTypes.string.isRequred,
  adLink: PropTypes.string.isRequred
}
