import PropTypes from 'prop-types'
import React from 'react'
import { Notification } from 'react-notification'

export const ErrorNotification = ({error, hideNotification}) =>
  <Notification
    isActive={Boolean(error)}
    message={error || ''}
    action={'Sakrij'}
    onClick={hideNotification}
  />

ErrorNotification.propTypes = {
  error: PropTypes.string,
  hideNotification: PropTypes.func.isRequired
}

ErrorNotification.defaultProps = {
  error: ''
}
