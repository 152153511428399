import PropTypes from 'prop-types'
import React from 'react'
import { debounce } from 'throttle-debounce'
import { connect } from 'react-redux'
import { getCategoriesWithGroups } from 'SRC/modules/categories/selectors'
import { getCities } from 'SRC/modules/geoObjects/cities/selectors'
import { getIsSelfConainedCategory } from 'SRC/modules/categories/functions'
import Router from 'next/router'
import { getSearchableArray, search } from 'SRC/modules/categories/components/AllCategoriesWithLettersPage/helpers'
import config from 'SRC/config/config.yaml'

export class SearchInputWithSuggestions extends React.Component {
  constructor (props) {
    super(props)

    this.inputRef = React.createRef()
    this.dropdownRef = React.createRef()

    this.state = {
      textValue: '',
      filteredCategories: null,
      showSuggestions: false
    }
  }

  componentDidMount () {
    const { categoriesWithGroups, cities } = this.props
    console.log('cities', cities)

    this.searchableArray = getSearchableArray(categoriesWithGroups, {
      cities
    })

    console.log('searchableArray', this.searchableArray)

    document.addEventListener('mousedown', this.toggleOptionsVisibility)
  }

  componentWillUnmount () {
    document.removeEventListener('mousedown', this.toggleOptionsVisibility)
  }

  toggleOptionsVisibility = (e) => {
    let showSuggestions = false

    if (this.inputRef.current && this.inputRef.current.contains(e.target)) {
      showSuggestions = true
    }

    if (this.dropdownRef.current && this.dropdownRef.current.contains(e.target)) {
      showSuggestions = true
    }

    this.setState({
      showSuggestions
    })
  }

  submit = (options) => {
    const { categoriesWithGroups } = this.props
    const { textValue } = this.state

    let as, href

    if (!options) {
      if (!textValue || textValue.length < 3) return

      as = `/pretraga?fullText=${textValue}&sortBy=dateDesc`
      href = {
        pathname: '/search',
        query: {
          fullText: textValue,
          sortBy: 'dateDesc'
        }
      }

      return Router.push(href, as)
    }

    const { category, parentCategory, specification, country, city, cities } = options

    const baseAs = '/'
    const baseHref = {
      pathname: specification || (country && city && Array.isArray(cities)) ? '/search' : '/category'
    }

    href = { ...baseHref, query: {} }
    as = baseAs

    let isSelfContainedParentCategory = false

    if (category === parentCategory) {
      const findParentCategory = Array.isArray(categoriesWithGroups)
        ? categoriesWithGroups.find(cat => cat.seo === parentCategory)
        : null

      if (findParentCategory && getIsSelfConainedCategory(findParentCategory)) {
        isSelfContainedParentCategory = true
      }
    }

    if (!isSelfContainedParentCategory) {
      href.query = {
        category,
        parentCategory
      }

      as += `${parentCategory}/${category}`
    } else {
      href.query = {
        parentCategory
      }

      as += `${parentCategory}`
    }

    if (specification) {
      href.query = {
        ...href.query,
        formStyle: 'basic',
        pageNumber: 1,
        specifications: JSON.stringify([specification]),
        sortBy: 'dateDesc'

      }

      as += `/pretraga?pageNumber=1&formStyle=basic&sortBy=dateDesc&specifications=${JSON.stringify([specification])}`
    }

    if (country && city && Array.isArray(cities)) {
      href.query = {
        ...href.query,
        formStyle: href.query.formStyle || 'basic',
        country,
        city,
        cities: JSON.stringify(cities),
        pageNumber: 1,
        sortBy: 'dateDesc'
      }

      if (as.indexOf('pretraga') !== -1) {
        as += `&country=${country}&city=${city}&cities=${JSON.stringify(cities)}`
      } else {
        as += `/pretraga?pageNumber=1&formStyle=${href.query.formStyle || 'basic'}&sortBy=dateDesc&country=${country}&city=${city}&cities=${JSON.stringify(cities)}`
      }
    }

    Router.push(href, as)
  }

  onTextSearch = debounce(250, () => {
    const { textValue } = this.state

    const filteredCategories = textValue === '' ? null : search(this.searchableArray, textValue)

    this.setState({
      filteredCategories
    })
  })

  suggestionClicked = item => {
    console.log('suggestionClicked item', item)

    if (item.parentCategory && item.parentCategory.isSelfContainedParentCategory) {
      const params = { category: item.seo, parentCategory: item.seo }

      if (item.specification) {
        params.specification = item.specification
      }

      if (item.city) {
        params.country = 1
        params.city = item.city.id
        params.cities = [item.city.id]
      }

      this.submit(params)
    } else {
      const params = { category: item.seo, parentCategory: item.parentCategory.seo }

      if (item.specification) {
        params.specification = item.specification
      }

      if (item.city) {
        params.country = 1
        params.city = item.city.id
        params.cities = [item.city.id]
      }

      this.submit(params)
    }

    this.setState({ showSuggestions: false })
  }

  renderSuggestions = () => {
    const { filteredCategories } = this.state

    if (Array.isArray(filteredCategories) && filteredCategories.length) {
      return (
        <section className='categories-suggestions' ref={this.dropdownRef}>
          {filteredCategories.map(({ item }, index) => {
            const suggestionCategory = item[1]
            const suggestionItems = item[2]

            if (Array.isArray(suggestionItems) && suggestionCategory) {
              return (
                <ul
                  key={`suggestion-${suggestionCategory.sId}`}
                  className='categories-suggestions-container'
                  style={{ margin: '.4rem' }}
                  onClick={() => this.suggestionClicked(suggestionCategory)}
                >
                  {suggestionItems.map((item, index) => ([
                    <li key={`suggestion-item-${suggestionCategory.sId}-${item}`}>{this.renderSuggestionItem(item)}</li>,
                    index !== suggestionItems.length - 1 ? <li key={`suggestion-separator-${item.id}`}> &gt; </li> : null
                  ]))}
                </ul>
              )
            }

            return null
          })}
        </section>
      )
    }

    return null
  }

  renderSuggestionItem = (item) => {
    return (
      <a href='javascript:void(0)'>
        {item}
      </a>
    )
  }

  render () {
    const placeholder = config.search.textSearchPlaceholder
    const { textValue, showSuggestions } = this.state

    return (
      <form onSubmit={e => { e.preventDefault(); this.submit() }} className='all-categories-with-letters'>
        <input
          ref={this.inputRef}
          type='text'
          placeholder={placeholder}
          onClick={() => { this.setState({ showSuggestions: true }) }}
          onChange={e => {
            const value = e.target.value

            this.setState({ textValue: value }, this.onTextSearch)
          }}
          defaultValue={textValue}
          value={textValue} maxLength={50}
        />
        {textValue && textValue.length >= 3 && showSuggestions ? this.renderSuggestions() : null}
        <button className='ads-search__button'>
          <i className='ogl-header-ico ico-pretraga-text-search-lupa ico' />
        </button>
      </form>
    )
  }
}

SearchInputWithSuggestions.propTypes = {
  categoriesWithGroups: PropTypes.array.isRequired,
  cities: PropTypes.array.isRequired
}

SearchInputWithSuggestions.defaultProps = {
  baseAs: '/',
  categoriesWithGroups: [],
  cities: [],
  baseHrefQuery: {}
}

export default connect(state => ({
  categoriesWithGroups: getCategoriesWithGroups(state),
  cities: getCities(state),
  filterValues: {}
}))(SearchInputWithSuggestions)
