import React from 'react'
import PropTypes from 'prop-types'

const getStringValue = val => val ? val.toString() : ''

export const Label = ({ options, checkedItems, labelDefault }) => {
  let label = labelDefault || 'Izaberi'

  if (!checkedItems.length) return <label>{label}</label>

  const labelItems = []

  options.forEach(option => {
    if (option.isGrouped) {
      option.options.forEach(childItem => {
        if (checkedItems.includes(getStringValue(childItem.value))) {
          if (!labelItems.includes(getStringValue(childItem.label))) labelItems.push(getStringValue(childItem.label))
        }
      })

      if (option.subGroups) {
        option.subGroups.forEach(subGroup => {
          if (Array.isArray(subGroup.options)) {
            subGroup.options.forEach(subGroupOption => {
              if (checkedItems.includes(getStringValue(subGroupOption.value))) {
                if (!labelItems.includes(getStringValue(subGroupOption.label))) {
                  labelItems.push(getStringValue(subGroupOption.label))
                }
              }
            })
          }
        })
      }
    } else {
      if (checkedItems.includes(getStringValue(option.value))) {
        if (!labelItems.includes(getStringValue(option.label))) labelItems.push(getStringValue(option.label))
      }
    }
  })

  if (labelItems.length && labelItems.length > 3) {
    label = `Odabrano: ${labelItems.length}`
  }

  if (labelItems.length && labelItems.length <= 3) {
    label = labelItems.slice(0, 3).join(', ')

    if (label.length > 25) label = `Odabrano: ${labelItems.length}`
  }

  return <label className={`${label !== 'Izaberi' ? 'select-dropdown-selected' : ''}`}>{label}</label>
}

Label.propTypes = {
  labelDefault: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.any,
    label: PropTypes.string,
    value: PropTypes.any,
    isGrouped: PropTypes.bool,
    options: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.any,
      label: PropTypes.string,
      value: PropTypes.any
    }))
  })).isRequired,
  checkedItems: PropTypes.array
}
