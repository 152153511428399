import React from 'react'

export const ConsumerRights = () => (
  <div className='novosti-text'>

		<p><strong>Prava potrošača prilikom kupovine preko interneta ili kupovine na daljinu proizvoda od firme WebLab d.o.o. i brenda AutoEkspert.me</strong></p>

    <p>Obavještavamo Vas da se prema Zakonu о zaštiti potrošača („Službeni list Crne Gore“, br. 070/17 od 27.10.2017), u daljem tekstu: Zakon, kupovina preko našeg internet servisa i prodavnice auto djelova AutoEkspert.me smatra prodajom na daljinu. Ova pravila se odnose isključivo na artikle koje je prodala naša firma WebLab d.o.o. putem našeg brenda i prodaje na daljinu AutoEkspert.me.
		</p>

		<br />
		<p><strong>Račun i fiskalni račun</strong></p>

		<p>Trgovac je dužan da potrošaču za kupljeni proizvod izda jasan, razumljiv, lako čitljiv račun na crnogorskom jeziku, u skladu sa zakonom. To znači da ćemo Vam prilikom kupovine izdati fiskalni račun firme WebLab d.o.o. (AutoEkspert.me) i sve potrebne dokumente koji prate proizvod, uključujući garantne listove i uputstva za upotrebu.</p>

		<p><strong>Pravo na odustanak od ugovora</strong></p>

		<p>Prema Zakonu, imate pravo da odustanete od ugovora zaključenog na daljinu u roku od 14 dana od dana kada Vam je proizvod isporučen. Prilikom odustanka od ugovora, niste dužni da navedete razloge odustajanja. Obrazac/Izjava o odustanku od ugovora proizvodi pravno dejstvo od dana kada je poslana trgovcu. U slučaju odustanka, imate pravo na povraćaj novca ili zamjenu za drugi proizvod. Povraćaj novca će biti izvršen po prijemu vraćenog proizvoda, nakon što se utvrdi da je proizvod neoštećen i ispravan.</p>
		
		<br />
		<p><strong>Troškovi vraćanja proizvoda</strong></p>

		<p>Troškove povraćaja robe, kao i organizaciju transporta u slučaju odustanka, snosi kupac. Moguće je vratiti samo proizvode koji su neoštećeni i, po mogućnosti, u originalnoj ambalaži, sa svim dodacima i propratnom dokumentacijom (garantni list, uputstva, itd.). Po prijemu proizvoda, utvrdiće se da li je proizvod ispravan i neoštećen. Kupac je odgovoran za neispravnost ili oštećenje proizvoda koji su rezultat neadekvatnog rukovanja.</p>
		
		<br />
		<p><strong>Reklamacije</strong></p>

		<p>Proizvodi kupljeni putem naše internet prodavnice AutoEkspert.me i fakturisane od strane firme WebLab d.o.o. prate zakonski neophodnu dokumentaciju uključujući i garantne listove proizvođača. Ukoliko se pojavi potreba za reklamacijom, ispunjavamo sve zakonski definisane obaveze za prodavca. Reklamacioni postupak se pokreće na zahtjev kupca, popunjavanjem Zahtjeva za reklamaciju na kvalitet proizvoda. Kontaktirajte nas na email info@weblab.group ili telefon +382 30 550 099 kako bismo Vam poslali ovaj zahtjev ili ga preuzmite <a href='https://oglasi.me/documents/Obrazac_1__raskid_ugovora_online_prodaja.pdf'>ovdje</a></p>

		<br />
		<p><strong>Ostali proizvodi koji su kupljeni putem portala i aplikacije Oglasi.me</strong></p>

		<p>Za sve ostale artikle koji su prodati putem oglasa na portalu Oglasi.me, WebLab d.o.o. ne snosi odgovornost. Oglasi postavljeni na Oglasi.me predstavljaju dogovor između korisnika koji je postavio oglas (fizičko ili pravno lice) i kupca. Sve transakcije i kupoprodajni ugovori između korisnika portala su u nadležnosti samih korisnika. Za detalje o ovim transakcijama, molimo Vas da se obratite <a href='https://oglasi.me/uslovi-koriscenja'>Pravilima i Uslovima Korišćenja</a> Portala i Aplikacija Oglasi.me.</p>

		<p><strong>Kontakt informacije</strong></p>
		<p style={{ marginTop: 0 }}>Sjedište firme:</p>
		<p style={{ marginTop: 0 }}>WebLab d.o.o.</p>
		<p style={{ marginTop: 0 }}>Iva Novakovića b.b.</p>
		<p style={{ marginTop: 0 }}>Sutomore 85355, Crna Gora</p>

		<br />

		<p><strong>Kancelarija za prijem klijenata i pošte:</strong></p>
		<p style={{ marginTop: 0 }}>WebLab d.o.o.</p>
		<p style={{ marginTop: 0 }}>Jovana Tomaševića 1,</p>
		<p style={{ marginTop: 0 }}>Bar 85000, Crna Gora</p>

		<br />

		<p><strong>Kontakt telefon:</strong></p>
		<p style={{ marginTop: 0 }}>+382 30 550 099</p>

		<br />

		<p><strong>Kontakt email:</strong></p>
		<p style={{ marginTop: 0 }}>info@weblab.group</p>
  </div>
)
