import React from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { withRouter } from 'next/router'
import PropTypes from 'prop-types'
import { getIsAuthorized } from 'SRC/modules/users/Auth/selectors'
import { get404, getIsMobileDevice } from 'SRC/modules/common/selectors'
import { getInfo } from 'SRC/modules/users/detail/selectors'
import { Default } from '../Default'
import { Profile } from '../Profile'
import { AdDetail } from '../AdDetail'
import { UserDetail } from '../UserDetail'
import { AddAd } from '../AddAd'
import { AdsList } from '../AdsList'

export class Router extends React.Component {
  getComponent = () => {
    const { router, is404, isAuthorized, isMobileDevice } = this.props

    if (is404) return Default

    switch (router.pathname) {
      case '/ad':
        return AdDetail
      case '/addAd':
        return !isMobileDevice ? AddAd : null
      case '/':
      case '/categories':
      case '/users':
        return !isMobileDevice ? AdsList : null
      case '/user':
        return UserDetail
      case '/search':
        const { userInfo } = this.props

        return userInfo ? UserDetail : Default
      case '/profile':
      case '/editAd':
      case '/activatePromo':
        return !isMobileDevice
          ? isAuthorized 
            ? Profile
            : Default
          : null
      default:
        return !isMobileDevice ? AdsList : null
    }
  }

  render () {
    const Sidebar = this.getComponent()

    return Sidebar ? <Sidebar /> : null
  }
}

const mapStateToProps = state => ({
  isAuthorized: getIsAuthorized(state),
  is404: get404(state),
  userInfo: getInfo(state),
  isMobileDevice: getIsMobileDevice(state)
})

Router.propTypes = {
  router: PropTypes.object,
  isAuthorized: PropTypes.bool.isRequired,
  is404: PropTypes.bool.isRequired,
  userInfo: PropTypes.shape(),
  isMobileDevice: PropTypes.string.isRequired
}

Router.defaultProps = {
  isAuthorized: false,
  is404: false
}

export default compose(
  withRouter,
  connect(mapStateToProps)
)(Router)
