import PropTypes from 'prop-types'
import React from 'react'
import { Item } from '../Item'
import { itemsBeforeUserTypes, itemsAfterUserTypes } from '../data/headerMenuItems'
import { get404, getUserTypes } from 'SRC/modules/common/selectors'
import { connect } from 'react-redux'
import { withRouter } from 'next/router'
import { compose } from 'recompose'

export const getUserTypeItems = (userTypes, options = {}) => {
  const isMobile = options.isMobile

  const items = []

  /**
   * Web prodavnice
   */
  const webShops = userTypes.find(userType => userType.seo === 'web-prodavnica')

  if (webShops) {
    items.push({
      id: webShops.seo,
      as: `/${webShops.seoPlu}`,
      link: `/users?parentUserType=${webShops.seo}`,
      text: 'Web prodavnice',
      icoClass: '',
      className: '',
      iconSvg: webShops.iconSvg,
      pages: ['/users', '/user', '/search'],
      query: {parentUserType: webShops.seo},
      child: null
    })
  }

  /**
   * Car shops
   */
  const carShops = userTypes.find(userType => userType.seo === 'auto-moto-nautika')

  if (carShops) {
    items.push({
      id: carShops.seo,
      as: `/${carShops.seoPlu}`,
      link: `/users?parentUserType=${carShops.seo}`,
      text: 'Prodavci vozila',
      icoClass: '',
      className: '',
      iconSvg: carShops.iconSvg,
      pages: ['/users', '/user', '/search'],
      query: { parentUserType: carShops.seo },
      child: null
    })
  }

  /**
   * Real estate
   */
  const realEstateShops = userTypes.find(userType => userType.seo === 'nekretnine')

  if (realEstateShops) {
    items.push({
      id: realEstateShops.seo,
      as: `/${realEstateShops.seoPlu}`,
      link: `/users?parentUserType=${realEstateShops.seo}`,
      text: !isMobile ? 'Agencije za nekretnine' : 'Nekretnine',
      icoClass: '',
      className: '',
      iconSvg: realEstateShops.iconSvg,
      pages: ['/users', '/user', '/search'],
      query: {parentUserType: realEstateShops.seo},
      child: null
    })
  }

  /**
   * Services
   */
  const service = userTypes.find(userType => userType.seo === 'servis-i-usluga')
  if (service) {
    items.push({
      id: service.seo,
      as: '/servis-i-usluge',
      link: `/users?parentUserType=${service.seo}`,
      text: 'Servisi i usluge',
      icoClass: '',
      className: '',
      iconSvg: service.iconSvg,
      pages: ['/users', '/user', '/search'],
      query: {parentUserType: service.seo},
      child: null
    })
  }

  /**
   * Job offers
   */
  const jobOffers = userTypes.find(userType => userType.seo === 'poslodavac')
  if (jobOffers) {
    items.push({
      id: jobOffers.seo,
      as: `/${jobOffers.seoPlu}`,
      link: '/users?parentUserType=poslodavac',
      text: 'Ponuda poslova',
      icoClass: '',
      className: '',
      iconSvg: jobOffers.iconSvg,
      pages: ['/users', '/user', '/search'],
      query: {parentUserType: 'poslodavac'},
      child: null
    })
  }

  return items
}

export const Menu = ({userTypes, is404, asPath}) => {
  const headerMenuItems = [...itemsBeforeUserTypes]
  const userTypesItems = getUserTypeItems(userTypes)

  headerMenuItems.push(...userTypesItems)
  headerMenuItems.push(...itemsAfterUserTypes)
  return (
    <div className='main-menu'>
      <ul className='main-menu__list'>
        {
          headerMenuItems.map(item => {
            const isActive = (item && item.as && asPath && asPath.startsWith(item.as)) ||
              (item && item.link && asPath && asPath.startsWith(item.link === asPath))

            return [
              <Item {...item} key={item.id} is404={is404} isActive={isActive}>
                {item.child !== null ? item.child() : ''}
              </Item>,
              '\n'
            ]
          })
        }
      </ul>
    </div>
  )
}

Menu.propTypes = {
  layoutStyle: PropTypes.string.isRequired,
  is404: PropTypes.bool,
  asPath: PropTypes.string.isRequired,
  userTypes: PropTypes.arrayOf(PropTypes.shape())
}

Menu.defaultProps = {
  userTypes: [],
  layoutStyle: 'default'
}

export default compose(
  withRouter,
  connect((state) => ({is404: get404(state), userTypes: getUserTypes(state)}))
)(Menu)
