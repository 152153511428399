import PropTypes from 'prop-types'
import React from 'react'
import { WorkingTimeItem } from '../WorkingTimeItem/index'
import { branch, renderComponent, renderNothing } from 'recompose'

const MonFriTime = ({from, to}) => WorkingTimeItem({from, to, title: 'Pon-Pet'})
const SaturdayTime = ({from, to}) => WorkingTimeItem({from, to, title: 'Subota'})
const SundayTime = ({from, to}) => WorkingTimeItem({from, to, title: 'Nedjelja'})

const workingTimeFromToProvided = (workingTimeFrom, workingTimeTo) => {
  return ((workingTimeFrom !== null && workingTimeFrom !== ':') &&
  (workingTimeTo !== null && workingTimeTo !== ':'))
}

export const WorkingTime = ({workingTime, index, allAddressesCount}) =>
  <div className='sidebar-user-ostalo'>
    <div className='sidebar-user-contact-content sidebar-user-radno-vreme'>
      <i className='ico-prodavnica-radno-vrijeme ico' />
      <p>
        <span>Radno vrijeme</span>&nbsp;
        {
          allAddressesCount > 1 ? `(Lokacija ${index} od ${allAddressesCount})` : null
        }
      </p>
    </div>
    <MonFriTime from={workingTime.monfriFrom} to={workingTime.monfriTo} />
    <SaturdayTime from={workingTime.saturdayFrom} to={workingTime.saturdayTo} />
    <SundayTime from={workingTime.sundayFrom} to={workingTime.sundayTo} />
  </div>

WorkingTime.propTypes = {
  allAddressesCount: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  workingTime: PropTypes.shape({
    monfriFrom: PropTypes.string,
    monfriTo: PropTypes.string,
    saturdayFrom: PropTypes.string,
    saturdayTo: PropTypes.string,
    sundayFrom: PropTypes.string,
    sundayTo: PropTypes.string
  }).isRequired
}

WorkingTime.defaultProps = {
  allAddressesCount: 0,
  index: -1
}

export default branch(
  ({workingTime, index, allAddressesCount}) => {
    const isFromAndToProvided = workingTimeFromToProvided(workingTime.monfriFrom, workingTime.monfriTo) ||
    workingTimeFromToProvided(workingTime.saturdayFrom, workingTime.saturdayTo) ||
    workingTimeFromToProvided(workingTime.sundayFrom, workingTime.sundayTo)

    return allAddressesCount && index !== -1 && workingTime && isFromAndToProvided
  },
  renderComponent(WorkingTime),
  renderNothing
)(WorkingTime)
