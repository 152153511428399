import React from 'react'
import ReactSVG from 'react-svg'
import config from 'SRC/config/config.yaml'

export const SocialLinks = () => {
  return (
    <div className='footer-social'>
      {config.footer.socialLinks ? config.footer.socialLinks.map((item, index) =>
        <a
          key={`social_link_${index + 1}`}
          href={item.link.url}
          target={item.link.targetBlank ? '_blank' : '_self'}
          rel='noreferrer'
        >
          {item.className
            ? <i className={item.className} />
            : item.svgIcon
              ? (
                <ReactSVG
                  src={item.svgIcon}
                  beforeInjection={svg => {
                    svg.setAttribute('style', 'width: 37px; height: 100%; margin-right: 2.5px;')
                  }}
                  wrapper='span'
                />
              )
              : null
          }
        </a>
      ) : null}
    </div>
  )
}
