import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { AdFilterField, SelectDropdown } from 'SRC/ui/FormElementsNew'
import { getCurrentSubCategory } from 'SRC/modules/categories/selectors/getCurrentSubCategory'
import { getSortOptions } from '../../functions'

const defaultSort = [
  {
    id: 'dateDesc',
    value: 'dateDesc',
    label: 'Oglasi - Najnoviji'
  },
  {
    id: 'dateAsc',
    value: 'dateAsc',
    label: 'Oglasi - Najstariji'
  },
  {
    id: 'priceAsc',
    value: 'priceAsc',
    label: 'Cijena - Najmanja'
  },
  {
    id: 'priceDesc',
    value: 'priceDesc',
    label: 'Cijena - Najveća'
  }
]

class Sort extends React.Component {
  onSortChange = value => {
    const { form } = this.props

    form.setFieldValue('sortBy', value)
  }

  render () {
    const { categorySort, field } = this.props
    const options = [
      ...defaultSort,
      ...getSortOptions(categorySort)
    ]
    return <AdFilterField>
      <SelectDropdown isOptional={false} id='sortBy' title='Sortiraj' options={options} onChange={this.onSortChange} value={field.value} />
    </AdFilterField>
  }
}

const mapStateToProps = (state) => {
  const currentSubcategory = getCurrentSubCategory(state)

  return {
    categorySort: Array.isArray(currentSubcategory?.sortings) ? currentSubcategory.sortings : []
  }
}

Sort.propTypes = {
  categorySort: PropTypes.array.isRequired,
  field: PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.any
  }).isRequired,
  form: PropTypes.shape({
    setFieldValue: PropTypes.func
  }).isRequired
}

Sort.defaultProps = {
  sort: []
}

export default connect(mapStateToProps)(Sort)
