import React from 'react'
import Link from 'next/link'
import PropTypes from 'prop-types'
import { imagesCDN } from 'src/core/constants/imagesCDN'

export const SubCategory = ({ico, iconSvg, text, href, as}) => (
  <div className='pretraga-item'>
    <Link href={href} as={as}>
      <a>
        {iconSvg ? <img src={`${imagesCDN}${iconSvg}`} /> : <i className={ico} />}
        <p>{text}</p>
      </a>
    </Link>
  </div>
)

SubCategory.propTypes = {
  ico: PropTypes.string,
  iconSvg: PropTypes.string,
  text: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
  as: PropTypes.string.isRequired
}

SubCategory.defaultProps = {
  ico: 'ico'
}
