import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Link from 'next/link'
import { AdFilterField } from 'SRC/ui/FormElementsNew'
import { getCurrentSubCategory } from 'SRC/modules/categories/selectors'

const CategoriesFilterMobile = ({ category }) => {
  return (
    <AdFilterField key='active-categories-filter'>
      <label>Kategorije</label>
      <Link
        href={{ pathname: '/categories', query: { targetPage: 'mobilna-pretraga' } }}
        as={{ pathname: '/kategorije', query: { targetPage: 'mobilna-pretraga' } }}
      >
        <a className='select-dropdown-picker' style={{ color: '#000' }}>
          <label className={category ? 'select-dropdown-selected' : ''}>
            {category ? category.title : 'Izaberi'}
          </label>
        </a>
      </Link>
    </AdFilterField>
  )
}

const mapStateToProps = state => {
  return {
    category: getCurrentSubCategory(state)
  }
}

CategoriesFilterMobile.propTypes = {
  category: PropTypes.object.isRequired
}

export default connect(mapStateToProps)(CategoriesFilterMobile)
