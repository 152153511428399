import { getIsSelfConainedCategory } from 'SRC/modules/categories/functions'

const replaceLetters = [['š', 's'], ['đ', 'dj'], ['č', 'c'], ['ć', 'c'], ['ž', 'z']]
const excludeWords = [
  'od', 'iz', 'sa', 'do', 'bez', 'blizu', 'blizini', 'kod', 'kraj', 'iznad', 'između', 'izmedju', 'oko', 'okolini',
  'pored', 'ispod', 'ispred', 'k', 'ka', 'prema', 'nasuprot', 'uz', 'niz', 'u', 'kroz', 'na', 'za',
  's', 'nad', 'pod', 'među', 'medju', 'po', 'o', 'pri'
]

const citiesAdditional = {
  'bar': ['baru', 'bara'],
  'budva': ['budvi', 'budve', 'budvu'],
  'herceg novi': ['herceg novom', 'herceg novog'],
  'kotor': ['kotoru', 'kotora'],
  'tivat': ['tivtu', 'tivta'],
  'ulcinj': ['ulcinju', 'ulcinja'],
  'cetinje': ['cetinju', 'cetinja'],
  'danilovgrad': ['danilovgradu', 'danilovgrada'],
  'niksic': ['niksicu', 'niksica'],
  'podgorica': ['podgoricu', 'podgorici', 'podgorice'],
  'andrijevica': ['andrijevicu', 'andrijevici', 'andrijevice'],
  'berane': ['beranama', 'berana'],
  'bijelo polje': ['bijelom polju', 'bijelog polja'],
  'gusinje': ['gusinju', 'gusinja'],
  'kolasin': ['kolasinu', 'kolasina'],
  'mojkovac': ['mojkovcu', 'mojkovca'],
  'petnjica': ['petnjici', 'petnjice', 'petnjicu'],
  'zabljak': ['zabljaku', 'zabljaka'],
  'plav': ['plavu', 'plava'],
  'pljevlja': ['pljevljima', 'pljevalja'],
  'pluzine': ['pluzinama', 'pluzina'],
  'rozaje': ['rozajama', 'rozaja'],
  'savnik': ['savnika', 'savniku']
}

let citiesKeys = []

const limitResults = 15

let searchableArray = {}
let foundCity = null

const getSearchTerm = text => {
  let searchTerm = text.trim().toLowerCase()

  const words = []

  searchTerm.split(' ').filter(w => w).forEach(word => {
    const trimmedWord = word.trim()
    if (!excludeWords.includes(trimmedWord)) {
      words.push(trimmedWord)
    }
  })

  searchTerm = words.join(' ')

  replaceLetters.forEach(letter => {
    searchTerm = searchTerm.replaceAll(letter[0], letter[1])
  })

  return searchTerm
}

const filterSearchTerm = (searchTerm, excludeWord) => {
  return searchTerm.split(excludeWord).filter(st => st).map(st => st.trim()).join(' ')
}

const getCategoryObject = category => {
  const categoryObject = {
    id: category.id,
    title: category.title,
    seo: category.seo
  }

  if (category.isSelfContainedParentCategory) {
    categoryObject.isSelfContainedParentCategory = true
  }

  return categoryObject
}

export const getSearchableArray = (categories, options = {}) => {
  console.log('options', options)
  searchableArray = { categories: [] }
  foundCity = null
  citiesKeys = Array.isArray(citiesKeys) && citiesKeys.length ? citiesKeys : []

  if (Array.isArray(categories)) {
    categories.forEach(parentCategory => {
      const isSelfContained = getIsSelfConainedCategory(parentCategory)

      if (isSelfContained) {
        const parentCategoryObject = {
          ...getCategoryObject(parentCategory),
          sId: `parent_${parentCategory.id}`,
          isSelfContainedParentCategory: true
        }

        const parentCategoryItem = [
          getSearchTerm(parentCategory.title),
          parentCategoryObject,
          [parentCategory.title]
        ]
        searchableArray.categories.push(parentCategoryItem)

        if (Array.isArray(parentCategory.groups[0].categories[0].searchSpecifications)) {
          getSpecificationsItems({
            parentCategory: parentCategoryObject,
            group: parentCategory.groups[0],
            category: parentCategory.groups[0].categories[0]
          })
        }

        if (Array.isArray(parentCategory.groups[0].categories[0].similarWords)) {
          getSimilarWordsItems({
            parentCategory: parentCategoryObject,
            group: parentCategory.groups[0],
            category: parentCategory.groups[0].categories[0]
          })
        }
      } else {
        if (Array.isArray(parentCategory.groups)) getGroups(parentCategory)
      }
    })
  }

  if (Array.isArray(options.cities) && options.cities.length) {
    searchableArray.cities = {}

    options.cities.forEach(city => {
      const key = getSearchTerm(city.label)

      citiesKeys.push(key)

      searchableArray.cities[key] = city

      if (Array.isArray(citiesAdditional[key])) {
        citiesAdditional[key].forEach(cityKey => {
          searchableArray.cities[cityKey] = city
          citiesKeys.push(cityKey)
        })
      }
    })
  }

  return searchableArray
}

const getGroups = parentCategory => {
  const { groups } = parentCategory

  const selfContainedGroups = groups.filter(group => Array.isArray(group.categories) && group.categories.length === 1 &&
    group.categories[0].seo === group.seo)

  const regularGroups = groups.filter(group => Array.isArray(group.categories) &&
    (group.categories.length > 1 || (group.categories.length === 1 && group.categories[0].seo !== group.seo))
  )

  selfContainedGroups.forEach(group => {
    getSelfContainedGroup(group, parentCategory)
  })

  regularGroups.forEach(group => {
    getSubcategories(group, parentCategory)
  })
}

const getSelfContainedGroup = (group, parentCategory) => {
  const subCategory = group.categories[0]

  const parentCategoryObject = getCategoryObject(parentCategory)

  const breadCrumbs = [parentCategory.title, subCategory.title]

  const subCategoryItem = [
    getSearchTerm(subCategory.title),
    {
      ...getCategoryObject(subCategory),
      parentCategory: parentCategoryObject,
      sId: `${parentCategory.id}_${group.id}_${subCategory.id}`,
      initialPriority: 0
    },
    [ ...breadCrumbs ]
  ]

  searchableArray.categories.push(subCategoryItem)

  if (Array.isArray(subCategory.similarWords)) {
    getSimilarWordsItems({
      parentCategory: parentCategoryObject, category: subCategory, group
    })
  }

  if (Array.isArray(subCategory.searchSpecifications)) {
    getSpecificationsItems({ parentCategory: parentCategoryObject, category: subCategory })
  }
}

const getSubcategories = (group, parentCategory) => {
  if (!Array.isArray(group.categories)) return

  const parentCategoryObject = getCategoryObject(parentCategory)

  group.categories.forEach(subCategory => {
    const breadCrumbs = [parentCategory.title, group.title, subCategory.title]

    const subCategoryItem = [
      getSearchTerm(subCategory.title),
      {
        ...getCategoryObject(subCategory),
        parentCategory: parentCategoryObject,
        sId: `${parentCategory.id}_${group.id}_${subCategory.id}`,
        initialPriority: 0
      },
      [ ...breadCrumbs ]
    ]

    searchableArray.categories.push(subCategoryItem)

    if (Array.isArray(subCategory.similarWords)) {
      getSimilarWordsItems({
        parentCategory: parentCategoryObject,
        category: subCategory,
        group
      })
    }

    if (Array.isArray(subCategory.searchSpecifications)) {
      getSpecificationsItems({
        parentCategory: parentCategoryObject,
        category: subCategory,
        group
      })
    }
  })
}

const getSimilarWordsItems = (options = {}) => {
  const { parentCategory, group, category } = options

  const categoryObject = getCategoryObject(category)
  categoryObject.parentCategory = getCategoryObject(parentCategory)

  let breadCrumbs = []

  if (parentCategory.isSelfContainedParentCategory) {
    breadCrumbs = [parentCategory.title]
  } else {
    breadCrumbs = [parentCategory.title, category.title]

    if (group) {
      categoryObject.group = getCategoryObject(group)
      breadCrumbs = [parentCategory.title, group.title, category.title]
    }
  }

  category.similarWords.forEach(word => {
    searchableArray.categories.push([
      getSearchTerm(word),
      {
        ...categoryObject,
        sId: `${parentCategory.id}_${group ? group.id : 'self'}_${category.id}`,
        initialPriority: word.length
      },
      breadCrumbs
    ])
  })
}

const getSpecificationsItems = (options = {}) => {
  const { parentCategory, group, category } = options

  category.searchSpecifications.forEach(spec => {
    const options = spec.options
    if (Array.isArray(options)) {
      const categoryObject = getCategoryObject(category)
      categoryObject.parentCategory = getCategoryObject(parentCategory)

      let breadCrumbs = []

      if (parentCategory.isSelfContainedParentCategory) {
        breadCrumbs = [parentCategory.title]
      } else {
        breadCrumbs = [parentCategory.title, category.title]

        if (group) {
          categoryObject.group = getCategoryObject(group)
          breadCrumbs = [parentCategory.title, group.title, category.title]
        }
      }

      options.forEach(option => {
        searchableArray.categories.push([
          getSearchTerm(option),
          {
            ...categoryObject,
            sId: `${parentCategory.id}_${group ? group.id : 'self'}_${category.id}_spec_${option}`,
            specification: {
              id: spec.id,
              values: [option],
              value: option
            }
          },
          [ ...breadCrumbs, option ]
        ])
      })
    }
  })
}

export const search = (searchableArray, text) => {
  if (text.length < 3) return null

  foundCity = null

  let searchTerm = getSearchTerm(text)
  let filteredCategories = []

  const findCityPhrase = findCityAndReturnPhrase(searchableArray, searchTerm)

  if (findCityPhrase) {
    searchTerm = filterSearchTerm(searchTerm, findCityPhrase)
  }

  const foundItems = getFoundItems(searchableArray, searchTerm)

  if (foundItems.length) {
    filteredCategories = getItemsSorted(foundItems)
  }

  let searchResult = filteredCategories.slice(0, limitResults)

  if (foundCity) {
    searchResult = searchResult.map(searchResultItem => {
      const updatedItem = [...searchResultItem.item]

      updatedItem[1].city = foundCity

      const breadCrumbs = [ ...updatedItem[2] ]

      if (foundCity && Array.isArray(breadCrumbs) && breadCrumbs.length) {
        breadCrumbs.push(foundCity.label)
        updatedItem[2] = breadCrumbs
      }

      return {
        ...searchResultItem,
        item: updatedItem
      }
    })
  }

  return searchResult
}

export const searchCities = (searchableArray, text) => {
  if (text.length < 3) return null

  foundCity = null

  let searchTerm = getSearchTerm(text)

  if (searchableArray.cities && Array.isArray(citiesKeys)) {
    for (let cityKey of citiesKeys) {
      let findIndex = searchTerm.indexOf(cityKey)

      if (findIndex !== -1) {
        foundCity = searchableArray.cities[cityKey]
      }
    }
  }

  return foundCity
}

const getFoundItems = (searchableArray, searchTerm) => {
  const foundItems = []
  const foundSIds = []

  console.log('foundItems searchableArray', searchableArray)

  const ignoredFirstLetter = searchTerm.substring(1, searchTerm.length)
  const ignoredLastLetter = searchTerm.substring(0, searchTerm.length - 1)

  searchableArray.categories.forEach(item => {
    const itemText = item[0]

    const indexFL = itemText.indexOf(ignoredFirstLetter)
    const indexLL = itemText.indexOf(ignoredLastLetter)
    const indexSt = itemText.indexOf(searchTerm)

    if (indexFL !== -1 || indexLL !== -1) {
      const isWordContained = indexSt !== -1
      const startIndex = isWordContained ? indexSt : indexFL !== -1 ? indexFL : indexLL

      const itemMeta = item[1]

      if (!foundSIds.includes(itemMeta.sId)) {
        const itemPriority = getSearchPriority({
          searchTerm,
          itemText,
          startIndex,
          ignoredFirstLetter,
          ignoredLastLetter,
          isWordContained,
          initialPriority: typeof itemMeta.initialPriority === 'undefined' ? 1 : itemMeta.initialPriority
        })

        if (itemPriority !== -1) {
          foundItems.push({
            priority: itemPriority.priority,
            wordIndex: itemPriority.wordIndex,
            item
          })
          foundSIds.push(itemMeta.sId)
        }
      }
    }
  })

  console.log('foundItems', foundItems)

  return foundItems
}

const findCityAndReturnPhrase = (searchableArray, searchTerm) => {
  let phrase = ''

  if (searchableArray.cities && Array.isArray(citiesKeys)) {
    for (let cityKey of citiesKeys) {
      let findIndex = searchTerm.indexOf(cityKey)

      if (findIndex !== -1) {
        phrase = cityKey
        foundCity = searchableArray.cities[cityKey]

        if (Array.isArray(citiesAdditional[cityKey])) {
          for (let additionalCityKey of citiesAdditional[cityKey]) {
            findIndex = searchTerm.indexOf(additionalCityKey)

            if (findIndex !== -1) {
              phrase = additionalCityKey
              foundCity = searchableArray.cities[cityKey]
            }
          }
        }

        return phrase
      }

      if (Array.isArray(citiesAdditional[cityKey])) {
        for (let additionalCityKey of citiesAdditional[cityKey]) {
          findIndex = searchTerm.indexOf(additionalCityKey)

          if (findIndex !== -1) {
            phrase = additionalCityKey
            foundCity = searchableArray.cities[cityKey]

            return phrase
          }
        }
      }
    }
  }

  return phrase
}

const getItemsSorted = items => {
  return items.slice().sort((item1, item2) => {
    if (item1.priority === item2.priority) {
      if (item1.wordIndex !== item2.wordIndex) {
        if (item1.wordIndex > item2.wordIndex) {
          return (item1.priority + item1.wordIndex) - item2.priority
        }

        return item1.priority - (item2.priority + item2.wordIndex)
      }

      if (item1.item[0].length < item2.item[0].length) {
        return -1
      } else if (item1.item[0].length > item2.item[0].length) {
        return 1
      } else {
        return item1.item[0].localeCompare(item2.item[0])
      }
    }

    return item1.priority - item2.priority
  })
}

const getSearchPriority = (options = {}) => {
  const {
    searchTerm,
    itemText,
    ignoredFirstLetter,
    ignoredLastLetter,
    isWordContained,
    initialPriority
  } = options

  let priority = 0

  const itemTextLength = itemText.length

  const words = itemText.split(/[ |&/]+/).map(w => {
    return w.trim().replaceAll(/[,()]/g, '')
  })

  words.push(itemText)

  const wordIndex = words.findIndex(w => w.indexOf(ignoredFirstLetter) !== -1 || w.indexOf(ignoredLastLetter) !== -1)

  if (wordIndex === -1) {
    return -1
  }

  const word = words[wordIndex]

  if (!word.startsWith(ignoredLastLetter) && !word.endsWith(ignoredFirstLetter)) {
    return -1
  }

  const wPriority = word.startsWith(searchTerm) || word.endsWith(searchTerm) ? itemTextLength - searchTerm.length : itemTextLength

  priority = initialPriority * (wPriority || 1)
  priority += isWordContained && word === searchTerm ? 0
    : isWordContained && initialPriority === 0 ? itemTextLength - searchTerm.length
      : 20

  return { priority, wordIndex }
}
