import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { connect as formikConnect } from 'formik'
import { compose } from 'recompose'
import { adFiltersLabels } from 'Core/constants'
import { getFlattenCategories, getCurrentSubCategory } from 'SRC/modules/categories/selectors'
import { AdsBrandsApi } from 'SRC/modules/ads/brands/api'
import { AdsProductsApi } from 'SRC/modules/ads/products/api'
import { SelectDropdown, AdFilterField, CheckboxNew } from 'SRC/ui/FormElementsNew'

export class Compatibilities extends React.Component {
  constructor (props) {
    super(props)

    let years = []
    for (let i = (new Date()).getFullYear(); i >= 1900; i--) years.push(i)
    this.years = years

    this.state = this.getDefaultState()
  }

  getDefaultState = () => ({
    brands: {},
    isBrandsLoading: false,
    products: {},
    isProductsLoading: false
  })

  getAvailableCompatibilityFields = () => {
    const { formik, categories, currentSubCategory } = this.props

    const fields = {
      brand: false,
      product: false,
      year: currentSubCategory && currentSubCategory.compatibilityYears,
      universality: currentSubCategory.compatibilityUniversality
    }

    const category = formik.values && formik.values.compatibility && formik.values.compatibility.category

    if (category && category !== -1) {
      const selectedCategory = categories && categories.length
        ? categories.find(cat => cat.id === category)
        : null

        console.log('selectedCategory', selectedCategory)
      if (selectedCategory) {
        const rao = selectedCategory.requiredAdOptions

        if (rao === 'BrandOnly') {
          fields.brand = true
        }

        if (rao === 'BrandModel') {
          fields.brand = true
          fields.product = true
        }
      }
    }

    return fields
  }

  /**
   * Compatibility Category
   */
  getCompatibilityCategoryField = () => {
    const { formik } = this.props

    return {
      id: 'compatibility-category',
      title: adFiltersLabels.compatibility.category,
      options: this.getCompatibilityCategoryOptions(),
      value: formik.values && formik.values.compatibility
        ? formik.values.compatibility.category
        : null,
      isSearchable: true,
      onChange: this.onCompatibilityCategoryChange
    }
  }

  getCompatibilityCategoryOptions = () => {
    const { currentSubCategory } = this.props

    return currentSubCategory && currentSubCategory.compatibilityCategories.length
      ? currentSubCategory.compatibilityCategories.map(category => ({
        id: category.id,
        label: category.title,
        value: category.id
      })) : []
  }

  onCompatibilityCategoryChange = async value => {
    const { formik } = this.props

    const category = Number(value)

    await formik.setFieldValue('compatibility', {
      ...formik.values.compatibility,
      category,
      brand: -1,
      product: -1
    })
  }

  /**
   * Brands
   */
  getBrandField = () => {
    const { formik } = this.props

    const { brands, isBrandsLoading } = this.state

    const category = formik.values && formik.values.compatibility && formik.values.compatibility.category

    if (category && category !== -1 && !brands[category] && !isBrandsLoading) {
      this.loadBrandsToState()
    }

    const isDisabled = !category || isBrandsLoading || !brands[category] || !brands[category].length

    return {
      id: 'compatibility-brand',
      title: adFiltersLabels.compatibility.brand,
      isDisabled,
      options: brands[category] || [],
      value: formik.values.compatibility.brand,
      isSearchable: true,
      onChange: this.onBrandChange
    }
  }

  loadBrandsToState = async () => {
    const { formik } = this.props
    const { brands } = this.state

    const category = formik.values && formik.values.compatibility && formik.values.compatibility.category

    if (category && category !== -1) {
      this.setState({ isBrandsLoading: true })

      if (brands[category] && brands[category].length) {
        return this.setState({ isBrandsLoading: false })
      }

      const api = new AdsBrandsApi()
      const result = await api.getBrandsByCategory(category)

      this.setState({
        brands: {
          ...brands,
          [category]: result.map(brand => ({
            id: brand.id,
            label: brand.name,
            value: brand.id
          }))
        },
        isBrandsLoading: false
      })
    }
  }

  onBrandChange = async value => {
    const { formik } = this.props

    const brand = Number(value)

    formik.setFieldValue('isUniversal', -1)

    await formik.setFieldValue('compatibility', {
      ...formik.values.compatibility,
      brand,
      product: -1
    })
  }

  /**
   * Products
   */
  getProductField = () => {
    const { formik } = this.props
    const { products, isProductsLoading } = this.state

    const brand = formik.values.compatibility.brand

    if (brand && brand !== -1 && !products[brand] && !isProductsLoading) {
      this.loadProductsToState()
    }

    const isDisabled = !brand || isProductsLoading || !products[brand] || !products[brand].length

    return {
      id: 'compatibility-product',
      title: adFiltersLabels.compatibility.product,
      isDisabled,
      options: products[brand] || [],
      value: formik.values.compatibility.product,
      isSearchable: true,
      onChange: this.onProductChange
    }
  }

  loadProductsToState = async () => {
    const { formik } = this.props
    const { products } = this.state

    const brand = formik.values && formik.values.compatibility && formik.values.compatibility.brand
    const category = formik.values && formik.values.compatibility && formik.values.compatibility.category

    if (brand && brand !== -1) {
      this.setState({ isProductsLoading: true })

      if (products[brand] && products[brand].length) {
        return this.setState({ isProductsLoading: false })
      }

      const api = new AdsProductsApi()

      const result = await api.fetchProductByBrandAndCategory({
        brand,
        category
      })

      this.setState({
        products: {
          ...products,
          [brand]: result.map(product => ({
            id: product.id,
            label: product.model,
            value: product.id
          }))
        },
        isProductsLoading: false
      })
    }
  }

  onProductChange = value => {
    const { formik } = this.props
    const productValue = Number(value)

    formik.setFieldValue('isUniversal', -1)

    formik.setFieldValue('compatibility', {
      ...formik.values.compatibility,
      product: productValue
    })
  }

  /**
   * Year
   */
  getCompatibilityYearField = () => {
    const { formik } = this.props

    return {
      id: 'compatibility-year',
      title: adFiltersLabels.compatibility.year,
      options: this.years.map(year => ({
        id: `year-${year}`,
        label: year,
        value: year
      })),
      value: formik.values.compatibility.year,
      isSearchable: true,
      onChange: this.onYearChange
    }
  }

  onYearChange = async value => {
    const { formik } = this.props

    const year = Number(value)

    formik.setFieldValue('isUniversal', -1)

    await formik.setFieldValue('compatibility', {
      ...formik.values.compatibility,
      year
    })
  }

  /**
   * Universality
   */
  getUniversalityField = () => {
    const { formik } = this.props
    // const isUniversal = this.checkUniversality()

    return {
      id: 'compatibility-universality',
      title: adFiltersLabels.compatibility.universal,
      isChecked: formik.values && formik.values.isUniversal === 1,
      onChange: this.onUniversalityChange
    }
  }

  onUniversalityChange = async e => {
    const { formik } = this.props

    const isChecked = e.target.checked

    formik.setFieldValue('isUniversal', isChecked ? 1 : -1)

    if (isChecked) {
      formik.setFieldValue('compatibility', {
        ...formik.values.compatibility,
        brand: -1,
        product: -1,
        year: -1
      })
    }
  }

  checkUniversality = () => {
    const { formik } = this.props

    const compatibilitValues = formik.values && formik.values.compatibility

    const isUniversal =  compatibilitValues && compatibilitValues.category && compatibilitValues.category !== -1 &&
      compatibilitValues.brand === -1 && compatibilitValues.product === -1 && compatibilitValues.year === -1

    return isUniversal
  }

  render () {
    const availableCompatibilityFields = this.getAvailableCompatibilityFields()

    return <React.Fragment>
      <AdFilterField>
        <SelectDropdown {...this.getCompatibilityCategoryField()} />
      </AdFilterField>

      {availableCompatibilityFields.brand
        ? <AdFilterField>
          <SelectDropdown {...this.getBrandField()} />
        </AdFilterField>
        : null
      }

      {availableCompatibilityFields.product
        ? <AdFilterField>
          <SelectDropdown {...this.getProductField()} />
        </AdFilterField>
        : null
      }

      {availableCompatibilityFields.year
        ? <AdFilterField>
          <SelectDropdown {...this.getCompatibilityYearField()} />
        </AdFilterField>
        : null
      }

      {availableCompatibilityFields.universality
        ? <AdFilterField className='ads-filter__checkbox'>
          <CheckboxNew {...this.getUniversalityField()} />
        </AdFilterField>
        : null
      }
    </React.Fragment>
  }
}

const mapStateToProps = (state) => {
  return {
    categories: getFlattenCategories(state),
    currentSubCategory: getCurrentSubCategory(state)
  }
}

Compatibilities.propTypes = {
  categories: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    seo: PropTypes.string
  })).isRequired,
  currentSubCategory: PropTypes.shape({
    id: PropTypes.number,
    seo: PropTypes.string,
    compatibilityCategories: PropTypes.array,
    compatibilityYears: PropTypes.bool,
    compatibilityUniversality: PropTypes.bool
  }).isRequired,
  formik: {
    values: PropTypes.object,
    setFieldValue: PropTypes.func
  }.isRequired
}

Compatibilities.defaultProps = {
  currentSubCategory: null
}

const enhance = compose(
  connect(mapStateToProps),
  formikConnect
)

export default enhance(Compatibilities)
