import React from 'react'
import PropTypes from 'prop-types'
import { debounce } from 'throttle-debounce'
import { normalizeString } from 'SRC/utils'

export const Search = React.forwardRef(({ setFilter, isMobile }, ref) => {
  const [showInput, setShowInput] = React.useState(!Boolean(isMobile))

  React.useEffect(() => { isMobile && setTimeout(() => { setShowInput(true) }, 1000) }, [isMobile])

  const onChange = e => {
    const text = e.target.value

    if (text) return setFilter(normalizeString(text))
    return setFilter('')
  }

  if (!showInput) return (
    <span
      style={{
        width: '100%',
        backgroundColor: 'white',
        display: 'block',
        height: '2.4rem',
        fontSize: '1.25rem',
        border: '1px solid #777',
        borderRadius: '4px',
        padding: '8px',
        fontSize: '6vw',
        color: '#777',
        display: 'flex',
        alignItems: 'center'
      }}
    >
      Pretraži
    </span>
  )

  return <input type='text' ref={ref} onChange={debounce(1000, onChange)} placeholder='Pretraži' />
})

Search.displayName = 'DropdownSearch'

Search.propTypes = {
  isMobile: PropTypes.bool,
  setFilter: PropTypes.func.isRequired
}

Search.defaultProps = {
  isMobile: false
}
