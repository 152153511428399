/* eslint-disable no-undef */
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import Router from 'next/router'
import { debounce } from 'throttle-debounce'
import { alphabet } from 'Core/constants'
import { getCategories, getCategoriesWithGroups } from 'SRC/modules/categories/selectors'
import { getIsSelfConainedCategory } from 'SRC/modules/categories/functions'
import { getIsMobileDevice } from 'SRC/modules/common/selectors'
import { Search } from '../Search'
import { ParentCategories } from '../ParentCategories'
import { Categories } from '../Categories'
import { SubCategories } from '../SubCategories'
import { getSearchableArray, search } from '../helpers'

export class Container extends React.Component {
  constructor(props) {
    super(props)

    this.state = this.getInitialState()
  }

  componentDidMount() {
    const { categoriesWithGroups } = this.props

    this.searchableArray = getSearchableArray(categoriesWithGroups)
  }

  getInitialState = () => ({
    currentParentCategory: null,
    filteredCategories: null,
    filter: ''
  })

  goToDestinationPage = (options) => {
    const { baseHref, baseAs, parentCategoryInUrl, categoriesWithGroups } = this.props

    const { category, parentCategory, specification } = options

    let hrefUrl = { pathname: baseHref }
    let asUrl = baseAs

    const isGoToAddAd = baseAs === '/dodaj/'
    const isGoToSearch = baseAs === '/'

    if (parentCategoryInUrl) {
      let isSelfContainedParentCategory = false

      if (category === parentCategory) {
        const findParentCategory = Array.isArray(categoriesWithGroups)
          ? categoriesWithGroups.find(cat => cat.seo === parentCategory)
          : null

        if (findParentCategory && getIsSelfConainedCategory(findParentCategory)) {
          isSelfContainedParentCategory = true
        }
      }

      if (!isSelfContainedParentCategory) {
        hrefUrl.query = {
          category,
          parentCategory
        }

        asUrl += `${parentCategory}/${category}`
      } else {
        hrefUrl.query = {
          parentCategory
        }

        asUrl += `${parentCategory}`
      }
    } else {
      hrefUrl.query = {
        category
      }

      asUrl += `${category}`
    }

    if (specification && isGoToSearch) {
      hrefUrl.pathname = '/search'
      hrefUrl.query = {
        ...hrefUrl.query,
        formStyle: 'basic',
        pageNumber: 1,
        specifications: JSON.stringify([specification]),
        sortBy: 'dateDesc'

      }

      asUrl += `/pretraga?pageNumber=1&formStyle=basic&sortBy=dateDesc&specifications=${JSON.stringify([specification])}`
    }

    if (specification && isGoToAddAd) {
      hrefUrl.query = {
        ...hrefUrl.query,
        defaultSpecification: JSON.stringify(specification)

      }

      asUrl += `?defaultSpecification=${JSON.stringify(specification)}`
    }

    console.log('hrefUrl', hrefUrl)
    console.log('asUrl', asUrl)

    Router.push(hrefUrl, asUrl)
  }

  onTextSearch = debounce(500, () => {
    const { filter } = this.state

    const filteredCategories = filter === '' ? null : search(this.searchableArray, filter)

    console.log('filteredCategories', filteredCategories)

    this.setState({
      filteredCategories
    })
  })

  /**
   * Parent Categories
   */

  onParentCategoryClick = parentCategory => {
    if (parentCategory && parentCategory.seo) {
      window.scrollTo(0, 0)

      if (getIsSelfConainedCategory(parentCategory)) {
        this.goToDestinationPage({ category: parentCategory.seo, parentCategory: parentCategory.seo })
      } else {
        this.setState({ currentParentCategory: parentCategory.seo })
      }
    }
  }

  /**
   * Subcategories
   */

  getSubcategoriesProps = () => {
    const { allCategories, baseAs, baseHref } = this.props
    const { currentParentCategory } = this.state

    if (currentParentCategory) {
      const parentCategory = allCategories.find(cat => cat.seo === currentParentCategory)

      if (parentCategory) {
        return {
          items: parentCategory.children,
          parentCategoryTitle: parentCategory.title.toUpperCase(),
          parentCategorySeo: parentCategory.seo,
          onBackBtnClick: this.onBackBtnClick,
          baseAs,
          baseHref
        }
      }
    }

    return {
      items: [],
      parentCategoryTitle: 'NO CATEGORIES',
      onClick: () => null,
      onBackBtnClick: this.onBackBtnClick
    }
  }

  onBackBtnClick = () => {
    this.setState({ currentParentCategory: null })
  }

  getCategoriesGroupedByLetter = () => {
    const { filteredCategories, filter } = this.state
    const { allCategories } = this.props

    let categories = []
    let categoriesIds = []

    if (allCategories) {
      allCategories.forEach(parentCategory => {
        const childCategories = parentCategory.children.filter(category => !categoriesIds.includes(category.id))
          .map(category => ({
            id: category.id,
            seo: category.seo,
            parentCategorySeo: parentCategory.seo,
            title: category.title,
            iconSvg: category.iconSvg,
            icon: category.icon
          }))

        categories = categories.concat(childCategories)

        categoriesIds = categoriesIds.concat(parentCategory.children.map(c => c.id))
      })
    }

    let alphabetActual = alphabet

    if (Array.isArray(filteredCategories)) {
      if (filteredCategories.length) {
        const filteredCategoriesIds = filteredCategories.map(fcItem => fcItem && Array.isArray(fcItem.item) && fcItem.item[1]
          ? fcItem.item[1].id
          : -1
        )

        categories = categories.filter(cat => filteredCategoriesIds.includes(cat.id))
      } else {
        categories = []
      }
    }

    if (filter) {
      alphabetActual = alphabetActual.slice().sort((a, b) => {
        if (filter.startsWith(a)) {
          return -1
        }

        if (filter.startsWith(b)) {
          return 1
        }

        return 0
      })
    }

    const categoriesByLetter = {}

    if (categories && categories.length) {
      alphabetActual.forEach(letter => {
        const filteredCategoriesByLetter = categories.filter(cat => cat.title.toLowerCase().startsWith(letter))

        if (filteredCategoriesByLetter && filteredCategoriesByLetter.length) {
          const categoriesByLetterSortedAlphabetically = filteredCategoriesByLetter.slice()
            .sort((a, b) => a.title.localeCompare(b.title))

          categoriesByLetter[letter] = categoriesByLetterSortedAlphabetically
        }
      })
    }

    return categoriesByLetter
  }

  getSortedSuggestions = suggestions => {
    const { filter } = this.state

    const sorted = suggestions.slice().sort((sg1, sg2) => {
      let s1Title = sg1[sg1.length - 1].title.toLowerCase()
      let s2Title = sg2[sg2.length - 1].title.toLowerCase()
      let textSearch = filter.toLowerCase()

      this.replaceLetters.forEach(letter => {
        s1Title = s1Title.replaceAll(letter[0], letter[1])
        s2Title = s2Title.replaceAll(letter[0], letter[1])
        textSearch = textSearch.replaceAll(letter[0], letter[1])
      })

      console.log('ss1t s1Title.indexOf(textSearch)', s1Title, s1Title.indexOf(textSearch))
      console.log('ss2t s2Title.indexOf(textSearch)', s2Title, s2Title.indexOf(textSearch))
      console.log('sstextSearch', textSearch)

      if (s1Title.indexOf(textSearch) === 0) {
        return -1
      }

      if (s1Title.indexOf(textSearch) === -1) {
        return 1
      }

      if (s1Title.indexOf(textSearch) > 0 && s2Title.indexOf(textSearch) === -1) {
        return -1
      }

      if (s1Title.indexOf(textSearch) > 0 && s2Title.indexOf(textSearch) > 0) {
        if (s1Title.indexOf(textSearch) < s2Title.indexOf(textSearch)) {
          return -1
        } else if (s1Title.indexOf(textSearch) > s2Title.indexOf(textSearch)) {
          return 1
        } else {
          return 0
        }
      }

      return 0
    })

    console.log('sorted', sorted)

    return sorted
  }

  renderSuggestions = () => {
    const { filteredCategories } = this.state

    if (Array.isArray(filteredCategories) && filteredCategories.length) {
      return (
        <section className='categories-suggestions'>
          {filteredCategories.map(({ item }, index) => {
            const suggestionCategory = item[1]
            const suggestionItems = item[2]

            if (Array.isArray(suggestionItems) && suggestionCategory) {
              return (
                <ul
                  key={`suggestion-${suggestionCategory.sId}`}
                  className='categories-suggestions-container'
                  onClick={() => this.suggestionClicked(suggestionCategory)}
                >
                  {suggestionItems.map((item, index) => ([
                    <li key={`suggestion-item-${suggestionCategory.sId}-${item}`}>{this.renderSuggestionItem(item)}</li>,
                    index !== suggestionItems.length - 1 ? <li key={`suggestion-separator-${item.id}`}> &gt; </li> : null
                  ]))}
                </ul>
              )
            }

            return null
          })}
        </section>
      )
    }

    return null
  }

  suggestionClicked = item => {
    if (item.isSelfContainedParentCategory) {
      const params = { category: item.seo, parentCategory: item.seo }

      if (item.specification) {
        params.specification = {
          id: item.specification,
          values: [item.value],
          value: item.value
        }
      }

      this.goToDestinationPage(params)
    } else {
      const params = { category: item.seo, parentCategory: item.parentCategory.seo }

      if (item.specification) {
        params.specification = item.specification
      }

      this.goToDestinationPage(params)
    }
  }

  renderSuggestionItem = (item) => {
    return (
      <a href='javascript:void(0)'>
        {item}
      </a>
    )
  }

  render() {
    const { textSearchPlaceholder, categoriesWithGroups, isMobileDevice } = this.props
    const { currentParentCategory, filteredCategories, filter } = this.state

    return [
      <Search
        onChange={e => {
          const value = e.target.value

          this.setState({ filter: value }, this.onTextSearch)
        }}
        placeholder={textSearchPlaceholder}
        key='search'
      />,
      <div className='all-categories-with-letters' key='main'>
        {filter && filter.length >= 3 ? this.renderSuggestions() : null}
        {!currentParentCategory ? (
          <ParentCategories
            items={categoriesWithGroups || []}
            onClick={this.onParentCategoryClick}
            isHidden={filteredCategories !== null}
            baseAs={this.props.baseAs}
            baseHref={this.props.baseHref}
          />
        ) : (
          <SubCategories
            {...this.getSubcategoriesProps()}
            isHidden={filteredCategories !== null}
          />
        )}
        {!isMobileDevice
          ? (
            <Categories
              items={this.getCategoriesGroupedByLetter()}
              title='SVE KATEGORIJE'
              baseAs={this.props.baseAs}
              baseHref={this.props.baseHref}
            />
          ) : null
        }
      </div>
    ]
  }
}

const mapStateToProps = (state) => {
  const categories = getCategories(state)
  const categoriesWithGroups = getCategoriesWithGroups(state)

  return {
    allCategories: categories,
    categoriesWithGroups,
    isMobileDevice: getIsMobileDevice(state)
  }
}

Container.propTypes = {
  allCategories: PropTypes.arrayOf(PropTypes.shape({
    seo: PropTypes.string,
    children: PropTypes.arrayOf(PropTypes.shape({
      seo: PropTypes.string
    }))
  })).isRequired,
  categoriesWithGroups: PropTypes.arrayOf(PropTypes.shape({
    seo: PropTypes.string
  })).isRequired,
  textSearchPlaceholder: PropTypes.string.isRequired,
  baseHref: PropTypes.string,
  baseAs: PropTypes.string,
  parentCategoryInUrl: PropTypes.bool.isRequired,
  isMobileDevice: PropTypes.string.isRequired
}

Container.defaultProps = {
  allCategories: [],
  baseAs: '/dodaj/',
  baseHref: '/addAd',
  parentCategoryInUrl: false
}

Container.displayName = 'AllCategoriesWithGroups'

export default connect(mapStateToProps)(Container)
