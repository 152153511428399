import React from 'react'
import PropTypes from 'prop-types'
import { useFormikContext } from 'formik'
import { connect } from 'react-redux'
import { naturalNumbers, rationalNumbers } from 'Core/masks'
import {
  AdFilterField,
  SelectDropdown,
  TextInputNew,
  InputRange,
  SelectRange
} from 'SRC/ui/FormElementsNew'
import { isSpecificationVisible } from '../../../functions'

const getIsVisible = visibilityConditionValues => {
  let isVisible = true
  if (!visibilityConditionValues.show && !visibilityConditionValues.hide) {
    isVisible = true
  }

  if (visibilityConditionValues.show && !visibilityConditionValues.hide) {
    isVisible = Object.values(visibilityConditionValues.show).some(v => v)
  }

  if (!visibilityConditionValues.show && visibilityConditionValues.hide) {
    isVisible = Object.values(visibilityConditionValues.hide).every(v => !v)
  }

  if (visibilityConditionValues.show && visibilityConditionValues.hide) {
    isVisible = Object.values(visibilityConditionValues.show).some(v => v) ||
      Object.values(visibilityConditionValues.hide).every(v => !v)
  }

  return isVisible
}

export const Container = ({ specification }) => {
  const formik = useFormikContext()

  /**
   * Single select specifications
   */
  const onSingleSelectSpecificationChange = (specId, value) => {
    const actualValue = value !== '-1' ? value : -1

    formik.setFieldValue('specifications', {
      ...formik.values.specifications,
      [specId]: {
        value: actualValue
      }
    })
  }

  const getSingleSelectField = () => {
    const specificationValues = formik.values.specifications

    return {
      id: `specification-filter-${specification.id}`,
      title: specification.title,
      options: specification.options,
      value: specificationValues && specificationValues[specification.id] ? specificationValues[specification.id].value : -1,
      isSearchable: specification.options.length > 10,
      onChange: (value) => onSingleSelectSpecificationChange(specification.id, value)
    }
  }

  /**
   * Multi select specifications
   */
  const onMultiSelectSpecificationChange = (specId, values) => {
    const actualValues = values.length ? values : -1

    formik.setFieldValue(`specifications.${specId}.values`, actualValues)
  }

  const getMultiSelectField = () => {
    const specificationValues = formik.values.specifications

    const checkedItems = specificationValues && specificationValues[specification.id] && specificationValues[specification.id].values !== -1
      ? Array.isArray(specificationValues[specification.id].values)
        ? specificationValues[specification.id].values.map(val => val.toString())
        : []
      : []

    return {
      id: `specification-filter-${specification.id}`,
      title: specification.title,
      options: specification.options,
      checkedItems,
      onChange: (values) => onMultiSelectSpecificationChange(specification.id, values),
      isMultiple: true,
      isSearchable: true
    }
  }

  /**
   * Text input specifications
   */
  const onTextInputSpecificationChange = (specId, value) => {
    formik.setFieldValue(`specifications.${specId}.value`, value || -1)
  }

  const getTextInputField = () => {
    const specificationValues = formik.values.specifications

    let mask = null

    if (Array.isArray(specification.allowedCharacters)) {
      if (specification.allowedCharacters.includes('rational') || specification.allowedCharacters.includes('natural') ||
        specification.allowedCharacters.includes('numbers')) {
        if (specification.allowedCharacters.length === 1) {
          mask = specification.allowedCharacters.includes('rational') ? rationalNumbers : naturalNumbers
        }
      }
    }

    return {
      id: `specification-filter-${specification.id}`,
      title: specification.title,
      hint: specification.hint,
      allowedCharacters: specification.allowedCharacters,
      placeholder: specification.placeholder,
      mask,
      className: 'polja-pretrage-input',
      value: specificationValues && specificationValues[specification.id] ? specificationValues[specification.id].value : -1,
      measure: specification.measure,
      onChange: value => onTextInputSpecificationChange(specification.id, value)
    }
  }

  /**
   * Text input range specifications
   */
  const onInputFromChange = (specId, value) => {
    const rangeFromValue = value || -1

    // Resolve dependencies
    if (Array.isArray(specification.dependents) && specification.dependents.some(d => d.dependentType === 'same')) {
      return updateRelatedSpecs({ specId, updatedRangeValue: rangeFromValue, direction: 'From' })
    }

    return formik.setFieldValue(`specifications.${specId}.rangeFrom`, rangeFromValue)
  }

  const onInputToChange = (specId, value) => {
    const rangeToValue = value || -1

    // Resolve dependencies
    if (Array.isArray(specification.dependents) && specification.dependents.some(d => d.dependentType === 'same')) {
      return updateRelatedSpecs({ specId, updatedRangeValue: rangeToValue, direction: 'To' })
    }

    return formik.setFieldValue(`specifications.${specId}.rangeTo`, rangeToValue)
  }

  const getInputRangeField = () => {
    const specificationValues = formik.values.specifications

    let valueFrom = specificationValues && specificationValues[specification.id]
      ? specificationValues[specification.id].rangeFrom : -1

    let valueTo = specificationValues && specificationValues[specification.id]
      ? specificationValues[specification.id].rangeTo : -1

    return {
      id: `specification-filter-${specification.id}`,
      title: specification.title,
      measure: specification.measure,
      allowedCharacters: specification.allowedCharacters,
      valueFrom,
      valueTo,
      onValueFromChange: value => onInputFromChange(specification.id, value),
      onValueToChange: value => onInputToChange(specification.id, value)
    }
  }

  /**
   * Select range specifications
   */
  const onSelectFromChange = (specId, value) => {
    const rangeValue = formik.values.specifications[specId].range
    let newRangeValue = {}

    if (typeof rangeValue === 'number' && rangeValue === -1) {
      newRangeValue = {
        from: value || -1,
        to: -1
      }
    } else {
      newRangeValue = {
        ...rangeValue,
        from: value || -1
      }
    }

    if ((newRangeValue.from === -1 && newRangeValue.to === -1) ||
      (!newRangeValue.hasOwnProperty('to') && newRangeValue.from === -1)) {
      newRangeValue = -1
    }

    formik.setFieldValue(`specifications.${specId}.range`, newRangeValue)
  }

  const onSelectToChange = (specId, value) => {
    const rangeValue = formik.values.specifications[specId].range
    let newRangeValue = {}

    if (typeof rangeValue === 'number' && rangeValue === -1) {
      newRangeValue = {
        to: value || -1,
        from: -1
      }
    } else {
      newRangeValue = {
        ...rangeValue,
        to: value || -1
      }
    }

    if ((newRangeValue.from === -1 && newRangeValue.to === -1) ||
      (!newRangeValue.hasOwnProperty('from') && newRangeValue.to === -1)) {
      newRangeValue = -1
    }

    formik.setFieldValue(`specifications.${specId}.range`, newRangeValue)
  }

  const getSelectRangeField = () => {
    const specificationValues = formik.values.specifications

    const rangeValue = specificationValues && specificationValues[specification.id] ? specificationValues[specification.id].range : -1

    let valueFrom = -1
    let valueTo = -1

    if (typeof rangeValue === 'object') {
      valueFrom = rangeValue.hasOwnProperty('from') ? rangeValue.from : -1
      valueTo = rangeValue.hasOwnProperty('to') ? rangeValue.to : -1
    }

    return {
      id: `specification-filter-${specification.id}`,
      title: specification.title,
      options: specification.options,
      valueFrom,
      valueTo,
      onValueFromChange: value => onSelectFromChange(specification.id, value),
      onValueToChange: value => onSelectToChange(specification.id, value)
    }
  }
  /**
   * Resolve dependencies
   */

  const checkSpecificationVisibility = () => {
    if (!formik.values.specifications || !formik.values.specifications[specification.id]) {
      return false
    }

    const { visibilityCondition } = specification

    if (visibilityCondition) {
      if (!visibilityCondition.hide && !visibilityCondition.show) return true

      const visibilityConditionValues = {
        hide: visibilityCondition.hide
          ? Object.keys(visibilityCondition.hide).reduce((acc, curr) => {
            const isVisible = isSpecificationVisible({
              dependentOn: curr,
              dependentType: 'hide',
              dependentValues: visibilityCondition.hide[curr],
              specValues: formik.values.specifications
            })
            acc[curr] = !isVisible

            return acc
          }, {})
          : null,
        show: visibilityCondition.show
          ? Object.keys(visibilityCondition.show).reduce((acc, curr) => {
            const isVisible = isSpecificationVisible({
              dependentOn: curr,
              dependentType: 'show',
              dependentValues: visibilityCondition.show[curr],
              specValues: formik.values.specifications
            })
            acc[curr] = isVisible

            return acc
          }, {})
          : null
      }

      const isVisible = getIsVisible(visibilityConditionValues)

      let updateVisibility = false

      if (!formik.values.specifications[specification.id].hasOwnProperty('isVisible')) {
        updateVisibility = true
      } else {
        updateVisibility = formik.values.specifications[specification.id].isVisible !== isVisible
      }

      updateVisibility && formik.setFieldValue(`specifications.${specification.id}`, {
        ...formik.values.specifications[specification.id],
        isVisible
      })

      return isVisible
    }

    return true
  }

  const updateRelatedSpecs = options => {
    const { specId, updatedRangeValue, direction } = options

    formik.setFieldValue(`specifications.${specId}.range${direction}`, updatedRangeValue)

    if (Array.isArray(specification.dependents) && specification.dependents.length) {
      specification.dependents.forEach(dependency => {
        if (dependency.dependent) {
          const dependentRatio = dependency.dependentRatio

          if (updatedRangeValue === -1) {
            return formik.setFieldValue(`specifications.${dependency.dependent}.range${direction}`, -1)
          }

          const rangeValue = Number(updatedRangeValue) !== -1 ? Math.round(updatedRangeValue * dependentRatio) : -1
          formik.setFieldValue(`specifications.${dependency.dependent}.range${direction}`, rangeValue)
        }
      })
    }
  }

  let className = ''
  if (specification.inputType === 'selectRange') className = 'polja-pretrage-item__select-range'
  if (specification.inputType === 'inputRange') className = 'polja-pretrage-item__input-range-wrapper'

  return checkSpecificationVisibility() ? (
    <AdFilterField key={`specification-filter-${specification.id}`} className={className}>
      {specification.inputType === 'textInput' ? <TextInputNew {...getTextInputField()} /> : null}
      {specification.inputType === 'singleselect' ? <SelectDropdown {...getSingleSelectField()} /> : null}
      {specification.inputType === 'multiselect' ? <SelectDropdown {...getMultiSelectField()} /> : null}
      {specification.inputType === 'inputRange'
        ? <InputRange key={`input-range-${specification.id}`} {...getInputRangeField()} />
        : null
      }
      {specification.inputType === 'selectRange' ? <SelectRange {...getSelectRangeField()} /> : null}
    </AdFilterField>
  ) : null
    
}

Container.displayName = 'SpecificationFilter'

Container.propTypes = {
  specification: {
    type: PropTypes.string,
    inputType: PropTypes.string,
    id: PropTypes.number,
    defaultValue: PropTypes.any,
    allowedCharacters: PropTypes.array,
    dependent: PropTypes.number,
    dependentRatio: PropTypes.number,
    dependentType: PropTypes.string,
    dependentValues: PropTypes.array,
    measure: PropTypes.string,
    options: PropTypes.array,
    title: PropTypes.string
  }.isRequired,
  formik: {
    values: PropTypes.object,
    setFieldValue: PropTypes.func
  }.isRequired,
  equipmentVisibility: PropTypes.object.isRequired,
  setEquipmentVisibility: PropTypes.func.isRequired
}

Container.defaultProps = {
  allSpecifications: []
}
