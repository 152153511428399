import React from 'react'
import PropTypes from 'prop-types'
import { normalizeString } from 'SRC/utils'

const getStringValue = val => val ? val.toString() : ''

export const DropdownItem = ({ checkedItems, option, onChange }) => {
  return (
    <li key={`option-${option.key}`} className='select-dropdown-item'>
      <input
        id={option.id}
        type='checkbox'
        onChange={!option.isDisabled ? onChange : null}
        data-value={getStringValue(option.value)}
        checked={checkedItems.includes(getStringValue(option.value))}
      />

      <label className='select-dropdown-item-label' htmlFor={option.id}>{option.label}</label>
    </li>
  )
}

DropdownItem.propTypes = {
  option: PropTypes.shape({
    id: PropTypes.string.isRequired,
    isDisabled: PropTypes.bool.isRequired,
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  }).isRequired,
  checkedItems: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired
}
