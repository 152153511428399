import PropTypes from 'prop-types'
import React from 'react'
import { List } from '../List/index'
import { SelectedAddress } from '../SelectedAddress/index'
import { WorkingTime } from '../WorkingTime/index'

export class Container extends React.PureComponent {
  constructor (props) {
    super(props)

    const mainAddress = this.getMainAddress()
    this.state = {selectedLocation: mainAddress ? mainAddress.id : 0}
  }

  static getDerivedStateFromProps (props, state) {
    if (Array.isArray(props.addresses) && props.addresses[0]) {
      let addressId = state.selectedLocation
      if (props.addresses[0].id !== state.selectedLocation) {
        addressId = props.addresses[0].id
      }
      return {selectedLocation: addressId}
    }
    return null
  }

  getMainAddress = () => {
    if (Array.isArray(this.props.addresses)) {
      const searchedAddress = this.props.addresses.filter(address => address.main)
      if (searchedAddress.length) {
        return searchedAddress[0]
      } else {
        if (this.props.addresses.length) {
          return this.props.addresses[0]
        } else {
          return null
        }
      }
    }
  }

  getAddressById = id => {
    let address = null
    if (Array.isArray(this.props.addresses)) {
      const searchedAddress = this.props.addresses.filter(address => address.id === id)
      if (searchedAddress.length) {
        address = searchedAddress[0]
      }
    }
    return address
  }

  getRestAddresses = id => Array.isArray(this.props.addresses)
    ? this.props.addresses.filter(address => address.id !== id)
    : []

  changeSelectedAddress = e => {
    const id = e.target.dataset.id ? e.target.dataset.id : e.target.parentNode.dataset.id
    this.setState({
      ...this.state,
      selectedLocation: Number(id)
    })
  }

  render () {
    const selectedAddress = this.getAddressById(this.state.selectedLocation)
    const indexOfSelectedAddress = this.props.addresses.findIndex(item => item.id === this.state.selectedLocation)
    const restAddresses = this.getRestAddresses(this.state.selectedLocation)
    return (
      this.props.addresses.length
        ? [
          <div className='sidebar-user-ostalo' key='addresses'>
            <div className='sidebar-user-contact-content sidebar-user-contact-lokacija'>
              <a href='javascript:void (0)'>
                <SelectedAddress address={selectedAddress} />
                <List items={restAddresses} onAddressChange={this.changeSelectedAddress} />
              </a>
            </div>
          </div>,
          <WorkingTime
            workingTime={selectedAddress}
            index={indexOfSelectedAddress + 1}
            allAddressesCount={this.props.addresses.length}
            key='workingTime'
          />
        ]
        : null
    )
  }
}

Container.propTypes = {
  addresses: PropTypes.arrayOf(PropTypes.shape({
    main: PropTypes.bool,
    id: PropTypes.number.isRequired
  })).isRequired
}

Container.defaultProps = {
  addresses: []
}

Container.displayName = 'BusinessAddresses'
