import PropTypes from 'prop-types'
import React from 'react'
import { formatDateForTableView } from '../../../functions/functions'

export const Time = ({time}) => {
  return (
    <div className='oglasi-vreme'>
      {formatDateForTableView(time)}
    </div>
  )
}

Time.propTypes = {
  time: PropTypes.string
}
