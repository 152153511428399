import React from 'react'
import PropTypes from 'prop-types'

export const Telegram = ({ baseUrl, asPath, adLink }) => {
  const link = baseUrl ? baseUrl + '/' + adLink : adLink

  return (
    <div className='kontakt-icon kontakt-telegram'>
      <a href={adLink ? 'https://telegram.me/share/url?url=' + encodeURIComponent(link)
        : 'https://telegram.me/share/url?url=' + encodeURIComponent(baseUrl + asPath)}>
        <img width='33' height='29' src='/img/telegram.png' className='ico' />
      </a>
    </div>
  )
}

Telegram.propTypes = {
  baseUrl: PropTypes.string.isRequired,
  asPath: PropTypes.string.isRequred,
  adLink: PropTypes.string.isRequred
}
