import PropTypes from 'prop-types'
import React from 'react'
import { debounce } from 'throttle-debounce'
import { connect } from 'react-redux'
import { AdsFilterApi } from 'SRC/modules/ads/filter/api'
import { getCities } from 'SRC/modules/geoObjects/cities/selectors'
import { fetchCitiesByCountry } from 'SRC/modules/geoObjects/cities/actions'
import { setParentCategory, setCurrentSubCategory } from 'SRC/modules/categories/actions'
import { setCurrentGroup } from 'SRC/modules/groups/actions'
import Router from 'next/router'
import { getSearchableArray, searchCities } from 'SRC/modules/categories/components/AllCategoriesWithLettersPage/helpers'
import { globalOptions } from 'SRC/core/constants'
import config from 'SRC/config/config.yaml'

const getCategoryBCItems = breadCrumb => {
  let parentCat, group, subCategory

  if (Array.isArray(breadCrumb) && breadCrumb[0] && breadCrumb[0].type === 'parentCategory') parentCat = breadCrumb[0]
  if (Array.isArray(breadCrumb) && breadCrumb[1] && breadCrumb[1].type === 'group') group = breadCrumb[1]
  if (Array.isArray(breadCrumb) && breadCrumb[2] && breadCrumb[2].type === 'subCategory') subCategory = breadCrumb[2]

  return { parentCat, group, subCategory }
}

const getBrandProductCBItems = breadCrumb => {
  let brand, product

  if (Array.isArray(breadCrumb) && breadCrumb[3] && breadCrumb[3].type === 'brand') brand = breadCrumb[3]
  if (Array.isArray(breadCrumb) && breadCrumb[4] && breadCrumb[4].type === 'product') product = breadCrumb[4]

  return { brand, product }
}

const getServicesCBItems = breadCrumb => {
  let service, serviceType

  if (Array.isArray(breadCrumb) && breadCrumb[3] && breadCrumb[3].type === 'service') service = breadCrumb[3]
  if (Array.isArray(breadCrumb) && breadCrumb[4] && breadCrumb[4].type === 'serviceType') serviceType = breadCrumb[4]

  return { service, serviceType }
}

const getCompatibilityCBItems = breadCrumb => {
  let compCategory, compBrand, compProduct

  if (Array.isArray(breadCrumb) && breadCrumb[3] && breadCrumb[3].type === 'compatibility_category') compCategory = breadCrumb[3]
  if (Array.isArray(breadCrumb) && breadCrumb[4] && breadCrumb[4].type === 'compatibility_brand') compBrand = breadCrumb[4]
  if (Array.isArray(breadCrumb) && breadCrumb[5] && breadCrumb[5].type === 'compatibility_product') compProduct = breadCrumb[5]

  return { compCategory, compBrand, compProduct }
}

const getSpecCBItem = breadCrumb => {
  let specification

  if (Array.isArray(breadCrumb) && breadCrumb[3] && breadCrumb[3].type === 'specification') specification = breadCrumb[3]

  return { specification }
}

export class SearchInput extends React.Component {
  constructor (props) {
    super(props)

    this.api = new AdsFilterApi()

    this.inputRef = React.createRef()
    this.dropdownRef = React.createRef()

    this.state = {
      textValue: '',
      suggestions: null,
      showSuggestions: false,
      activeSuggestion: null
    }
  }

  componentDidMount () {
    const { cities, fetchCitiesByCountry } = this.props

    this.searchableArray = getSearchableArray([], { cities })

    document.addEventListener('mousedown', this.toggleOptionsVisibility)
    document.addEventListener('keyup', this.onKeyUp)

    if (!Array.isArray(cities) || !cities.length) {
      fetchCitiesByCountry(globalOptions.MontenegroId)
    }
  }

  componentDidUpdate = prevProps => {
    const { cities } = this.props

    const prevCities = prevProps.cities

    if ((!Array.isArray(prevCities) || !prevCities.length) && (Array.isArray(cities) && cities.length)) {
      this.searchableArray = getSearchableArray([], { cities })
    }
  }

  componentWillUnmount () {
    document.removeEventListener('mousedown', this.toggleOptionsVisibility)
    document.removeEventListener('keyup', this.onKeyUp)
  }

  onKeyUp = event => {
    // console.log('event.code', event.code)
    const { showSuggestions, activeSuggestion, suggestions } = this.state

    const suggestionsCount = Array.isArray(suggestions) ? suggestions.length : 0

    if (event.code === 'Enter' && activeSuggestion === null) { // Enter
      return this.submit({ isFullText: true })
    }

    if (!showSuggestions || !suggestionsCount) return

    let currentActiveSuggestion = activeSuggestion

    if (event.code === 'ArrowUp') { // arrow up
      if (activeSuggestion === null) return

      if (activeSuggestion > 0) currentActiveSuggestion--
    }

    if (event.code === 'ArrowDown') { // arrow down
      if (activeSuggestion === null) currentActiveSuggestion = 0

      if (activeSuggestion !== null && activeSuggestion < suggestionsCount - 1) currentActiveSuggestion++
    }

    if (event.code === 'Enter' && activeSuggestion !== null) { // Enter
      return this.suggestionClicked(suggestions[activeSuggestion])
    }

    this.setState({ activeSuggestion: currentActiveSuggestion })
  }

  toggleOptionsVisibility = (e) => {
    let activeSuggestion = null
    let showSuggestions = false

    if (this.inputRef.current && this.inputRef.current.contains(e.target)) {
      showSuggestions = true
      activeSuggestion = this.state.activeSuggestion
    }

    if (this.dropdownRef.current && this.dropdownRef.current.contains(e.target)) {
      showSuggestions = true
      activeSuggestion = this.state.activeSuggestion
    }

    this.setState({
      showSuggestions,
      activeSuggestion
    })
  }

  submitFullText = () => {
    const { setParentCategory, setCurrentSubCategory, setCurrentGroup } = this.props
    const { textValue } = this.state

    if (!textValue || textValue.length < 3) return

    const as = `/pretraga?fullText=${textValue}&sortBy=dateDesc`
    const href = {
      pathname: '/search',
      query: {
        fullText: textValue,
        sortBy: 'dateDesc'
      }
    }

    setParentCategory(null)
    setCurrentGroup(null)
    setCurrentSubCategory(null)

    return Router.push(href, as)
  }

  submit = (options) => {
    let as, href

    if (options && options.isFullText) return this.submitFullText()

    const { category, parentCategory, ...restParams } = options

    const pathname = restParams && Object.keys(restParams).length ? '/search' : '/category'

    const baseAs = '/'
    const baseHref = { pathname }

    href = { ...baseHref, query: {} }
    as = baseAs

    const isSelfContainedParentCategory = category === parentCategory

    if (!isSelfContainedParentCategory) {
      href.query = {
        category,
        parentCategory
      }

      as += `${parentCategory}/${category}`
    } else {
      href.query = {
        parentCategory
      }

      as += `${parentCategory}`
    }

    if (restParams && Object.keys(restParams).length) {
      href.query = {
        ...href.query,
        formStyle: 'basic',
        pageNumber: 1,
        sortBy: 'dateDesc',
        ...restParams
      }

      as += `/pretraga?pageNumber=1&formStyle=basic&sortBy=dateDesc`
      Object.keys(restParams).forEach(paramKey => {
        const paramValue = restParams[paramKey]

        as += `&${paramKey}=${paramValue}`
      })
    }

    Router.push(href, as)
  }

  onTextSearch = debounce(250, () => {
    const { textValue } = this.state

    this.getSuggestions(textValue)
  })

  getSuggestions = async textValue => {
    this.setState({ suggestions: [] })

    const suggestions = []

    if (textValue === '' || textValue < 3) { this.setState({ suggestions: [] }); return }

    const searchResult = await this.api.getElasticCBP(textValue)

    if (!searchResult || !Array.isArray(searchResult.result) || !searchResult.result.length) {
      this.setState({ suggestions: [] })
      return
    }

    for (let item of searchResult.result) {
      const breadCrumb = item.breadcrumbs
      const suggestion = {}

      const { parentCat, group, subCategory } = getCategoryBCItems(breadCrumb)
      const { brand, product } = getBrandProductCBItems(breadCrumb)
      const { service, serviceType } = getServicesCBItems(breadCrumb)
      const { compCategory, compBrand, compProduct } = getCompatibilityCBItems(breadCrumb)
      const { specification } = getSpecCBItem(breadCrumb)

      if (!parentCat || !group || !subCategory) continue

      suggestion.parentCategory = { ...parentCat, isSelfContained: true }
      suggestion.category = subCategory

      const isSelfContainedCategory = parentCat.seo === group.seo && group.seo === subCategory.seo

      if (isSelfContainedCategory) suggestion.breadCrumbs = [subCategory]
      else if (group.seo === subCategory.seo) suggestion.breadCrumbs = [parentCat, subCategory]
      else suggestion.breadCrumbs = [parentCat, group, subCategory]

      if (brand) {
        suggestion.breadCrumbs.push(brand)
        suggestion.brand = brand

        if (product) {
          suggestion.breadCrumbs.push(product)
          suggestion.product = product
        }
      }

      if (service) {
        suggestion.breadCrumbs.push(service)
        suggestion.service = service

        if (serviceType) {
          suggestion.breadCrumbs.push(serviceType)
          suggestion.serviceType = serviceType
        }
      }

      if (specification) {
        suggestion.breadCrumbs.push(specification)
        suggestion.specification = specification
      }

      if (compCategory) {
        suggestion.breadCrumbs.push(compCategory)
        suggestion.compatibilityCategory = compCategory

        if (compBrand) {
          suggestion.breadCrumbs.push(compBrand)
          suggestion.compatibilityBrand = compBrand

          if (compProduct) {
            suggestion.breadCrumbs.push(compProduct)
            suggestion.compatibilityProduct = compProduct
          }
        }
      }

      const city = searchCities(this.searchableArray, textValue)

      if (city) {
        suggestion.breadCrumbs.push({
          type: 'city',
          id: city.id,
          seo: null,
          title: city.label
        })
        suggestion.city = city
      }

      suggestions.push(suggestion)
    }

    this.setState({ suggestions })
  }

  suggestionClicked = item => {
    const params = {
      parentCategory: item.parentCategory.seo,
      category: item.category.seo
    }

    if (item.specification) {
      params.specifications = JSON.stringify([
        {
          id: item.specification.id,
          value: item.specification.title,
          values: [item.specification.title]
        }
      ])
    }

    if (item.brand) {
      params.brand = item.brand.id
      params.brands = JSON.stringify([item.brand.id])
      if (item.product) {
        params.product = item.product.id
        params.products = JSON.stringify([item.product.id])
      }
    }

    if (item.service) {
      params.service = item.service.id
      if (item.serviceType) params.serviceType = item.serviceType.id
    }

    if (item.compatibilityCategory) {
      const compatibility = {
        category: item.compatibilityCategory.id
      }

      if (item.compatibilityBrand) compatibility.brand = item.compatibilityBrand.id
      if (item.compatibilityProduct) compatibility.product = item.compatibilityProduct.id

      params.compatibility = JSON.stringify(compatibility)
    }

    if (item.city) {
      params.country = 1
      params.city = item.city.id
      params.cities = JSON.stringify([item.city.id])
    }

    this.submit(params)

    this.setState({ showSuggestions: false })
  }

  renderSuggestions = () => {
    const { suggestions, activeSuggestion } = this.state

    if (Array.isArray(suggestions) && suggestions.length) {
      return (
        <section className='categories-suggestions' ref={this.dropdownRef}>
          {suggestions.map((suggestion, index) => {
            const suggestionCategory = suggestion.category
            const suggestionItems = suggestion.breadCrumbs

            const activeSuggestionClassName = activeSuggestion === index
              ? 'categories-suggestions-container__active'
              : ''

            if (Array.isArray(suggestionItems) && suggestionCategory) {
              return (
                <ul
                  key={`suggestion-${index}`}
                  className={`categories-suggestions-container ${activeSuggestionClassName}`}
                  style={{ margin: '.4rem' }}
                  onClick={() => this.suggestionClicked(suggestion)}
                >
                  {suggestionItems.map((item, index1) => (
                    [
                      <li key={`suggestion-item-${suggestionCategory.id}-${item.id}-${index1}`}>
                        {this.renderSuggestionItem(item.title)}
                      </li>,
                      index1 !== suggestionItems.length - 1 ? <li key={`suggestion-separator-${item.id}`}> &gt; </li> : null
                    ]
                  ))}
                </ul>
              )
            }

            return null
          })}
        </section>
      )
    }

    return null
  }

  renderSuggestionItem = (title) => {
    return (
      <a href='javascript:void(0)'>
        {title}
      </a>
    )
  }

  render () {
    const placeholder = config.search.textSearchPlaceholder
    const { textValue, showSuggestions } = this.state

    return (
      <form
        onSubmit={e => { e.preventDefault() }}
        className='all-categories-with-letters'
      >
        <input
          ref={this.inputRef}
          type='text'
          placeholder={placeholder}
          onClick={() => {
            this.setState({ showSuggestions: true })
          }}
          onChange={e => {
            const value = e.target.value

            this.setState({ textValue: value }, this.onTextSearch)
          }}
          defaultValue={textValue}
          value={textValue} maxLength={50}
        />

        {textValue && textValue.length >= 3 && showSuggestions ? this.renderSuggestions() : null}

        <button className='ads-search__button' onClick={() => { this.submit({ isFullText: true }) }}>
          <i className='ogl-header-ico ico-pretraga-text-search-lupa ico' />
        </button>
      </form>
    )
  }
}

SearchInput.propTypes = {
  cities: PropTypes.array.isRequired,
  setParentCategory: PropTypes.func.isRequired,
  setCurrentSubCategory: PropTypes.func.isRequired,
  setCurrentGroup: PropTypes.func.isRequired,
  fetchCitiesByCountry: PropTypes.func.isRequired
}

SearchInput.defaultProps = {
  baseAs: '/',
  cities: [],
  baseHrefQuery: {}
}

const mapStateToProps = state => ({
  cities: getCities(state),
  filterValues: {}
})

export default connect(mapStateToProps, {
  setParentCategory,
  setCurrentSubCategory,
  setCurrentGroup,
  fetchCitiesByCountry
})(SearchInput)
