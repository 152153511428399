import initialState from 'SRC/modules/redux/initialState'
import actionTypes from 'SRC/modules/redux/actionTypes'

export const usersListSidebarReducer = (state = initialState.user.listSidebar, action) => {
  switch (action.type) {
    case actionTypes.SET_SIDEBAR_USERS_LOADING:
      return {...state, isLoading: action.payload}
    case actionTypes.SET_SIDEBAR_USERS:
      const users = action.payload
      if (users && users.length) {
        return { ...state, elements: users }
      } else {
        return { ...state, elements: [] }
      }
    default:
      return state
  }
}
