import React from 'react'
import PropTypes from 'prop-types'

export const Search = ({onChange, onSubmit, placeholder}) => (
  <div className='dodavanje-oglasa-search'>
    <div className='izaberi-kategoriju'>
      <i className='ico-klik ico' />
      <p>Izaberite kategoriju</p>
      <div className='ili'>
        ili
      </div>
    </div>
    <form className='dodavanje-oglasa-form' onSubmit={onSubmit}>
      <input type='text' placeholder={placeholder} onChange={onChange} />
      <button type='submit'><i className='ico-pretraga-text-search-lupa ico' /></button>
    </form>
  </div>
)

Search.propTypes = {
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
  placeholder: PropTypes.string
}

Search.defaultProps = {
  onChange: () => {}
}
