/* eslint-disable no-tabs */
import React from 'react'
import config from 'SRC/config/config.yaml'

const AutodilerContact = () => (
  <div className='novosti-text'>
    <p>Ukoliko želite da se Vaš oglas izbriše, izvrši promjena cijene ili odradi neka druga korekcija,
      poruku ili poziv morate izvršiti isključivo sa broja koji je u Vašem oglasu ostavljen kao kontakt telefon. Na ovaj način spriječavamo svaku zloupotrebu Vaših oglasa.</p>
    <p>Brojevi telefona naših administratora:</p>
    <p><strong>Mobilni:</strong> 067/312-555 : Ponedjeljak - Subota: od 09:00h do 22:00h</p>
    <p><strong>Mobilni:</strong> 067/313-555 : Nedelja od 09:00h do 22:00h</p>
    <br />
    <p>Za sva ostala pitanja, komentare, kritike ili pohvale, možete nas kontaktirati na email adresu.</p>
    {/* <p><strong>Fiksni:</strong> 030/55-00-99</p> */}
    <p><strong>Email:</strong><a href='mailto:info@autodiler.me'>info@autodiler.me</a></p>

    <br />

    <div>
      <div>
        <iframe width='670' height='500' id='gmap_canvas'
          src='https://maps.google.com/maps?q=WebLab%20d.o.o.&t=&z=17&ie=UTF8&iwloc=&output=embed'
          frameBorder='0' />
      </div>
    </div>
  </div>
)

const OglasiContact = () => (
  <div className='novosti-text'>
    <p>Ukoliko želite da se Vaš oglas izbriše, izvrši promjena cijene ili odradi neka druga korekcija,
      poruku ili poziv morate izvršiti isključivo sa broja koji je u Vašem oglasu ostavljen kao kontakt telefon. Na ovaj način spriječavamo svaku zloupotrebu Vaših oglasa.</p>
    <p>Brojevi telefona naših administratora:</p>
    <p><strong>Mobilni:</strong> 067/312-555 : Ponedjeljak - Subota: od 09:00h do 22:00h</p>
    <p><strong>Mobilni:</strong> 067/313-555 : Nedelja od 09:00h do 22:00h</p>
    <br />
    <p>Za sva ostala pitanja, komentare, kritike ili pohvale, možete nas kontaktirati na email adresu.</p>
    {/* <p><strong>Fiksni:</strong> 030/55-00-99</p> */}
    <p><strong>Email:</strong><a href='mailto:info@oglasi.me'>info@oglasi.me</a></p>

    <br />

    <div>
      <div>
        <iframe width='670' height='500' id='gmap_canvas'
          src='https://maps.google.com/maps?q=WebLab%20d.o.o.&t=&z=17&ie=UTF8&iwloc=&output=embed'
          frameBorder='0' />
      </div>
    </div>
  </div>
)

export const Contact = () => {
  switch (config.siteName) {
    case 'AutoDiler': return <AutodilerContact />
    case 'Oglasi': return <OglasiContact />
    default: return null
  }
}
