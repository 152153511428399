import { TypesHelper } from 'SRC/utils'
import { globalOptions, adPriceTypes } from 'Core/constants'
import moment from 'moment'

export function formatLocationForTableView (location) {
  if (TypesHelper.isString(location) && location.length > 0) {
    if (location.length <= globalOptions.maxLengthOfCityName) {
      return location.slice(0)
    } else {
      return location.slice(0, globalOptions.maxLengthOfCityName) + '..'
    }
  } else {
    return location
  }
}

export function formatDateForTableView (date) {
  let formatDate = ''
  if (date) {
    const timePassedSinceLastRefresh = moment.duration(moment(new Date()).diff(moment(date))).asSeconds()
    if (timePassedSinceLastRefresh <= 59) {
      if (timePassedSinceLastRefresh <= 0) {
        formatDate = moment(date).format('DD.MM.YY')
      } else {
        formatDate = `prije ${Math.ceil(timePassedSinceLastRefresh)} sek`
      }
    } else if (timePassedSinceLastRefresh >= 60 && timePassedSinceLastRefresh <= 3570) {
      const minutes = Math.floor(timePassedSinceLastRefresh / 60 + (timePassedSinceLastRefresh % 60 > 30 ? 1 : 0))
      formatDate = `prije ${minutes} min`
    } else if (timePassedSinceLastRefresh >= 3571 && timePassedSinceLastRefresh <= 5400) {
      formatDate = `prije 1 h`
    } else if (timePassedSinceLastRefresh > 5400 && timePassedSinceLastRefresh <= 84600) {
      const hours = Math.floor(timePassedSinceLastRefresh / 3600 + (timePassedSinceLastRefresh % 3600 > 1800 ? 1 : 0))
      formatDate = `prije ${hours} h`
    } else if (timePassedSinceLastRefresh > 84600 && timePassedSinceLastRefresh <= 129600) {
      formatDate = `prije 1 dan`
    } else if (timePassedSinceLastRefresh > 129600 && timePassedSinceLastRefresh <= 648000) {
      const days = Math.floor(timePassedSinceLastRefresh / 86400 + (timePassedSinceLastRefresh % 86400 > 43200 ? 1 : 0))
      formatDate = `prije ${days} dana`
    } else {
      formatDate = moment(date).format('DD.MM.YY')
    }
  }
  return formatDate
}

export const sortSpecifications = (specifications = []) => {
  if (Array.isArray(specifications)) {
    return specifications.sort((a, b) => b.weightCatalog - a.weightCatalog)
  } else {
    return []
  }
}

export const getTitleSpec = (id, specs) => {
  let title = ''
  if (id && Array.isArray(specs)) {
    const spec = specs.filter(item => item.id === id)
    if (spec.length) {
      title = spec[0].value
    }
  }
  return title
}

export const getPriceTypeTitle = priceType => {
  let title
  switch (priceType) {
    case adPriceTypes.fixed.id:
      title = adPriceTypes.fixed.title
      break
    case adPriceTypes.negotiated.id:
      title = adPriceTypes.negotiated.title
      break
    case adPriceTypes.urgent.id:
      title = adPriceTypes.urgent.title
      break
    default:
      title = ''
  }
  return title
}

export const getFormatDate = str => {
  if (str) {
    const date = new Date(str)
    if (date) {
      let day = date.getDate()
      if (day < 10) {
        day = `0${day}`
      }
      let month = date.getMonth() + 1
      if (month < 10) {
        month = `0${month}`
      }
      const year = date.getFullYear()
      let hours = date.getHours()
      if (hours < 10) {
        hours = `0${hours}`
      }
      let minutes = date.getMinutes()
      if (minutes < 10) {
        minutes = `0${minutes}`
      }
      return `${day}.${month}.${year} u ${hours}:${minutes}h`
    }
    return ''
  }
  return ''
}
