import { BaseApi } from 'Core/api/BaseApi'

export class Api extends BaseApi {
  getUsers = async (page, limit, types) => {
    try {
      const users = await Api.post('/user/list', { page, limit, types })
      if (!users.body.error && Array.isArray(users.body)) {
        return users.body
      } else {
        console.log(users.body.error)
        return { error: users.body.error }
      }
    } catch (e) {
      return { error: e.message }
    }
  }
}
