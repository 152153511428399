import initialState from 'src/modules/redux/initialState'
import actionTypes from 'src/modules/redux/actionTypes'

export const ordersReducer = (state = initialState.orders, action) => {
  switch (action.type) {
    /**
     * List
     */
    case actionTypes.SET_BOUGHT_ITEMS:
      return {
        ...state,
        items: action.payload
      }
    case actionTypes.SET_BOUGHT_ITEMS_LOADING:
      return {
        ...state,
        itemsLoading: action.payload
      }

    /**
     * Pagination
     */
    case actionTypes.SET_BOUGHT_ITEMS_PAGINATION_PARAMS:
      return {
        ...state,
        pagination: action.payload
      }

    /**
     * Notification
     */
    case actionTypes.SET_UPDATE_BOUGHT_ITEM_NOTIFICATION:
      return {
        ...state,
        boughtItemUpdateNotification: action.payload
      }

    /**
     * Current item
     */
    case actionTypes.SET_CURRENT_BOUGHT_ITEM:
      return {
        ...state,
        currentItem: action.payload
      }

    /**
     * Reset
     */
    case actionTypes.RESET_BOUGHT_ITEMS:
      return {
        ...state,
        items: [],
        pagination: {
          page: 1,
          limit: 10,
          count: 0
        },
        itemsLoading: false,
        boughtItemUpdateNotification: '',
        currentItem: null
      }
    default:
      return state
  }
}
