import PropTypes from 'prop-types'
import React from 'react'
import Link from 'next/link'

export const Item = ({ item }) => {
  return <div className='korisne-informacije-item clearfix'>
    <Link
      href={`${item.url && item.url.href ? item.url.href : 'javascript:void(0)'}`}
      as={item.url && item.url.as ? item.url.as : 'javascript:void(0)'}
    >
      <a href='javascript:void(0)'>
        <div className='korisne-informacije-img'>
          <i className={item.icon} />
        </div>
        <div className='korisne-informacije-content'>
          <h5>{item.title}</h5>
          <p>{item.content} {item.contentBold ? <strong>{item.contentBold}</strong> : null}</p>
        </div>
      </a>
    </Link>
  </div>
}

Item.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    contentBold: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    url: PropTypes.shape({
      as: PropTypes.string.isRequired,
      href: PropTypes.string.isRequired
    }).isRequired
  })
}
