import { BaseApi } from 'Core/api/BaseApi'
import { TypesHelper } from 'SRC/utils'

export class Api extends BaseApi {
  getCitiesByRegion = async regionId => {
    const defaultResult = []
    if (TypesHelper.isPositiveNumber(regionId)) {
      try {
        const {body} = await Api.post(`/city/listByRegion`, {region: regionId})
        if (!body.error && body.cities && Array.isArray(body.cities)) {
          return body.cities.map(city => ({
            id: city.id,
            label: city.name,
            value: city.id
          }))
        }
      } catch (error) {
        return defaultResult
      }
    } else {
      return defaultResult
    }
  }

  getCitiesByCountry = async country => {
    const defaultResult = []
    if (TypesHelper.isPositiveNumber(country)) {
      try {
        const {body} = await Api.post(`/city/listByCountry`, {country})
        if (!body.error && body.cities && Array.isArray(body.cities)) {
          return body.cities.map(city => ({
            id: city.id,
            label: city.name,
            seo: city.seo,
            value: city.id,
            region: city.region
          }))
        } else {
          return defaultResult
        }
      } catch (error) {
        return defaultResult
      }
    } else {
      return defaultResult
    }
  }

  getCitiesByCountryWithinRegions = async country => {
    const defaultResult = []
    if (TypesHelper.isPositiveNumber(country)) {
      try {
        const {body} = await Api.post(`/city/listByCountryWithinRegions`, {country})
        if (!body.error && body.regions && Array.isArray(body.regions)) {
          return body.regions.map(region => ({
            id: region.id,
            label: region.name,
            cities: region.cities.map(city => ({
              id: city.id,
              label: city.name,
              seo: city.seo,
              value: city.id
            }))
          }))
        } else {
          return defaultResult
        }
      } catch (error) {
        return defaultResult
      }
    } else {
      return defaultResult
    }
  }
}
