import React from 'react'
import PropTypes from 'prop-types'
import { Item } from '../Item'

export const List = ({ items }) => {
  return items.map(item => <Item item={item} />)
}

List.propTypes = {
  items: PropTypes.array.isRequired
}
