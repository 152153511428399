import { AdsListApi } from '../../api'
import { setAdsWithPromos } from '../setAdsWithPromos'
import { setAdsWithPromosQuery } from '../setAdsWithPromosQuery'
import { getAdsWithPromos } from '../../selectors'
import { setAdsWithPromosLoading } from '../setAdsWithPromosLoading'
import { setPaginationParams } from '../../../paginationWithPromos/actions'
import { getPaginationParams } from '../../../paginationWithPromos/selectors'

export const fetchAdsWithPromos = (requestParams) => {
  return async (dispatch, getState) => {
    const state = getState()
    const currentAds = getAdsWithPromos(state)

    try {
      dispatch(setAdsWithPromosLoading(true))
      const api = new AdsListApi()

      const paginationParams = getPaginationParams(state)

      const { ads, count } = await api.getAdsWithPromos({ ...requestParams, limit: paginationParams.countPerPage })
      console.log('pads, count', ads, count)

      dispatch(setAdsWithPromos(ads))

      const pagination = {
        currentPage: requestParams.page,
        count,
        countPerPage: paginationParams.countPerPage
      }

      dispatch(setAdsWithPromosQuery({ phone: requestParams.phone }))
      dispatch(setPaginationParams(pagination))
      dispatch(setAdsWithPromosLoading(false))
    } catch (e) {
      dispatch(setAdsWithPromos(currentAds))
      dispatch(setAdsWithPromosLoading(false))
      dispatch(setAdsWithPromosQuery(null))
    }
  }
}
