import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Router from 'next/router'
import { SelectDropdown, AdFilterField } from 'SRC/ui/FormElementsNew'
import {
  getParentCategory,
  getCurrentSubCategory,
  getCategories,
  getActiveCategories
} from 'SRC/modules/categories/selectors'
import { fetchCategories } from 'SRC/modules/categories/actions'
import { getCurrentGroup, getGroups } from 'SRC/modules/groups/selectors'

const CategoriesFilter = ({
  currentParentCategory,
  currentSubCategory,
  currentGroup,
  categories,
  groups,
  activeCategories,
  baseAs,
  baseHrefQuery,
  fetchCategories
}) => {
  React.useEffect(() => { fetchCategories() }, [])

  const prepareCategoriesByParents = () => (categories || []).map(parentCategory => ({
    id: parentCategory.id,
    label: parentCategory.title,
    isGrouped: true,
    options: parentCategory.children.map(childCategory => ({
      id: childCategory.id,
      label: childCategory.title,
      value: JSON.stringify({
        parentCategory: parentCategory.seo,
        category: childCategory.seo
      })
    }))
  }))

  const onCategoryWithParentsChange = data => {
    const value = JSON.parse(data)

    if (Number(value) === -1) return Router.push('/index', '/')

    const isSelfContained = value.parentCategory === value.category

    let href = `/category?parentCategory=${value.parentCategory}`
    let as = `/${value.parentCategory}`

    if (!isSelfContained) {
      href = `/category?parentCategory=${value.parentCategory}&category=${value.category}`
      as = `/${value.parentCategory}/${value.category}`
    }

    Router.push(href, as)
  }

  const prepareCategoriesWithGroups = () => {
    if (!currentParentCategory || !Array.isArray(groups)) return []

    return groups.map(group => {
      let isGrouped = true

      if (group.isCategoryGroup) isGrouped = false
      if (group.isGroup) isGrouped = true

      if (isGrouped) {
        return {
          id: group.id,
          label: group.title,
          isGrouped,
          options: group.categories.map(category => ({
            id: category.id,
            label: category.title,
            value: JSON.stringify({
              parentCategory: currentParentCategory.seo,
              category: category.seo
            })
          }))
        }
      }

      return {
        id: group.id,
        label: group.title,
        isGrouped,
        value: JSON.stringify({
          parentCategory: currentParentCategory.seo,
          category: group.seo
        })
      }
    })
  }

  const onCategoryWithGroupsChange = data => {
    const value = JSON.parse(data)

    const isSelfContained = value && value.category && value.parentCategory && value.category === value.parentCategory

    let href, as

    if (Number(value) === -1 || isSelfContained) {
      if (!currentParentCategory) return null
      href = `/category?parentCategory=${currentParentCategory.seo}`
      as = `/${currentParentCategory.seo}`

      return Router.push(href, as)
    }

    href = `/category?parentCategory=${value.parentCategory}&category=${value.category}`
    as = `/${value.parentCategory}/${value.category}`

    Router.push(href, as)
  }

  const prepareCategoriesByCurrentGroup = () => {
    if (!currentGroup || !currentGroup.isGroup) return []

    return (currentGroup.categories || []).map(category => {
      return {
        id: category.id,
        label: category.title,
        value: JSON.stringify({
          parentCategory: currentParentCategory?.seo || null,
          category: category.seo
        })
      }
    })
  }

  const getCategoryValue = () => {
    let value = '-1'

    if (currentParentCategory && currentSubCategory) {
      value = JSON.stringify({
        parentCategory: currentParentCategory.seo,
        category: currentSubCategory.seo
      })
    }

    return value
  }

  const onCategoryByCurrentGroupChange = data => {
    const value = JSON.parse(data)

    let href, as

    const isSelfContained = value && value.category && value.parentCategory && value.category === value.parentCategory

    if (Number(value) === -1 || isSelfContained) {
      if (!currentParentCategory || !currentGroup || !currentSubCategory) return null

      if (currentSubCategory.seo === currentGroup.seo) {
        href = `/category?parentCategory=${currentParentCategory.seo}`
        as = `/${currentParentCategory.seo}`
      } else {
        href = `/category?parentCategory=${currentParentCategory.seo}&category=${currentGroup.seo}`
        as = `/${currentParentCategory.seo}/${currentGroup.seo}`
      }

      return Router.push(href, as)
    }

    href = `/category?parentCategory=${value.parentCategory}&category=${value.category}`
    as = `/${value.parentCategory}/${value.category}`

    Router.push(href, as)
  }

  const prepareActiveCategories = () => {
    return Array.isArray(activeCategories) ? activeCategories.map(parentCategory => ({
      id: parentCategory.id,
      label: parentCategory.title,
      isGrouped: true,
      options: parentCategory.children.map(childCategory => ({
        id: childCategory.id,
        label: childCategory.title,
        value: JSON.stringify({
          parentCategory: parentCategory.seo,
          category: childCategory.seo
        })
      }))
    })) : []
  }

  const onActiveCategoryChange = data => {
    const value = JSON.parse(data)

    let as = baseAs
    let href = {
      pathname: '/user',
      query: {
        ...baseHrefQuery
      }
    }

    if (Number(value) === -1) return Router.push(href, as)

    href = {
      pathname: '/user',
      query: {
        ...baseHrefQuery,
        parentCategory: value.parentCategory
      }
    }

    as = `${baseAs}${value.parentCategory}`

    if (typeof value.parentCategory === 'string' && typeof value.category === 'string' && value.parentCategory !== value.category) {
      href.query.category = value.category
      as = `${baseAs}${value.parentCategory}/${value.category}`
    }

    Router.push(href, as)
  }

  if (baseHrefQuery.username) {
    const preparedActiveCategories = prepareActiveCategories()

    return (
      <AdFilterField key='active-categories-filter'>
        <SelectDropdown
          id='active-categories'
          title='Kategorije'
          isDisabled={!Array.isArray(activeCategories) || !activeCategories.length}
          isSearchable
          value={getCategoryValue()}
          options={preparedActiveCategories}
          onChange={onActiveCategoryChange}
        />
      </AdFilterField>
    )
  }

  if (!currentParentCategory) {
    const preparedCategoriesByParents = prepareCategoriesByParents()

    return preparedCategoriesByParents && preparedCategoriesByParents.length ? (
      <AdFilterField key='categories-filter'>
        <SelectDropdown
          id='categories-with-parents'
          title='Kategorije'
          isSearchable
          options={preparedCategoriesByParents}
          onChange={onCategoryWithParentsChange}
        />
      </AdFilterField>
    ) : null
  }

  const categoriesByCurrentGroup = prepareCategoriesByCurrentGroup()

  if (categoriesByCurrentGroup.length) {
    return (
      <AdFilterField key='categories-filter'>
        <SelectDropdown
          title='Kategorije'
          value={getCategoryValue()}
          isSearchable
          options={categoriesByCurrentGroup}
          onChange={onCategoryByCurrentGroupChange}
        />
      </AdFilterField>
    )
  }

  const preparedCategoriesWithGroups = prepareCategoriesWithGroups()

  return preparedCategoriesWithGroups?.length ? (
    <AdFilterField key='categories-filter'>
      <SelectDropdown
        title='Kategorije'
        isSearchable
        isGrouped
        value={getCategoryValue()}
        options={preparedCategoriesWithGroups}
        onChange={onCategoryWithGroupsChange}
      />
    </AdFilterField>
  ) : null

}

const mapStateToProps = state => {
  return {
    currentParentCategory: getParentCategory(state),
    currentSubCategory: getCurrentSubCategory(state),
    currentGroup: getCurrentGroup(state),
    groups: getGroups(state),
    categories: getCategories(state),
    activeCategories: getActiveCategories(state)
  }
}

CategoriesFilter.propTypes = {
  currentParentCategory: PropTypes.shape({
    id: PropTypes.number,
    seo: PropTypes.string
  }).isRequired,
  currentSubCategory: PropTypes.shape({
    id: PropTypes.number,
    seo: PropTypes.string
  }).isRequired,
  currentGroup: PropTypes.shape({
    id: PropTypes.number,
    seo: PropTypes.string,
    parentCategory: PropTypes.number,
    categories: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      title: PropTypes.string,
      seo: PropTypes.string
    }))
  }).isRequired,
  categories: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    seo: PropTypes.string,
    children: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      title: PropTypes.string,
      seo: PropTypes.string
    }))
  })).isRequired,
  groups: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    seo: PropTypes.string,
    parentCategory: PropTypes.number,
    iconSvg: PropTypes.string,
    subClass: PropTypes.string,
    categories: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      title: PropTypes.string,
      seo: PropTypes.string
    })),
    isGroup: PropTypes.bool,
    isCategoryGroup: PropTypes.bool,
    showCategoriesAsDropdown: PropTypes.bool,
    weight: PropTypes.number
  })).isRequired,
  activeCategories: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    parentCategory: PropTypes.number,
    title: PropTypes.string,
    seo: PropTypes.string,
    children: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      title: PropTypes.string,
      seo: PropTypes.string
    }))
  })),
  baseAs: PropTypes.string,
  baseHrefQuery: PropTypes.object,
  fetchCategories: PropTypes.func.isRequired
}

CategoriesFilter.defaultProps = {
  baseAs: '/',
  baseHrefQuery: {},
  categories: []
}

export default connect(mapStateToProps, { fetchCategories })(CategoriesFilter)
