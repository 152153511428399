import PropTypes from 'prop-types'
import React from 'react'
import { Form, reduxForm } from 'redux-form'
import { ReportRadio } from '../Radio'
// import { ReportInput } from '../Input'
import { ReportTextarea } from '../Textarea'

export const ReportForm = ({onClose, reportTypes, handleSubmit, onSubmit}) =>
  <div className='report-block'>
    <div className='report-content'>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className='report-form'>
          <div className='report-column'>
            {
              reportTypes.map(
                item => <ReportRadio id={item.id} value={item.type} label={item.description} key={item.id} />
              )
            }
          </div>
          <div className='report-column'>
            <ReportTextarea name='comment' classname='report-comment' placeholder='Dodatni komentar' />
            {/* <ReportInput className='report-email' name='email' placeholder='Upiši tvoj e-mail' /> */}
          </div>
        </div>
        <div className='report-buttons'>
          <button className='report-button__close' onClick={onClose}>Zatvori</button>
          <button className='report-button__submit' type='submit'>U redu</button>
        </div>
      </Form>
    </div>
  </div>

ReportForm.propTypes = {
  onClose: PropTypes.func.isRequired,
  reportTypes: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    description: PropTypes.string.isRequired,
    type: PropTypes.number.isRequired
  })),
  handleSubmit: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired
}

export default reduxForm({form: 'report'})(ReportForm)
