import config from 'SRC/config/config.yaml'

const getPriceTitle = ({
  price,
  priceOnRequest,
  priceFrom,
  priceTo, 
  priceDaily,
  priceMonthly,
  priceSpecification
}) => {
  if (priceSpecification === 0) return null

  if (typeof priceSpecification === 'object' && priceSpecification !== null && typeof priceSpecification.value !== 'undefined') {
    return `${priceSpecification.value} ${priceSpecification.measure || ''}`
  }

  if (priceOnRequest) return 'Cijena na upit'

  if (priceFrom && priceTo) return `${priceFrom} do ${priceTo} €`
  if (priceFrom && !priceTo) return `Već od ${priceFrom} €`
  if (!priceFrom && priceTo) return `Do ${priceTo} €`

  if (priceDaily && priceMonthly) return `${priceDaily} € na dan - ${priceMonthly} € mjesečno`
  if (priceDaily && !priceMonthly) return `${priceDaily} € na dan`
  if (!priceDaily && priceMonthly) return `${priceMonthly} € mjesečno`

  if (price) return `Cijena ${price} €`
  return 'Po dogovoru'
}

export const getTitleForCategory = ({
  category,
  titleCompiled,
  price,
  priceOnRequest,
  priceFrom,
  priceTo,
  priceDaily,
  priceMonthly,
  brand,
  product,
  service,
  serviceType,
  adsSpecifications,
  country,
  city,
  location
}) => {
  let title
  const titleParts = []
  const adTitle = titleCompiled || ''

  if (adTitle) {
    titleParts.push(adTitle)
  }

  const priceSpecification = category ? category.priceSpecification : null

  const priceTitle = getPriceTitle({
    price,
    priceOnRequest,
    priceFrom,
    priceTo,
    priceDaily,
    priceMonthly,
    priceSpecification
  })

  if (priceTitle) {
    titleParts.push(priceTitle)
  }

  let restPartOfTitle
  const arRestPartOfTitle = []
  if (country && country.name) {
    arRestPartOfTitle.push(country.name)
  }
  if (city && city.name) {
    arRestPartOfTitle.push(city.name)
  }
  if (location && location.name) {
    arRestPartOfTitle.push(location.name)
  }
  if (category && category.title) {
    arRestPartOfTitle.push(category.title)
  }
  restPartOfTitle = arRestPartOfTitle.join(' ')
  if (restPartOfTitle) {
    titleParts.push(restPartOfTitle)
  }

  title = titleParts.join(' - ')

  return title ? `${title} | ${config.siteName}` : config.siteName
}
