import PropTypes from 'prop-types'
import Link from 'next/link'
import React from 'react'
import ReactSVG from 'react-svg'
import { isObjectContainedAnotherObject } from 'SRC/utils/Utils'

const renderIcon = icoClass => {
  return <i className={`${icoClass} ico`} />
}

const renderSvg = iconSvg => {
  return (
    <ReactSVG src={iconSvg} beforeInjection={svg => {
      svg.setAttribute('style', 'width: auto; margin-top: 5px; height: 25px;')
    }} wrapper='span' />
  )
}

export const ItemBottom = ({
  id,
  icoClass,
  text,
  children,
  className,
  link,
  targetBlank,
  rightText,
  rightBg,
  as,
  pages,
  isActive,
  is404,
  iconSvg,
  closeIcon
}) => {
  const activeClass = !is404 && isActive ? `active` : ``

  const itemClassName = `${className}${activeClass ? ` ${activeClass}` : ''}${id ? ` ${id}` : ''}`

  return (
    <li className={itemClassName.trim()}>
      {link ? (
        <Link href={link} as={as}>
          <a target={targetBlank ? '_blank' : '_self'} onClick={closeIcon}>
            {iconSvg
              ? renderSvg(iconSvg)
              : icoClass
                ? renderIcon(icoClass)
                : null
            }
            <p className='bm-item-bottom__text'>{text}</p>
            {rightText ? (
              <p
                className='bm-item__text-right'
                style={{ backgroundColor: rightBg || '#54317E' }}
              >
                {rightText}
              </p>
            ) : null}
          </a>
        </Link>
      ) : null}
    </li>
  )
}

ItemBottom.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  icoClass: PropTypes.string,
  link: PropTypes.string,
  as: PropTypes.string,
  rightText: PropTypes.string,
  rightBg: PropTypes.string,
  pages: PropTypes.any,
  text: PropTypes.string,
  isActive: PropTypes.bool,
  is404: PropTypes.bool.isRequired,
  closeIcon: PropTypes.func.isRequired
}

ItemBottom.defaultProps = {
  is404: false
}
