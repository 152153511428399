import React from 'react'
import PropTypes from 'prop-types'
import { adsListViews } from 'Core/constants'

import { Banner } from '../Banner'
import { Item } from '../Item'

export const List = React.forwardRef((props, ref) => (
  <div
    className={`oglasi-content-text ${props.ads.length ? '' : 'oglasi-content-list_no-ads'}`}
    ref={ref}
  >
    {props.ads.length && props.style === adsListViews.TABLE ? props.ads.map(item => {
      if (item.banner) return <Banner key={item.key} slotId={item.slotId} />
      return <Item ad={item} key={item.id} />
    }) : (
      <div className='oglasi-content-text__no-ads-text'>
        {props.noAdsMessage}
      </div>
    )}
  </div>
))

List.displayName = 'GridView'

List.propTypes = {
  ads: PropTypes.arrayOf(PropTypes.object).isRequired,
  style: PropTypes.oneOf([
    adsListViews.LIST,
    adsListViews.TABLE
  ]),
  noAdsMessage: PropTypes.string
}

List.defaultProps = {
  ads: []
}
