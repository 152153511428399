import PropTypes from 'prop-types'
import React from 'react'
import { debounce } from 'throttle-debounce'
import { connect } from 'react-redux'
import Router from 'next/router'
import { getCategoriesWithGroups } from 'SRC/modules/categories/selectors'
import { getCities } from 'SRC/modules/geoObjects/cities/selectors'
import { getIsSelfConainedCategory } from 'SRC/modules/categories/functions'
import { AdsFilterApi } from 'SRC/modules/ads/filter/api'
import { getSearchableArray, search } from 'SRC/modules/categories/components/AllCategoriesWithLettersPage/helpers'
import Modal from 'react-responsive-modal'
import { Header } from '../Header'
import { Ads } from '../Ads'
// import config from 'SRC/config/config.yaml'

const popupStyles = {
  modalContainer: {
    overflowY: 'hidden'
  },
  modal: {
    width: '85%',
    maxWidth: '85%',
    height: '90vh',
    borderRadius: 10
  }
}

class Popup extends React.Component {
  constructor (props) {
    super(props)

    this.inputRef = React.createRef()
    this.dropdownRef = React.createRef()
    this.popupRef = React.createRef()

    this.api = new AdsFilterApi()

    this.state = {
      textValue: '',
      filteredCategories: null,
      showSuggestions: false,
      ads: []
    }
  }

  componentDidMount () {
    const { categoriesWithGroups, cities } = this.props
    console.log('cities', cities)

    this.searchableArray = getSearchableArray(categoriesWithGroups, {
      cities
    })

    console.log('searchableArray', this.searchableArray)

    document.addEventListener('mousedown', this.toggleOptionsVisibility)
  }

  componentWillUnmount () {
    document.removeEventListener('mousedown', this.toggleOptionsVisibility)
  }

  toggleOptionsVisibility = (e) => {
    let showSuggestions = false

    if (this.inputRef.current && this.inputRef.current.contains(e.target)) {
      showSuggestions = true
    }

    if (this.dropdownRef.current && this.dropdownRef.current.contains(e.target)) {
      showSuggestions = true
    }

    this.setState({
      showSuggestions
    })
  }

  submit = (options) => {
    const { categoriesWithGroups } = this.props
    const { textValue } = this.state

    let as, href

    if (!options) {
      if (!textValue || textValue.length < 3) return

      as = `/pretraga?fullText=${textValue}&sortBy=dateDesc`
      href = {
        pathname: '/search',
        query: {
          fullText: textValue,
          sortBy: 'dateDesc'
        }
      }

      return Router.push(href, as)
    }

    const { category, parentCategory, specification, country, city, cities } = options

    const baseAs = '/'
    const baseHref = {
      pathname: specification || (country && city && Array.isArray(cities)) ? '/search' : '/category'
    }

    href = { ...baseHref, query: {} }
    as = baseAs

    let isSelfContainedParentCategory = false

    if (category === parentCategory) {
      const findParentCategory = Array.isArray(categoriesWithGroups)
        ? categoriesWithGroups.find(cat => cat.seo === parentCategory)
        : null

      if (findParentCategory && getIsSelfConainedCategory(findParentCategory)) {
        isSelfContainedParentCategory = true
      }
    }

    if (!isSelfContainedParentCategory) {
      href.query = {
        category,
        parentCategory
      }

      as += `${parentCategory}/${category}`
    } else {
      href.query = {
        parentCategory
      }

      as += `${parentCategory}`
    }

    if (specification) {
      href.query = {
        ...href.query,
        formStyle: 'basic',
        pageNumber: 1,
        specifications: JSON.stringify([specification]),
        sortBy: 'dateDesc'

      }

      as += `/pretraga?pageNumber=1&formStyle=basic&sortBy=dateDesc&specifications=${JSON.stringify([specification])}`
    }

    if (country && city && Array.isArray(cities)) {
      href.query = {
        ...href.query,
        formStyle: href.query.formStyle || 'basic',
        country,
        city,
        cities: JSON.stringify(cities),
        pageNumber: 1,
        sortBy: 'dateDesc'
      }

      if (as.indexOf('pretraga') !== -1) {
        as += `&country=${country}&city=${city}&cities=${JSON.stringify(cities)}`
      } else {
        as += `/pretraga?pageNumber=1&formStyle=${href.query.formStyle || 'basic'}&sortBy=dateDesc&country=${country}&city=${city}&cities=${JSON.stringify(cities)}`
      }
    }

    Router.push(href, as)
  }

  getAds = async () => {
    const { textValue } = this.state

    if (textValue && textValue.length >= 3) {
      const textSearchResult = await this.api.getElasticTextSearch(textValue)

      console.log('textSearchResult', textSearchResult)
      this.setState({ ads: textSearchResult })
    }
  }

  onTextSearch = debounce(1000, () => {
    const { textValue } = this.state

    const filteredCategories = textValue === '' ? null : search(this.searchableArray, textValue)

    this.setState({
      filteredCategories
    })

    this.getAds()
  })

  suggestionClicked = item => {
    console.log('suggestionClicked item', item)

    if (item.parentCategory && item.parentCategory.isSelfContainedParentCategory) {
      const params = { category: item.seo, parentCategory: item.seo }

      if (item.specification) {
        params.specification = item.specification
      }

      if (item.city) {
        params.country = 1
        params.city = item.city.id
        params.cities = [item.city.id]
      }

      this.submit(params)
    } else {
      const params = { category: item.seo, parentCategory: item.parentCategory.seo }

      if (item.specification) {
        params.specification = item.specification
      }

      if (item.city) {
        params.country = 1
        params.city = item.city.id
        params.cities = [item.city.id]
      }

      this.submit(params)
    }

    this.setState({ showSuggestions: false })
  }

  render () {
    const { popupVisible, closePopup } = this.props
    const { textValue, ads, showSuggestions, filteredCategories } = this.state

    return (
      <Modal
        open={popupVisible}
        onClose={closePopup}
        center
        closeOnEsc
        closeOnOverlayClick
        onExited={closePopup}
        styles={popupStyles}
      >
        <section className='ads-search-popup'>
          <Header
            textValue={textValue}
            showSuggestions={showSuggestions && textValue && textValue.length >= 3}
            filteredCategories={filteredCategories}
            suggestionClicked={this.suggestionClicked}
            onTextChange={e => {
              const value = e.target.value

              this.setState({ textValue: value }, this.onTextSearch)
            }}
          />

          <main className='ads-search-popup-main'>
            <div className='ads-search-popup__left' />
            {Array.isArray(ads) && ads.length ? <Ads ads={ads} /> : null}
          </main>
        </section>
      </Modal>
    )
  }
}

Popup.propTypes = {
  categoriesWithGroups: PropTypes.array.isRequired,
  cities: PropTypes.array.isRequired,
  popupVisible: PropTypes.bool.isRequired,
  closePopup: PropTypes.func.isRequired
}

Popup.defaultProps = {
  baseAs: '/',
  categoriesWithGroups: [],
  cities: [],
  baseHrefQuery: {}
}

export default connect(state => ({
  categoriesWithGroups: getCategoriesWithGroups(state),
  cities: getCities(state),
  filterValues: {}
}))(Popup)
