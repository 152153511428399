import PropTypes from 'prop-types'
import React from 'react'

export const Success = ({onClose}) =>
  <div className='report-block'>
    <div className='report-content'>
      <div className='report-title'>Hvala na prijavi</div>
      <br />
      <div className='report-description'>
        Administrator sajta AutoDiler će u najkraćem mogućem roku proveriti tvoju primedbu.
      </div>
    </div>
    <br />
    <div className='report-buttons'>
      <button className='report-button__submit' onClick={onClose}>U redu</button>
    </div>
  </div>

Success.propTypes = {
  onClose: PropTypes.func.isRequired
}
