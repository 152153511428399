import React from 'react'
import Link from 'next/link'
import { connect } from 'react-redux'
import { getIsTransitioning } from 'SRC/modules/common/selectors'
import PropTypes from 'prop-types'
import config from 'SRC/config/config.yaml'
import ReactSVG from 'react-svg'

export const Item = ({ title, shortTitle, icon, iconSvg, href, as, isCurrent, isTransitioning }) => {
  console.log('isTransitioning', isTransitioning)

  if (!isTransitioning || isCurrent) {
    return (
      <div className={`subcategories-horizontal-menu-item ${isCurrent ? 'active' : ''} `}>
        <Link href={href} as={as}>
          <a>
            {icon ? <i className={icon} /> : null}
            {!icon && iconSvg ? <ReactSVG src={`${config.mediaCDN}${iconSvg}`} beforeInjection={svg => {
              svg.setAttribute('style', 'height: 22px; width: auto;')
            }} wrapper='span' /> : null}
            <p>{shortTitle || title}</p>
          </a>
        </Link>
      </div>
    )
  }

  return (
    <div className={`subcategories-horizontal-menu-item disabled`}>
      <a>
        {icon ? <i className={icon} /> : null}
        {!icon && iconSvg ? <ReactSVG src={`${config.mediaCDN}${iconSvg}`} beforeInjection={svg => {
          svg.setAttribute('style', 'height: 22px; width: auto;')
        }} wrapper='span' /> : null}
        <p>{shortTitle || title}</p>
      </a>
    </div>
  )
}

Item.propTypes = {
  title: PropTypes.string.isRequired,
  shortTitle: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  iconSvg: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
  as: PropTypes.string.isRequired,
  isCurrent: PropTypes.bool.isRequired,
  isTransitioning: PropTypes.bool.isRequired
}

const mapStateToProps = state => ({
  isTransitioning: getIsTransitioning(state)
})

export default connect(mapStateToProps)(Item)
