import React from 'react'
import { connect } from 'react-redux'
import Link from 'next/link'
import { withRouter } from 'next/router'
import { compose } from 'recompose'
import PropTypes from 'prop-types'
import ReactSVG from 'react-svg'
import { getIsMobileDevice } from 'SRC/modules/common/selectors'
import { TopLinks } from '../Links'
import { LangVersionsList, Logo } from '../'
import { Menu } from '../Menu'
import { BurgerMenu } from '../BurgerMenu'
import config from 'SRC/config/config.yaml'
import { ElasticSearch } from 'SRC/modules/ads/filter/components'
import { getIsAuthorized } from 'src/modules/users/Auth/selectors'

const Item = ({ text, icon, iconSvg, href, as }) => (
  <Link href={href} as={as}>
    <a className='topnav-item'>
      {iconSvg
        ? (
          <ReactSVG src='/icons/online-uplaga-oglasa.svg' beforeInjection={svg => {
            svg.setAttribute('style', 'width: 45px; height: 100%; margin-top: .4rem;')
          }} wrapper='span' />
        )
        : icon
          ? <i className={`ico ${icon}`} style={{alignSelf: 'center'}} />
          : null
      }
      <span>{text}</span>
    </a>
  </Link>
)
Item.propTypes = {
  text: PropTypes.string.isRequired,
  icon: PropTypes.string,
  iconSvg: PropTypes.string,
  href: PropTypes.string.isRequired,
  as: PropTypes.string.isRequired
}

const NotAuth = () => (
  <div className='topnav-auth'>
    <Item text='PRIJAVI SE' icon='ico-top-meni-prijavi-se' href={'/profile'} as={'/profil'} />
    {/* <Item text='REGISTRUJ SE' icon='ico-top-meni-registruj-se' href={'/registration'} as={'/registracija'} /> */}
  </div>
)

const Auth = () => (
  <div className='topnav-auth'>
    <Item text='MOJ PROFIL' icon='ico-top-meni-prijavi-se' href={'/profile'} as={'/profil'} />
    <Item text='MOJI OGLASI' icon='ico-moji-oglasi' href={{pathname: '/profile', query: {page: 'oglasi'}}} as='/profil/oglasi' />
  </div>
)

class Header extends React.Component {
  render () {
    const { router, isMobileDevice } = this.props

    const isUserPage = router && router.pathname === '/user'

    return (
      <header>
        {!isMobileDevice ? <TopLinks /> : null}
        <div className='brand-header'>
          <div className='logo-oglasi'>
            <Logo isMobileDevice={isMobileDevice} />
          </div>

          <div className='topnav-pretraga'>
            <ElasticSearch isUserPage={isUserPage} />
            {this.props.isAuthorized && !isMobileDevice ? <Auth /> : <NotAuth />}
          </div>

          {isMobileDevice ? <BurgerMenu asPath={router ? router.asPath : null} /> : null}
        </div>
        {!isMobileDevice ? <Menu asPath={router ? router.asPath : null} /> : null}
      </header>
    )
  }
}

Header.propTypes = {
  router: PropTypes.shape().isRequired,
  isAuthorized: PropTypes.bool.isRequired,
  isMobileDevice: PropTypes.string.isRequired
}

const mapStateToProps = (state) => ({
  isAuthorized: getIsAuthorized(state),
  isMobileDevice: getIsMobileDevice(state)
})

const enhance = compose(
  withRouter,
  connect(mapStateToProps, {})
)

export default enhance(Header)
