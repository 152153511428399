import PropTypes from 'prop-types'
import React from 'react'

export const AddPageContent = ({children, title, description, descriptionClass, narrow}) => (
  <div className={`dodaj-oglas-content ${narrow ? ' dodaj-oglas-content__narrow' : ''}`}>
    <p className='heading'>{title}</p>
    <p className={descriptionClass}>{description}</p>
    {children}
  </div>
)

AddPageContent.propTypes = {
  children: PropTypes.any,
  description: PropTypes.string,
  descriptionClass: PropTypes.string,
  title: PropTypes.string,
  narrow: PropTypes.bool
}
