import PropTypes from 'prop-types'
import React, { useState, useEffect, useRef } from 'react'
import { connect } from 'react-redux'
import { passwordValidator, requiredValidator } from 'Core/validators'
import { AsYouType } from 'libphonenumber-js'
import { PhoneInputWithDial, Wrapper, SelectDropdown } from 'SRC/ui/FormElementsNew'
import Link from 'next/link'
import { Formik, Form, ErrorMessage } from 'formik'
import { setActiveTip } from 'SRC/modules/users/Registration/actions/setActiveTip'
import { getActiveTip } from 'SRC/modules/users/Registration/selectors'
import { getCountries } from 'SRC/modules/geoObjects/countries/selectors'
import config from 'SRC/config/config.yaml'
import { PhoneTip } from '../../PhoneTip'

const Canvas = ({ num1, num2 }) => {
  const canvasRef = useRef(null)

  useEffect(() => {
    const canvas = canvasRef.current
    const ctx = canvas.getContext('2d')

    ctx.clearRect(0, 0, canvas.width, canvas.height)

    ctx.font = "30px Arial";
    ctx.strokeText(`${num1} + ${num2} =`, 10, 50);
  }, [num1, num2])

  return <canvas ref={canvasRef} width={120} height={75} />
}

const SMS_HEADING_TEXT = `Izaberite državu i unesite Vaš broj telefona na koji Vam možemo poslati
  verifikacioni kod putem SMS ili VIBER poruke.`

const Phone = ({ countries, isLoading, showQuestion, onSubmit }) => {
  const [selectedCountry, setSelectedCountry] = useState(JSON.stringify({ country: 1, dial: 382 }))

  const [questionNum1, setQuestionNum1] = useState(0)
  const [questionNum2, setQuestionNum2] = useState(0)
  const [answerAttempts, setAnswerAttempts] = useState(0)
  const [answer, setAnswer] = useState('')
  const [isAnswerCorrect, setIsAnswerCorrect] = useState(null)

  useEffect(() => {
    if (showQuestion) {
      const num1 = Math.floor(Math.random() * 10 + 1)
      const num2 = Math.floor(Math.random() * 10 + 1)

      setQuestionNum1(num1)
      setQuestionNum2(num2)
      setIsAnswerCorrect(null)
      setAnswer('')
      setAnswerAttempts(0)
    }
  }, [showQuestion])

  const checkAnswer = (values, actions) => {
    setAnswerAttempts(answerAttempts + 1)

    const isCorrect = Boolean(questionNum1 + questionNum2 === answer)

    setIsAnswerCorrect(isCorrect)

    setTimeout(() => {
      const recaptchaCheck = false
      if (isCorrect) onSubmit(values, actions, recaptchaCheck)
    }, 2000)
  }

  const validate = (values) => {
    const error = {}

    const phone = values.phone.replace(/ /g, '')

    if (phone.length < 8 || phone.charAt(0) === '0') error.phone = 'Broj nije validan'
    if (!requiredValidator(phone)) error.phone = 'Telefon je obavezan'
    if (!requiredValidator(values.dial)) error.dial = 'Pozivni broj je obavezan'
    if (!Number(phone)) error.phone = 'Broj nije validan'

    return error
  }

  const prepareCountries = () => (countries || []).filter(country => !country.parent).map(country => {
    const isGrouped = Boolean((countries || []).filter(item => item.parent === country.id).length)
    return {
      id: country.id,
      label: country.label,
      value: JSON.stringify({
        country: country.id,
        dial: country.dial
      }),
      dial: country.dial,
      isGrouped,
      options: isGrouped ? (countries || []).filter(item => item.parent === country.id).map(childCountry => ({
        id: childCountry.id,
        value: JSON.stringify({
          country: childCountry.id,
          dial: childCountry.dial
        }),
        label: childCountry.label,
        dial: childCountry.dial
      })) : null
    }
  })

  return (
    <React.Fragment>
      <p className='heading-lead'>{SMS_HEADING_TEXT}</p>

      <PhoneTip />

      <Formik
        initialValues={{ country: 1, dial: 382, phone: '' }}
        validate={validate}
        onSubmit={!showQuestion
          ? (values, actions) => { onSubmit(values, actions) }
          : (values, actions) => { checkAnswer(values, actions) }
        }
      >
        {formikProps => (
          <Form className='login-form-passwordless'>
            <Wrapper>
              <SelectDropdown
                id='country'
                title='Država'
                isDisabled={!Array.isArray(countries) || !countries.length}
                isSearchable
                isRequired
                value={selectedCountry}
                options={prepareCountries()}
                onChange={async value => {
                  try {
                    const parsedValue = JSON.parse(value)
                    console.log('selectedCountry parsedValue', parsedValue)
                    if (parsedValue.country && parsedValue.dial) {
                      setSelectedCountry(JSON.stringify({ country: parsedValue.country, dial: parsedValue.dial }))

                      formikProps.setFieldValue('country', parsedValue.country)
                      formikProps.setFieldValue('dial', parsedValue.dial)
                      formikProps.setFieldValue('phone', '')
                    }
                  } catch (err) {
                    formikProps.setFieldValue('country', 1)
                    formikProps.setFieldValue('phone', '')
                    formikProps.setFieldValue('dial', 382)
                  }
                }}
              />

              <ErrorMessage
                name='newUser.country'
                render={msg => <span style={{ display: 'block', marginTop: 5 }} className='form-group__error-message'>{msg}</span>}
              />
            </Wrapper>

            <PhoneInputWithDial
              label={config.loginForm.labels.phone}
              isRequired
              isFullWidth={false}
              inputClass='form-group__login-phone'
              field={{ name: 'phone' }}
              onChange={value => {
                let phoneValue = value
                if (phoneValue.charAt(0) === '0') phoneValue = phoneValue.slice(1)
                const phoneNumber = `+${formikProps.values.dial}${phoneValue.trim().replace(/ /g, '')}`

                const parsedPhoneNumber = new AsYouType().input(phoneNumber)
                const [dial, ...rest] = parsedPhoneNumber.split(' ')
                const formattedPhone = rest.join(' ')

                formikProps.setFieldValue('phone', formattedPhone)
              }}
              form={formikProps}
            />

            {showQuestion ? (
              <>
                <p style={{ width: '100%' }}>Unesite tačan odgovor i kliknite na potvrdi.</p>

                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Canvas num1={questionNum1} num2={questionNum2} />
                  <input
                    type='text'
                    value={answer}
                    onChange={e => setAnswer(Number(e.target.value))}
                    style={{ width: '20%', padding: 0, border: '1px solid #dcdae5', display: 'inline-block', textAlign: 'center' }}
                  />

                  {isAnswerCorrect !== null ? (
                    !isAnswerCorrect ? (
                      <span style={{ color: 'orangered', fontSize: '1.5rem', marginLeft: '0.5rem' }}>&#10005;</span>
                    ) : (
                      <span style={{ color: 'darkgreen', fontSize: '1.5rem', marginLeft: '0.5rem' }}>&#10004;</span>
                    )
                  ) : null}

                  <span style={{ marginLeft: 5, marginRight: 5, fontSize: 22, fontWeight: 'bold' }}>{answerAttempts}/3</span>
                </div>
              </>
            ) : null}

            <button
              type='submit'
              name='submit-btn'
              disabled={isLoading || answerAttempts >= 3 || isAnswerCorrect}
              style={{ opacity: isLoading || answerAttempts >= 3 || isAnswerCorrect ? .4 : 1, width: '100%' }}
            >
              Potvrdi
            </button>
          </Form>
        )}
      </Formik>
      <p className='btn-submit-lead' key='license'>
        Klikom na dugme POTVRDI prihvatate <a href='/uslovi-koriscenja' target='_blank'>uslove korišćenja</a> portala Oglasi.me
      </p>

      <br />

      <p>
        This site is protected by reCAPTCHA and the Google <a href="/politika-privatnosti">Privacy Policy</a> and <a href="/uslovi-koriscenja">Terms of Service</a> apply.
      </p>
    </React.Fragment>
  )
}

const mapStateToProps = (state) => ({
  countries: getCountries(state)
})

Phone.propTypes = {
  countries: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
  showQuestion: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired
}

export default connect(mapStateToProps)(Phone)
