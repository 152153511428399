import PropTypes from 'prop-types'
import React from 'react'

export const HideDetailBtn = ({onClick}) => (
  <button className='filter-buttons-toggle-detail' onClick={e => { e.preventDefault(); onClick() }}>
    OSNOVNA PRETRAGA <i className='ico-pretraga-polja-lupa ico' />
  </button>
)

HideDetailBtn.propTypes = {
  onClick: PropTypes.func.isRequired
}
