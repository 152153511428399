/* eslint-disable no-tabs */
import React from 'react'
import config from 'SRC/config/config.yaml'

const AutodilerPrivacyPolicy = () => (
  <div className='novosti-text'>
    <p>Zaštita privatnosti naših korisnika prilikom korištenja Internet stranice www.AutoDiler.me od iznimnog je značaja za društvo www.AutoDiler.me</p>
    <p>www.AutoDiler.me sve korisnike stranice www.AutoDiler.me smatra svojim partnerima s kojima surađuje u skladu sa dobrim poslovnim običajima, uz puno poštivanje domaće i strane legislative.</p>
    <p>Ovo se partnerstvo ponekad odnosi i na korištenje ličnih podataka naših korisnika. Ovom Obaviješću o zaštiti privatnosti i ličnih podataka informiramo Vas o tome kako prikupljamo i koristimo Vaše lične podatke, te kome ih otkrivamo i kako im možete pristupiti, promijeniti, ukloniti ili preuzeti.</p>
    <p>Stoga Vas u nastavku detaljno informiramo o prikupljanju ličnih i anonimnih podataka, te svim relevantnim podacima u vezi sa vašim ličnim podacima.</p>
    <p>&nbsp;</p>
    <p><strong>Podaci koje prikupljamo</strong></p>
    <p>Podaci koje www.AutoDiler.me prikuplja i čuva na svojim serverima su samo oni podaci koji su potrebi radi korištenja stranice www.AutoDiler.me, kao i radi profesionalnih odnosa između www.AutoDiler.me i korisnika, te korisnika međusobno.</p>
    <p>Određeni podaci mogu se u skladu da odredbama Zakona o zaštiti ličnih podataka i odredbama GDPR-a smatrati ličnima, a takvi su:</p>
    <ul className='classic'>
      <li>Ime i prezime</li>
      <li>e-mail adresa</li>
      <li>broj telefona</li>
      <li>adresa stanovanja</li>
      <li>IP adresa</li>
    </ul>
    <p>&nbsp;</p>
    <p><strong>Zaštita podataka svih korisnika </strong></p>
    <p>Neovisno o tome što www.AutoDiler.me ima privatne i komercijalne korisnike, za zaštitu podataka i jednih i drugih poduzeli smo tehničke i organizacijske mjere, posebno protiv gubitka, manipulacije i neovlaštenog pristupa. Postupak zaštite podataka kontinuirano prilagođavamo tehničkom razvoju.</p>
    <p>&nbsp;</p>
    <p><strong>Prikupljanje i obrada osobnih podataka</strong></p>
    <p>Posebno značajnim smatramo istaknuti to da se osobni podaci naših korisnika pohranjuju uvijek i isključivo na dobrovoljnoj osnovi. To znači, samo onda kada nam naši korisnici, npr. u okviru registracije kod upita, nagradne igre ili za pretplatu na Newsletter – izričito ustupe svoje lične podatke. Način ustupanja tih podataka podrazumijeva da svaki korisnik sam, dobrovoljno unese podatke o sebi prilikom registracije, a obim unesenih ličnih podataka ovisi isključivo o korisniku.</p>
    <p>www.AutoDiler.me ne vrši obradu podataka korisnika, a svako prikupljanje podataka izvršeno je isključivo uz suglasnost i pristanak naših korisnika i služi isključivo da bi naši korisnici mogli koristiti opcije stranice www.AutoDiler.me.</p>
    <p>Podatke naših korisnika nikada ne šaljemo trećima bez izričite saglasnosti korisnika, osim u zakonom propisanim uvjetima (na zahtjev ovlaštenih državnih organa i sl…)</p>
    <p>&nbsp;</p>
    <p><strong>POVLAČENJE SAGLASNOSTI</strong></p>
    <p>Svaki naš korisnik saglasnost može povući u bilo kojem trenutku bez roka i sa stupanjem na snagu odmah, klikom na sljedeći link : Postavke</p>
    <p>Kako bismo osigurali da će Vaš zahtjev za povlačenjem ličnih podataka odmah stići na pravu adresu, za sve korisnike odredili smo poseban odjel u Društvu koji zaprima te zahtjeve.</p>
    <p>Osim toga, korisnici imaju tehničku mogućnost da se uklone sa liste primatelja Newslettera, klikom na poveznicu koja se nalazi u svakom izdanju.</p>
    <p>Registracija i kupovina online</p>
    <p>Prilikom registracije na stranicu www.AutoDiler.me, svi naši korisnici trebaju unijeti određene podatke. Najznačajniji podatak u tom smislu je e-mail adresa korisnika.</p>
    <p>Ostale podatke koji bi se mogli smatrati ličnima korisnik unosi isključivo ukoliko to želi. www.AutoDiler.me nikada neće prisiljavati svoje korisnike da unose adresu, broj telefona ili druge lične podatke.</p>
    <p>Svaki korisnik nakon registracije na www.AutoDiler.me dobija na toj stranici svoju malu stranicu.</p>
    <p>Toj stranici korisnik pristupa pomoću lozinke/passworda i korisničkog imena odnosno e- mail adrese. S obzirom da je lozinka/password kriptirana važno je da ih korisnik nikome ne otkriva.</p>
    <p>Određene osjetljive informacije se kriptiraju koristeći Secure Socket Layer (SSL) tehnologiju, na taj način nastojimo osigurati da su osobne informacije naših korisnika van dosega neovlaštenom korištenju.</p>
    <p>&nbsp;</p>
    <p><strong>Podaci koje www.AutoDiler.me automatski prikuplja/ Korištenje kolačića (eng. Cookies)</strong></p>
    <p>Radi održavanja internetske stranice i osiguranja njezinog funkcionisanja www.AutoDiler.me za www.AutoDiler.me koristi tehnologiju koja se obično naziva „kolačićima“ (cookies). Kolačići su male datoteke koje šaljemo na računar korisnika, a mi im kasnije možemo pristupiti. Mogu biti privremeni ili stalni te koristiti, na primjer, Javascrípt ili Flash tehnologiju. Zahvaljujući kolačićima, korisnici našu stranicu mogu pretraživati bez poteškoća..</p>
    <p>Kolačić je mala tekstualna datoteka, koja u sebi sprema Internet postavke naših korisnika. Gotovo svaka internetska stranica koristi ovu tehnologiju. Prilikom prve posjete korisnika nekoj stranici Internet preglednik svakog pojedinog korisnika tu datoteku skida na uređaj tog korisnika. Kod idućeg posjeta iste stranice na istom uređaju, kolačić a time i u njemu spremljene informacije šalje se natrag stranici koja ga je kreirala (First Party Cookie) ili na neku drugu stranicu kojoj pripada (Third Party Cookie). Time stranica saznaje da je na tom uređaju i u tom pregledniku već bila otvarana te ponekad prikazuje drugačiji sadržaj nego prilikom prvog posjećivanja.</p>
    <p>Naznačavamo da ova tehnologija nema nikakve veze sa stranicom www.AutoDiler.me, odnosno predstavlja platformu koja pomaže i korisnicima i www.AutoDiler.me, ali www.AutoDiler.me nije autor kolačića, niti odgovara za ovu platformu.</p>
    <p>Najveći dio kolačića koji mi koristimo brišu se automatski sa uređaja naših korisnika na kraju pregledavanja u pregledniku („Session-Cookies“).</p>
    <p>Uz to koristimo i kolačiće koji i nakon prekida sesije i dalje ostaju na uređaju našeg korisnika. Najčešći razlog je da bi poboljšali vaše iskustvo sa stranicom te prilagodili prikaz Vašim potrebama i time optimirali vrijeme učitavanja stranice. Ove podatke nije moguće povezati sa pojedinačnim korisnikom jer se prikupljaju isključivo anonimno.</p>
    <p>&nbsp;</p>
    <p>Otvaranjem naših stranica podaci koji se obrađuju preko kolačića su:</p>
    <ul className='classic'>
      <li>anonimizirana forma IP-adrese uređaja koji šalje zahtjev</li>
      <li>datum i vrijeme učitavanja odnosno zahtjeva</li>
      <li>naziv učitane stranice odnosno datoteke</li>
      <li>ID sesije</li>
      <li>Referrer-URL (poveznica na stranicu, s koje je učitana ova stranica)</li>
      <li>količina prenesenih podataka</li>
      <li>web preglednici koje je korisnik koristio uključujući verziju web preglednika</li>
      <li>operacijski sustav kojeg je korisnik koristio</li>
    </ul>
    <p>&nbsp;</p>
    <p><strong>Third-Party Cookies</strong></p>
    <p>Na ovim stranicama integrirani su i sadržaji trećih strana. Te treće strane mogu teoretski postaviti kolačiće za vrijeme korištenja naših stranica i time dobiti informacije da je korisnik otvorio neku od naših stranica. Molimo korisnike da posjete stranice trećih strana kako bi saznali više o korištenju kolačića sa njihove strane.</p>
    <p>&nbsp;</p>
    <p><strong>Analitički partneri:</strong></p>
    <p>Da bi ste bolje razumeli kako koristiti našu platformu, radimo sa raznim analitički partnerima. Ovim partnerima za analitiku dozvoljavamo da koriste kolačiće, SDK-ove i druge srodne tehnologije:</p>
    <p>Google analytics<br />
      Dotmetrics (Ipsos)</p>
    <p>&nbsp;</p>
    <p><strong>Reklamne mreže:</strong></p>
    <p>Sljedeće reklamne mreže mogu koristiti ciljane i reklamne kolačiče za postavljanje oglasa za koje vjeruju da će biti od interesa za Vas i za mjerenje učinkovitosti takvih oglasa na našoj Platformi:</p>
    <p>Adx<br />
      AdSense<br />
      AdMob<br />
      Oracle BlueKai</p>
    <p>&nbsp;</p>
    <p><strong>Postavke kolačića</strong></p>
    <p>Internet preglednik svakog našeg korisnika može se podesiti tako da kolačići budu prihvaćeni samo uz iziričitu suglasnost ili da budu u potpunosti odbijeni. Ovdje moramo upozoriti da se može desiti da bez korištenja kolačića područja ili pojedini dijelovi stranica funkcioniraju ograničeno odn. da ih uopće nije moguće koristiti.</p>
    <p>Za bolje snalaženje predlažemo da pogledate sljedeće načine upravljanjem korištenjem kolačića (eng. Cookies) odnosno njihovog blokiranja podešavanjem postavki Internet preglednika na sljedeći način:</p>
    <ul className='classic'>
      <li>Internet Explorer, pogledajte ( http://windows.microsoft.com/en-in/windows7/block-enable-or-allow-cookies ) Tools – Internet options – Advanced – pod područjem Security postaviti kvačicu u polje uz “Send Do Not Track requests to sites you visit in Internet Explorer” – potvrditi sa ok</li>
      <li>Firefox, pogledajte ( https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences ) Meni – Options – Privacy – postavite Use custom settings for history – Accept cookies from sites</li>
      <li>Google Chrome, pogledajte ( https://support.google.com/chrome/answer/95647?hl=en ) Meni – Settings – Advanced settings – Privacy – Cookies – ukloniti kvačicu – Done</li>
      <li>Safari, pogledajte ( https://support.apple.com/kb/ph5042?locale=en_US ) Safari – Preferences – Privacy – pod Block Cookies postaviti željeno – potvrditi</li>
    </ul>
    <p>Neophodni kolačići omogućuju pretraživanje internetske stranice i korištenje njezinih neophodnih funkcija.</p>
    <p>Kolačići učinkovitosti prikupljaju anonimne informacije o stranicama koje posjećuješ. To nam govori koje su ponude najpopularnije i što korisnike na našoj stranici zanima.</p>
    <p>Funkcijski kolačići pamte tvoje odabire i poboljšavaju sljedeće posjete stranici.</p>
    <p>Oglašivački kolačići omogućuju dijeljenje i slanje informacija drugim internetskim stranicama koje bi ti mogle biti važne.</p>
    <p>Kolačićima spremljenima na računaru korisnika možemo pristupiti kad korisnik posjeti www.AutoDiler.me.</p>
    <p>&nbsp;</p>
    <p><strong>Implementiranje dodataka za društvene mreže (Social Plugins)Facebook</strong></p>
    <p>Izrazito je važno da sve naše korisnike upoznamo sa načinom dijeljenja podataka putem društvene strancie Facebook.</p>
    <p>Kao i sve internetske stranice današnjice, i www.AutoDiler.me ima svoju stranicu na facebooku.</p>
    <p>Isto tako, i na stranici www.AutoDiler.me koriste se dodaci Facebooka. Za to korisnici koriste opciju “Sviđa mi se” (eng. “like”). Riječ je o ponudi američke tvrtke Facebook Inc. (1601S. California Ave., Palo Alto, CA 94304 SAD).</p>
    <p>Međutim, posjećivanjem stranice www.AutoDiler.me, naši korisnici nisu automatski dovedeni u vezu s ovom tvrtkom. To će se dogoditi tek nakon što korisnik da svoju izričitu suglasnost koja će se od korisnika biti zatražena nakon što klikne na odgovarajuće polje.</p>
    <p>Svrhu i opseg prikupljanja te daljnju obradu i korištenje podataka koje koristi Facebook kao i uz to povezana Vaša prava i mogućnosti postavki za zaštitu Vaše privatnosti pogledajte pod zaštitom podataka kod Facebooka na njihovim stranica: http://www.facebook.com/policy.php</p>
    <p>Ako neki korisnik ne želi da Facebook podatke prikupljene preko naše web stranice pridruži Vašem Facebook računu, molimo korisnike da se prije korištenja naše stranica odjave sa Facebooka.</p>
    <p>Učitavanje Facebook dodataka možete spriječiti i uz pomoć dodataka (Add-On) za određeni internet preglednik, npr. sa “Facebook Blocker” ( http://webgraph.com/resources/facebookblocker/ ).</p>
    <p>Posebno ističemo da nikada ne proslijeđujemo lične podatke naših korisnika facebooku. Korisnik sam odabirom opcije „like“ odnosno „sviđa mi se“ stranica www.AutoDiler.me na facebooku daje suglasnost stranici facebook da obrađuje njegove podatke. To je nešto s čim www.AutoDiler.me nema nikakve veze, niti na to može utjecati.</p>
    <p>&nbsp;</p>
    <p><strong>ZA KRAJ</strong></p>
    <p>Na kraju ove obavijesti želimo još jednom istaknuti da svi korisnici podatke unose dobrovoljno, da imaju pravo da ih obrišu sami ili da od nas zatraže da to učinimo i mogu biti sigurni da ne obrađujemo njihove podatke radi postizanja dobiti.</p>
    <p>Nadamo se da su ova tri osnovna pravila dovoljna za uspješnu i dugotrajnu saradnju i ugodno pretraživanje sadržaja www.AutoDiler.me.</p>
  </div>
)

const OglasiPrivacyPolicy = () => (
  <div className='novosti-text'>
    <p>Zaštita privatnosti naših korisnika prilikom korištenja Internet stranice www.oglasi.me od iznimnog je značaja za društvo www.oglasi.me</p>
    <p>www.oglasi.me sve korisnike stranice www.oglasi.me smatra svojim partnerima s kojima surađuje u skladu sa dobrim poslovnim običajima, uz puno poštivanje domaće i strane legislative.</p>
    <p>Ovo se partnerstvo ponekad odnosi i na korištenje ličnih podataka naših korisnika. Ovom Obaviješću o zaštiti privatnosti i ličnih podataka informiramo Vas o tome kako prikupljamo i koristimo Vaše lične podatke, te kome ih otkrivamo i kako im možete pristupiti, promijeniti, ukloniti ili preuzeti.</p>
    <p>Stoga Vas u nastavku detaljno informiramo o prikupljanju ličnih i anonimnih podataka, te svim relevantnim podacima u vezi sa vašim ličnim podacima.</p>
    <p>&nbsp;</p>
    <p><strong>Podaci koje prikupljamo</strong></p>
    <p>Podaci koje www.oglasi.me prikuplja i čuva na svojim serverima su samo oni podaci koji su potrebi radi korištenja stranice www.oglasi.me, kao i radi profesionalnih odnosa između www.oglasi.me i korisnika, te korisnika međusobno.</p>
    <p>Određeni podaci mogu se u skladu da odredbama Zakona o zaštiti ličnih podataka i odredbama GDPR-a smatrati ličnima, a takvi su:</p>
    <ul className='classic'>
      <li>Ime i prezime</li>
      <li>e-mail adresa</li>
      <li>broj telefona</li>
      <li>adresa stanovanja</li>
      <li>IP adresa</li>
    </ul>
    <p>&nbsp;</p>
    <p><strong>Zaštita podataka svih korisnika </strong></p>
    <p>Neovisno o tome što www.oglasi.me ima privatne i komercijalne korisnike, za zaštitu podataka i jednih i drugih poduzeli smo tehničke i organizacijske mjere, posebno protiv gubitka, manipulacije i neovlaštenog pristupa. Postupak zaštite podataka kontinuirano prilagođavamo tehničkom razvoju.</p>
    <p>&nbsp;</p>
    <p><strong>Prikupljanje i obrada osobnih podataka</strong></p>
    <p>Posebno značajnim smatramo istaknuti to da se osobni podaci naših korisnika pohranjuju uvijek i isključivo na dobrovoljnoj osnovi. To znači, samo onda kada nam naši korisnici, npr. u okviru registracije kod upita, nagradne igre ili za pretplatu na Newsletter – izričito ustupe svoje lične podatke. Način ustupanja tih podataka podrazumijeva da svaki korisnik sam, dobrovoljno unese podatke o sebi prilikom registracije, a obim unesenih ličnih podataka ovisi isključivo o korisniku.</p>
    <p>www.oglasi.me ne vrši obradu podataka korisnika, a svako prikupljanje podataka izvršeno je isključivo uz suglasnost i pristanak naših korisnika i služi isključivo da bi naši korisnici mogli koristiti opcije stranice www.oglasi.me.</p>
    <p>Podatke naših korisnika nikada ne šaljemo trećima bez izričite saglasnosti korisnika, osim u zakonom propisanim uvjetima (na zahtjev ovlaštenih državnih organa i sl…)</p>
    <p>&nbsp;</p>
    <p><strong>POVLAČENJE SAGLASNOSTI</strong></p>
    <p>Svaki naš korisnik saglasnost može povući u bilo kojem trenutku bez roka i sa stupanjem na snagu odmah, klikom na sljedeći link : Postavke</p>
    <p>Kako bismo osigurali da će Vaš zahtjev za povlačenjem ličnih podataka odmah stići na pravu adresu, za sve korisnike odredili smo poseban odjel u Društvu koji zaprima te zahtjeve.</p>
    <p>Osim toga, korisnici imaju tehničku mogućnost da se uklone sa liste primatelja Newslettera, klikom na poveznicu koja se nalazi u svakom izdanju.</p>
    <p>Registracija i kupovina online</p>
    <p>Prilikom registracije na stranicu www.oglasi.me, svi naši korisnici trebaju unijeti određene podatke. Najznačajniji podatak u tom smislu je e-mail adresa korisnika.</p>
    <p>Ostale podatke koji bi se mogli smatrati ličnima korisnik unosi isključivo ukoliko to želi. www.oglasi.me nikada neće prisiljavati svoje korisnike da unose adresu, broj telefona ili druge lične podatke.</p>
    <p>Svaki korisnik nakon registracije na www.oglasi.me dobija na toj stranici svoju malu stranicu.</p>
    <p>Toj stranici korisnik pristupa pomoću lozinke/passworda i korisničkog imena odnosno e- mail adrese. S obzirom da je lozinka/password kriptirana važno je da ih korisnik nikome ne otkriva.</p>
    <p>Određene osjetljive informacije se kriptiraju koristeći Secure Socket Layer (SSL) tehnologiju, na taj način nastojimo osigurati da su osobne informacije naših korisnika van dosega neovlaštenom korištenju.</p>
    <p>&nbsp;</p>
    <p><strong>Podaci koje www.oglasi.me automatski prikuplja/ Korištenje kolačića (eng. Cookies)</strong></p>
    <p>Radi održavanja internetske stranice i osiguranja njezinog funkcionisanja www.oglasi.me za www.oglasi.me koristi tehnologiju koja se obično naziva „kolačićima“ (cookies). Kolačići su male datoteke koje šaljemo na računar korisnika, a mi im kasnije možemo pristupiti. Mogu biti privremeni ili stalni te koristiti, na primjer, Javascrípt ili Flash tehnologiju. Zahvaljujući kolačićima, korisnici našu stranicu mogu pretraživati bez poteškoća..</p>
    <p>Kolačić je mala tekstualna datoteka, koja u sebi sprema Internet postavke naših korisnika. Gotovo svaka internetska stranica koristi ovu tehnologiju. Prilikom prve posjete korisnika nekoj stranici Internet preglednik svakog pojedinog korisnika tu datoteku skida na uređaj tog korisnika. Kod idućeg posjeta iste stranice na istom uređaju, kolačić a time i u njemu spremljene informacije šalje se natrag stranici koja ga je kreirala (First Party Cookie) ili na neku drugu stranicu kojoj pripada (Third Party Cookie). Time stranica saznaje da je na tom uređaju i u tom pregledniku već bila otvarana te ponekad prikazuje drugačiji sadržaj nego prilikom prvog posjećivanja.</p>
    <p>Naznačavamo da ova tehnologija nema nikakve veze sa stranicom www.oglasi.me, odnosno predstavlja platformu koja pomaže i korisnicima i www.oglasi.me, ali www.oglasi.me nije autor kolačića, niti odgovara za ovu platformu.</p>
    <p>Najveći dio kolačića koji mi koristimo brišu se automatski sa uređaja naših korisnika na kraju pregledavanja u pregledniku („Session-Cookies“).</p>
    <p>Uz to koristimo i kolačiće koji i nakon prekida sesije i dalje ostaju na uređaju našeg korisnika. Najčešći razlog je da bi poboljšali vaše iskustvo sa stranicom te prilagodili prikaz Vašim potrebama i time optimirali vrijeme učitavanja stranice. Ove podatke nije moguće povezati sa pojedinačnim korisnikom jer se prikupljaju isključivo anonimno.</p>
    <p>&nbsp;</p>
    <p>Otvaranjem naših stranica podaci koji se obrađuju preko kolačića su:</p>
    <ul className='classic'>
      <li>anonimizirana forma IP-adrese uređaja koji šalje zahtjev</li>
      <li>datum i vrijeme učitavanja odnosno zahtjeva</li>
      <li>naziv učitane stranice odnosno datoteke</li>
      <li>ID sesije</li>
      <li>Referrer-URL (poveznica na stranicu, s koje je učitana ova stranica)</li>
      <li>količina prenesenih podataka</li>
      <li>web preglednici koje je korisnik koristio uključujući verziju web preglednika</li>
      <li>operacijski sustav kojeg je korisnik koristio</li>
    </ul>
    <p>&nbsp;</p>
    <p><strong>Third-Party Cookies</strong></p>
    <p>Na ovim stranicama integrirani su i sadržaji trećih strana. Te treće strane mogu teoretski postaviti kolačiće za vrijeme korištenja naših stranica i time dobiti informacije da je korisnik otvorio neku od naših stranica. Molimo korisnike da posjete stranice trećih strana kako bi saznali više o korištenju kolačića sa njihove strane.</p>
    <p>&nbsp;</p>
    <p><strong>Analitički partneri:</strong></p>
    <p>Da bi ste bolje razumeli kako koristiti našu platformu, radimo sa raznim analitički partnerima. Ovim partnerima za analitiku dozvoljavamo da koriste kolačiće, SDK-ove i druge srodne tehnologije:</p>
    <p>Google analytics<br />
      Dotmetrics (Ipsos)</p>
    <p>&nbsp;</p>
    <p><strong>Reklamne mreže:</strong></p>
    <p>Sljedeće reklamne mreže mogu koristiti ciljane i reklamne kolačiče za postavljanje oglasa za koje vjeruju da će biti od interesa za Vas i za mjerenje učinkovitosti takvih oglasa na našoj Platformi:</p>
    <p>Adx<br />
      AdSense<br />
      AdMob<br />
      Oracle BlueKai</p>
    <p>&nbsp;</p>
    <p><strong>Postavke kolačića</strong></p>
    <p>Internet preglednik svakog našeg korisnika može se podesiti tako da kolačići budu prihvaćeni samo uz iziričitu suglasnost ili da budu u potpunosti odbijeni. Ovdje moramo upozoriti da se može desiti da bez korištenja kolačića područja ili pojedini dijelovi stranica funkcioniraju ograničeno odn. da ih uopće nije moguće koristiti.</p>
    <p>Za bolje snalaženje predlažemo da pogledate sljedeće načine upravljanjem korištenjem kolačića (eng. Cookies) odnosno njihovog blokiranja podešavanjem postavki Internet preglednika na sljedeći način:</p>
    <ul className='classic'>
      <li>Internet Explorer, pogledajte ( http://windows.microsoft.com/en-in/windows7/block-enable-or-allow-cookies ) Tools – Internet options – Advanced – pod područjem Security postaviti kvačicu u polje uz “Send Do Not Track requests to sites you visit in Internet Explorer” – potvrditi sa ok</li>
      <li>Firefox, pogledajte ( https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences ) Meni – Options – Privacy – postavite Use custom settings for history – Accept cookies from sites</li>
      <li>Google Chrome, pogledajte ( https://support.google.com/chrome/answer/95647?hl=en ) Meni – Settings – Advanced settings – Privacy – Cookies – ukloniti kvačicu – Done</li>
      <li>Safari, pogledajte ( https://support.apple.com/kb/ph5042?locale=en_US ) Safari – Preferences – Privacy – pod Block Cookies postaviti željeno – potvrditi</li>
    </ul>
    <p>Neophodni kolačići omogućuju pretraživanje internetske stranice i korištenje njezinih neophodnih funkcija.</p>
    <p>Kolačići učinkovitosti prikupljaju anonimne informacije o stranicama koje posjećuješ. To nam govori koje su ponude najpopularnije i što korisnike na našoj stranici zanima.</p>
    <p>Funkcijski kolačići pamte tvoje odabire i poboljšavaju sljedeće posjete stranici.</p>
    <p>Oglašivački kolačići omogućuju dijeljenje i slanje informacija drugim internetskim stranicama koje bi ti mogle biti važne.</p>
    <p>Kolačićima spremljenima na računaru korisnika možemo pristupiti kad korisnik posjeti www.oglasi.me.</p>
    <p>&nbsp;</p>
    <p><strong>Implementiranje dodataka za društvene mreže (Social Plugins)Facebook</strong></p>
    <p>Izrazito je važno da sve naše korisnike upoznamo sa načinom dijeljenja podataka putem društvene strancie Facebook.</p>
    <p>Kao i sve internetske stranice današnjice, i www.oglasi.me ima svoju stranicu na facebooku.</p>
    <p>Isto tako, i na stranici www.oglasi.me koriste se dodaci Facebooka. Za to korisnici koriste opciju “Sviđa mi se” (eng. “like”). Riječ je o ponudi američke tvrtke Facebook Inc. (1601S. California Ave., Palo Alto, CA 94304 SAD).</p>
    <p>Međutim, posjećivanjem stranice www.oglasi.me, naši korisnici nisu automatski dovedeni u vezu s ovom tvrtkom. To će se dogoditi tek nakon što korisnik da svoju izričitu suglasnost koja će se od korisnika biti zatražena nakon što klikne na odgovarajuće polje.</p>
    <p>Svrhu i opseg prikupljanja te daljnju obradu i korištenje podataka koje koristi Facebook kao i uz to povezana Vaša prava i mogućnosti postavki za zaštitu Vaše privatnosti pogledajte pod zaštitom podataka kod Facebooka na njihovim stranica: http://www.facebook.com/policy.php</p>
    <p>Ako neki korisnik ne želi da Facebook podatke prikupljene preko naše web stranice pridruži Vašem Facebook računu, molimo korisnike da se prije korištenja naše stranica odjave sa Facebooka.</p>
    <p>Učitavanje Facebook dodataka možete spriječiti i uz pomoć dodataka (Add-On) za određeni internet preglednik, npr. sa “Facebook Blocker” ( http://webgraph.com/resources/facebookblocker/ ).</p>
    <p>Posebno ističemo da nikada ne proslijeđujemo lične podatke naših korisnika facebooku. Korisnik sam odabirom opcije „like“ odnosno „sviđa mi se“ stranica www.oglasi.me na facebooku daje suglasnost stranici facebook da obrađuje njegove podatke. To je nešto s čim www.oglasi.me nema nikakve veze, niti na to može utjecati.</p>
    <p>&nbsp;</p>
    <p><strong>ZA KRAJ</strong></p>
    <p>Na kraju ove obavijesti želimo još jednom istaknuti da svi korisnici podatke unose dobrovoljno, da imaju pravo da ih obrišu sami ili da od nas zatraže da to učinimo i mogu biti sigurni da ne obrađujemo njihove podatke radi postizanja dobiti.</p>
    <p>Nadamo se da su ova tri osnovna pravila dovoljna za uspješnu i dugotrajnu saradnju i ugodno pretraživanje sadržaja www.oglasi.me.</p>
  </div>
)

export const PrivacyPolicy = () => {
  switch (config.siteName) {
    case 'AutoDiler': return <AutodilerPrivacyPolicy />
    case 'Oglasi': return <OglasiPrivacyPolicy />
    default: return null
  }
}
