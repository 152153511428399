import PropTypes from 'prop-types'
import React from 'react'

export const Password = ({ name, isLoading, onSubmit }) => {
  const [password, setPassword] = React.useState('')

  const buttonDisabled = isLoading || password.length < 6

  return (
    <div className='login-form'>
      <p style={{ marginBottom: 10 }}>
        Vi ste registrovani poslovni korisnik, potrebno je da unesete vašu šifru/lozinku kako bi pristupili vašem profilu.
      </p>

      <div className='form-group sms-code'>
        <label>Korisnik:</label>

        <input disabled type='text' value={name} />
      </div>

      <div className='form-group sms-code'>
        <label>Unesite lozinku:</label>

        <input
          disabled={isLoading}
          type='password'
          onChange={(e) => {
            const value = e.target.value
            setPassword(value)
          }}
          value={password}
        />
      </div>

      <button
        type='submit'
        name='submit-btn'
        className='sms-code-button'
        disabled={buttonDisabled}
        onClick={() => onSubmit(password)}
        style={{ opacity: buttonDisabled ? .4 : 1 }}
      >
        Prijavi se
      </button>
    </div>
  )
}

Password.propTypes = {
  name: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired
}
