import React from 'react'
import Link from 'next/link'
import moment from 'moment'
import { CookiesHelper } from 'SRC/core/cookiesHelper/CookiesHelper'
import config from 'SRC/config/config.yaml'

export class CookieConsent extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      accepted: true
    }
    this.cookies = new CookiesHelper()
  }

  componentDidMount () {
    const isCookiesAccepted = this.cookies.get('cookies_accepted')
    this.setState({ accepted: isCookiesAccepted })
  }

  acceptCookies = () => {
    const nextYear = moment(new Date()).add(1, 'year')
    this.cookies.set('cookies_accepted', true, { expires: new Date(nextYear) })
    this.setState({ accepted: true })
  }

  render () {
    return !this.state.accepted ? <section className='cookie-consent'>
      <p>{config.siteName} koristi kolačiće za pružanje boljeg korisničkog iskustva. Nastavkom korišćenja sajta prihvatate&nbsp;
        <Link href={{ pathname: '/static', query: { url: '/uslovi-koriscenja' } }} as='/uslovi-koriscenja'><a>uslove korišćenja</a></Link>
        &nbsp;i <Link href={{ pathname: '/static', query: { url: '/politika-privatnosti' } }} as='/politika-privatnosti'>
          <a>politiku privatnosti</a>
        </Link>.
      </p>
      <button onClick={this.acceptCookies}>Prihvati i zatvori</button>
    </section> : null
  }
}
