import React from 'react'
import { AdFormHeader } from 'SRC/ui/FormElementsNew'
import { FirstRow } from '../FirstRow'
import { SecondRow } from '../SecondRow'

export const Container = () => {
  return (
    <React.Fragment>
      <AdFormHeader key='header' title='Cijena / stanje / prodavac / lokacija' />
      <FirstRow key='first-row' />
      <SecondRow key='second-row' />
    </React.Fragment>
  )
}
