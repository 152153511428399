import { CitiesApi } from '../../api'
import { getCitiesByCountry } from '../../selectors'
import { setCitiesLoading, setCities, setCitiesByCountry } from '../../actions'

export const fetchCitiesByCountry = (country) => {
  return async (dispatch, getState) => {
    if (!country) {
      dispatch(setCities([]))
      dispatch(getCitiesByCountry([]))
    } else {
      dispatch(setCitiesLoading(true))
      const state = getState()
      const cities = getCitiesByCountry(state, country)
      if (!cities || (cities && !cities.length)) {
        const api = new CitiesApi()
        const result = await api.getCitiesByCountry(country)
        dispatch(setCities(result))
        dispatch(setCitiesByCountry(country, result))
      } else {
        dispatch(setCities(cities))
        dispatch(setCitiesByCountry(country, cities))
      }
      dispatch(setCitiesLoading(false))
    }
  }
}
