import React from 'react'
import config from 'SRC/config/config.yaml'

export const BrowserNotSupported = () => {
  return <div className='old-browser'>
    <img src={config.header.logoPath} alt='Logo' />
    <div className='old-browser__message'>
      <p>{config.oldBrowser.message}</p>
    </div>

    <div className='old-browser__container'>
      <ul className='old-browser__items'>
        <li className='old-browser__item'>
          <a href='https://www.google.com/chrome/' className='old-browser__link' target='_blank'>
            <img className='old-browser__logo' src='/img/oldbrowser-chrome.png' alt='logo chrome' />
          </a>
          <ul className='old-browser__underItems'>
            <li className='old-browser__underItem'>
              <img className='old-browser__availableLogo' src='/img/oldbrowser-windows.png' alt='logo windows' />
            </li>
            <li className='old-browser__underItem'>
              <img className='old-browser__availableLogo' src='/img/oldbrowser-apple.png' alt='logo apple' />
            </li>
            <li className='old-browser__underItem'>
              <img className='old-browser__availableLogo' src='/img/oldbrowser-linux.png' alt='logo linux' />
            </li>
          </ul>
        </li>

        <li className='old-browser__item'>
          <a href='https://www.mozzila.org/' className='old-browser__link' target='_blank'>
            <img className='old-browser__logo' src='/img/oldbrowser-firefox.png' alt='logo firefox' />
          </a>
          <ul className='old-browser__underItems'>
            <li className='old-browser__underItem'>
              <img className='old-browser__availableLogo' src='/img/oldbrowser-windows.png' alt='logo windows' />
            </li>
            <li className='old-browser__underItem'>
              <img className='old-browser__availableLogo' src='/img/oldbrowser-apple.png' alt='logo apple' />
            </li>
            <li className='old-browser__underItem'>
              <img className='old-browser__availableLogo' src='/img/oldbrowser-linux.png' alt='logo linux' />
            </li>
          </ul>
        </li>

        <li className='old-browser__item'>
          <a href='https://www.apple.com/' className='old-browser__link' target='_blank'>
            <img className='old-browser__logo' src='/img/oldbrowser-safari.png' alt='logo safari' />
          </a>
          <ul className='old-browser__underItems'>
            <li className='old-browser__underItem'>
              <img className='old-browser__availableLogo' src='/img/oldbrowser-apple.png' alt='logo apple' />
            </li>
          </ul>
        </li>

        <li className='old-browser__item'>
          <a href='https://www.microsoft.com/' className='old-browser__link' target='_blank'>
            <img className='old-browser__logo' src='/img/oldbrowser-edge.png' alt='logo edge' />
          </a>
          <ul className='old-browser__underItems'>
            <li className='old-browser__underItem'>
              <img className='old-browser__availableLogo' src='/img/oldbrowser-windows.png' alt='logo windows' />
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </div>
}
