import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { getCurrentGroup } from 'SRC/modules/groups/selectors'
import { getParentCategory, getCurrentSubCategory } from 'SRC/modules/categories/selectors'
import { Item } from '../Item'

const List = ({
  currentGroup,
  currentSubCategory,
  currentParentCategory
}) => {
  return <section className='subcategories-horizontal-menu'>
    {currentGroup && currentGroup.categories && currentGroup.categories.length && !currentGroup.showCategoriesAsDropdown
      ? currentGroup.categories.filter(item => item && !item.hideIcon)
        .slice().map(item => {
          const href = currentParentCategory ? `/category?parentCategory=${currentParentCategory.seo}&category=${item.seo}` : ''
          const as = currentParentCategory ? `/${currentParentCategory.seo}/${item.seo}` : ''
          return <Item key={`subcategory-${item.seo}`} title={item.title} shortTitle={item.shortTitle} href={href} as={as}
            isCurrent={currentSubCategory && currentSubCategory.id === item.id} icon={item.icon} iconSvg={item.iconSvg} />
        })
      : null
    }
  </section>
}

const mapStateToProps = (state) => {
  return {
    currentGroup: getCurrentGroup(state),
    currentParentCategory: getParentCategory(state),
    currentSubCategory: getCurrentSubCategory(state)
  }
}

List.propTypes = {
  currentGroup: PropTypes.shape({
    categories: PropTypes.arrayOf(PropTypes.shape({
      seo: PropTypes.string,
      title: PropTypes.string,
      shortTitle: PropTypes.string,
      icon: PropTypes.string,
      iconSvg: PropTypes.string
    })),
    showCategoriesAsDropdown: PropTypes.bool
  }).isRequired,
  currentSubCategory: PropTypes.shape({
    id: PropTypes.number,
    seo: PropTypes.string,
    title: PropTypes.string,
    icon: PropTypes.string,
    iconSvg: PropTypes.string
  }),
  currentParentCategory: PropTypes.shape({
    seo: PropTypes.string
  })
}

export default connect(mapStateToProps)(List)
