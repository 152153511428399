import { AdsListApi } from '../../api'
import { setAds } from '../setAds'
import { setLoading } from '../setLoading'
import { setPaidAds } from '../setPaidAds'
import { setInfiniteScrollEnabled } from '../setInfiniteScrollEnabled'
import { setPaginationParams } from '../../../pagination/actions'
import { adsTypes } from 'SRC/core/constants'
import config from 'SRC/config/config.yaml'

export const fetchAds = (requestParams, paginationParams = {}, currentAds = []) => {
  return async (dispatch, getState) => {
    try {
      dispatch(setLoading(true))
      const api = new AdsListApi()

      const {paidAds, freeAds, paidCount, freeCount} = await api.getAds(requestParams)

      const isInfiniteScrollEnabled = paidCount > requestParams.limit || paginationParams.adsType === adsTypes.free

      dispatch(setInfiniteScrollEnabled(isInfiniteScrollEnabled))

      let newAds = []

      if (paidCount > requestParams.limit && paginationParams.adsType === adsTypes.all) {
        newAds = [...paidAds]
      } else {
        newAds = [...paidAds, ...freeAds]
      }

      const isMobileDevice = getState().common.isMobileDevice

      // Insert banner at random position if exists
      if (config.banners.adsList && config.banners.adsList.betweenAdsMobile && isMobileDevice) {
        console.log('newadslength', newAds.length)
        if (newAds.length) {
          newAds.push({
            slotId: `div-gpt-ad-1681800373893-${Math.floor((Math.random() * 10000) + 1)}`,
            key: `banner-${Math.floor((Math.random() * 10000) + 1)}`,
            banner: true
          })
        }
      }

      const ads = currentAds.concat([...newAds])
      const totalCount = (paidCount || 0) + (freeCount || 0)

      dispatch(setAds(ads))
      dispatch(setPaidAds(paidAds.map(ad => ad.id)))
      const pagination = {
        ...paginationParams,
        paidCount: !isNaN(paidCount) ? paidCount : 0,
        freeCount: !isNaN(freeCount) ? freeCount : 0,
        totalCount,
        currentPage: paginationParams.currentPage
      }

      dispatch(setPaginationParams(pagination))
      dispatch(setLoading(false))
    } catch (e) {
      dispatch(setAds(currentAds))
      dispatch(setLoading(false))
    }
  }
}
