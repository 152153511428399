import React from 'react'
import PropTypes from 'prop-types'

export const Preloader = ({ type }) =>
  <div className={type === 'small' ? 'preloader preloader-transparent' : 'preloader'}>
    <div className={type === 'small' ? 'preloader__icon-small' : 'preloader__icon'} />
  </div>

export const AdditionalPreloader = ({ type }) =>
  <div className={type === 'small' ? 'item-loading' : 'ads-loading'}><Preloader type={type} /></div>

export const FixedPreloader = () =>
  <div className='preloader preloader-fixed'>
    <div className='preloader__icon' />
  </div>

export const TextPreloader = () =>
  <div className='ads-loading-text'>Učitavanje oglasa...</div>

Preloader.propTypes = {
  type: PropTypes.string.isRequired
}
Preloader.defaultProps = {
  type: 'default'
}

AdditionalPreloader.propTypes = {
  type: PropTypes.string.isRequired
}
AdditionalPreloader.defaultProps = {
  type: 'default'
}
