import { BaseApi } from 'Core/api/BaseApi'
import { TypesHelper } from 'SRC/utils'

export class Api extends BaseApi {
  fetchProductsByBrand = async brand => {
    const defaultResult = []
    if (TypesHelper.isPositiveNumber(brand)) {
      try {
        const {body} = await Api.post(`/product/listByBrand`, {brand})
        if (!body.error && body.products && Array.isArray(body.products)) {
          return body.products
        } else {
          return defaultResult
        }
      } catch (error) {
        return defaultResult
      }
    } else {
      return defaultResult
    }
  }

  fetchProductByBrandAndCategory = async options => {
    const defaultResult = []
    if (options && TypesHelper.isPositiveNumber(options.brand) && TypesHelper.isPositiveNumber(options.category)) {
      try {
        const {brand, category} = options
        const {body} = await Api.post(`/product/listByBrandAndCategory`, {brand, category})
        if (!body.error && Array.isArray(body.products)) {
          return body.products
        } else {
          return defaultResult
        }
      } catch (error) {
        return defaultResult
      }
    } else {
      return defaultResult
    }
  }

  fetchDefaultSpecsForProductByBrand = async (brand, product) => {
    const defaultResult = []
    if (brand && product && TypesHelper.isPositiveNumber(brand) && TypesHelper.isPositiveNumber(product)) {
      try {
        const {body} = await Api.post(`/phones/get`, {brand, product})
        if (!body.error && body.ad && body.ad.adsSpecifications && Array.isArray(body.ad.adsSpecifications)) {
          return body.ad.adsSpecifications
        } else {
          return defaultResult
        }
      } catch (error) {
        return defaultResult
      }
    } else {
      return defaultResult
    }
  }
}
