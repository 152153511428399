import { BaseApi } from 'Core/api/BaseApi'
import { TypesHelper } from 'SRC/utils'
// import { globalOptions } from 'SRC/core/constants/globalOptions'

export class Api extends BaseApi {
  fetchGroups = async () => {
    const defaultResult = []
    try {
      const {body} = await Api.post('/group/list')
      if (!body.error && body.groups && Array.isArray(body.groups)) {
        return body.groups
      } else {
        return defaultResult
      }
    } catch (error) {
      return defaultResult
    }
  }

  fetchCategories = async () => {
    const defaultResult = []
    try {
      const {body} = await Api.post('/category/list')
      if (!body.error && body.categories && Array.isArray(body.categories)) {
        return body.categories
      } else {
        return defaultResult
      }
    } catch (error) {
      return defaultResult
    }
  }

  fetchCategoriesWithGroups = async () => {
    const defaultResult = []
    try {
      const {body} = await Api.post(`/category/listWithGroups`)
      if (!body.error && body.categories && Array.isArray(body.categories)) {
        return body.categories
      } else {
        return defaultResult
      }
    } catch (error) {
      return defaultResult
    }
  }

  fetchCategoryBySeoWithFilters = async options => {
    const defaultResult = {}
    try {
      if (TypesHelper.isNotEmptyString(options.seo)) {
        const {body} = await Api.post('/category/getBySeoWithFilters', options)
        if (!body.error && body.category) {
          return body.category
        }

        return {
          error: body.error
        }
      } else {
        return defaultResult
      }
    } catch (e) {
      return defaultResult
    }
  }

  fetchCategoryBySeo = async options => {
    const defaultResult = {}
    try {
      if (TypesHelper.isNotEmptyString(options.seo)) {
        const {body} = await Api.post('/category/getBySeo', options)
        if (!body.error && body.category) {
          return body.category
        } else {
          return defaultResult
        }
      } else {
        return defaultResult
      }
    } catch (e) {
      return defaultResult
    }
  }

  fetchSpecificationsByCategory = async category => {
    const defaultResult = []
    if (TypesHelper.isPositiveNumber(category)) {
      try {
        const {body} = await Api.post('/specification/listByCategory', {category})
        if (!body.error && body.categoryspecifications && Array.isArray(body.categoryspecifications)) {
          return body.categoryspecifications
        } else {
          return defaultResult
        }
      } catch (error) {
        return defaultResult
      }
    } else {
      return defaultResult
    }
  }

  fetchAdditionalDependenciesByCategory = async category => {
    const defaultResult = []
    if (TypesHelper.isPositiveNumber(category)) {
      try {
        const {body} = await Api.post('/category/listAdditionalDependencies', {category})
        if (!body.error && body.category && Array.isArray(body.category.additionalDependencies)) {
          return body.category.additionalDependencies
        } else {
          return defaultResult
        }
      } catch (error) {
        return defaultResult
      }
    } else {
      return defaultResult
    }
  }

  fetchCategoryV2 = async (parentSeo, seo) => {
    const defaultResult = null

    let queryParams = ''
    if (TypesHelper.isNotEmptyString(parentSeo) && TypesHelper.isNotEmptyString(seo)) queryParams = `?parentSeo=${parentSeo}&seo=${seo}`
    else if (TypesHelper.isNotEmptyString(parentSeo)) queryParams = `?parentSeo=${parentSeo}`
    else queryParams = ''

    return fetch(`https://oglasi.me/category${queryParams}`)
      .then(response => response.json())
      .then(body => {
        if (!body.error && Array.isArray(body.parentCategories) && Array.isArray(body.parentCategories) && typeof body.group !== 'undefined' && typeof body.category !== 'undefined') {
          return body
        }

        return defaultResult
      })
      .catch(error => defaultResult)
  }
}
