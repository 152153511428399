import React from 'react'
import ReactSVG from 'react-svg'
import config from 'SRC/config/config.yaml'

export const AppsMobile = () =>
  <section className='footer-apps-mobile'>
    <span className='footer-column-title'>Preuzmi aplikacije</span>

    <div style={{ display: 'flex' }}>
      <a
        href='https://play.google.com/store/apps/details?id=me.oglasi'
        target='_blank'
        rel='noreferrer'
      >
        <ReactSVG
          src={'/icons/google-play.svg'}
          beforeInjection={svg => {
            svg.classList.add('footer-apps-svg-mobile')
          }}
          wrapper='span'
        />
      </a>

      <a
        href='https://oglasi.link/ios'
        target='_blank'
        rel='noreferrer'
      >
        <ReactSVG
          src={'/icons/apple-app-store.svg'}
          beforeInjection={svg => {
            svg.classList.add('footer-apps-svg-mobile')
            svg.classList.add('footer-apps-svg-mobile__app-store')
          }}
          wrapper='span'
        />
      </a>

      <a
        href='https://appgallery.huawei.com/app/C104507907'
        target='_blank'
        rel='noreferrer'
      >
        <ReactSVG
          src={'/icons/appgallery.svg'}
          beforeInjection={svg => {
            svg.classList.add('footer-apps-svg-mobile')
          }}
          wrapper='span'
        />
      </a>
    </div>
  </section>
