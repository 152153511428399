import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Formik, Form } from 'formik'
import { getIsMobileDevice } from 'SRC/modules/common/selectors'
import { AdFilterShowDetailBtn } from 'SRC/ui/FormElementsNew'
import { CategoriesFilter, CategoriesFilterMobile, Sort, CitiesFilter, Price } from '../../components'

export class DefaultFilters extends React.Component {
  render () {
    const { onSubmit, initialValues, baseAs, baseHrefQuery, isMobileDevice } = this.props
    // const priceField = this.getPriceField()
    return (
      <Formik
        onSubmit={onSubmit}
        initialValues={initialValues}
        enableReinitialize
      >
        <Form className='polja-pretrage ads-filter'>
          {!isMobileDevice ? (
            <CategoriesFilter baseAs={baseAs} baseHrefQuery={baseHrefQuery} />
          ) : <CategoriesFilterMobile />}
          <Price />
          <CitiesFilter />
          <Sort />
          <div className='filter-buttons'>
            <AdFilterShowDetailBtn onClick={null} disabled />
            <button className='filter-buttons-search'>
              PRETRAŽI <i className='ico-pretraga-polja-lupa ico' />
            </button>
          </div>
        </Form>
      </Formik>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const initialValues = {}

  const existingFilterValues = ownProps.filterValues

  /**
   * Price
   */
  if (existingFilterValues && Array.isArray(existingFilterValues.price) && existingFilterValues.price.length) {
    initialValues.priceFrom = existingFilterValues.price[0] || -1
    initialValues.priceTo = existingFilterValues.price[1] || -1
  } else {
    initialValues.priceFrom = -1
    initialValues.priceTo = -1
  }

  /**
   * Cities
   */
  initialValues.cities = existingFilterValues.cities || []

  /**
   * Sort
   */
  if (existingFilterValues.sortBy) {
    initialValues.sortBy = typeof existingFilterValues.sortBy === 'object'
      ? JSON.stringify(existingFilterValues.sortBy)
      : existingFilterValues.sortBy
  } else {
    initialValues.sortBy = 'dateDesc'
  }

  return {
    initialValues,
    isMobileDevice: getIsMobileDevice(state)
  }
}

DefaultFilters.propTypes = {
  baseAs: PropTypes.string,
  baseHrefQuery: PropTypes.object,
  initialValues: PropTypes.object.isRequired,
  isMobileDevice: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired
}

DefaultFilters.defaultProps = {
  baseAs: '/',
  baseHrefQuery: {}
}

export default connect(mapStateToProps)(DefaultFilters)
