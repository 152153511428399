import React from 'react'
import { ParentCategoriesMenu } from '../ParentCategoriesMenu'
import config from 'SRC/config/config.yaml'

const getChildComponent = itemChildComponentName => {
  switch (itemChildComponentName) {
    case 'ParentCategoriesMenu': return () => <ParentCategoriesMenu />
    default: return null
  }
}

export const itemsBeforeUserTypes = config.menu.items.beforeUserTypes.map(item => {
  return {
    id: item.key,
    link: item.link ? item.link : '',
    as: item.as ? item.as : '',
    text: item.text ? item.text : '',
    icoClass: item.icoClass ? item.icoClass : '',
    className: item.className ? item.className : '',
    pages: item.pages,
    child: getChildComponent(item.child)
  }
})

export const itemsAfterUserTypes = config.menu.items.afterUserTypes.map(item => {
  return {
    id: item.key,
    link: item.link ? item.link : '',
    text: item.text ? item.text : '',
    as: item.as ? item.as : '',
    icoClass: item.icoClass ? item.icoClass : '',
    className: item.className ? item.className : '',
    pages: item.pages,
    child: getChildComponent(item.child)
  }
})
