import PropTypes from 'prop-types'
import React from 'react'
import { getFormattedPhoneNumber } from 'SRC/utils'

export const Phone = ({ phone }) => {
  const parsedPhone = phone?.phone ? getFormattedPhoneNumber(phone.phone) : null

  return phone && parsedPhone ? (
    <div className='sidebar-user-contact-content sidebar-user-contact-telefon'>
      <i className='ico-user-telefon ico' />
      <p key='shown-phone'>{parsedPhone}</p>
      {phone.viber || phone.whatsapp
        ? <span key='viber-whatsapp' className='dostupan-na'>
          Aktivan
          {phone.viber ? <i className='ico-ima-viber ico' /> : null}
          {phone.whatsapp ? <i className='ico-ima-whatsapp ico' /> : null}
        </span>
        : null}

    </div>
  ) : null
}

Phone.propTypes = {
  phone: PropTypes.shape({
    phone: PropTypes.string.isRequired,
    viber: PropTypes.bool,
    whatsapp: PropTypes.bool
  }).isRequired
}
