import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { getCurrentSubCategory } from 'SRC/modules/categories/selectors'
import { getSearchAsUrl, getSearchHrefUrl } from '../../functions'
import Router from 'next/router'
import { AdsFilterApi } from '../../api'
import config from 'SRC/config/config.yaml'

export class SearchInput extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      cbpValue: null,
      textValue: '',
      suggestions: {},
      showDropdown: false
    }
    this.api = new AdsFilterApi()
    this.hideMasterCategories = ['Glavne kategorije']
    this.hideGroups = ['Automobili', 'Radne maÅ¡ine', 'Servisi i usluge', 'Ostale kategorije', 'Djelovi i oprema']
    this.suggestionShowCount = 7
  }

  componentDidMount () {
    const fullText = this.props.filterValues && this.props.filterValues.fullText ? this.props.filterValues.fullText : ''
    if (fullText) {
      this.setState({ textValue: fullText })
    }
  }

  sortHits = hits => {
    let groupCategoryHits = []
    let restHits = []
    if (this.props.group) {
      if (this.props.category) {
        groupCategoryHits = hits.filter((hit1) => hit1.breadcrumbs.some(breadcrumb =>
          breadcrumb.type === 'category' && breadcrumb.id === this.props.category.id
        ))
        restHits = hits.filter((hit1) => hit1.breadcrumbs.every(breadcrumb =>
          !(breadcrumb.type === 'category' && breadcrumb.id === this.props.category.id)
        ))
      } else {
        groupCategoryHits = hits.filter((hit1) => hit1.breadcrumbs.some(breadcrumb =>
          breadcrumb.type === 'group' && breadcrumb.id === this.props.group.id
        ))
        restHits = hits.filter((hit1) => hit1.breadcrumbs.every(breadcrumb =>
          !(breadcrumb.type === 'group' && breadcrumb.id === this.props.group.id)
        ))
      }
    }

    if (!groupCategoryHits.length && !restHits.length) return hits
    return [...groupCategoryHits, ...restHits]
  }

  getElasticCBP = async text => {
    const result = await this.api.getElasticCBP(text)
    let suggestions = {}
    let showDropdown = this.state.showDropdown

    if (result.hits && result.hits.length) {
      let hits = this.sortHits(result.hits)

      if (hits.length > this.suggestionShowCount) hits = hits.slice(0, this.suggestionShowCount)
      for (const hit in hits) {
        const suggestion = {}
        for (const cbps of hits[hit].breadcrumbs) {
          switch (cbps.type) {
            case 'masterCategory':
              if (!this.hideMasterCategories.includes(cbps.title)) {
                suggestion[cbps.type] = { id: cbps.id, title: cbps.title, seo: cbps.seo }
              }
              break
            case 'group':
              if (!this.hideGroups.includes(cbps.title)) {
                suggestion[cbps.type] = { id: cbps.id, title: cbps.title, seo: cbps.seo }
              }
              break
            case 'compatibilityCategory':
            case 'compatibilityBrand':
            case 'compatibilityProduct':
              if (!suggestion.compatibility) suggestion.compatibility = {}
              if (cbps.type === 'compatibilityCategory') {
                suggestion.compatibility.category = { id: cbps.id, title: cbps.title }
              }
              if (cbps.type === 'compatibilityBrand') {
                suggestion.compatibility.brand = { id: cbps.id, title: cbps.title }
              }
              if (cbps.type === 'compatibilityProduct') {
                suggestion.compatibility.product = { id: cbps.id, title: cbps.title }
              }
              break
            default:
              suggestion[cbps.type] = { id: cbps.id, title: cbps.title, seo: cbps.seo }
              break
          }
        }
        // Don't save suggestion if it has only master category
        if (!(Object.keys(suggestion).length === 1 && Object.keys(suggestion)[0] === 'masterCategory')) {
          suggestions[`${hits[hit].title}_${hit}`] = suggestion
        }
      }
      showDropdown = true
    } else {
      showDropdown = false
    }

    this.setState({ suggestions, showDropdown })
  }

  onChange = async e => {
    this.setState({ textValue: e.target.value, cbpValue: null })
    // this.getElasticCBP(e.target.value)
  }

  getBreadcrumbs = suggestionTitle => {
    const suggestion = this.state.suggestions[suggestionTitle]
    let breadcrumbs = []

    if (suggestion.group) {
      breadcrumbs.push(suggestion.group.title)
    }
    if (suggestion.masterCategory) {
      breadcrumbs.push(suggestion.masterCategory.title)
    }
    if (suggestion.category) {
      breadcrumbs.push(suggestion.category.title)
    }
    if (suggestion.brand) {
      breadcrumbs.push(suggestion.brand.title)
    }
    if (suggestion.product) {
      breadcrumbs.push(suggestion.product.title)
    }
    if (suggestion.compatibility) {
      Object.values(suggestion.compatibility).forEach(element => breadcrumbs.push(element.title))
    }
    return breadcrumbs.join(' > ')
  }

  onSuggestionClicked = (e, suggestionTitle) => {
    this.setState({
      cbpValue: this.state.suggestions[suggestionTitle],
      textValue: this.getBreadcrumbs(suggestionTitle),
      showDropdown: false
    }, () => this.onSubmit(e))
  }

  onSubmit = e => {
    e.preventDefault()
    const { cbpValue, textValue } = this.state
    let query = {}
    let seo
    if (!cbpValue) {
      query = textValue ? {fullText: textValue} : {}
      seo = this.props.category && this.props.category.seo ? this.props.category.seo : null
    } else {
      if (cbpValue.category) seo = cbpValue.category.seo
      if (cbpValue.group) query.group = cbpValue.group.id
      if (cbpValue.brand) query.brand = cbpValue.brand.id
      if (cbpValue.product) query.product = cbpValue.product.id
      if (cbpValue.compatibility) {
        const compatibilities = Object.keys(cbpValue.compatibility)
          .reduce((acc, curr) => {
            acc[curr] = cbpValue.compatibility[curr].id
            return acc
          }, {})
        query.compatibility = JSON.stringify(compatibilities)
      }
    }

    query.sortBy = 'dateDesc'

    const { profileAdStatus } = this.props
    if (profileAdStatus) query.status = profileAdStatus

    const asUrl = getSearchAsUrl(seo, this.props.baseAs, '')
    const as = {pathname: asUrl, query}
    const hrefUrl = this.props.baseHref ? this.props.baseHref : getSearchHrefUrl()
    const hrefQuery = {...query, category: seo || ``, ...this.props.baseHrefQuery}
    const href = {pathname: hrefUrl, query: hrefQuery}
    if (this.props.resetFilterState) this.props.resetFilterState(true)
    this.setState({
      cbpValue: null,
      // textValue: '',
      showDropdown: false
    })
    Router.push(href, as)
  }

  render () {
    const {filterValues, dropdownStyle} = this.props
    const fullText = filterValues && filterValues.fullText ? filterValues.fullText : ''
    const placeholder = config.search.textSearchPlaceholder
    return (
      <form onSubmit={this.onSubmit}>
        <input type='text' placeholder={placeholder} onChange={this.onChange} defaultValue={fullText}
          value={this.state.textValue} maxLength={50} />
        {this.state.showDropdown
          ? <div className={dropdownStyle && dropdownStyle === 'profile' ? 'search-dropdown search-dropdown__profile' : 'search-dropdown'}>
            {Object.keys(this.state.suggestions).map(suggestionTitle =>
              <p onClick={(e) => this.onSuggestionClicked(e, suggestionTitle)} key={suggestionTitle}>
                {this.getBreadcrumbs(suggestionTitle)}
              </p>
            )}
          </div> : null}
        <button className='ads-search__button'>
          <i className='ogl-header-ico ico-pretraga-text-search-lupa ico' />
        </button>
      </form>
    )
  }
}

SearchInput.propTypes = {
  baseAs: PropTypes.string.isRequired,
  category: PropTypes.shape({
    id: PropTypes.number.isRequired,
    seo: PropTypes.string.isRequired
  }),
  group: PropTypes.object,
  baseHrefQuery: PropTypes.shape(),
  filterValues: PropTypes.shape({
    fullText: PropTypes.string
  }),
  resetFilterState: PropTypes.func.isRequired,
  baseHref: PropTypes.string,
  dropdownStyle: PropTypes.string,
  profileAdStatus: PropTypes.string
}

SearchInput.defaultProps = {
  baseAs: '/',
  category: null,
  baseHrefQuery: {}
}

export default connect(state => ({
  category: getCurrentSubCategory(state),
  filterValues: {}
}))(SearchInput)
