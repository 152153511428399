import React from 'react'
import { withRouter } from 'next/router'
import PropTypes from 'prop-types'
import Link from 'next/link'
import ReactSVG from 'react-svg'

export const FilterButtons = ({ router }) => {
	let filterSearchHref = {
		pathname: '/mobileAdFilter',
		query: { page: 'category-filter', ...router.query }
	}

	let filterSearchAs = `mobilna-pretraga${router.asPath}`

	if (router.asPath.startsWith('/oglasi/')) {
		const parentCategory = router.query.parentCategory
		const category = router.query.category

		filterSearchHref = {
			pathname: '/mobileAdFilter',
			query: {
				parentCategory,
				category
			}
		}

		filterSearchAs = `/mobilna-pretraga${parentCategory ? `/${parentCategory}` : ''}${category ? `/${category}` : ''}`
	}

  return (
		<ul className='filter-navigation'>
			<li className='filter-navigation-item'>
				<Link
					href={{ pathname: '/mobileAdFilter', query: { page: 'tekstualna-pretraga' } }}
					as={'/mobilna-pretraga/tekstualna-pretraga'}
				>
					<a>
						<ReactSVG
							src={'/icons/pretraga-po-tekstu.svg'}
							beforeInjection={svg => {
								svg.setAttribute('style', 'width: auto; margin-top: 5px; height: 35px;')
							}} wrapper='span'
						/>
						<div className='filter-navigation-item-text'>
							<p>Pretraga</p>
							<p>po tekstu</p>
						</div>
					</a>
				</Link>
			</li>

			<li className='filter-navigation-item'>
				<Link
					href={filterSearchHref}
					as={filterSearchAs}
				>
					<a>
						<ReactSVG
							src={'/icons/pretraga-po-filterima.svg'}
							beforeInjection={svg => {
								svg.setAttribute('style', 'width: auto; margin-top: 5px; height: 35px;')
							}} wrapper='span'
						/>
						<div className='filter-navigation-item-text'>
							<p>Pretraga</p>
							<p>po filterima</p>
						</div>
					</a>
				</Link>
			</li>
		</ul>
  )
}

FilterButtons.propTypes = {
	router: PropTypes.shape({
		asPath: PropTypes.string
	}).isRequired
}

export default withRouter(FilterButtons)
