import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import { connect as formikConnect } from 'formik'
import { adPriceTypes } from 'Core/constants'
import { adFiltersLabels } from 'src/core/constants'
import { SelectDropdown, AdFilterField, AdFilterEmpty } from 'SRC/ui/FormElementsNew'
import { getSelectableUserTypes } from 'SRC/modules/common/selectors'
import { getParentCategory, getCurrentSubCategory, getAdditionalDependenciesVisibility } from 'SRC/modules/categories/selectors'
import { Price } from '../../Price'
import { Condition } from '../../Condition'

export class FirstRow extends React.Component {
  getPriceTypeField = () => {
    const { formik } = this.props

    return {
      id: 'city',
      title: adFiltersLabels['priceType'],
      options: [
        {
          id: `price-type-${adPriceTypes.fixed.id}`,
          label: adPriceTypes.fixed.title,
          value: adPriceTypes.fixed.id
        },
        {
          id: `price-type-${adPriceTypes.negotiated.id}`,
          label: adPriceTypes.negotiated.title,
          value: adPriceTypes.negotiated.id
        },
        {
          id: `price-type-${adPriceTypes.urgent.id}`,
          label: adPriceTypes.urgent.title,
          value: adPriceTypes.urgent.id
        }
      ],
      onChange: this.onPriceTypeChange,
      value: formik.values.priceType
    }
  }

  onPriceTypeChange = value => {
    const { formik } = this.props
    const priceType = Number(value)
    formik.setFieldValue('priceType', priceType)
  }

  getUserTypeField = () => {
    const { userTypes, formik } = this.props

    return {
      id: 'userType',
      title: adFiltersLabels['userType'],
      options: userTypes.map(type => ({
        id: type.id,
        label: type.name,
        value: type.id
      })),
      onChange: this.onUserTypeChange,
      value: formik.values.userType
    }
  }

  onUserTypeChange = value => {
    const { formik } = this.props
    const userType = Number(value)
    formik.setFieldValue('userType', userType)
  }

  render () {
    const { currentParentCategory, currentSubCategory } = this.props

    const priceTypeField = this.getPriceTypeField()
    const userTypeField = this.getUserTypeField()

    return (
      <div className='ads-filter__row'>
        {!currentSubCategory || !currentSubCategory.hasPriceSpecification ? <Price key='price' /> : null}

        <AdFilterField>
          <SelectDropdown {...priceTypeField} />
        </AdFilterField>

        <AdFilterField>
          <SelectDropdown {...userTypeField} />
        </AdFilterField>
        {
          currentSubCategory && currentSubCategory.condition ? <Condition /> : <AdFilterEmpty />
        }
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  userTypes: getSelectableUserTypes(state),
  currentParentCategory: getParentCategory(state),
  currentSubCategory: getCurrentSubCategory(state),
  additionalDependenciesVisibility: getAdditionalDependenciesVisibility(state)
})

FirstRow.propTypes = {
  userTypes: PropTypes.array.isRequired,
  currentSubCategory: PropTypes.shape({
    condition: PropTypes.bool.isRequired,
    hasPriceSpecification: PropTypes.bool
  }),
  additionalDependenciesVisibility: PropTypes.shape({
    price: PropTypes.bool,
    price_period: PropTypes.bool,
    swapp: PropTypes.bool
  }).isRequired,
  formik: PropTypes.shape({
    values: PropTypes.object,
    setFieldValue: PropTypes.func
  }).isRequired
}

FirstRow.defaultProps = {
  userTypes: [],
  currentSubCategory: null
}

const enhance = compose(
  connect(mapStateToProps),
  formikConnect
)

export default enhance(FirstRow)
