import { AdsEquipmentApi } from '../../api'
import { groups } from 'SRC/modules/ads/equipment/data'
import { setEquipment } from '../setEquipment'
import { setEquipmentDependencies } from '../setEquipmentDependencies'
import { setEquipmentVisibility } from '../setEquipmentVisibility'

export const fetchEquipment = (category) => {
  return async (dispatch) => {
    const initialVisibility = Array.isArray(groups)
      ? groups.reduce((acc, curr) => {
        acc[curr.code] = true

        return acc
      }, {})
      : {}

    console.log('fetchEquipment initialVisibility', initialVisibility)
    try {
      const api = new AdsEquipmentApi()
      const equipment = await api.fetchEquipmentByCategory(category)
      dispatch(setEquipment(equipment))
      dispatch(setEquipmentDependencies(equipment))
      dispatch(setEquipmentVisibility(initialVisibility))
    } catch (error) {
      dispatch(setEquipment([]))
      dispatch(setEquipmentDependencies({}))
      dispatch(setEquipmentVisibility(initialVisibility))
    }
  }
}
