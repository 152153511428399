import React from 'react'
import PropTypes from 'prop-types'

export const WhatsApp = ({ baseUrl, asPath, adLink }) => {
  const link = baseUrl ? baseUrl + '/' + adLink : adLink

  return (
    <div className='kontakt-icon kontakt-whatsapp'>
      <a href={adLink ? 'whatsapp://send?text=' + encodeURIComponent(link)
        : 'whatsapp://send?text=' + encodeURIComponent(baseUrl + asPath)}>
        <i className='ico-share-whatsapp ico' />
      </a>
    </div>
  )
}

WhatsApp.propTypes = {
  baseUrl: PropTypes.string.isRequired,
  asPath: PropTypes.string.isRequred,
  adLink: PropTypes.string.isRequred
}
