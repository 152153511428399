import PropTypes from 'prop-types'
import React from 'react'

export const EmailVerification = ({isVerified, needShowText}) =>
  <div className={
    `sidebar-user-verifikacija-item user-card__verification-email ${isVerified ? 'is-verifikovana' : ''}`
  }>
    <i className='ico-user-verifikacija-email ico' />
    {needShowText ? <p>verifikovan email</p> : null}
  </div>

EmailVerification.propTypes = {
  isVerified: PropTypes.bool.isRequired,
  needShowText: PropTypes.bool.isRequired
}

EmailVerification.defaultProps = {
  isVerified: false,
  needShowText: true
}
