export const getSearchAsUrl = (parentCategory, category, baseAs) => {
  if (parentCategory) {
    if (category) {
      return `${baseAs}${parentCategory}/${category}/pretraga`
    } else {
      return `${baseAs}${parentCategory}/pretraga`
    }
  } else {
    return `${baseAs}pretraga`
  }
}
