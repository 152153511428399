import initialState from '../../../redux/initialState'
import actionTypes from '../../../redux/actionTypes'

export const closerLocationsReducer = (state = initialState.geoObjects.closerLocations, action) => {
  switch (action.type) {
    case actionTypes.SET_CLOSER_LOCATIONS:
      return {
        ...state,
        elements: action.payload
      }
    case actionTypes.SET_CLOSER_LOCATIONS_LOADING:
      return {
        ...state,
        isLoading: action.payload
      }
    default:
      return state
  }
}
