import PropTypes from 'prop-types'
import React from 'react'
import { swapOptions } from 'Core/constants'
import { SelectDropdown, AdFilterField } from 'SRC/ui/FormElementsNew'
import { connect as formikConnect } from 'formik'

export class Swapp extends React.Component {
  getSwappField = () => {
    const { formik } = this.props

    return {
      id: 'swapp',
      title: 'Zamjena',
      options: swapOptions.map(option => ({ ...option, label: option.title })),
      value: formik.values.swapp,
      onChange: this.onSwapChange
    }
  }

  onSwapChange = async value => {
    const { formik } = this.props

    const swappValue = isNaN(value) ? value : Number(value)

    formik.setFieldValue('swapp', swappValue)
    formik.setFieldValue('swapps', {
      category: -1,
      brand: -1,
      product: -1,
      specification1: -1,
      specificationValue1: -1,
      specification2: -1,
      specificationValue2: -1
    })
  }

  render () {
    const swappField = this.getSwappField()

    return <AdFilterField className='polja-pretrage-item_swapps'>
      <SelectDropdown {...swappField} />
    </AdFilterField>
  }
}

Swapp.propTypes = {
  formik: {
    values: PropTypes.object,
    setFieldValue: PropTypes.func
  }.isRequired
}

export default formikConnect(Swapp)
