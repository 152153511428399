import React from 'react'
import PropTypes from 'prop-types'
import { Item } from '../Item'
import { SectionHeader } from '../../SectionHeader'

export class Container extends React.PureComponent {
  constructor(props) {
    super(props)

    this.lettersRefs = Object.keys(props.items).reduce((acc, curr) => {
      acc[curr] = React.createRef()
      return acc
    }, {})
  }

  scrollToLetter = letter => {
    const letterRef = this.lettersRefs[letter]
    if (letterRef && letterRef.current) {
      const startY = window.scrollY
      const stopY = letterRef.current.offsetTop
      if (stopY > startY) {
        let timer = 1
        const step = Math.abs(Math.round(stopY/startY))
        for (let i = startY; i <= stopY; i+=step) {
          timer = i * ((10 / stopY) * 10)

          setTimeout(() => {
            window.scroll(0, i)
          }, timer)
        }
      }
    }
  }

  render() {
    const { items, title, baseAs, baseHref } = this.props
    const letters = Object.keys(items)

    return <section className='all-categories-with-letters-section'>
      <div className='letters'>
        {letters.length
          ? letters.map(letter => (
            <p key={`letter-${letter}`} onClick={() => this.scrollToLetter(letter)}>
              {letter.toUpperCase()}
            </p>
          )) : null
        }
      </div>
      <SectionHeader title={title} />
      <div className='categories-items'>
        {letters.length
          ? letters.map(letter => (
            <React.Fragment key={`letter-items-${letter}`}>
              <section key={`letter-${letter}`} ref={this.lettersRefs[letter]}>
                <hr />
                <p className='letter'>{letter.toUpperCase()}</p>
                <div className='categories-items-group'>
                  {items[letter].map(item => {
                    return (
                      <Item
                        key={`letter-item-${item.seo}`}
                        seo={item.seo}
                        parentCategorySeo={item.parentCategorySeo}
                        title={item.title}
                        iconSvg={item.iconSvg}
                        icon={item.icon}
                        baseAs={baseAs}
                        baseHref={baseHref}
                      />
                    )
                  })}
                </div>
              </section>
            </React.Fragment>
          )) : <h1>Nema rezultata</h1>
        }
      </div>
    </section>
  }
}

Container.propTypes = {
  items: PropTypes.objectOf(
    PropTypes.arrayOf({
      seo: PropTypes.string,
      parentCategorySeo: PropTypes.string,
      title: PropTypes.string,
      iconSvg: PropTypes.string,
      icon: PropTypes.string
    })
  ).isRequired,
  title: PropTypes.string.isRequired,
  baseHref: PropTypes.string,
  baseAs: PropTypes.string,
}

Container.defaultProps = {
  items: {}
}
