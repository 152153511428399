import React from 'react'
import { groups as equipmentGroups } from 'SRC/modules/ads/equipment/data'
import { Group as EquipmentGroup } from '../Group'
import PropTypes from 'prop-types'
import { getEquipment, getEquipmentVisibility } from 'SRC/modules/ads/equipment/selectors'
import { connect } from 'react-redux'

export class Container extends React.Component {
  groupEquipment = () => {
    const { equipment, equipmentVisibility } = this.props

    const equipmentSorted = equipment.slice().sort((a, b) => a.weight - b.weight)
    return equipmentGroups.map(group => ({
      title: group.title,
      groupCode: group.code,
      items: equipmentSorted.filter(item => item.grupa === group.code),
      isVisible: equipmentVisibility[group.code]
    }))
  }

  render () {
    const equipmentGroups = this.groupEquipment()

    return (
      equipmentGroups.map(group => group.isVisible ? <EquipmentGroup key={`eq-group-${group.groupCode}`} {...group} /> : null)
    )
  }
}

const mapStateToProps = (state) => ({
  equipment: getEquipment(state),
  equipmentVisibility: getEquipmentVisibility(state)
})

Container.propTypes = {
  equipment: PropTypes.array.isRequired,
  equipmentVisibility: PropTypes.object.isRequired,
  category: PropTypes.shape({
    id: PropTypes.number.isRequired
  }).isRequired
}

Container.defaultProps = {
  equipment: []
}

export default connect(mapStateToProps)(Container)
