import React from 'react'
import config from 'SRC/config/config.yaml'

export const Copyright = () =>
  <div className='footer-bottom'>
    <p>{config.footer.copyright.text[0]} <a target='_blank' href='https://weblab.group/'>{config.footer.copyright.text[1]}</a>.
      Copyright &copy; {config.footer.copyright.year}. {config.footer.copyright.text[2]}.</p>
    <div className='web-dizajn'>
      <p>{config.footer.copyright.design.text}&nbsp;
        <a href={config.footer.copyright.design.link.href} target='_blank'>
          {config.footer.copyright.design.link.text}
        </a></p>
    </div>
  </div>
