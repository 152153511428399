import PropTypes from 'prop-types'
import React from 'react'
import { connect as formikConnect } from 'formik'
import { adFiltersLabels } from 'src/core/constants'
import { AdFilterField, InputRange } from 'SRC/ui/FormElementsNew'
import { priceMask } from 'Core/masks'

export class Price extends React.Component {
  getPriceField = () => {
    const { formik } = this.props

    const priceFrom = formik.values.priceFrom
    const priceTo = formik.values.priceTo

    return {
      id: 'price',
      title: adFiltersLabels['price'],
      mask: priceMask,
      valueFrom: priceFrom,
      valueTo: priceTo,
      allowedCharacters: ['rational'],
      measure: '€',
      onValueFromChange: this.onPriceFromChange,
      onValueToChange: this.onPriceToChange
    }
  }

  onPriceFromChange = value => {
    const { formik } = this.props

    formik.setFieldValue('priceFrom', value || -1)
  }

  onPriceToChange = value => {
    const { formik } = this.props

    formik.setFieldValue('priceTo', value || -1)
  }

  render () {
    const priceField = this.getPriceField()
    return (
      <AdFilterField className='polja-pretrage-item-cena'>
        <InputRange {...priceField} isPrice />
      </AdFilterField>
    )
  }
}

Price.propTypes = {
  formik: PropTypes.shape({
    values: PropTypes.object,
    setFieldValue: PropTypes.func
  }).isRequired
}

export default formikConnect(Price)
