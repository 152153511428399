import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { connect as formikConnect } from 'formik'
import { compose } from 'recompose'
import { adFiltersLabels } from 'Core/constants'
import {
  getFlattenCategories,
  getCurrentSubCategory,
  getAdditionalDependenciesVisibility
} from 'SRC/modules/categories/selectors'
import { AdsBrandsApi } from 'SRC/modules/ads/brands/api'
import { AdsProductsApi } from 'SRC/modules/ads/products/api'
import { SelectDropdown, AdFilterField } from 'SRC/ui/FormElementsNew'
import { Swapp } from '../Swapp'

export class Container extends React.Component {
  constructor (props) {
    super(props)

    this.state = this.getDefaultState()
  }

  getDefaultState = () => ({
    brands: {},
    isBrandsLoading: false,
    products: {},
    isProductsLoading: false
  })

  getAvailableSwappFields = () => {
    const { formik, categories } = this.props

    const fields = {
      brand: false,
      product: false,
      specification1: null,
      specification2: null
    }

    const category = formik.values.swapps.category

    if (category && category !== -1) {
      const selectedCategory = categories && categories.length
        ? categories.find(cat => cat.id === category)
        : null

      if (selectedCategory) {
        const rao = selectedCategory.requiredAdOptions
        const swappSpecification1 = selectedCategory.swappSpecification1
        const swappSpecification2 = selectedCategory.swappSpecification2

        if (rao === 'BrandOnly') {
          fields.brand = true
        }

        if (rao === 'BrandModel') {
          fields.brand = true
          fields.product = true
        }

        if (swappSpecification1) {
          fields.specification1 = swappSpecification1
        }

        if (swappSpecification2) {
          fields.specification2 = swappSpecification2
        }
      }
    }

    return fields
  }

  /**
   * Swapp Category
   */
  getSwappCategoryField = () => {
    const { formik } = this.props

    return {
      id: 'swapp-category',
      title: adFiltersLabels.category,
      isSearchable: true,
      options: this.getSwappCategoryOptions(),
      value: formik.values.swapps.category,
      onChange: this.onSwappCategoryChange
    }
  }

  getSwappCategoryOptions = () => {
    const { categories } = this.props

    return categories && categories.length ? categories.filter(category => category.swappPresence && !category.parent)
      .map(category => ({
        id: category.id,
        label: category.title,
        value: category.id
      })) : []
  }

  onSwappCategoryChange = async value => {
    const { formik } = this.props

    const category = Number(value)

    await formik.setFieldValue('swapps', {
      category,
      brand: -1,
      product: -1,
      specification1: -1,
      specificationValue1: -1,
      specification2: -1,
      specificationValue2: -1
    })
  }

  /**
   * Brands
   */
  getBrandField = () => {
    const { formik, categories } = this.props
    const { brands, isBrandsLoading } = this.state

    const category = formik.values.swapps.category

    if (category && category !== -1 && !brands[category] && !isBrandsLoading) {
      const selectedCategory = categories && categories.length
        ? categories.find(cat => cat.id === category)
        : null

      if (selectedCategory) {
        const rao = selectedCategory.requiredAdOptions

        if (['BrandOnly', 'BrandModel'].includes(rao)) {
          this.loadBrandsToState(category)
        }
      }
    }

    const isDisabled = category === -1 || isBrandsLoading || !brands[category] || !brands[category].length

    return {
      id: 'swapp-brand',
      title: adFiltersLabels.brand,
      isSearchable: true,
      isDisabled,
      options: brands[category] || [],
      value: formik.values.swapps.brand,
      onChange: this.onBrandChange
    }
  }

  loadBrandsToState = async category => {
    const { brands } = this.state

    await this.setState({ isBrandsLoading: true })

    if (brands[category] && brands[category].length) {
      return this.setState({ isBrandsLoading: false })
    }

    const api = new AdsBrandsApi()
    const result = await api.getBrandsByCategory(category)

    this.setState({
      brands: {
        ...brands,
        [category]: result.map(brand => ({
          id: brand.id,
          label: brand.name,
          value: brand.id
        }))
      },
      isBrandsLoading: false
    })
  }

  onBrandChange = async value => {
    const { formik } = this.props

    const brand = Number(value)

    await formik.setFieldValue('swapps', {
      ...formik.values.swapps,
      brand,
      product: -1
    })
  }

  /**
   * Products
   */
  getProductsField = () => {
    const { formik } = this.props
    const { products, isProductsLoading } = this.state

    const brand = formik.values.swapps.brand

    if (brand && brand !== -1 && !products[brand] && !isProductsLoading) {
      this.loadProductsToState(brand)
    }

    const isDisabled = !brand || isProductsLoading || !products[brand] || !products[brand].length

    return {
      id: 'swapp-product',
      title: adFiltersLabels.product,
      isSearchable: true,
      isDisabled,
      options: products[brand] || [],
      value: formik.values.swapps.product,
      onChange: this.onProductChange
    }
  }

  loadProductsToState = async brand => {
    const { formik } = this.props
    const { products } = this.state

    const category = formik.values.swapps.category

    await this.setState({ isProductsLoading: true })

    if (products[brand] && products[brand].length) {
      return this.setState({ isProductsLoading: false })
    }

    const api = new AdsProductsApi()

    const result = await api.fetchProductByBrandAndCategory({
      brand,
      category
    })

    this.setState({
      products: {
        ...products,
        [brand]: result.map(product => ({
          id: product.id,
          label: product.model,
          value: product.id
        }))
      },
      isProductsLoading: false
    })
  }

  onProductChange = value => {
    const { formik } = this.props
    const productValue = Number(value)

    formik.setFieldValue('swapps', {
      ...formik.values.swapps,
      product: productValue
    })
  }

  /**
   * Specifications
   */
  getSpecification1Field = specification => {
    const { formik } = this.props

    const isDisabled = !specification.options || !specification.options.length

    return {
      id: 'swapp-specification-1',
      title: specification.title,
      isDisabled,
      options: specification.options.map((option, index) => ({
        id: `swapp-specification-1-option-${index}`,
        label: option,
        value: option
      })),
      value: formik.values.swapps.specificationValue1,
      onChange: (value) => this.onSpecificationChange1(value, specification.id)
    }
  }

  onSpecificationChange1 = (value, specId) => {
    const { formik } = this.props

    const specValue = isNaN(value) ? value : Number(value)

    formik.setFieldValue('swapps', {
      ...formik.values.swapps,
      specification1: specValue !== -1 ? specId : -1,
      specificationValue1: specValue
    })
  }

  getSpecification2Field = specification => {
    const { formik } = this.props

    const isDisabled = !specification.options || !specification.options.length

    return {
      id: 'swapp-specification-2',
      title: specification.title,
      isDisabled,
      options: specification.options.map((option, index) => ({
        id: `swapp-specification-2-option-${index}`,
        label: option,
        value: option
      })),
      value: formik.values.swapps.specificationValue2,
      onChange: (value) => this.onSpecificationChange2(value, specification.id)
    }
  }

  onSpecificationChange2 = (value, specId) => {
    const { formik } = this.props

    const specValue = isNaN(value) ? value : Number(value)

    formik.setFieldValue('swapps', {
      ...formik.values.swapps,
      specification2: specValue !== -1 ? specId : -1,
      specificationValue2: specValue
    })
  }

  render () {
    const { formik, additionalDependenciesVisibility } = this.props

    const swappVisibility = additionalDependenciesVisibility && additionalDependenciesVisibility.swapp

    const swapp = formik.values.swapp
    const showSwapps = swapp && swapp !== 'No' && swapp !== -1

    const availableSwappFields = this.getAvailableSwappFields()

    return swappVisibility ? (
      <div className='ads-filter__swap'>
        <div className='oglas-form-header' key='swap-header'>
          <p>Zamjena</p>
        </div>
        <Swapp />
        {showSwapps ? <React.Fragment>
          <AdFilterField className='polja-pretrage-item_swapps'>
            <SelectDropdown {...this.getSwappCategoryField()} />
          </AdFilterField>

          {availableSwappFields.brand
            ? <AdFilterField className='polja-pretrage-item_swapps'>
              <SelectDropdown {...this.getBrandField()} />
            </AdFilterField>
            : null
          }

          {availableSwappFields.product
            ? <AdFilterField className='polja-pretrage-item_swapps'>
              <SelectDropdown {...this.getProductsField()} />
            </AdFilterField>
            : null
          }

          {availableSwappFields.specification1
            ? <AdFilterField className='polja-pretrage-item_swapps'>
              <SelectDropdown {...this.getSpecification1Field(availableSwappFields.specification1)} />
            </AdFilterField>
            : null
          }

          {availableSwappFields.specification2
            ? <AdFilterField className='polja-pretrage-item_swapps'>
              <SelectDropdown {...this.getSpecification2Field(availableSwappFields.specification2)} />
            </AdFilterField>
            : null
          }
        </React.Fragment> : null}
      </div>
    ) : null
  }
}

const mapStateToProps = (state) => {
  return {
    categories: getFlattenCategories(state),
    additionalDependenciesVisibility: getAdditionalDependenciesVisibility(state)
  }
}

Container.propTypes = {
  categories: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    seo: PropTypes.string,
    swappPresence: PropTypes.bool
  })).isRequired,
  formik: {
    values: PropTypes.object,
    setFieldValue: PropTypes.func
  }.isRequired,
  additionalDependenciesVisibility: PropTypes.object.isRequired
}

const enhance = compose(
  connect(mapStateToProps),
  formikConnect
)

export default enhance(Container)
