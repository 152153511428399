import initialState from 'src/modules/redux/initialState'
import actionTypes from 'src/modules/redux/actionTypes'

export const cartReducer = (state = initialState.cart, action) => {
  switch (action.type) {
    /**
     * List
     */
    case actionTypes.SET_CART_ITEMS:
      return {
        ...state,
        list: {
          ...state.list,
          items: action.payload
        }

      }
    case actionTypes.SET_CART_ITEMS_LOADING:
      return {
        ...state,
        list: {
          ...state.list,
          isLoading: action.payload
        }
      }
    case actionTypes.SET_CART_ITEMS_FETCHED_INITIALLY:
      return {
        ...state,
        initiallyFetched: action.payload
      }

    /**
     * Add/remove cart item loading
     */
    case actionTypes.SET_CART_ITEM_LOADING:
      return {
        ...state,
        cartItemLoading: action.payload
      }

    /**
     * Buy cart notification
     */
    case actionTypes.SET_BUY_CART_NOTIFICATION:
      return {
        ...state,
        buyCartNotification: action.payload
      }

    /**
     * Cart history
     */
    case actionTypes.SET_CART_HISTORY_ITEMS:
      return {
        ...state,
        history: {
          ...state.history,
          items: action.payload
        }
      }
    case actionTypes.SET_CART_HISTORY_LOADING:
      return {
        ...state,
        history: {
          ...state.history,
          itemsLoading: action.payload
        }
      }
    case actionTypes.SET_CART_HISTORY_FETCHED_INITIALLY:
      return {
        ...state,
        history: {
          ...state.history,
          initiallyFetched: action.payload
        }
      }
    case actionTypes.SET_CART_HISTORY_PAGINATION_PARAMS:
      return {
        ...state,
        history: {
          ...state.history,
          pagination: action.payload
        }
      }
    case actionTypes.SET_CART_CURRENT_HISTORY_ITEM:
      return {
        ...state,
        history: {
          ...state.history,
          currentItem: action.payload
        }
      }
    case actionTypes.SET_CART_CURRENT_HISTORY_ITEM_LOADING:
      return {
        ...state,
        history: {
          ...state.history,
          currentItemLoading: action.payload
        }
      }

    case actionTypes.RESET_CART:
      return {
        ...state,
        list: {
          items: [],
          isLoading: false
        },
        cartItemLoading: false,
        initiallyFetched: false,
        buyCartNotification: '',
        history: {
          items: [],
          itemsLoading: false,
          initiallyFetched: false,
          pagination: {
            page: 1,
            limit: 10,
            count: 0
          },
          currentItem: null,
          currentItemLoading: false
        }
      }
    default:
      return state
  }
}
