import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import Link from 'next/link'
import { getInfo } from 'SRC/modules/users/detail/selectors'
import { getParentCategory, getCurrentSubCategory } from 'SRC/modules/categories/selectors'
import { prepareFilterValues } from 'SRC/modules/ads/filter/functions'
import { CategoriesFilter } from 'SRC/modules/ads/filter/components'

export const UserAdsCategoryFilterMobile = ({
  baseAs,
  baseHrefQuery,
  userInfo,
  currentParentCategory,
  currentSubcategory,
  filterValues
}) => {
  return (
    <React.Fragment>
      <CategoriesFilter baseAs={baseAs} baseHrefQuery={baseHrefQuery} />

      {currentParentCategory && currentSubcategory && userInfo ? (
        <Link
          href={{
            pathname: '/mobileAdFilter',
            query: {
              page: 'category-filter',
              username: userInfo.username,
              adUserType: userInfo.type ? userInfo.type.seo : 'korisnik',
              parentCategory: currentParentCategory.seo,
              category: currentSubcategory.seo,
              ...prepareFilterValues(filterValues)
            }
          }}
          as={{
            pathname: `/mobilna-pretraga/${currentParentCategory.seo}/${currentSubcategory.seo}`,
            query: {
              username: userInfo.username,
              adUserType: userInfo.type ? userInfo.type.seo : 'korisnik',
              ...prepareFilterValues(filterValues)
            }
          }}
        >
          <a className='filter-btn'>
            Filtriraj za detalje
          </a>
        </Link>
      ) : null}
    </React.Fragment>
  )
}

const mapStateToProps = state => ({
  userInfo: getInfo(state),
  currentParentCategory: getParentCategory(state),
  currentSubcategory: getCurrentSubCategory(state)
})

UserAdsCategoryFilterMobile.propTypes = {
  userInfo: PropTypes.shape({
    id: PropTypes.number
  }).isRequired,
  baseAs: PropTypes.string,
  baseHrefQuery: PropTypes.object,
  currentParentCategory: PropTypes.shape({
    seo: PropTypes.string
  }),
  currentSubcategory: PropTypes.shape({
    seo: PropTypes.string
  }),
  filterValues: PropTypes.object
}

UserAdsCategoryFilterMobile.defaultProps = {
  filterValues: {}
}

export default connect(mapStateToProps)(UserAdsCategoryFilterMobile)
