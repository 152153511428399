import React from 'react'
import PropTypes from 'prop-types'
import Router from 'next/router'
import { DetailForm } from '../DetailForm'
import { BasicForm } from '../BasicForm'
import { DefaultFilters } from '../DefaultFilters'
import { getSearchAsUrl, getSearchHrefUrl, prepareFilterValues } from '../../functions'

export class Container extends React.Component {
  onSubmit = async values => {
    const { baseAs, baseHrefQuery, currentParentCategory, currentGroup, currentSubcategory, formStyle } = this.props
    const { category, ...restFilterValues } = values

    let actualCategory = category

    if (!category && currentGroup) {
      actualCategory = currentGroup.seo
    }

    if (!category && currentSubcategory) {
      actualCategory = currentSubcategory.seo
    }

    if (currentParentCategory && actualCategory && currentParentCategory.seo === actualCategory) {
      actualCategory = null
    }

    const filterValues = this.prepareFilterFormValues(restFilterValues)

    let hrefUrl = getSearchHrefUrl()

    const href = {
      pathname: hrefUrl,
      query: {
        parentCategory: currentParentCategory ? currentParentCategory.seo : null,
        pageNumber: 1,
        formStyle,
        ...filterValues,
        ...baseHrefQuery
      }
    }

    if (actualCategory) {
      href.query.category = actualCategory
    }

    const asUrl = getSearchAsUrl(currentParentCategory ? currentParentCategory.seo : null, actualCategory, baseAs)
    const as = {
      pathname: asUrl,
      query: {
        pageNumber: 1,
        formStyle,
        ...filterValues
      }
    }

    console.log('onsubmit href, as', href, as)
    Router.push(href, as)
  }

  prepareFilterFormValues = values => {
    const filterValues = {}
    for (let property in values) {
      switch (property) {
        case 'equipment':
          let equipment = []
          for (let group in values[property]) {
            const groupItems = values[property][group]
            equipment = equipment.concat(groupItems)
          }

          if (equipment.length) filterValues[property] = JSON.stringify(equipment)

          break
        case 'priceFrom':
        case 'priceTo':
        case 'swapps':
        case 'compatibility':
        case 'specifications':
          break
        default:
          if (typeof values[property] === 'number' || typeof values[property] === 'string') {
            if (values[property] !== -1) {
              filterValues[property] = values[property]
            }
          }

          if (typeof values[property] === 'object') {
            const isNotEmpty = (Array.isArray(values[property]) && values[property].length) ||
              (Object.keys(values[property]) && Object.keys(values[property]).length)

            if (isNotEmpty) {
              filterValues[property] = JSON.stringify(values[property])
            }
          }
          break
      }
    }

    /**
     * Specifications
     */
    if (values.specifications) {
      const specifications = []

      for (let specId in values.specifications) {
        if (values.specifications[specId].hasOwnProperty('isVisible') && !values.specifications[specId].isVisible) {
          // skip hidden spec
          continue
        }

        const specificationObj = values.specifications[specId]

        if (specificationObj.hasOwnProperty('rangeFrom') || specificationObj.hasOwnProperty('rangeTo')) {
          const specificationRange = { range: {} }
          if (Number(specificationObj.rangeFrom) !== -1) {
            specificationRange.range.from = specificationObj.rangeFrom
          }

          if (Number(specificationObj.rangeTo) !== -1) {
            specificationRange.range.to = specificationObj.rangeTo
          }

          if (Object.keys(specificationRange.range).length) {
            specifications.push({ id: specId, ...specificationRange })
            continue
          }
        }

        const valueProperty = Object.keys(values.specifications[specId])[0]

        const value = values.specifications[specId][valueProperty]

        if (value !== -1) {
          const specificationObject = {
            id: specId,
            [valueProperty]: value
          }

          // Clear empty values in the specification object (ex. rangeFrom = -1)
          if (typeof specificationObject[valueProperty] === 'object' && !Array.isArray(specificationObject[valueProperty])) {
            const specificationValueObject = Object.keys(specificationObject[valueProperty])
              .filter(specificationObjectKey => specificationObject[valueProperty][specificationObjectKey] !== -1)
              .reduce((acc, curr) => {
                acc[curr] = specificationObject[valueProperty][curr]
                return acc
              }, {})
            specificationObject[valueProperty] = specificationValueObject
          }

          specifications.push(specificationObject)
        }
      }

      if (specifications.length) {
        filterValues.specifications = JSON.stringify(specifications)
      }
    }

    /**
     * Compatibility
     */

    if (values.compatibility) {
      const compatibilityProperties = Object.keys(values.compatibility)
        .filter(compatibilityProperty => values.compatibility[compatibilityProperty] !== -1)

      if (compatibilityProperties.length) {
        const compatibility = {}

        compatibilityProperties.forEach(compatibilityProperty => {
          const value = values.compatibility[compatibilityProperty]

          if (compatibilityProperty !== 'year') {
            compatibility[compatibilityProperty] = value
          } else {
            /** Year must be sent as a string */
            compatibility[compatibilityProperty] = value.toString()
          }
        })

        filterValues.compatibility = JSON.stringify(compatibility)
      }
    }

    /**
     * Swapps
     */

    if (values.swapps) {
      const swappsProperties = Object.keys(values.swapps).filter(swappProperty => values.swapps[swappProperty] !== -1)

      if (swappsProperties.length) {
        const swapps = {}
        swappsProperties.forEach(swappProperty => {
          swapps[swappProperty] = values.swapps[swappProperty]
        })

        filterValues.swapps = JSON.stringify(swapps)
      }
    }

    /**
     * Price
     */
    const price = [null, null]

    if (values.priceFrom && values.priceFrom !== -1) {
      price[0] = values.priceFrom
    }

    if (values.priceTo && values.priceTo !== -1) {
      price[1] = values.priceTo
    }

    if (price[0] || price[1]) filterValues.price = JSON.stringify(price)

    return filterValues
  }

  setBasicForm = () => {
    const { baseAs, baseHrefQuery, currentPage, currentParentCategory, currentSubcategory, filterValues, isMobileDevice } = this.props

    const hrefUrl = getSearchHrefUrl(isMobileDevice)

    const href = {
      pathname: hrefUrl,
      query: {
        parentCategory: currentParentCategory ? currentParentCategory.seo : null,
        category: currentSubcategory && !currentParentCategory.isSelfContainedParentCategory ? currentSubcategory.seo : null,
        pageNumber: currentPage,
        formStyle: 'basic',
        ...prepareFilterValues(filterValues),
        ...baseHrefQuery
      }
    }

    const asUrl = getSearchAsUrl(
      currentParentCategory ? currentParentCategory.seo : null,
      currentSubcategory && !currentParentCategory.isSelfContainedParentCategory ? currentSubcategory.seo : null,
      !isMobileDevice ? baseAs : '/mobilna-pretraga/'
    )

    const as = {
      pathname: asUrl,
      query: {
        ...prepareFilterValues(filterValues),
        pageNumber: currentPage,
        formStyle: 'basic'
      }
    }

    Router.push(href, as)
  }

  setDetailForm = () => {
    const {
      baseAs,
      baseHrefQuery,
      currentPage,
      currentParentCategory,
      currentSubcategory,
      filterValues,
      isMobileDevice
    } = this.props

    const hrefUrl = getSearchHrefUrl(isMobileDevice)
    const href = {
      pathname: hrefUrl,
      query: {
        parentCategory: currentParentCategory ? currentParentCategory.seo : null,
        category: currentSubcategory && !currentParentCategory.isSelfContainedParentCategory ? currentSubcategory.seo : null,
        pageNumber: currentPage,
        formStyle: 'detail',
        ...prepareFilterValues(filterValues),
        ...baseHrefQuery
      }
    }

    const asUrl = getSearchAsUrl(
      currentParentCategory ? currentParentCategory.seo : null,
      currentSubcategory && !currentParentCategory.isSelfContainedParentCategory ? currentSubcategory.seo : null,
      !isMobileDevice ? baseAs : '/mobilna-pretraga/'
    )

    const as = {
      pathname: asUrl,
      query: {
        ...prepareFilterValues(filterValues),
        pageNumber: currentPage,
        formStyle: 'detail'
      }
    }

    Router.push(href, as)
  }

  render () {
    const { filterValues, formStyle, baseAs, baseHrefQuery, currentSubcategory } = this.props

    const formProps = {
      onSubmit: this.onSubmit,
      filterValues: filterValues || {},
      onToggleFormStyle: formStyle === 'basic' ? this.setDetailForm : this.setBasicForm,
      baseAs,
      baseHrefQuery
    }

    return (
      <React.Fragment>
        {formStyle === 'default' && !currentSubcategory ? <DefaultFilters {...formProps} /> : null}
        {formStyle === 'basic' || (formStyle === 'default' && currentSubcategory) ? <BasicForm {...formProps} /> : null}
        {formStyle === 'detail' ? <DetailForm {...formProps} /> : null}
      </React.Fragment>
    )
  }
}

Container.propTypes = {
  baseAs: PropTypes.string,
  baseHrefQuery: PropTypes.object,
  currentPage: PropTypes.number,
  filterValues: PropTypes.object.isRequired,
  formStyle: PropTypes.string.isRequired,
  currentParentCategory: PropTypes.shape({
    seo: PropTypes.string,
    isSelfContainedParentCategory: PropTypes.bool
  }),
  currentSubcategory: PropTypes.object,
  currentGroup: PropTypes.object,
  isMobileDevice: PropTypes.string.isRequired,
}

Container.defaultProps = {
  baseAs: '/',
  baseHrefQuery: {},
  currentPage: 1,
  isMobileDevice: false
}
