import React from 'react'
import PropTypes from 'prop-types'
import Link from 'next/link'
import config from 'SRC/config/config.yaml'
import ReactSVG from 'react-svg'

export const Item = ({ item }) => {
  return <Link href={`/category?parentCategory=${item.seo}`} as={`/${item.seo}`}>
    <div className='parent-categories-dropdown-menu-item'>
      <div className='parent-categories-dropdown-menu-item-svg'>
        <ReactSVG src={`${config.mediaCDN}${item.iconSvg}`} beforeInjection={svg => {
          svg.setAttribute('style', 'width: 35px; height: 100%;')
        }} wrapper='span' />
      </div>
      <p>{item.title}</p>
    </div>
  </Link>
}

Item.propTypes = {
  item: PropTypes.object.isRequired
}
