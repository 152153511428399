import { setParentCategory, setCurrentSubCategory } from 'SRC/modules/categories/actions'
import { CategoriesApi } from 'SRC/modules/categories/api'
import { getIsSelfConainedCategory } from 'SRC/modules/categories/functions'
import { setCurrentGroup, setGroups } from 'SRC/modules/groups/actions'

export const setCategoriesAndGroups = (parentCategoryInUrl = '', categoryInUrl = '') => {
  return async (dispatch, getState) => {
    const api = new CategoriesApi()

    const result = await api.fetchCategoryV2(parentCategoryInUrl, categoryInUrl)

    console.log('setCategoriesAndGroups parentCategoryInUrl', parentCategoryInUrl)
    console.log('setCategoriesAndGroups result', result)

    if (!result) {
      dispatch(setParentCategory(null))
      dispatch(setCurrentSubCategory(null))
      dispatch(setCurrentGroup(null))
      dispatch(setGroups([]))

      return
    }

    dispatch(setParentCategory(result.parentCategory))
    dispatch(setCurrentSubCategory(result.category))
    dispatch(setCurrentGroup(result.group))
    dispatch(setGroups(result.groups))
  }
}
