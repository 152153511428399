import { CategoriesApi } from '../../api'
import { setParentCategory } from '../setParentCategory'
import { setCurrentSubCategory } from '../setCurrentSubCategory'
import { setCategoryBySeo } from '../setCategoryBySeo'
import { getCategoryBySeo } from '../../selectors'

export const fetchCategory = (options, withFilters = true) => {
  return async (dispatch, getState) => {
    const state = getState()
    let category = getCategoryBySeo(options.seo)(state)

    if (!category || (category && category.isWithFilters !== withFilters)) {
      const api = new CategoriesApi()

      category = withFilters
        ? await api.fetchCategoryBySeoWithFilters({ seo: options.seo })
        : await api.fetchCategoryBySeo({ seo: options.seo })

      category.isWithFilters = withFilters
      if (category && !category.error) {
        dispatch(setCategoryBySeo(options.seo, category))
      }
    }
    if (Object.keys(category).length && !category.error) {
      if (category.parent || (options?.layoutStyle === 'no-sidebar') || options.setCurrentSubCategory) {
        dispatch(setCurrentSubCategory(category))
      } else {
        dispatch(setParentCategory(category))
        dispatch(setCurrentSubCategory(null))
      }

    } else {
      dispatch(setCurrentSubCategory(null))
    }
  }
}
