import React from 'react'
import PropTypes from 'prop-types'
import config from 'SRC/config/config.yaml'

import { Popup } from '../Popup'
import { SearchInput } from '../SearchInput'

export const Container = ({ isUserPage }) => {
  const [popupVisible, setPopupVisible] = React.useState(false)

  return <SearchInput />

  // if (!isUserPage) return <SearchInput />

  // const placeholder = config.search.textSearchPlaceholder

  // if (!popupVisible) {
  //   return (
  //     <form className='all-categories-with-letters'>
  //       <input
  //         type='text'
  //         placeholder={placeholder}
  //         onClick={() => { setPopupVisible(true) }}
  //       />
  //     </form>
  //   )
  // }

  // return <Popup popupVisible={popupVisible} closePopup={() => { setPopupVisible(false) }} />
}

Container.propTypes = {
  isUserPage: PropTypes.bool.isRequired
}
