import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { RegistrationApi } from 'SRC/modules/users/Registration/api'
import parsePhoneNumber from 'libphonenumber-js'
import { getFormattedPhoneNumber } from 'SRC/utils'

export const SmsCode = ({ isLoading, phone, setErrorMessage, onSubmit, wrongNumberClicked }) => {
  const [smsCode, setSmsCode] = React.useState('')
  const [countdown, setCountdown] = React.useState(0)
  const [attempts, setAttempts] = React.useState(1)
  const [isSendingSms, setIsSendingSms] = React.useState(false)
  const [supportText, setSupportText] = React.useState('')

  useEffect(() => {
    setCountdown(120)
    fetch(`${location.protocol}//${location.host}/settings`)
      .then(res => res.json())
      .then(response => {
        if (response?.settings && response?.settings.support) setSupportText(response.settings.support)
      })
  }, [])

  useEffect(() => {
    if (countdown > 0) setTimeout(() => setCountdown(countdown - 1), 1000)
  }, [countdown])

  useEffect(() => {
    if (smsCode.length === 6) onSubmit(smsCode)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [smsCode])

  const getCountdownText = () => {
    let text = ''

    const minutes = Math.floor(countdown / 60)

    let seconds = '0'

    if (countdown % 60 !== 0) seconds = `${countdown % 60}`

    text = `0${minutes}:${seconds.length === 1 ? `0${seconds}` : `${seconds}`}`

    return text
  }

  const resendSms = async () => {
    setIsSendingSms(true)
    const regApi = new RegistrationApi()
    const smsResponse = await regApi.sendSmsCode(phone)

    console.log('smsResponse', smsResponse)
    if (smsResponse.error) {
      setErrorMessage(smsResponse.error)
      setIsSendingSms(false)
    } else {
      const { statusCode } = smsResponse

      if (typeof statusCode !== 'undefined' && (statusCode === 0 || statusCode === 2)) {
        setAttempts(attempts + 1)
        setCountdown(300)
      }

      setIsSendingSms(false)
    }
  }

  const submitButtonDisabled = isLoading || !Number(smsCode) || smsCode.length < 6
  const resendButtonDisabled = isLoading || isSendingSms || countdown > 0

  return (
    <div className='login-form'>
      <p>KOD je poslat na broj <strong style={{ fontSize: '1rem' }}>{getFormattedPhoneNumber(phone)}</strong></p>
      <a
        href='javascript:void(0)'
        style={{ marginBottom: 15 }}
        onClick={wrongNumberClicked}
      >
        Pogrešan broj?
      </a>

      <div className='form-group sms-code'>
        <label>Unesite vaš 6-cifreni kod: <span className='required'>*</span></label>

        <input
          disabled={isLoading}
          maxLength={6}
          type='text'
          onChange={(e) => {
            const value = e.target.value
            if (Number(value) || value === '') setSmsCode(value)
          }}
          value={smsCode}
        />
      </div>

      <div className='resend-sms-code'>
        <span className='countdown-text'>SAČEKAJTE: {getCountdownText()}</span>
        <button
          disabled={resendButtonDisabled}
          className='sms-code-button'
          style={{ opacity: resendButtonDisabled ? .4 : 1 }}
          onClick={resendSms}
        >
          POŠALJI PONOVO
        </button>
        {attempts > 1 ? (
          <span className='support-text'>{supportText}</span>
        ) : null}
      </div>

      <button
        type='submit'
        name='submit-btn'
        className='sms-code-button'
        disabled={submitButtonDisabled}
        onClick={() => onSubmit(smsCode)}
        style={{ opacity: submitButtonDisabled ? .4 : 1 }}
      >
        Potvrdi
      </button>
    </div>
  )
}

SmsCode.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  phone: PropTypes.string.isRequired,
  setErrorMessage: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  wrongNumberClicked: PropTypes.func.isRequired
}
