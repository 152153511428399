import React from 'react'
import Link from 'next/link'
import config from 'SRC/config/config.yaml'

export const AddAdBlock = () => (
  <div className='prodajete-tehniku'>
    <span className='footer-column-title'>{config.footer.addAd.header}</span>
    <div className='postavi-oglas'>
      <p>{config.footer.addAd.text} {config.siteName}</p>
      <Link href={'/dodaj'}>
        <a className='dodaj-oglas-btn'>
          <i className='ico-footer-dodaj-oglas ico' />
          {config.pages.addAd.headerTitle}
        </a>
      </Link>
    </div>
  </div>
)
