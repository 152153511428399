import { createSelector } from 'reselect'

const defaultParams = {
  currentPage: 1,
  countPerPage: 10,
  count: 0
}

export const getPaginationParams = createSelector(
  (state) => state && state.ads && state.ads.adsWithPromosPagination && state.ads.adsWithPromosPagination.params
    ? state.ads.adsWithPromosPagination.params
    : defaultParams,
  (params) => params
)
