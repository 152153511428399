import { BaseApi } from 'Core/api/BaseApi'

export class Api extends BaseApi {
  fetchCart = async payload => {
    const defaultResult = []

    const { userId, token } = payload
    try {
      const { body } = await Api.post('/cart/list', { userId, token })

      const result = body.items

      return body.error ? defaultResult : result
    } catch (e) {
      return defaultResult
    }
  }

  addCartItem = async payload => {
    const { userId, token, ad } = payload
    if (userId && token && ad) {
      try {
        const { body } = await Api.post('/cart/add', payload)
        const error = body.error
        if (error || !body.items) {
          return {status: Api.responseTypes.ERROR, error}
        } else {
          return { status: Api.responseTypes.OK, items: body.items }
        }
      } catch (e) {
        const result = {
          status: Api.responseTypes.ERROR
        }

        return result
      }
    }
  }

  removeCartItem = async payload => {
    const { userId, token, item } = payload
    if (userId && token && item) {
      try {
        const { body } = await Api.post('/cart/delete', payload)
        const error = body.error
        if (error || !body.items) {
          return {status: Api.responseTypes.ERROR, error}
        } else {
          return { status: Api.responseTypes.OK, items: body.items }
        }
      } catch (e) {
        const result = {
          status: Api.responseTypes.ERROR
        }

        return result
      }
    }
  }

  updateCartItem = async payload => {
    const { userId, token, item, amount } = payload

    if (userId && token && item && amount > 0) {
      try {
        const { body } = await Api.post('/cart/update', payload)
        const error = body.error
        if (error || !body.items) {
          return {status: Api.responseTypes.ERROR, error}
        } else {
          return { status: Api.responseTypes.OK, items: body.items }
        }
      } catch (e) {
        const result = {
          status: Api.responseTypes.ERROR
        }

        return result
      }
    }
  }

  buy = async payload => {
    if (payload.userId && payload.token) {
      try {
        const { body } = await Api.post('/cart/buy', payload)
        const error = body.error
        if (error || !body.items) {
          return {status: Api.responseTypes.ERROR, error}
        } else {
          return { status: Api.responseTypes.OK, items: body.items }
        }
      } catch (e) {
        const result = {
          status: Api.responseTypes.ERROR
        }

        return result
      }
    }
  }

  fetchCartHistory = async payload => {
    const defaultResult = []

    try {
      const { body } = await Api.post('/cart/listHistory', payload)

      const result = body

      return body.error ? defaultResult : result
    } catch (e) {
      return defaultResult
    }
  }

  getCartHistoryItem = async payload => {
    const defaultResult = null

    try {
      const { body } = await Api.post('/cart/getHistoryItem', payload)

      const result = body.item

      return body.error ? defaultResult : result
    } catch (e) {
      return defaultResult
    }
  }
}
