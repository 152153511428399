import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Router from 'next/router'
import { SelectDropdown } from 'SRC/ui/FormElementsNew'
import { getCurrentSubCategory, getParentCategory } from 'SRC/modules/categories/selectors'
import { getCurrentGroup } from 'SRC/modules/groups/selectors'


const prepareCategories = ({ currentGroup, currentParentCategory, currentSubCategory }) => {
  return currentGroup && currentParentCategory && Array.isArray(currentGroup.categories) ? currentGroup.categories.map(category => ({
    id: category.id,
    label: category.title,
    value: JSON.stringify({
      parentCategory: currentParentCategory.seo,
      category: category.seo
    })
  })) : []
}

const getCategoryValue = ({ currentParentCategory, currentSubCategory }) => {
  let value = '-1'

  if (currentParentCategory && currentSubCategory) {
    value = JSON.stringify({
      parentCategory: currentParentCategory.seo,
      category: currentSubCategory.seo
    })
  }

  return value
}

const GroupCategoriesDropdown = ({ currentGroup, currentParentCategory, currentSubCategory }) => {
  const preparedCategories = prepareCategories({ currentGroup, currentParentCategory })

  return (
    <SelectDropdown
      id='categories-dropdown'
      labelDefault='Izaberi kategoriju'
      isSearchable
      isDisabled={!currentGroup || !Array.isArray(currentGroup.categories) || !currentGroup.categories.length}
      value={getCategoryValue({ currentParentCategory, currentSubCategory })}
      options={preparedCategories}
      onChange={data => {
        const value = JSON.parse(data)

        const isSelfContained = currentParentCategory?.isSelfContainedParentCategory

        let as = `/${currentParentCategory.seo}`
        let href = {
          pathname: '/category',
          query: { parentCategory: currentParentCategory.seo }
        }

        if (!isSelfContained) {
          as = `/${currentParentCategory.seo}/${currentGroup.seo}`
          href.query.category = currentGroup.seo
        }

        if (Number(value) === -1) return Router.push(href, as)

        as = `/${value.parentCategory}`
        href = {
          pathname: '/category',
          query: { parentCategory: value.parentCategory }
        }

        if (!isSelfContained) {
          as = `/${value.parentCategory}/${value.category}`
          href.query.category = value.category
        }

        Router.push(href, as)
      }}
    />
  )
}

const mapStateToProps = state => {
  return {
    currentParentCategory: getParentCategory(state),
    currentSubCategory: getCurrentSubCategory(state),
    currentGroup: getCurrentGroup(state)
  }
}

GroupCategoriesDropdown.propTypes = {
  currentParentCategory: PropTypes.shape({
    id: PropTypes.number,
    seo: PropTypes.string
  }).isRequired,
  currentSubCategory: PropTypes.shape({
    id: PropTypes.number,
    seo: PropTypes.string
  }).isRequired,
  currentGroup: PropTypes.shape({
    id: PropTypes.number,
    seo: PropTypes.string,
    parentCategory: PropTypes.number,
    categories: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      title: PropTypes.string,
      seo: PropTypes.string
    }))
  }).isRequired
}

export default connect(mapStateToProps)(GroupCategoriesDropdown)
