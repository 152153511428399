import React from 'react'
import config from 'SRC/config/config.yaml'

export const PhoneTip = () => {
  const labels = config.loginForm.labels
  return <div className='info-polje info-login'>
    <p className='heading'>{labels.phone}</p>
    <p>{config.loginForm.toolTips.phone}</p>
  </div>
}
