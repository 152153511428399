import React from 'react'
import { connect } from 'react-redux'
import { getIsAuthorized } from '../../selectors'
import { LoginPasswordless } from '../LoginPasswordless'

const AuthContainer = ({ isAuthorized, children }) => {
	if (isAuthorized) return children

	return <LoginPasswordless />
}

const mapStateToProps = state => {
	return {
		isAuthorized: getIsAuthorized(state)
	}
}

export default connect(mapStateToProps)(AuthContainer)

