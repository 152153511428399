import actionTypes from 'SRC/modules/redux/actionTypes'

export const setEquipmentDependencies = (equipment) => {
  const dependenciesBySpec = {}
  const dependenciesByGroup = {}

  if (Array.isArray(equipment)) {
    equipment.forEach(eqItem => {
      if (eqItem.dependent) {
        const depObj = {
          dependent: eqItem.dependent,
          dependentType: eqItem.dependentType,
          dependentRatio: eqItem.dependentRatio,
          dependentValues: eqItem.dependentValues,
          grupa: eqItem.grupa
        }

        if (!Array.isArray(dependenciesBySpec[eqItem.dependent])) {
          dependenciesBySpec[eqItem.dependent] = [depObj]
        } else {
          dependenciesBySpec[eqItem.dependent].push(depObj)
        }

        if (!dependenciesByGroup[eqItem.grupa]) {
          dependenciesByGroup[eqItem.grupa] = { hide: null, show: null }
        }

        if (!dependenciesByGroup[eqItem.grupa][eqItem.dependentType]) {
          dependenciesByGroup[eqItem.grupa][eqItem.dependentType] = {
            [eqItem.dependent]: eqItem.dependentValues
          }
        } else {
          dependenciesByGroup[eqItem.grupa][eqItem.dependentType][eqItem.dependent] = eqItem.dependentValues
        }
      }
    })
  }

  return {
    type: actionTypes.SET_EQUIPMENT_DEPENDENCIES,
    payload: {
      bySpec: dependenciesBySpec,
      byGroup: dependenciesByGroup
    }
  }
}
