import React from 'react'
import { Menu, Weblab, ProjectsList, AddAdBlock, Copyright, AppsMobile } from '../index'

export const Footer = () => (
  <footer>
    <div className='footer-main'>
      <Menu />
      <div className='footer-prodaja'>
        <AddAdBlock />
        <ProjectsList />
        <Weblab />
      </div>

      <AppsMobile />
    </div>

    <p className='footer-text'>
      Ovdje prikazani podaci, naročito podaci naših korisnika i oglasa, ne smiju se kopirati bez prethodne saglasnosti
      WebLab d.o.o. Nije dozvoljeno umnožavati i distribuirati podatke ili cjelokupnu bazu podataka i/ili vršiti te radnje
      preko trećih lica. Kršenje ovih pravila predstavlja povredu autorskih prava i podleže krivičnom gonjenju.
    </p>

    <Copyright />
  </footer>
)
