import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { CookiesHelper } from 'Core/cookiesHelper/CookiesHelper'
import Router from 'next/router'
import { getCurrentGroup } from 'SRC/modules/groups/selectors'
import { getParentCategory, getCurrentSubCategory } from 'SRC/modules/categories/selectors'
import { getSavedSearchBtnEnabled } from 'SRC/modules/ads/list/selectors'
import { setSavedSearchBtnEnabled } from 'SRC/modules/ads/list/actions'
import { AdApi } from '../../../shared/api'

export class SavedSearch extends React.Component {
  constructor (props) {
    super(props)

    this.cookies = new CookiesHelper()
  }

  addSavedSearch = async () => {
    const { setSavedSearchBtnEnabled } = this.props

    const preparedFields = this.prepareFields()
    if (preparedFields) {
      console.log('savedsearch preparedFields', preparedFields)

      const api = new AdApi()
      const result = await api.addSavedSearch(preparedFields)
      console.log('savedsearch result', result)
      if (result.status === 'ok') {
        setSavedSearchBtnEnabled(false)
      }
    }
  }

  prepareFields = () => {
    const { formStyle, filterValues, currentParentCategory, currentGroup, currentSubCategory } = this.props

    console.log('savedsearch formstyle filtervals', formStyle, filterValues)

    const userId = this.cookies.get('userId')
    const token = this.cookies.get('token')

    if (userId && token) {
      let fields = {}
      if (Object.keys(filterValues).length === 0) return null

      fields = filterValues

      fields.formStyle = formStyle

      fields.userId = userId
      fields.token = token

      if (currentParentCategory) fields.parentCategory = currentParentCategory.id
      if (currentGroup) fields.group = currentGroup.id
      if (currentSubCategory) fields.category = currentSubCategory.id

      return fields
    } else {
      Router.push('/profile', '/profil')
    }
  }

  render () {
    const { buttonEnabled } = this.props

    return (
      <li onClick={buttonEnabled ? this.addSavedSearch : null}>
        <span className={`oglasi-filter-grid_item${buttonEnabled ? '' : ' active'}`}>
          <i className='ico-filter-sacuvaj-pretragu ico' />
        </span>
        <div className='tooltip'>{buttonEnabled ? 'Sačuvaj pretragu' : 'Sačuvano'}</div>
      </li>
    )
  }
}

const mapStateToProps = state => ({
  currentSubCategory: getCurrentSubCategory(state),
  currentParentCategory: getParentCategory(state),
  currentGroup: getCurrentGroup(state),
  buttonEnabled: getSavedSearchBtnEnabled(state)
})

SavedSearch.propTypes = {
  filterValues: PropTypes.object.isRequired,
  formStyle: PropTypes.string,
  currentSubCategory: PropTypes.object.isRequired,
  currentParentCategory: PropTypes.object.isRequired,
  currentGroup: PropTypes.object.isRequired,
  buttonEnabled: PropTypes.bool.isRequired,
  setSavedSearchBtnEnabled: PropTypes.func.isRequired
}

SavedSearch.defaultProps = {
  filterValues: {}
}

export default connect(mapStateToProps, { setSavedSearchBtnEnabled })(SavedSearch)
