import initialState from 'SRC/modules/redux/initialState'
import actionTypes from 'SRC/modules/redux/actionTypes'

export const addReducer = (state = initialState.ads.add, action) => {
  switch (action.type) {
    case actionTypes.SET_ADD_AD_RESULT:
      return {...state, result: action.payload}
    case actionTypes.RESET_ADD_AD_STATE:
      return initialState.ads.add
    case actionTypes.SET_ADD_AD_ACTIVE_TAB:
      return {...state, activeTab: action.payload}
    case actionTypes.SET_ADD_AD_ERROR:
      return {...state, error: action.payload}
    case actionTypes.SET_ADD_AD_SMS_WAS_SENT:
      return {...state, wasSentSms: action.payload}
    case actionTypes.SET_NEED_TO_RESET_ADD_AD_FORM_VALUES:
      return {...state, needToResetFormValues: action.payload}
    default:
      return state
  }
}
