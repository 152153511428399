import React from 'react'
import config from 'SRC/config/config.yaml'
import Link from 'next/link'

export const Help = () => {
  const [registrationVisible, setRegistrationVisible] = React.useState(false)
  const [deletingAccVisible, setDeletingAccVisible] = React.useState(false)

  return (
    <div className='novosti-text' style={{ width: '100%' }}>
      <ul style={{ listStyle: 'disc', padding: 'revert' }}>
        <li style={{ marginBottom: 10, display: 'list-item' }}>
          <a href='javascript:void(0)' onClick={() => setRegistrationVisible(!registrationVisible)} style={{ color: '#0377cd' }}>
            <strong>Registracija profila</strong>&nbsp;
            <span>
              {registrationVisible ? '(-)' : '(+)'}
            </span>
          </a>

          {registrationVisible ? (
            <section>
              <p>U nastavku teksta ćemo Vam pojasniti stavke pri kreiranju Vašeg budućeg profila.</p>
              <p>Potrebno je da pratite uputstva kako biste se uspješno registrovali na Oglasi.me.</p>
              <p>Link za registraciju je dostupan na sljedećem linku&nbsp;
                <a href='https://www.oglasi.me/registracija'>https://www.oglasi.me/registracija</a> gdje
                prvo bitate vrstu korisničkog profila skladno vašim potrebama.
              </p>
              
              <p>Za standardnu i besplatnu registraciju je potrebno izabrati vrstu profila <b>KORISNIK</b> sa izabranim paketom&nbsp;
                <b>FREE</b> i popuniti dalje tražene <b style={{ color: '#e91c23' }}>*obavezne</b> podatke:
              </p>
              
              <ol>
                <li style={{ fontWeight: 'bold' }}>
                  <b>Ime</b>

                  <p style={{ marginTop: 0, fontWeight: 'normal' }}>
                    Potrebno je unijeti Vaše IME ili NADIMAK po kom će te biti prepoznati
                    kad budete objavljivali oglase i komunicirali sa korisnicima.
                  </p>
                </li>

                <li style={{ marginTop: 15, fontWeight: 'bold' }}>
                  <b>Pol</b>

                  <p style={{ marginTop: 0, fontWeight: 'normal' }}>Potrebno je označiti vaš pol klikom na odgovarajuću sličicu.</p>
                </li>

                <li style={{ marginTop: 15, fontWeight: 'bold' }}>
                  <b>Država / Grad / Lokacija</b>

                  <p style={{ marginTop: 0, fontWeight: 'normal' }}>Potrebno je odabrati državu, grad i lokaciju gdje je vase mjesto prebivališta.</p>
                </li>

                <li style={{ marginTop: 15, fontWeight: 'bold' }}>
                  <b>Broj telefona</b>

                  <p style={{ marginTop: 0, fontWeight: 'normal' }}>
                    Potrebno je unijeti ispravan broj telefona, a da on već nije zauzet od strane drugog korisnika.
                  </p>
                </li>

                <li style={{ marginTop: 15, fontWeight: 'bold' }}>
                  <b>Šifra (lozinka)</b>

                  <p style={{ marginTop: 0, fontWeight: 'normal' }}>
                    Šifra je zapravo lozinka (password) tj. kombinacija slova ili brojeva od minimalno 6 (šest) karaktera,
                    koju trebate osmisliti i znati samo Vi. Obratite veliku pažnju pri unošenju Vaše šifre/passworda i obavezno
                    zapamtite šifru, iako uvijek imate mogućnost da je zamijenite pozivom na broj +382 67 312 555.
                  </p>
                </li>

                <li style={{ marginTop: 15, fontWeight: 'bold' }}>
                  <b>Verifikacija broja telefona</b>

                  <p style={{ marginTop: 0, fontWeight: 'normal' }}>
                    Klikom na dugme <b>REGISTRUJ SE</b> dobićete SMS sa kodom koji trebate da unesete u naznačenom polju. Ukoliko vam posle
                    2 (dva) minuta ne stigne SMS kod možete ga zahtijevati ponovo ili pozvati administratora +382 67 312 555. 
                  </p>
                </li>
              </ol>
            </section>
          ) : null}
        </li>

        <li style={{ marginBottom: 10, display: 'list-item' }}>
          <a href='javascript:void(0)' onClick={() => setDeletingAccVisible(!deletingAccVisible)} style={{ color: '#0377cd' }}>
            <strong>Brisanje profila</strong>&nbsp;
            <span>
              {deletingAccVisible ? '(-)' : '(+)'}
            </span>
          </a>

          {deletingAccVisible ? (
            <section>
              <p>Profil možete izbrisati na sledeći način:</p>

              <ul style={{ listStyle: 'disc', padding: 'revert' }}>
                <li style={{ padding: 5, display: 'list-item' }}>Prijavite se na svoj profil klikom na <b>Prijavi se</b> u gornjem desnom uglu</li>
                <li style={{ padding: 5, display: 'list-item' }}>
                  Nakon što ste se uspješno prijavili koristeći broj telefona i šifru (lozinku), kliknite na&nbsp;
                  <Link href={{ pathname: '/formRequest', query: { url: '/forma/korisnicka-podrska' } }} as='/forma/korisnicka-podrska'>
                    <a style={{ fontWeight: 'bold' }}>formu - Korisnička podrška</a>
                  </Link>.
                </li>
                <li style={{ padding: 5, display: 'list-item' }}>
                  U polju Prijava problema odaberite <b>Problem sa nalogom</b>, a u polju
                  &quot;Detaljan opis problema&quot; navedite zbog čega želite da vaš profil bude obrisan.
                </li>
              </ul>

              <p>Vaš profil biće obrisan u roku 15 dana od momenta potvrde vlasništva nad profilom. Imajte u vidu da jednom obrisan
                nalog više ne možete povratiti.
              </p>
            </section>
          ) : null}
        </li>
      </ul>
    </div>
  )
}

