import PropTypes from 'prop-types'
import React from 'react'
import { Item } from '../Item'

export const Column = ({className, title, items, children, id}) => (
  <div className={className}>
    {title ? <span className='footer-column-title'>{title}</span> : null}
    <ul key={id}>
      {items.map((item) => [
        <Item key={item.id} {...item} />,
        '\n'
      ])}
    </ul>
    {children}
  </div>
)

Column.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  id: PropTypes.number.isRequired,
  items: PropTypes.array.isRequired,
  title: PropTypes.string
}

Column.defaultProps = {
  items: []
}
