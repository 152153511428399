import React from 'react'
import { PageHeader } from '../PageHeader'
import { Page } from '../Page'
import Link from 'next/link'

export const Page404 = () =>
  <Page>
    <PageHeader>Nepostojeća stranica</PageHeader>
    <div className='page__not-found'>
      Nepostojeća stranica
      <p>Pogledaj sve oglase na početnoj strani</p>
      <Link href='/'>
        <a>KLIKNI OVDJE</a>
      </Link>
    </div>
  </Page>
