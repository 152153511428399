import {
	string,
	object,
	number,
	lazy,
	array,
	mixed
} from 'yup'

import config from 'SRC/config/config.yaml'

export const getSpecificationErrorMessage = ({ value: { id } }, categorySpecifications) => {
	const findCategorySpecification = Array.isArray(categorySpecifications)
		? categorySpecifications.find(catSpec => catSpec.specification.id === id)
		: null

	return findCategorySpecification ? `${findCategorySpecification.specification.title} je obavezno polje.` : ''
}

export const getValidationSchema = ({
	category = {},
	categorySpecifications = [],
	formType = '',
	isAuthorized,
	compUniversalSelected,
	activeTab
}) => {
	const { adForm: { equipmentGroups } } = config

	const validation = {}

	/**
	 * Title, description and duration
	 */
	validation.title = string().required('Popunite naslov')
	validation.description = string()
	validation.duration = number().test('required', 'Popunite trajanje oglasa', val => Number(val) !== -1)
	validation.price = number().test('required', 'Popunite cijenu', val => !isNaN(Number(val)))
	validation.priceDaily = number().test('required', 'Popunite dnevnu cijenu', val => !isNaN(Number(val)))
	validation.priceMonthly = number().test('required', 'Popunite mjesečnu cijenu', val => !isNaN(Number(val)))

	/**
	 * Brands, products, services and service types
	 */
	if (['addBasic', 'addDetail'].includes(formType)) validation.listing = number().required('Izaberite promo paket')

	const chooseBrandMessage = 'Izaberite proizvođača'
	const chooseProductMessage = 'Izaberite model'
	const chooseServiceMessage = 'Izaberite uslugu'
	const chooseServiceTypeMessage = 'Izaberite tip usluge'

	if (category) {
		switch (category.requiredAdOptions) {
			case 'BrandModel':
				validation.brand = mixed().nullable(true).test('required', chooseBrandMessage, val => Number(val) !== -1)
				validation.product = mixed().nullable(true).test('required', chooseProductMessage, val => Number(val) !== -1)
				break
			case 'BrandOnly':
				validation.brand = mixed().nullable(true).test('required', chooseBrandMessage, val => Number(val) !== -1)
				break
			case 'Services':
				validation.service = number().test('required', chooseServiceMessage, val => Number(val) !== -1)
				validation.serviceType = number().test('required', chooseServiceTypeMessage, val => Number(val) !== -1)
				break
			default: break
		}
	}

	/**
	 * Equipment
	 */
	validation.equipment = object().shape(equipmentGroups.reduce((acc, curr) => {
		acc[curr.id] = array().of(number())
		return acc
	}, {}))

	/**
	 * Geo
	 */
	validation.country = number().test('country', 'Izaberite državu', value => Number(value) !== -1)
	validation.city = number().test('city', 'Izaberite grad', value => Number(value) !== -1)
	validation.location = number().test('location', 'Izaberite lokaciju', value => Number(value) !== -1)

	/**
	 * Swapps
	 */
	if (category && category.swappPresence) {
		validation.swapp = string().test('swapp', 'Izaberite tip zamjene', value => {
			return ['No', 'Cheaper', 'Same', 'Expensive', 'Yes'].includes(value)
		})

		validation.swapps = lazy(value => {
			if (value === -1) {
				return mixed().notRequired()
			}
	
			return array().of(object().shape({
				category: number().test('swappCategory', 'Izaberite kategoriju', value => Number(value) !== -1),
				brand: number(),
				product: number(),
				specification1: number(),
				specificationValue1: mixed(),
				specification2: number(),
				specificationValue2: mixed()
			}))
		})
	}

	/**
	 * Specifications
	 */
	validation.specifications = lazy(() => {
		return array().of(object().test('requiredSpec', options => getSpecificationErrorMessage(options, categorySpecifications), formSpec => {
			let isRequired = true

			if (formSpec.isHidden || formSpec.isBuyingAd) {
				isRequired = false
			} else {
				const findCategorySpecification = Array.isArray(categorySpecifications)
					? categorySpecifications.find(catSpec => catSpec.specification.id === formSpec.id)
					: null

				if (findCategorySpecification) {
					isRequired = findCategorySpecification.required
				} else {
					isRequired = true
				}
			}

			const isValid = !isRequired || (typeof formSpec.value !== 'undefined' && Number(formSpec.value) !== -1)
			return isValid
		}))
	})

	/**
	 * Compatibility
	 */
	if (category && category.compatibilityCategories && category.compatibilityCategories.length) {
		validation.compatibilityCategory = number().test('compatibilityCategory', 'Izaberite kategoriju', value => Number(value) !== -1)

		const brandRequired = category.compatibilityBrandsQuantity && ['BrandModel', 'BrandOnly'].includes(category.requiredAdOptions)
		const productsRequired = category.compatibilityModelsQuantity && category.requiredAdOptions === 'BrandModel'

		validation.compatibilities = array().of(object().shape({
			brand: brandRequired ? mixed().test('compatibilityBrand', 'Izaberite proizvođača', function (brandValue) {
				if(compUniversalSelected) return true
				
				return Number(brandValue) !== -1
			}) : mixed(),

			product: productsRequired ? mixed().test('compatibilityProduct', 'Izaberite model', function (productValue) {
				if(compUniversalSelected) return true

				return Number(productValue) !== -1
			}) : mixed(),

			// years: array().of(number()).test('compatibilityYears', 'Izaberite godišta', yearsValue => {
			// 	return !category.compatibilityYears || compUniversalSelected || yearsValue.length
			// })
			years: lazy(yearsValue => {
				if (!category.compatibilityYears || compUniversalSelected) return mixed().notRequired()

				return array().of(number())
			})
		}))
	}

	/**
	 * New user
	 */
	if (['addBasic', 'addDetail'].includes(formType) && !isAuthorized && activeTab === 'register') {
		validation.newUser = object().shape({
			name: string().required('Popunite ime'),
			surname: string(),
			country: number().test('newUserCountry', 'Izaberite državu', value => Number(value) !== -1),
			city: number().test('newUserCity', 'Izaberite grad', value => Number(value) !== -1),
			location: number().test('newUserLocation', 'Izaberite lokaciju', value => Number(value) !== -1),
			phone: string('Pogrešan format telefona').required('Popunite telefon'),
			code: string()
		})
	}

	/**
	 * Existing user
	 */
	if (['addBasic', 'addDetail'].includes(formType) && !isAuthorized && activeTab === 'auth') {
		validation.existingUser = object().shape({
			phone: string('Pogrešan format telefona').required('Popunite telefon'),
			password: string('Pogrešna lozinka').required('Unesite lozinku').min(3, 'Loznika mora biti dugačka minimalno 3 karaktera.')
		})
	}

	const schema = object().shape(validation)

	return schema
}
