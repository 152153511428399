import PropTypes from 'prop-types'
import React from 'react'
import { Logo } from '../Logo'
import { Name } from '../Name'
import { Status } from '../../Status'
import { PhoneVerification } from '../../PhoneVerification'
import { EmailVerification } from '../../EmailVerification'
import { AddressVerification } from '../../AddressVerification'

export const Container = ({user}) => user ? (
  <div className='sidebar-user-main-info'>
    <Logo key='logo' gender={user.gender} packageName={user.type} />

    <div key='status' className='sidebar-user-status'>
      <Status status={user.status} />
      <Name login={user.login} name={user.name} />
      <div className='sidebar-user-verifikacija'>
        <PhoneVerification isVerified={user.isPhoneVerified} />
        <EmailVerification isVerified={user.isEmailVerified} />
        <AddressVerification isVerified={user.isAddressVerified} />
      </div>
    </div>
  </div>
) : null

Container.propTypes = {
  user: PropTypes.shape({
    gender: PropTypes.string,
    status: PropTypes.string,
    login: PropTypes.string,
    name: PropTypes.string,
    type: PropTypes.object,
    isPhoneVerified: PropTypes.bool,
    isEmailVerified: PropTypes.bool,
    isAddressVerified: PropTypes.bool
  }).isRequired
}

Container.displayName = 'UserCardFree'
