export const getAdThumbnailUrl = item => {
  if (!item) return
  const size = item.size || '236x176'
  if (item && item.thumbnail) {
    const thumbnailsIndex = item.thumbnail.fd.indexOf('/thumbnails')
    if (thumbnailsIndex !== -1) {
      return item.thumbnail.fd.replace('/thumbnails', `/size/${size}`)
    } else {
      const fd = item.thumbnail.fd
      let base = fd.substring(0, fd.indexOf('/', 'https://'.length))
      const path = fd.substring(fd.indexOf('/', 'https://'.length), fd.length)
      base += `/size/${size}`
      return base + path
    }
  } else if (item && item.image) {
    const fd = item.image.fd
    let base = fd.substring(0, fd.indexOf('/', 'https://'.length))
    const path = fd.substring(fd.indexOf('/', 'https://'.length), fd.length)
    base += `/size/${size}`
    return base + path
  } else {
    return 'https://placehold.it/168x126'
  }
}

export const getAdImageUrl = image => {
  if (!image) {
    return 'https://placehold.it/168x126'
  } else {
    return image.fd.replace('fontel', 'autodiler')
  }
}

export const getFdWithoutTimestamp = fd => {
  return fd.split('?')[0]
}

export const getFdWithTimestamp = fd => {
  const fdArr = fd.split('?')
  if (fdArr.length > 1) {
    return `${fdArr[0]}?t=${Date.now()}`
  } else {
    return `${fd}?t=${Date.now()}`
  }
}

export const getIsBuyingAd = isBuyingAd => {
  if (typeof isBuyingAd === 'undefined') {
    return false
  } else {
    try {
      if (typeof isBuyingAd === 'string') return JSON.parse(isBuyingAd)
      if (typeof isBuyingAd === 'boolean') return isBuyingAd
    } catch (err) {
      return false
    }
  }
}
