import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import ReactSVG from 'react-svg'
import { getParentCategory, getCurrentSubCategory } from 'SRC/modules/categories/selectors'
import { getCurrentGroup } from 'SRC/modules/groups/selectors'
import Link from 'next/link'

const getSubcategoryHref = ({ parentCategory, group, subCategory }) => {
  if (parentCategory && group) {
    if (group.isGroup) {
      return {
        pathname: '/category',
        query: { parentCategory: parentCategory.seo, category: group.seo }
      }
    }

    return {
      pathname: '/category',
      query: { parentCategory: parentCategory.seo }
    }
  }

  return { pathname: '/' }
}

const getSubcategoryAs = ({ parentCategory, group, subCategory }) => {
  if (parentCategory && group) {
    if (group.isGroup) {
      return `/${parentCategory.seo}/${group.seo}`
    }

    return `/${parentCategory.seo}`
  }

  return '/'
}

const svgStyle = 'width: 1rem; height: 1rem; display: flex; align-items: center;'

const Sausages = ({ parentCategory, group, subCategory }) => {
  return parentCategory ? (
    <section className='sausage-buttons'>
      <div key='sausage-parent-category' className='sausage-button'>
        <p>{parentCategory.title}</p>
        
        <Link href='/' as='/'>
          <a className='sausage-button-close'>
            <ReactSVG
              src={'/icons/close.svg'}
              beforeInjection={svg => {
                svg.setAttribute('style', svgStyle)
              }}
              wrapper='span'
            />
          </a>
        </Link>
      </div>

      {group && group.isGroup ? (
        <div key='sausage-group' className='sausage-button'>
          <p>{group.title}</p>

          <Link
            href={{ pathname: '/category', query: { parentCategory: parentCategory.seo } }}
            as={`/${parentCategory.seo}`}
          >
            <a className='sausage-button-close'>
              <ReactSVG
                src={'/icons/close.svg'}
                beforeInjection={svg => {
                  svg.setAttribute('style', svgStyle)
                }}
                wrapper='span'
              />
            </a>
          </Link>
        </div>
      ) : null}

      {subCategory && !parentCategory?.isSelfContainedParentCategory ? (
        <div key='sausage-subcategory' className='sausage-button'>
          <p>{subCategory.title}</p>

          <Link
            href={getSubcategoryHref({ parentCategory, group, subCategory })}
            as={getSubcategoryAs({ parentCategory, group, subCategory })}
          >
            <a className='sausage-button-close'>
              <ReactSVG
                src={'/icons/close.svg'}
                beforeInjection={svg => {
                  svg.setAttribute('style', svgStyle)
                }}
                wrapper='span'
              />
            </a>
          </Link>
        </div>
      ) : null}
    </section>
  ) : null
}

const mapStateToProps = state => {
  return {
    parentCategory: getParentCategory(state),
    group: getCurrentGroup(state),
    subCategory: getCurrentSubCategory(state)
  }
}

Sausages.propTypes = {
  parentCategory: PropTypes.object.isRequired,
  group: PropTypes.object.isRequired,
  subCategory: PropTypes.object.isRequired,
}

export default connect(mapStateToProps)(Sausages)
