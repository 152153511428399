import initialState from 'SRC/modules/redux/initialState'
import actionTypes from 'SRC/modules/redux/actionTypes'

export const adsWithPromosReducer = (state = initialState.ads.adsWithPromos, action) => {
  switch (action.type) {
    case actionTypes.SET_ADS_WITH_PROMOS:
      return {
        ...state,
        elements: action.payload
      }
    case actionTypes.SET_ADS_WITH_PROMOS_LOADING:
      return {
        ...state,
        isLoading: action.payload
      }
    case actionTypes.SET_ADS_WITH_PROMOS_QUERY:
      return {
        ...state,
        query: action.payload
      }
    default:
      return state
  }
}
