import React from 'react'
import PropTypes from 'prop-types'
import Link from 'next/link'
import config from 'SRC/config/config.yaml'

export const Logo = ({ isMobileDevice }) => (
  <Link href='/'>
    <a>
      <div className={'logo-oglasi-img'}>
        <img src={!isMobileDevice ? '/img/logo.png' : '/img/logo-mobile.svg'} alt='Logo' />
      </div>
    </a>
  </Link>
)

Logo.propTypes = {
  isMobileDevice: PropTypes.bool.isRequired
}
