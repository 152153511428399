import PropTypes from 'prop-types'
import React from 'react'

export const Button = ({onReportClick}) =>
  <div className='sidebar-user-akcije-item'>
    <a href='javascript:void (0)' onClick={onReportClick}>
      <i className='ico-user-prijavi-oglas ico' />
      <p>Prijavi oglas</p>
    </a>
  </div>

Button.propTypes = {
  onReportClick: PropTypes.func.isRequired
}
