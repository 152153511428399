import PropTypes from 'prop-types'
import React from 'react'

import { useFormikContext, ErrorMessage } from 'formik'
import { TextInputNew, SelectDropdown, Wrapper } from 'SRC/ui/FormElementsNew'

import config from 'SRC/config/config.yaml'

const messengerOptions = [
	{
		id: 'viber',
		label: 'Viber',
		value: 'viber'
	},
	{
		id: 'whatsapp',
		label: 'WhatsApp',
		value: 'whatsapp'
	}
]

export const Contact = (props) => {
	const formik = useFormikContext()

	const getCheckedMessengers = () => {
		const checkedMessengers = []
		if (formik.values.phoneUpdate?.viber) checkedMessengers.push('viber')
		if (formik.values.phoneUpdate?.whatsapp) checkedMessengers.push('whatsapp')
		return checkedMessengers
	}

	const { contact } = props

	return (
		<div className='form-contact'>
			<Wrapper>
				<TextInputNew
					id='contact-phone'
					isDisabled
					isRequired
					title={'Telefon'}
					value={contact?.phone ? `+${contact.phone}` : ''}
				/>
				</Wrapper>

				<Wrapper>
				<SelectDropdown
          id='viber-whatsapp'
          title='Imaš Viber i WhatsApp?'
          isMultiple
          checkedItems={getCheckedMessengers()}
          options={messengerOptions}
          onChange={async selectedOptions => {
            if (Array.isArray(selectedOptions)) {
              if (selectedOptions.includes('viber')) formik.setFieldValue('phoneUpdate.viber', true)
              else formik.setFieldValue('phoneUpdate.viber', false)

              if (selectedOptions.includes('whatsapp')) formik.setFieldValue('phoneUpdate.whatsapp', true)
              else formik.setFieldValue('phoneUpdate.whatsapp', false)
            }
          }}
        />
				</Wrapper>

        
		</div>
	)
}

Contact.propTypes = {
	contact: PropTypes.shape({
		phone: PropTypes.string,
		viber: PropTypes.bool,
		whatsapp: PropTypes.bool
	}).isRequired
}
