import initialState from 'SRC/modules/redux/initialState'
import actionTypes from 'SRC/modules/redux/actionTypes'

export const usersListReducer = (state = initialState.user.list, action) => {
  switch (action.type) {
    case actionTypes.SET_USERS_LIST_QUERY:
      return {...state, query: action.payload}
    case actionTypes.SET_USERS_LIST_ELEMENTS:
      return {...state, elements: action.payload}
    case actionTypes.SET_USERS_LIST_LOADING:
      return {...state, isLoading: action.payload}
    case actionTypes.SET_USERS_LIST_USER_TYPE:
      return {...state, userType: action.payload}
    case actionTypes.SET_USERS_LIST_PAGINATION:
      return {...state, pagination: action.payload}
    case actionTypes.SET_USERS_LIST_ADDITIONAL_LOADING:
      return {...state, isAdditionalLoading: action.payload}
    case actionTypes.SET_USERS_LIST_LAST_CHANGED_FIELD:
      return {...state, lastChangedField: action.payload}
    case actionTypes.SET_JOB_FIELD_SPECIFICATION:
      return {...state, jobFieldSpecification: action.payload}
    default:
      return state
  }
}
