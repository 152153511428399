export const selectableUserTypes = [
	{
		"children": [],
		"parent": null,
		"name": "Korisnik",
		"weight": 1,
		"icon": "ico-registracija-korisnik ico",
		"iconSvg": "https://slike.oglasi.me/usertypes/oglasi/korisnik.svg",
		"description": "Besplatnan profil za sva fizička lica sa ukupno 10 besplatnih oglasa u različitim kategorijama...",
		"seo": "korisnik",
		"seoPlu": "korisnici",
		"namePlu": "Korisnici",
		"visibleGarage": null,
		"id": 1,
		"createdAt": "2020-12-03T07:06:25.000Z",
		"updatedAt": null
	},
	{
		"children": [],
		"parent": null,
		"name": "Prodavnica",
		"weight": 2,
		"icon": "ico-registracija-prodavnica ico",
		"iconSvg": "https://slike.oglasi.me/usertypes/oglasi/prodavnica.svg",
		"description": "Profil namjenjen prodavnicama tehnike, buticima, knjižarama, pet shopovima i ostalim djelatnostima...",
		"seo": "web-prodavnica",
		"seoPlu": "web-prodavnice",
		"namePlu": "Web prodavnice",
		"visibleGarage": null,
		"id": 6,
		"createdAt": null,
		"updatedAt": null
	},
	{
		"children": [],
		"parent": null,
		"name": "Servis i Usluga",
		"weight": 5,
		"icon": "ico-registracija-adresar ico",
		"iconSvg": "https://slike.oglasi.me/usertypes/oglasi/servisi_i_usluge.svg",
		"description": "Profil namjenjen za prezentovanje servisa i usluga za sva pravna i fizička lica...",
		"seo": "servis-i-usluga",
		"seoPlu": "servis-i-usluge",
		"namePlu": "Servisi i usluge",
		"visibleGarage": null,
		"id": 3,
		"createdAt": null,
		"updatedAt": null
	},
	{
		"children": [],
		"parent": null,
		"name": "Poslodavac",
		"weight": 6,
		"icon": null,
		"iconSvg": "https://slike.oglasi.me/categories/5d4ab164-749d-44de-b1e5-1772cecf6cb3.svg",
		"description": "Besplatan profil za sve poslodavce koji nude slobodna radna mjesta...",
		"seo": "poslodavac",
		"seoPlu": "poslodavci",
		"namePlu": "Poslodavci",
		"visibleGarage": null,
		"id": 14,
		"createdAt": "2020-12-03T07:06:25.000Z",
		"updatedAt": null
	},
	{
		"name": "Auto plac",
		"weight": 7,
		"icon": null,
		"iconSvg": null,
		"description": null,
		"seo": "auto-plac",
		"seoPlu": "auto-placevi",
		"namePlu": "Auto placevi",
		"visibleGarage": null,
		"parent": 4,
		"id": 7,
		"createdAt": null,
		"updatedAt": null
	},
	{
		"name": "Ovlašćeni zastupnik",
		"weight": 8,
		"icon": null,
		"iconSvg": null,
		"description": null,
		"seo": "ovlasceni-zastupnik",
		"seoPlu": "ovlasceni-zastupnici",
		"namePlu": "Ovlašćeni zastupnici",
		"visibleGarage": null,
		"parent": 4,
		"id": 8,
		"createdAt": null,
		"updatedAt": null
	},
	{
		"name": "Prodavnica djelova",
		"weight": 9,
		"icon": null,
		"iconSvg": null,
		"description": null,
		"seo": "prodavnica-djelova",
		"seoPlu": "prodavnice-djelova",
		"namePlu": "Prodavnice djelova",
		"visibleGarage": null,
		"parent": 4,
		"id": 9,
		"createdAt": null,
		"updatedAt": null
	},
	{
		"name": "Auto otpad",
		"weight": 10,
		"icon": null,
		"iconSvg": null,
		"description": null,
		"seo": "auto-otpad",
		"seoPlu": "auto-otpadi",
		"namePlu": "Auto otpadi",
		"visibleGarage": null,
		"parent": 4,
		"id": 10,
		"createdAt": null,
		"updatedAt": null
	},
	{
		"name": "Agencija za nekretnine",
		"weight": 11,
		"icon": null,
		"iconSvg": null,
		"description": null,
		"seo": "agencija-za-nekretnine",
		"seoPlu": "agencije-za-nekretnine",
		"namePlu": "Agenicje za nekretnine",
		"visibleGarage": null,
		"parent": 5,
		"id": 11,
		"createdAt": null,
		"updatedAt": null
	},
	{
		"name": "Investitor",
		"weight": 12,
		"icon": null,
		"iconSvg": null,
		"description": null,
		"seo": "investitor",
		"seoPlu": "investitori",
		"namePlu": "Investitori",
		"visibleGarage": null,
		"parent": 5,
		"id": 12,
		"createdAt": null,
		"updatedAt": null
	},
	{
		"name": "Banka",
		"weight": 13,
		"icon": null,
		"iconSvg": null,
		"description": null,
		"seo": "banka",
		"seoPlu": "banke",
		"namePlu": "Banke",
		"visibleGarage": null,
		"parent": 5,
		"id": 13,
		"createdAt": null,
		"updatedAt": null
	}
]