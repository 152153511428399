import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { getIsTransitioning } from 'SRC/modules/common/selectors'
import ReactSVG from 'react-svg'
import Link from 'next/link'

const Item = ({ category, className, isTransitioning }) => {
  if (!isTransitioning || className.includes('active')) {
    return (
      <Link href={`/category?parentCategory=${category.seo}`} as={`/${category.seo}`}>
        <a className={className}>
          {category.svg ? (
            <ReactSVG
              wrapper='span'
              className='ogl-header-categories-horizontal-menu-item-icon'
              src={category.svg} beforeInjection={svg => {
                svg.setAttribute('style', 'max-width: 70px; height: 50px;')
              }}
            />
          ) : null}

          <p>{category.title}</p>
        </a>
      </Link>
    )
  }

  return (
    <a href='javascript:void(0)' className={`${className} disabled`}>
      {category.svg ? (
        <ReactSVG
          wrapper='span'
          className='ogl-header-categories-horizontal-menu-item-icon'
          src={category.svg} beforeInjection={svg => {
            svg.setAttribute('style', 'max-width: 70px; height: 50px;')
          }}
        />
      ) : null}

      <p>{category.title}</p>
    </a>
  )
}

Item.propTypes = {
  category: PropTypes.shape({
    id: PropTypes.number,
    svg: PropTypes.string,
    title: PropTypes.string,
    seo: PropTypes.string
  }).isRequired,
  className: PropTypes.string.isRequired,
  isTransitioning: PropTypes.bool.isRequired
}

const mapStateToProps = state => ({
  isTransitioning: getIsTransitioning(state)
})

export default connect(mapStateToProps)(Item)
