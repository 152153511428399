import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { passwordValidator, requiredValidator } from 'Core/validators'
import { ErrorNotification } from 'SRC/ui'
import { Password, PhoneInputWithSelectDial } from 'SRC/ui/FormElementsNew'
import Link from 'next/link'
import { Formik, Form } from 'formik'
import { setActiveTip } from 'SRC/modules/users/Registration/actions/setActiveTip'
import { getActiveTip } from 'SRC/modules/users/Registration/selectors'
import { getCountries } from 'SRC/modules/geoObjects/countries/selectors'
import config from 'SRC/config/config.yaml'
import { login, setAuthFormErrors } from '../../actions'
import { PhoneTip } from '../PhoneTip'
import { PasswordTip } from '../PasswordTip'
import { getLoginError } from '../../selectors'

export class LoginForm extends React.Component {
  onSubmit = async values => {
    console.log('onSubmit', values)
    const preparedFields = this.prepareFields(values)

    await this.props.login(preparedFields)
  }

  setPhoneActiveTip = () => {
    this.props.setActiveTip('phone')
  }

  setPasswordActiveTip = () => {
    this.props.setActiveTip('password')
  }

  validate = (values) => {
    console.log('vals', values)
    const error = {}

    const phone = values.phone.replace(/ /g, '')

    if (phone.length < 8) {
      error.phone = 'Broj nije validan'
    }

    if (!requiredValidator(phone)) {
      error.phone = 'Telefon je obavezan'
    }

    if (!requiredValidator(values.dial)) {
      error.dial = 'Pozivni broj je obavezan'
    }

    if (!passwordValidator(values.password)) {
      error.password = 'Lozinka nije validna'
    }

    return error
  }

  prepareFields = (fields) => {
    const preparedFields = {}

    preparedFields.phone = `${fields.dial}${fields.phone.replace(/ /g, '')}`

    preparedFields.password = fields.password

    return preparedFields
  }

  prepareCountries = () => this.props.countries.filter(country => !country.parent).map(country => {
    const isGrouped = Boolean(this.props.countries.filter(item => item.parent === country.id).length)
    return {
      id: country.id,
      label: country.label,
      value: country.id,
      dial: country.dial,
      isGrouped,
      options: isGrouped ? this.props.countries.filter(item => item.parent === country.id).map(childCountry => ({
        id: childCountry.id,
        value: childCountry.id,
        label: childCountry.label,
        dial: childCountry.dial
      })) : null
    }
  })

  hideError = () => {
    this.props.setAuthFormErrors('')
  }

  render () {
    const { activeTip } = this.props
    return (
      <div className='login-form'>
        {activeTip === 'phone' ? <PhoneTip /> : null}
        {activeTip === 'password' ? <PasswordTip /> : null}
        <Formik
          initialValues={{
            dial: 382,
            phone: '',
            password: ''
          }}
          validate={this.validate}
          onSubmit={this.onSubmit}
        >
          {formikProps => (
            <Form>
              <PhoneInputWithSelectDial
                label={config.loginForm.labels.phone}
                isFullWidth={false}
                inputClass='form-group__login-phone'
                field={{ name: 'phone' }}
                form={formikProps}
                countries={this.prepareCountries()}
                onMouseEnter={this.setPhoneActiveTip}
                showStar={false}
              />

              <Password showStar={false} formikProps={formikProps} onMouseEnter={this.setPasswordActiveTip} />
              <ErrorNotification error={this.props.loginError} hideNotification={this.hideError} />
              <button
                type='submit'
                name='submit-btn'
                disabled={formikProps.isSubmitting}
                style={{ opacity: formikProps.isSubmitting ? .4 : 1 }}
              >
                {config.loginForm.submitBtnText}
              </button>

              <Link href={'/registration'} as={'/registracija'}>
                <a className='register-btn'>&nbsp;{config.loginForm.registerText[1]}</a>
              </Link>
            </Form>
          )}
        </Formik>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  loginError: getLoginError(state),
  activeTip: getActiveTip(state),
  countries: getCountries(state)
})

LoginForm.propTypes = {
  countries: PropTypes.array.isRequired,
  loginError: PropTypes.string,
  login: PropTypes.func.isRequired,
  setAuthFormErrors: PropTypes.func.isRequired,
  setActiveTip: PropTypes.func.isRequired,
  activeTip: PropTypes.string.isRequired
}

export default connect(mapStateToProps, { login, setAuthFormErrors, setActiveTip })(LoginForm)
