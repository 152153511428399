import { ShoppingCartApi } from '../../api'
import { setCartItems } from '../setCartItems'
import { setCartLoading } from '../setCartLoading'
import { setCartItemsFetchedInitially } from '../setCartItemsFetchedInitially'
import { CookiesHelper } from 'SRC/core/cookiesHelper/CookiesHelper'

export const fetchCart = () => {
  return async (dispatch, getState) => {
    try {
      const cookies = new CookiesHelper()

      const userId = cookies.get('userId')
      const token = cookies.get('token')

      if (!userId || !token) return

      dispatch(setCartLoading(true))

      const api = new ShoppingCartApi()
      const cartItems = await api.fetchCart({ userId, token })

      console.log('cartItems', cartItems)

      dispatch(setCartItems(cartItems))
      dispatch(setCartLoading(false))
      dispatch(setCartItemsFetchedInitially(true))
    } catch (e) {
      dispatch(setCartItems([]))
      dispatch(setCartLoading(false))
    }
  }
}
