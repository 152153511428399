import { setSpecifications } from '../setSpecifications'
import { CategoriesApi } from '../../api'

export const fetchSpecifications = category => {
  return async dispatch => {
    const api = new CategoriesApi()
    const specifications = await api.fetchSpecificationsByCategory(category)
    dispatch(setSpecifications(specifications))
  }
}
