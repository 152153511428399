import PropTypes from 'prop-types'
import React from 'react'
import Link from 'next/link'

const A = () =>
  <a href='javascript:void (0)'><LinkContent /></a>

const ALink = ({seo, username}) =>
  <Link href={`/user?userType=${seo}&username=${username}`} as={`/${seo}/${username}`}>
    <a><LinkContent /></a>
  </Link>

const LinkContent = () => [
  <i key='icon' className='ico-user-svi-oglasi-prodavca ico' />,
  <p key='text'>Pogledaj sve oglase prodavca</p>
]

export const UserAds = ({username, seo}) =>
  <div className='sidebar-user-ostalo-item'>
    {
      username && seo
        ? <ALink seo={seo} username={username} />
        : <A />
    }
  </div>

UserAds.propTypes = {
  seo: PropTypes.string,
  username: PropTypes.string
}

ALink.propTypes = {
  seo: PropTypes.string.isRequired,
  username: PropTypes.string.isRequired
}
