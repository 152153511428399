import PropTypes from 'prop-types'
import React from 'react'

import { useFormikContext, ErrorMessage } from 'formik'
import { TextInputNew, Wrapper } from 'SRC/ui/FormElementsNew'

import config from 'SRC/config/config.yaml'

export const NameSurname = ({ showSurname }) => {
	const formik = useFormikContext()

	const nameError = formik.errors && formik.errors.name && formik.touched && formik.touched.name ? formik.errors.name : ''
	const surnameError = formik.errors && formik.errors.surname && formik.touched && formik.touched.surname ? formik.errors.surname : ''

	return (
		<React.Fragment>
			<Wrapper isError={nameError}>
				<TextInputNew
					id='name'
					name='name'
					title={'Ime'}
					isRequired
					value={formik.values.name}
					onChange={async textValue => {
						await formik.setFieldValue('name', textValue)
						if (!formik.touched.name) formik.setFieldTouched('name', true)
					}}
				/>

				<ErrorMessage
					name='name'
					render={msg => <span style={{ display: 'block', marginTop: 5 }} className='form-group__error-message'>{msg}</span>}
				/>
			</Wrapper>

			{showSurname ? (
				<Wrapper isError={surnameError}>
					<TextInputNew
						id='surname'
						name='surname'
						title={'Prezime'}
						value={formik.values.surname}
						onChange={async textValue => {
							await formik.setFieldValue('surname', textValue)
							if (!formik.touched.surname) formik.setFieldTouched('surname', true)
						}}
					/>

					<ErrorMessage
						name='surname'
						render={msg => <span style={{ display: 'block', marginTop: 5 }} className='form-group__error-message'>{msg}</span>}
					/>
				</Wrapper>
			) : null}
		</React.Fragment>
	)
}

NameSurname.propTypes = {
	showSurname: PropTypes.bool
}
