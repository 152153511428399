import PropTypes from 'prop-types'
import Link from 'next/link'
import React from 'react'
import { logout } from 'SRC/modules/users/Auth/actions'
import { getIsAuthorized } from 'SRC/modules/users/Auth/selectors'
import { getUserInfo } from 'SRC/modules/users/Profile/selectors'

import { connect } from 'react-redux'

const Auth = ({ user, isAuthorized, is404, logout, closeIcon, asPath }) => {
  return (
    <React.Fragment>
      <li key='myProfile' className={`bm-item bm-item-bottom${asPath === '/profil/moj' ? ' active' : ''}`}>
        <Link href={'/profile'} as={'/profil/moj'}>
          <a onClick={closeIcon}>
            <i className='ico-moj-profil ico' />

            <p className='bm-item-bottom__text'>Moj profil{isAuthorized && user ? ` (${user.username})` : ''}</p>
          </a>
        </Link>
      </li>

      {isAuthorized ? (
        <li key='login' className='bm-item bm-item-bottom'>
          <a href='javascript:void(0)' onClick={() => { logout && logout(); closeIcon() }}>
            <i className='ico-izloguj-se ico' />

            <p className='bm-item-bottom__text'>Izloguj se</p>
          </a>
        </li>
      ) : (
        <li key='login' className='bm-item bm-item-bottom'>
          <Link href={'/profile'} as={'/profil'}>
            <a onClick={closeIcon}>
              <i className='ico-top-meni-prijavi-se ico' />

              <p className='bm-item-bottom__text'>Prijavi se</p>
            </a>
          </Link>
        </li>
      )}
      
    </React.Fragment>
  )
}

Auth.propTypes = {
  is404: PropTypes.bool.isRequired,
  isAuthorized: PropTypes.bool.isRequired,
  user: PropTypes.shape({
    usename: PropTypes.string
  }),
  asPath: PropTypes.string,
  closeIcon: PropTypes.func.isRequired
}

Auth.defaultProps = {
  is404: false
}

const mapStateToProps = state => {
	return {
		isAuthorized: getIsAuthorized(state),
    user: getUserInfo(state)
	}
}

export default connect(mapStateToProps, { logout })(Auth)
