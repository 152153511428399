import React from 'react'
import PropTypes from 'prop-types'
import Link from 'next/link'
import config from 'SRC/config/config.yaml'

export const Item = ({ seo, parentCategorySeo, title, iconSvg, icon, baseAs, baseHref }) => {
  let hrefUrl = { pathname: baseHref }
  let asUrl = baseAs

  if (parentCategorySeo && baseHref !== '/addAd') {
    let isSelfContainedParentCategory = false

    if (seo && seo === parentCategorySeo) isSelfContainedParentCategory = true

    if (!isSelfContainedParentCategory) {
      hrefUrl.query = { category: seo, parentCategory: parentCategorySeo }
      asUrl += `${parentCategorySeo}/${seo}`
    } else {
      hrefUrl.query = { parentCategory: parentCategorySeo }

      asUrl += `${parentCategorySeo}`
    }
  } else {
    hrefUrl.query = { category: seo }

    asUrl += `${seo}`
  }

  return (
    <Link
      href={hrefUrl}
      as={asUrl}
    >
    <a className='categories-item'>
      {iconSvg ? <img src={`${config.mediaCDN}${iconSvg}`} /> : <i className={icon} />}
      <p>{title}</p>
    </a>
    </Link>
  )
}

Item.propTypes = {
  seo: PropTypes.string,
  parentCategorySeo: PropTypes.string,
  title: PropTypes.string,
  iconSvg: PropTypes.string,
  icon: PropTypes.string,
  baseHref: PropTypes.string,
  baseAs: PropTypes.string,
}
