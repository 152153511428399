import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import Link from 'next/link'
import { List } from '../List'
import config from 'SRC/config/config.yaml'

export const Container = ({items}) => {
  return <div className='parent-categories-dropdown-menu'>
    <div className='parent-categories-dropdown-menu-container'>
      <div className='parent-categories-dropdown-menu-items'>
        <List items={items} />
      </div>
      <div className='parent-categories-dropdown-menu-banner'>
      </div>
    </div>
    <Link href={'/categories'} as={'/kategorije'}>
      <a className='pogledaj-sve-btn'>
        <i className='ico-gavni-meni-pogledaj-sve-kategorije ico' />
        Pogledaj sve kategorije i podkategorije
      </a>
    </Link>
    {/*  */}
  </div>
}

const mapStateToProps = (state) => ({
  items: []
})

Container.propTypes = {
  items: PropTypes.array.isRequired
}

Container.defaultProps = {
  items: []
}

Container.displayName = 'ParentCategoriesMenu'

export default connect(mapStateToProps)(Container)
