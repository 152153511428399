import React from 'react'
import PropTypes from 'prop-types'
import { IMaskInput } from 'react-imask'
import Imask from 'imask'
import { debounce } from 'throttle-debounce'
import { rationalNumbers } from 'Core/masks'
import { getChar, getRegularExpression } from 'SRC/utils'
import { InputProcessingContext } from 'SRC/modules/ads/filter/context/inputProcessingContext'

export const Input = ({
  inputId,
  mask,
  measure,
  value,
  className,
  placeholder,
  onAccept,
  isPrice,
  style = {},
  allowedCharacters,
}) => {
  const [maskedValue, setMaskedValue] = React.useState('')

  const inputRef = React.useRef()

  const masked = Imask.createMask(mask || rationalNumbers)

  const [inputProcessing, setInputProcessing] = React.useContext(InputProcessingContext)

  React.useEffect(() => {
    if (Number(value) === -1) {
      if (inputRef && inputRef.current) inputRef.current.value = ''
      return setMaskedValue('')
    }

    masked.resolve(`${value}`)
    setMaskedValue(masked.value)
    if (inputRef && inputRef.current) inputRef.current.value = maskedValue
  }, [value, maskedValue, inputRef.current?.value])

  const isRational = () => Array.isArray(allowedCharacters) && allowedCharacters.includes('rational')

  const onChangeRational = debounce(1250, e => {
    console.log('debug: onChangeRational', e.target.value)
    const text = e.target.value

    const endsWithDotOrZero = text.endsWith('.') || text.endsWith('.0')
    const dotRepeated = text.indexOf('.') !== text.lastIndexOf('.')

    if (!endsWithDotOrZero || dotRepeated || text.indexOf(',') !== -1) {
      masked.resolve(e.target.value)
      onAccept(masked.unmaskedValue)
    }

    setInputProcessing('')
  })

  const onChange = debounce(1250, e => {
    masked.resolve(e.target.value)
    console.log('debug: onchange', e.target.value)

    onAccept(masked.unmaskedValue)

    setInputProcessing('')
  })

  const onKeyPress = e => {
    const chr = getChar(e)
    if (e.ctrlKey || e.altKey || chr == null) return

    if (Array.isArray(allowedCharacters) && allowedCharacters.length) {
      const regexp = getRegularExpression(allowedCharacters)
      const isValid = regexp ? regexp.test(chr) : true
      if (!isValid) {
        e.preventDefault()
        e.stopPropagation()
      }
    }
  }

  return (
    <div className='input-range-item' style={style}>
      <input
        ref={inputRef}
        defaultValue={maskedValue}
        onKeyDown={e => {
          console.log('debug: keydown')

          console.log('debug: chr', e.key)

          if (Array.isArray(allowedCharacters) && allowedCharacters.length) {
            const regexp = getRegularExpression(allowedCharacters)
            const isValid = regexp ? regexp.test(e.key) : true
            if (isValid && !inputProcessing) setInputProcessing(inputId)
          }
        }}
        onKeyPress={onKeyPress}
        onChange={isRational() ? onChangeRational : onChange}
        placeholder={placeholder}
        disabled={inputProcessing && inputProcessing !== inputId}
        className={className}
      />
      {measure
        ? (
          <span
            style={{ fontSize: isPrice ? '16px' : '14px', fontFamily: isPrice ? 'Tahoma' : 'Trebuchet MS' }}
            className='polja-pretrage-item__input-range-measure'
          >
            {measure}
          </span>
        ) : null
      }
    </div>
  )
}

Input.propTypes = {
  inputId: PropTypes.string.isRequired,
  className: PropTypes.any,
  placeholder: PropTypes.string,
  value: PropTypes.any,
  measure: PropTypes.string,
  mask: PropTypes.shape({
    mask: PropTypes.any.required
  }),
  allowedCharacters: PropTypes.array,
  isPrice: PropTypes.bool,
  style: PropTypes.object,
  onAccept: PropTypes.func
}
