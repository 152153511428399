import React from 'react'
import PropTypes from 'prop-types'
import config from 'SRC/config/config.yaml'

const renderSuggestionItem = (item) => {
  return (
    <a href='javascript:void(0)'>
      {item}
    </a>
  )
}

const placeholder = config.search.textSearchPlaceholder

export const Header = ({
  onTextChange,
  textValue,
  showSuggestions,
  filteredCategories,
  suggestionClicked
}) => {
  return (
    <header>
      <h1>PRETRAGA</h1>

      <input
        // ref={this.inputRef}
        type='text'
        placeholder={placeholder}
        onChange={onTextChange}
        defaultValue={textValue}
        value={textValue}
        maxLength={50}
      />

      <button>
        <i className='ico-pretraga-text-search-lupa ico' />
      </button>

      {Array.isArray(filteredCategories) && filteredCategories.length
        ? (
          <section
            className='categories-suggestions'
            // ref={this.dropdownRef}
          >
            {filteredCategories.map(({ item }, index) => {
              const suggestionCategory = item[1]
              const suggestionItems = item[2]

              if (Array.isArray(suggestionItems) && suggestionCategory) {
                return (
                  <ul
                    key={`suggestion-${suggestionCategory.sId}`}
                    className='categories-suggestions-container'
                    style={{ margin: '.4rem' }}
                    onClick={() => suggestionClicked(suggestionCategory)}
                  >
                    {suggestionItems.map((item, index) => (
                      <React.Fragment>
                        {<li key={`suggestion-item-${suggestionCategory.sId}-${item}`}>{renderSuggestionItem(item)}</li>}
                        {index !== suggestionItems.length - 1 ? <li key={`suggestion-separator-${item.id}`}> > </li> : null}
                      </React.Fragment>
                    ))}
                  </ul>
                )
              }

              return null
            })}
          </section>
        )
        : null
      }
    </header>
  )
}

Header.propTypes = {
  textValue: PropTypes.string.isRequired,
  showSuggestions: PropTypes.bool.isRequired,
  filteredCategories: PropTypes.array.isRequired,
  onTextChange: PropTypes.func.isRequired,
  suggestionClicked: PropTypes.func.isRequired
}
