import actionTypes from 'SRC/modules/redux/actionTypes'
import Router from 'next/router'

import { profileMenu } from 'SRC/modules/users/Profile/constants'
import { AdApi } from 'SRC/modules/ads/shared/api'
import { setError } from 'SRC/modules/ads/add/actions'

import config from 'SRC/config/config.yaml'

export const updateAd = payload => {
	return async (dispatch, getState) => {
		console.log('updateAd payload', payload)

		const api = new AdApi()
		const response = await api.updateAd(payload)

		console.log('updateAd response', response)

		if (response.status === AdApi.responseTypes.OK) {
      const adStatus = response.data ? response.data.status : ''

      const hrefUrl = `/profile`
      const query = {
        page: profileMenu.ads.code
      }

      if (adStatus) query.status = adStatus

      Router.push({pathname: hrefUrl, query}, `/profil/${profileMenu.ads.code}${adStatus ? `?status=${adStatus}` : ''}`)
        .then(() => window.scrollTo(0, 0))
    } else {
      dispatch(setError(this.errors.DEFAULT_SERVER))
    }
	}
}
