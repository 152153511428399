import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import { connect as formikConnect } from 'formik'
import { fetchCitiesByCountryWithinRegions } from 'SRC/modules/geoObjects/cities/actions'
import { getCitiesByCountryWithinRegions, getCitiesLoading } from 'SRC/modules/geoObjects/cities/selectors'
import { SelectDropdown, AdFilterField } from 'SRC/ui/FormElementsNew'
import { adFiltersLabels, globalOptions } from 'SRC/core/constants'

class CitiesFilter extends React.Component {
  constructor(props) {
    super(props)

    const { citiesWithinRegions, formik } = props

    const countryId = !formik.values.country || Number(formik.values.country) === -1
      ? globalOptions.MontenegroId
      : formik.values.country

    let citiesFlat = []

    if (Array.isArray(citiesWithinRegions[countryId])) {
      citiesWithinRegions[countryId].forEach(region => {
        citiesFlat = citiesFlat.concat(region.cities)
      })
    }

    this.state = { citiesFlat }
  }

  componentDidMount() {
    const { citiesWithinRegions, fetchCitiesByCountryWithinRegions, formik } = this.props

    const countryId = !formik.values.country || Number(formik.values.country) === -1
      ? globalOptions.MontenegroId
      : formik.values.country

    if (!citiesWithinRegions[countryId]) {
      fetchCitiesByCountryWithinRegions(countryId)
    }
  }

  componentDidUpdate = prevProps => {
    const { citiesLoading, citiesWithinRegions, formik } = this.props

    const countryId = !formik.values.country || Number(formik.values.country) === -1
      ? globalOptions.MontenegroId
      : formik.values.country

    if (prevProps.citiesLoading && !citiesLoading && Array.isArray(citiesWithinRegions[countryId])) {
      let citiesFlat = []

      citiesWithinRegions[countryId].forEach(region => {
        citiesFlat = citiesFlat.concat(region.cities)
      })

      this.setState({ citiesFlat })
    }
  }

  getCitiesField = () => {
    const { citiesWithinRegions, formik } = this.props

    const countryId = !formik.values.country || Number(formik.values.country) === -1
      ? globalOptions.MontenegroId
      : formik.values.country

    const cities = citiesWithinRegions[countryId]

    return {
      id: 'city',
      isDisabled: !cities || !cities.length,
      title: 'Grad',
      options: this.getCitiesOptions(),
      checkedItems: formik.values.cities,
      onChange: this.onCitiesChange,
      isSearchable: true,
      hasSelectAll: true,
      isMultiple: true
    }
  }

  getCitiesOptions = () => {
    const { citiesWithinRegions, formik } = this.props

    const countryId = !formik.values.country || Number(formik.values.country) === -1
      ? globalOptions.MontenegroId
      : formik.values.country

    const cities = citiesWithinRegions ? citiesWithinRegions[countryId] : null

    return Array.isArray(cities)
      ? cities.map(region => ({
        id: `region-${region.id}`,
        label: region.label,
        isGrouped: true,
        options: region.cities.map(city => ({
          id: `city-${city.value}`,
          value: city.value,
          label: city.label
        }))
      }))
      : []
  }

  onCitiesChange = selectedCities => {
    const { formik } = this.props
    const { citiesFlat } = this.state

    formik.setFieldValue('cities', selectedCities)

    const citiesText = []

    if (Array.isArray(selectedCities) && selectedCities.length === 1) {
      const findCity = citiesFlat.find(city => city.id === Number(selectedCities[0]))

      if (findCity) {
        formik.setFieldValue('citiesText', [findCity?.label || ''])
        formik.setFieldValue('cityBc', { seo: findCity.seo, text: findCity?.label || '' })
      } else {
        formik.setFieldValue('country', -1)

        formik.setFieldValue('citiesText', [])
        formik.setFieldValue('cityBc', {})
      }
    } else {
      formik.setFieldValue('country', -1)

      formik.setFieldValue('citiesText', [])
      formik.setFieldValue('cityBc', {})
    }
  }

  render() {
    const { citiesLoading } = this.props

    return !citiesLoading ? (
      <AdFilterField>
        <SelectDropdown {...this.getCitiesField()} />
      </AdFilterField>
    ) : null
  }
}

const mapStateToProps = state => {
  return {
    citiesWithinRegions: getCitiesByCountryWithinRegions(state),
    citiesLoading: getCitiesLoading(state)
  }
}

CitiesFilter.propTypes = {
  regions: PropTypes.array.isRequired,
  citiesWithinRegions: PropTypes.object.isRequired,
  citiesLoading: PropTypes.bool.isRequired,
  fetchCitiesByCountryWithinRegions: PropTypes.func.isRequired,
  formik: PropTypes.shape({
    values: PropTypes.object,
    setFieldValue: PropTypes.func
  }).isRequired
}

const enhance = compose(
  connect(mapStateToProps, { fetchCitiesByCountryWithinRegions }),
  formikConnect
)

export default enhance(CitiesFilter)
