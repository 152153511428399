import PropTypes from 'prop-types'
import React from 'react'
import Link from 'next/link'
import { connect } from 'react-redux'
import { getIsMobileDevice } from 'SRC/modules/common/selectors'
import { useRouter } from 'next/router'
import { getCurrentSubCategory } from 'SRC/modules/categories/selectors'
import { getCitiesByCountry } from 'SRC/modules/geoObjects/cities/selectors'
import config from 'SRC/config/config.yaml'

const getSortedCities = cities => {
	return cities.slice().sort((c1, c2) => {
		if (c1.label < c2.label) return -1
		if (c1.label > c2.label) return 1
		return 0
	})
}

const QuickSearch = ({ currentSubcategory, cities, isMobileDevice }) => {
	const router = useRouter()

	const parentCategorySeo = router?.query?.parentCategory

	console.log('QuickSearchparentCategory', parentCategorySeo)
	if (!currentSubcategory || !Array.isArray(cities) || !cities.length || !parentCategorySeo) return null

	const hrefQuery = { parentCategory: parentCategorySeo }
	let asPath = `/oglasi/${currentSubcategory.seo}`

	if (currentSubcategory.seo !== parentCategorySeo) {
		asPath = `/oglasi/${parentCategorySeo}/${currentSubcategory.seo}`
		hrefQuery.category = currentSubcategory.seo
	}

	if (parentCategorySeo === 'nekretnine') {
		if (isMobileDevice) {
			return (
				<div className='oglasi-opis' style={{ padding: '5px 5px 15px 7px' }}>
					<div className='oglasi-info-heading' style={{ marginLeft: 0 }}>
						<p>Brza pretraga</p>
					</div>

					<div style={{ display: 'flex', justifyContent: 'space-between' }}>
						<section className='quick-search quick-search-half'>
							{getSortedCities(cities).map(city => (
								<Link
									key={`${currentSubcategory.seo}_${city.seo}_prodaja`}
									href={{ pathname: '/search', query: { ...hrefQuery, '0': [`namjena-prodaja/grad-${city.seo}`] } }}
									as={`${asPath}/namjena-prodaja/grad-${city.seo}`}
								>
									<a
										style={{
											paddingLeft: currentSubcategory?.seo === 'poslovni-prostori' ? 15 : 0,
											paddingRight: currentSubcategory?.seo === 'poslovni-prostori' ? 15 : 0
										}}
									>
										Prodaja {currentSubcategory.title} {city.label}
									</a>
								</Link>
							))}
						</section>

						<section className='quick-search quick-search-half'>
							{getSortedCities(cities).map(city => (
								<Link
									key={`${currentSubcategory.seo}_${city.seo}_izdavanje`}
									href={{ pathname: '/search', query: { ...hrefQuery, '0': [`namjena-izdavanje/grad-${city.seo}`] } }}
									as={`${asPath}/namjena-izdavanje/grad-${city.seo}`}
								>
									<a
										style={{
											paddingLeft: currentSubcategory?.seo === 'poslovni-prostori' ? 15 : 0,
											paddingRight: currentSubcategory?.seo === 'poslovni-prostori' ? 15 : 0
										}}
									>
										Izdavanje {currentSubcategory.title} {city.label}
									</a>
								</Link>
							))}
						</section>
					</div>
				</div>
			)
		}

		return (
			<>
				<div className='oglasi-opis'>
					<div className='oglasi-info-heading'>
						<p>Brza pretraga prodaja</p>
					</div>

					<section className='quick-search'>
						{getSortedCities(cities).map(city => (
							<Link
								key={`${currentSubcategory.seo}_${city.seo}_prodaja`}
								href={{ pathname: '/search', query: { ...hrefQuery, '0': [`namjena-prodaja/grad-${city.seo}`] } }}
								as={`${asPath}/namjena-prodaja/grad-${city.seo}`}
							>
								<a>
									Prodaja {currentSubcategory.title} {city.label}
								</a>
							</Link>
						))}
					</section>
				</div>

				<div className='oglasi-opis'>
					<div className='oglasi-info-heading'>
						<p>Brza pretraga izdavanje</p>
					</div>

					<section className='quick-search'>
						{getSortedCities(cities).map(city => (
							<Link
								key={`${currentSubcategory.seo}_${city.seo}_izdavanje`}
								href={{ pathname: '/search', query: { ...hrefQuery, '0': [`namjena-izdavanje/grad-${city.seo}`] } }}
								as={`${asPath}/namjena-izdavanje/grad-${city.seo}`}
							>
								<a>
									Izdavanje {currentSubcategory.title} {city.label}
								</a>
							</Link>
						))}
					</section>
				</div>
			</>
		)
	}

	return (
		<div className='oglasi-opis'>
			<div className='oglasi-info-heading'>
				<p>Brza pretraga</p>
			</div>

			<section className='quick-search'>
				{getSortedCities(cities).map(city => (
					<Link
						key={`${currentSubcategory.seo}_${city.seo}`}
						href={{ pathname: '/search', query: { ...hrefQuery, '0': [`grad-${city.seo}`] } }}
						as={`${asPath}/grad-${city.seo}`}
					>
						<a>
							{currentSubcategory.title} {city.label}
						</a>
					</Link>
				))}
			</section>
		</div>
	)
}

const mapStateToProps = (state) => {
	return {
		currentSubcategory: getCurrentSubCategory(state),
		cities: getCitiesByCountry(state, config.globalOptions.MontenegroId),
		isMobileDevice: getIsMobileDevice(state)
	}
}

QuickSearch.propTypes = {
	currentSubcategory: PropTypes.shape({
		id: PropTypes.number,
		title: PropTypes.string,
		seo: PropTypes.string
	}),
	cities: PropTypes.arrayOf(PropTypes.shape({
		id: PropTypes.number,
		label: PropTypes.string,
		seo: PropTypes.string
	})),
	page: PropTypes.oneOf(['ad']),
	isMobileDevice: PropTypes.bool
}

export default connect(mapStateToProps)(QuickSearch)
