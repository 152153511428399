import { setIsAuthorized } from 'SRC/modules/users/Auth/actions'
import { ProfileApi } from '../../api'
import { setUserInfo } from '../../actions'
import { logout, setAuthError } from '../../../Auth/actions'

export const fetchUserInfo = (userId, token, countActiveAds) => {
  return async (dispatch, getState) => {
    const state = getState()
    const userInfo = state.user.profile.info

    if (userInfo && userInfo.id === Number(userId)) return

    const api = new ProfileApi()
    const {info, error, publishAdsCount} = await api.getUserInfo(userId, token, Boolean(countActiveAds))

    if (error || !info) {
      dispatch(setAuthError(error))
      dispatch(logout())
      dispatch(setUserInfo(null))
    } else {
      dispatch(setUserInfo({...info, publishAdsCount}))
      dispatch(setIsAuthorized(true))
    }
  }
}
