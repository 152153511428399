import PropTypes from 'prop-types'
import React from 'react'
import Link from 'next/link'
import { withRouter } from 'next/router'

const ForNonAuthUsers = ({togglePopupVisibility, router: { pathname }}) => (
  <div className='header-links__no-auth-user'>
    <ul className='header-links__list'>
      <li className={`main-nav-actions last-in-nav-second ${pathname === '/profile' ? 'active' : ''}`}>
        <Link href={'/profile'} as={'/profil'}>
          <a className='header-links__register'>
            <i className='ico-top-meni-prijavi-se ico' />
            Prijavi se
          </a>
        </Link>
      </li>
      {/* <li className={`main-nav-actions last-in-nav ${pathname === '/registration' ? 'active' : ''}`}>
        <Link href={'/registration'} as={'/registracija'}>
          <a className='header-links__register'>
            <i className='ico-top-meni-registruj-se ico' />
            Registruj se
          </a>
        </Link>
      </li> */}
    </ul>
  </div>
)

ForNonAuthUsers.propTypes = {
  togglePopupVisibility: PropTypes.func,
  router: PropTypes.shape({
    pathname: PropTypes.string.isRequired
  }).isRequired
}

export default withRouter(ForNonAuthUsers)
