const actionTypes = {
  SET_POPUP_LOGIN_BLOCK_VISIBILITY: 'SET_POPUP_LOGIN_BLOCK_VISIBILITY',
  SET_AUTH_LOADING: 'SET_AUTH_LOADING',
  SET_IS_AUTHORIZED_USER: 'SET_IS_AUTHORIZED_USER',
  SET_AUTH_FORM_ERRORS: 'SET_AUTH_FORM_ERRORS',
  SET_AUTH_POPUP_FORM_ERRORS: 'SET_AUTH_POPUP_FORM_ERRORS',
  SET_AUTH_ERROR: 'SET_AUTH_ERROR',
  SET_FORM_FIELDS: 'SET_FORM_FIELDS',
  SET_FORM_LOADING: 'SET_FORM_LOADING',
  SET_FORM_ERROR: 'SET_FORM_ERROR',
  SET_FORM_RESULT: 'SET_FORM_RESULT',
  SET_SMS_CODE_VERIFIED: 'SET_SMS_CODE_VERIFIED',
  SET_SMS_WAS_SENT: 'SET_SMS_WAS_SENT',
  SET_GROUPS: 'SET_GROUPS',
  SET_CATEGORIES: 'SET_CATEGORIES',
  SET_CATEGORIES_WITH_GROUPS: 'SET_CATEGORIES_WITH_GROUPS',
  SET_CATEGORY_BY_SEO: 'SET_CATEGORY_BY_SEO',
  SET_CURRENT_GROUP: 'SET_CURRENT_GROUP',
  SET_PARENT_CATEGORY: 'SET_PARENT_CATEGORY',
  SET_PARENT_CATEGORIES: 'SET_PARENT_CATEGORIES',
  SET_CURRENT_SUBCATEGORY: 'SET_CURRENT_SUBCATEGORY',
  SET_IS_CATEGORY_PAGE: 'SET_IS_CATEGORY_PAGE',
  SET_CATEGORY_SPECIFICATIONS: 'SET_CATEGORY_SPECIFICATIONS',
  SET_CATEGORY_ADDITIONAL_DEPENDENCIES: 'SET_CATEGORY_ADDITIONAL_DEPENDENCIES',
  SET_CATEGORY_ADDITIONAL_DEPENDENCIES_VISIBILITY: 'SET_CATEGORY_ADDITIONAL_DEPENDENCIES_VISIBILITY',
  SET_IS_GROUP_PAGE: 'SET_IS_GROUP_PAGE',
  SET_SIMILAR_WORDS: 'SET_SIMILAR_WORDS',
  SET_ADS: 'SET_ADS',
  SET_ADS_WITH_PROMOS: 'SET_ADS_WITH_PROMOS',
  SET_ADS_WITH_PROMOS_QUERY: 'SET_ADS_WITH_PROMOS_QUERY',
  SET_ADS_WITH_PROMOS_PAGINATION_PARAMS: 'SET_ADS_WITH_PROMOS_PAGINATION_PARAMS',
  SET_PAID_ADS: 'SET_PAID_ADS',
  SET_PAID_ADS_REQUESTED: 'SET_PAID_ADS_REQUESTED',
  SET_PAGINATION_PARAMS: 'SET_PAGINATION_PARAMS',
  SET_ADS_LOADING: 'SET_ADS_LOADING',
  SET_ADS_WITH_PROMOS_LOADING: 'SET_ADS_WITH_PROMOS_LOADING',
  SET_INFINITE_SCROLL_ENABLED: 'SET_INFINITE_SCROLL_ENABLED',
  UPDATE_AD_BY_ID: 'UPDATE_AD_BY_ID',
  REMOVE_AD_FROM_PAID: 'REMOVE_AD_FROM_PAID',
  SET_CURRENT_AD: 'SET_CURRENT_AD',
  SET_SEARCH_PARAMS: 'SET_SEARCH_PARAMS',
  SET_CURRENT_USERTYPE: 'SET_CURRENT_USERTYPE',
  SET_CURRENT_PARENT_USERTYPE: 'SET_CURRENT_PARENT_USERTYPE',
  SET_REGISTRATION_LOADING: 'SET_REGISTRATION_LOADING',
  SET_REGISTRATION_SMS_WAS_SENT: 'SET_REGISTRATION_SMS_WAS_SENT',
  SET_REGISTRATION_TEMP_LOGO: 'SET_REGISTRATION_TEMP_LOGO',
  SET_REGISTRATION_LOGO: 'SET_REGISTRATION_LOGO',
  SET_ACTIVE_TIP: 'SET_ACTIVE_TIP',
  SET_COUNTRIES: 'SET_COUNTRIES',
  SET_COUNTRIES_LOADING: 'SET_COUNTRIES_LOADING',
  SET_REGIONS: 'SET_REGIONS',
  SET_REGIONS_BY_COUNTRY: 'SET_REGIONS_BY_COUNTRY',
  SET_REGIONS_LOADING: 'SET_REGIONS_LOADING',
  SET_CITIES: 'SET_CITIES',
  SET_CITIES_BY_COUNTRY: 'SET_CITIES_BY_COUNTRY',
  SET_CITIES_BY_COUNTRY_WITHIN_REGIONS: 'SET_CITIES_BY_COUNTRY_WITHIN_REGIONS',
  SET_CITIES_LOADING: 'SET_CITIES_LOADING',
  SET_LOCATIONS: 'SET_LOCATIONS',
  SET_LOCATIONS_BY_CITY: 'SET_LOCATIONS_BY_CITY',
  SET_LOCATIONS_LOADING: 'SET_LOCATIONS_LOADING',
  SET_CLOSER_LOCATIONS: 'SET_CLOSER_LOCATIONS',
  SET_CLOSER_LOCATIONS_LOADING: 'SET_CLOSER_LOCATIONS_LOADING',
  SET_PROFILE_PAGE: 'SET_PROFILE_PAGE',
  SET_BRANDS: 'SET_BRANDS',
  SET_PRODUCTS: 'SET_PRODUCTS',
  RESET_PRODUCTS: 'RESET_PRODUCTS',
  SET_PRODUCTS_LOADING: 'SET_PRODUCTS_LOADING',
  SET_EQUIPMENT: 'SET_EQUIPMENT',
  SET_EQUIPMENT_DEPENDENCIES: 'SET_EQUIPMENT_DEPENDENCIES',
  SET_EQUIPMENT_VISIBILITY: 'SET_EQUIPMENT_VISIBILITY',
  SET_USER_TYPES: 'SET_USER_TYPES',
  SET_SELECTABLE_USER_TYPES: 'SET_SELECTABLE_USER_TYPES',
  SET_ADD_AD_ACTIVE_TAB: 'SET_ADD_AD_ACTIVE_TAB',
  SET_ADD_AD_RESULT: 'SET_ADD_AD_RESULT',
  SET_ADD_AD_ERROR: 'SET_ADD_AD_ERROR',
  SET_ADD_AD_SMS_WAS_SENT: 'SET_ADD_AD_SMS_WAS_SENT',
  SET_NEED_TO_RESET_ADD_AD_FORM_VALUES: 'SET_NEED_TO_RESET_ADD_AD_FORM_VALUES',
  SET_PROFILE_NOTIFICATIONS: 'SET_PROFILE_NOTIFICATIONS',
  SET_ADS_PACKAGES: 'SET_ADS_PACKAGES',
  RESET_ADD_AD_STATE: 'RESET_ADD_AD_STATE',
  SET_IS_SERVER_STATE: 'SET_IS_SERVER_STATE',
  SET_USER_INFO: 'SET_USER_INFO',
  SET_USER_PACKAGES: 'SET_USER_PACKAGES',
  SET_PACKAGES_DISCOUNTS: 'SET_PACKAGES_DISCOUNTS',
  SET_ADS_LISTINGS: 'SET_ADS_LISTINGS',
  SET_SELECTED_PACKAGE: 'SET_SELECTED_PACKAGE',
  SET_NEW_LOGO: 'SET_NEW_LOGO',
  SET_NEW_BANNER: 'SET_NEW_BANNER',
  SET_PROFILE_UPDATE_ERROR: 'SET_PROFILE_UPDATE_ERROR',
  SET_PROFILE_LOADING: 'SET_PROFILE_LOADING',
  RESET_ADS_FILTER_STATE: 'RESET_ADS_FILTER_STATE',
  SET_ROUTER: 'SET_ROUTER',
  SAVE_SERVICE_PROVIDER_PHOTO: 'SAVE_SERVICE_PROVIDER_PHOTO',
  DELETE_SERVICE_PROVIDER_PHOTO: 'DELETE_SERVICE_PROVIDER_PHOTO',
  UPDATE_SERVICE_PROVIDER_PHOTO_BY_ID: 'UPDATE_SERVICE_PROVIDER_PHOTO_BY_ID',
  UPDATE_SERVICE_PROVIDER_PHOTO_BY_TMP_ID: 'UPDATE_SERVICE_PROVIDER_PHOTO_BY_TMP_ID',
  SET_SERVICE_PROVIDER_PHOTOS: 'SET_SERVICE_PROVIDER_PHOTOS',
  SET_SERVICES: 'SET_SERVICES',
  SET_SERVICE_TYPES: 'SET_SERVICE_TYPES',
  SET_SERVICE_TYPES_LOADING: 'SET_SERVICE_TYPES_LOADING',
  SET_404: 'SET_404',
  SET_PROFILE_ADS_QUERY: 'SET_PROFILE_ADS_QUERY',
  SET_EDIT_AD: 'SET_EDIT_AD',
  SET_EDIT_AD_ERROR: 'SET_EDIT_AD_ERROR',
  SET_EDIT_AD_SUCCESS: 'SET_EDIT_AD_SUCCESS',
  SET_AD_PHOTOS: 'SET_AD_PHOTOS',
  RESET_AD_PHOTOS: 'RESET_AD_PHOTOS',
  SET_AD_PHOTOS_UPLOADING: 'SET_AD_PHOTOS_UPLOADING',
  SAVE_AD_PHOTO: 'SAVE_AD_PHOTO',
  DELETE_AD_PHOTO: 'DELETE_AD_PHOTO',
  UPDATE_AD_PHOTO_BY_ID: 'UPDATE_AD_PHOTO_BY_ID',
  UPDATE_AD_PHOTO_BY_TMP_ID: 'UPDATE_AD_PHOTO_BY_TMP_ID',
  RESET_EDIT_AD_STATE: 'RESET_EDIT_AD_STATE',
  RESET_PROFILE_INFO: 'RESET_PROFILE_INFO',
  SET_PROFILE_ADS_ERROR: 'SET_PROFILE_ADS_ERROR',
  SET_AD_FOR_PROMO: 'SET_AD_FOR_PROMO',
  SET_ACTIVATE_PROMO_ERROR: 'SET_ACTIVATE_PROMO_ERROR',
  SET_APP_LOADING: 'SET_APP_LOADING',
  SET_IS_TRANSITIONING: 'SET_IS_TRANSITIONING',
  SET_APP_STATE: 'SET_APP_STATE',
  SET_USERS_LIST_QUERY: 'SET_USERS_LIST_QUERY',
  SET_USERS_LIST_ELEMENTS: 'SET_USERS_LIST_ELEMENTS',
  SET_USERS_LIST_LOADING: 'SET_USERS_LIST_LOADING',
  SET_USERS_LIST_ADDITIONAL_LOADING: 'SET_USERS_LIST_ADDITIONAL_LOADING',
  SET_USERS_LIST_USER_TYPE: 'SET_USERS_LIST_USER_TYPE',
  SET_USERS_LIST_PAGINATION: 'SET_USERS_LIST_PAGINATION',
  SET_USER_DETAIL_INFO: 'SET_USER_DETAIL_INFO',
  SET_APP_INTERNAL_TRANSITION_FUNC: 'SET_APP_INTERNAL_TRANSITION_FUNC',
  SET_USERS_LIST_LAST_CHANGED_FIELD: 'SET_USERS_LIST_LAST_CHANGED_FIELD',
  SET_JOB_FIELD_SPECIFICATION: 'SET_JOB_FIELD_SPECIFICATION',
  SET_ACTIVE_CATEGORIES: 'SET_ACTIVE_CATEGORIES',
  SET_ADS_REPORT_TYPES: 'SET_ADS_REPORT_TYPES',
  SET_ADDONS: 'SET_ADDONS',
  SET_ADDONS_LOADING: 'SET_ADDONS_LOADING',
  SET_AGENTS: 'SET_AGENTS',
  SET_AGENTS_LOADING: 'SET_AGENTS_LOADING',
  SET_PROFILE_FAVOURITE_ADS: 'SET_PROFILE_FAVOURITE_ADS',
  SET_PROFILE_FAVOURITE_ADS_LOADING: 'SET_PROFILE_FAVOURITE_ADS_LOADING',
  UPDATE_PROFILE_FAVOURITE_ADS: 'UPDATE_PROFILE_FAVOURITE_ADS',
  SET_SIMILAR_ADS: 'SET_SIMILAR_ADS',
  SET_SIMILAR_ADS_LOADING: 'SET_SIMILAR_ADS_LOADING',
  SET_SAVED_FILTERS: 'SET_SAVED_FILTERS',
  SET_SAVED_FILTERS_LOADING: 'SET_SAVED_FILTERS_LOADING',
  SET_SAVED_FILTERS_BUTTON_ENABLED: 'SET_SAVED_FILTERS_BUTTON_ENABLED',
  SET_PROFILE_PAGINATION: 'SET_PROFILE_PAGINATION',
  SET_FAVOURITE_PROFILES: 'SET_FAVOURITE_PROFILES',
  SET_FAVOURITE_PROFILES_LOADING: 'SET_FAVOURITE_PROFILES_LOADING',
  SET_SIDEBAR_USERS: 'SET_SIDEBAR_USERS',
  SET_SIDEBAR_USERS_LOADING: 'SET_SIDEBAR_USERS_LOADING',
  SET_REGISTRATION_FORMULAS: 'SET_REGISTRATION_FORMULAS',
  SET_REGISTRATION_PREMIUM_CLASSES: 'SET_REGISTRATION_PREMIUM_CLASSES',
  SET_REGISTRATION_FORMULA_DETAILS: 'SET_REGISTRATION_FORMULA_DETAILS',
  SET_PACKAGE_CATEGORIES: 'SET_PACKAGE_CATEGORIES',
  SET_PACKAGE_CATEGORIES_LOADING: 'SET_PACKAGE_CATEGORIES_LOADING',
  SET_USER_PACKAGE_INACTIVE_MESSAGE: 'SET_USER_PACKAGE_INACTIVE_MESSAGE',

  /**
   * Cart
   */
  SET_CART_ITEMS: 'SET_CART_ITEMS',
  SET_CART_ITEMS_LOADING: 'SET_CART_ITEMS_LOADING',
  SET_CART_ITEM_LOADING: 'SET_CART_ITEM_LOADING',
  SET_CART_ITEMS_FETCHED_INITIALLY: 'SET_CART_ITEMS_FETCHED_INITIALLY',
  SET_BUY_CART_NOTIFICATION: 'SET_BUY_CART_NOTIFICATION',
  RESET_CART: 'RESET_CART',

  /**
   * Cart history
   */
  SET_CART_HISTORY_LOADING: 'SET_CART_HISTORY_LOADING',
  SET_CART_HISTORY_ITEMS: 'SET_CART_HISTORY_ITEMS',
  SET_CART_HISTORY_PAGINATION_PARAMS: 'SET_CART_HISTORY_PAGINATION_PARAMS',
  SET_CART_HISTORY_FETCHED_INITIALLY: 'SET_CART_HISTORY_FETCHED_INITIALLY',

  SET_CART_CURRENT_HISTORY_ITEM_LOADING: 'SET_CART_CURRENT_HISTORY_ITEM_LOADING',
  SET_CART_CURRENT_HISTORY_ITEM: 'SET_CART_CURRENT_HISTORY_ITEM',

  /**
   * Bought items
   */
  SET_BOUGHT_ITEMS: 'SET_BOUGHT_ITEMS',
  SET_UPDATE_BOUGHT_ITEM_NOTIFICATION: 'SET_UPDATE_BOUGHT_ITEM_NOTIFICATION',
  SET_BOUGHT_ITEMS_LOADING: 'SET_BOUGHT_ITEMS_LOADING',
  SET_BOUGHT_ITEMS_PAGINATION_PARAMS: 'SET_BOUGHT_ITEMS_PAGINATION_PARAMS',
  SET_CURRENT_BOUGHT_ITEM: 'SET_CURRENT_BOUGHT_ITEM',
  RESET_BOUGHT_ITEMS: 'RESET_BOUGHT_ITEMS',

  /**
   * Set is mobile device detected
   */
  SET_IS_MOBILE_DEVICE: 'SET_IS_MOBILE_DEVICE',

  /**
   * Page meta tags
   */
  SET_PAGE_META_TAGS: 'SET_PAGE_META_TAGS',
  SET_PAGE_META_TAGS_SINGLE: 'SET_PAGE_META_TAGS_SINGLE',
}

export default actionTypes
