import React from 'react'
import Link from 'next/link'
import { getAdThumbnailUrl } from 'SRC/modules/ads/shared/functions'

import {
  ItemImage,
  ItemGridViewDescription,
  // ItemGridViewDescriptionWithIcons,
  ItemPrice,
  ItemLocation
} from '../../../components/Item'
import PropTypes from 'prop-types'
import { AdditionalPreloader } from 'SRC/ui/Preloader'

const getAdSpecsSorted = specs => {
  return specs.slice().sort((spec1, spec2) => spec1.weightCatalog - spec2.weightCatalog).slice(0, 3)
}

export const Item = ({ad}) => {
  if (!ad.category) return null

  const img = getAdThumbnailUrl(ad)

  const parentCategorySeo = ad && ad.parentCategories && ad.parentCategories.length ? ad.parentCategories[0].seo : null

  let hrefUrl = `/ad?parentCategory=${parentCategorySeo}&category=${ad.category.seo}&ad=${ad.seo}`
  let asUrl = `/${parentCategorySeo}/${ad.category.seo}/${ad.seo}`

  if (parentCategorySeo === ad.category.seo) {
    hrefUrl = `/ad?parentCategory=${parentCategorySeo}&ad=${ad.seo}`
    asUrl = `/${parentCategorySeo}/${ad.seo}`
  }

  return (
    <div className={`oglasi-item-tekst oglasi-item-tekst-${ad.category.seo}`}>
      <ItemImage
        category={ad.category.seo}
        seo={ad.seo}
        hrefUrl={hrefUrl}
        asUrl={asUrl}
        payed={ad.isPaid}
        className='oglasi-item-tekst-img'
        img={img}
        title={ad.titleCompiled}
      />

      <Link href={hrefUrl} as={asUrl}>
        <a className='oglasi-item-heading'>
          <h3>{ad.titleCompiled || ''}</h3>
        </a>
      </Link>
      <ItemGridViewDescription
        description={ad.description}
        specs={ad.specifications ? getAdSpecsSorted(ad.specifications) : []}
        compatibilities={ad.compatibilities ? ad.compatibilities : []}
        compatibilityBrands={ad.compatibilityBrands ? ad.compatibilityBrands : []}
        compatibilitiesCount={ad.compatibilitiesCount ? ad.compatibilitiesCount : []}
        compatibilityBrandsCount={ad.compatibilityBrandsCount ? ad.compatibilityBrandsCount : []}
        service={ad.service ? ad.service : null}
        serviceType={ad.serviceType ? ad.serviceType : null} />
      {'\n'}
      <ItemPrice
        newPrice={ad.price}
        oldPrice={ad.oldPrice}
        priceFrom={ad.priceFrom}
        priceTo={ad.priceTo}
        promotion={ad.akcija}
        priceOnRequest={ad.priceOnRequest}
        priceDaily={ad.priceDaily}
        priceMonthly={ad.priceMonthly}
        priceSpecification={ad.priceSpecification}
        isBuying={ad.isBuying}
        type='tekst'
      />
      <ItemLocation place={ad.city && ad.city.name} time={ad.activated} />
    </div>
  )
}

Item.propTypes = {
  ad: PropTypes.shape({
    id: PropTypes.number.isRequired,
    titleCompiled: PropTypes.string.isRequired,
    isPaid: PropTypes.bool.isRequired,
    price: PropTypes.number.isRequired,
    oldPrice: PropTypes.number,
    priceFrom: PropTypes.number,
    priceTo: PropTypes.number,
    priceDaily: PropTypes.number,
    priceMonthly: PropTypes.number,
    priceSpecification: PropTypes.shape({
      value: PropTypes.string
    }),
    seo: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    media: PropTypes.array,
    category: PropTypes.shape({
      seo: PropTypes.string,
      specsShownWeb: PropTypes.bool
    }).isRequired,
    parentCategories: PropTypes.arrayOf(PropTypes.shape({
      seo: PropTypes.string
    })),
    specifications: PropTypes.array,
    priceOnRequest: PropTypes.bool,
    akcija: PropTypes.number,
    pro: PropTypes.bool,
    brand: PropTypes.object,
    product: PropTypes.object,
    city: PropTypes.object,
    activated: PropTypes.string,
    compatibilities: PropTypes.array,
    compatibilityBrands: PropTypes.array,
    compatibilitiesCount: PropTypes.number,
    compatibilityBrandsCount: PropTypes.number,
    status: PropTypes.string.isRequired,
    service: PropTypes.object,
    serviceType: PropTypes.object,
    isBuying: PropTypes.bool
  }).isRequired
}
