import { ProfileApi } from '../../api'
import { setAgents } from '../setAgents'
import { setAgentsLoading } from '../setAgentsLoading'

export const fetchAgents = (userId, token) => {
  return async (dispatch) => {
    dispatch(setAgentsLoading(true))

    const api = new ProfileApi()
    const agents = await api.getAgents(userId, token)

    dispatch(setAgents(agents))
    dispatch(setAgentsLoading(false))
  }
}
