import React from 'react'
import PropTypes from 'prop-types'
import { GridViewList } from 'SRC/modules/ads/list/components/GridView'

export const Ads = ({ ads }) => {
  return (
    <div className='ads-list'>
      <GridViewList
        ads={ads}
        noAdsMessage={'Nema oglasa'}
        style='TABLE'
      />
    </div>
  )
}

Ads.propTypes = {
  ads: PropTypes.arrayOf(PropTypes.shape({
    titleCompiled: PropTypes.string.isRequired,
    thumbnail: PropTypes.string.isRequired
  })).isRequired
}
