import React from 'react'
import { genders } from 'SRC/core/constants'
import { Radio } from 'SRC/ui/FormElementsNew'
import config from 'SRC/config/config.yaml'

const options = [
  {
    value: genders.male.code,
    className: genders.male.className,
    label: genders.male.title
  },
  {
    value: genders.female.code,
    className: genders.female.className,
    label: genders.female.title
  }
]

export const Gender = () => {
  return (
    <div className='form-pol' key='field'>
      <label>
        {config.registrationForm.genders.label}
        <span className='required'>*</span>
        <span className='lead-label'>{config.registrationForm.genders.leadLabel}</span>
      </label>
      <Radio
        options={options}
        name='gender'
      />
    </div>
  )
}
