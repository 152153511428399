import PropTypes from 'prop-types'
import React from 'react'
import { Hint } from '../Hint'

export const Checkbox = ({ id, name, hint, label, onChange, checkedItems }) => {
  return (
    <div className='dodatna-oprema-checkbox'>
      <input
        name={name}
        onChange={(e) => onChange(id, e.target.checked)}
        id={id}
        defaultChecked={Array.isArray(checkedItems) && checkedItems.includes(id)}
        type='checkbox'
        value={id}
      />
      <label htmlFor={id}>{label}</label>
      {
        hint ? <Hint hint={hint} /> : null
      }
    </div>
  )
}

Checkbox.propTypes = {
  id: PropTypes.any.isRequired,
  name: PropTypes.string.isRequired,
  hint: PropTypes.string,
  label: PropTypes.string.isRequired,
  checkedItems: PropTypes.array,
  onChange: PropTypes.func
}
