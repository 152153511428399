import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { getParentCategories, getParentCategory } from 'SRC/modules/categories/selectors'
import { Item } from '../Item'
import Router from 'next/router'
import config from 'SRC/config/config.yaml'

class Container extends React.Component {
  prepareCategories = () => {
    const { parentCategories } = this.props

    const preparedCategories = parentCategories.map(category => {
      return {
        id: category.id,
        title: category.title,
        seo: category.seo,
        svg: `${config.mediaCDN}${category.iconSvg}`
      }
    })

    return preparedCategories
  }

  getItemClassName = (categoryId) => {
    const { currentParentCategory } = this.props

    let className = 'ogl-header-categories-horizontal-menu-item'
    if (currentParentCategory && currentParentCategory.id === categoryId) className += ' active'

    return className
  }

  render () {
    const parentCategories = this.prepareCategories()

    return <section className={`ogl-header-categories-horizontal-menu`}>
      {parentCategories.map(category => {
        return (
          <Item
            key={`parent-category-${category.id}`}
            category={category}
            className={this.getItemClassName(category.id)}
          />
        )
      })}
    </section>
  }
}

const mapStateToProps = state => {
  return {
    parentCategories: getParentCategories(state),
    currentParentCategory: getParentCategory(state)
  }
}

Container.propTypes = {
  parentCategories: PropTypes.array.isRequired,
  currentParentCategory: PropTypes.object.isRequired
}

Container.defaultProps = {
  parentCategories: []
}

export default connect(mapStateToProps)(Container)
