import { CommonApi } from '../../../common/api'
import { setPageMetaTags } from '../setPageMetaTags'
import { setPageMetaTagsSingle } from '../setPageMetaTagsSingle'

export const fetchPageMetaTags = (fields = {}) => {
  const api = new CommonApi()

  return async (dispatch, getState) => {
    if (fields.category || fields.group) {
      const pageMetaTagsResponse = await api.getPageMetaTags(fields)
      if (pageMetaTagsResponse) dispatch(setPageMetaTagsSingle(pageMetaTagsResponse))
      else dispatch(setPageMetaTagsSingle(null))
    } else {
      const pageMetaTags = getState().common.pageMetaTags

      if (!Array.isArray(pageMetaTags) || !pageMetaTags.length) {
        const pageMetaTagsResponse = await api.getPageMetaTags()

        if (Array.isArray(pageMetaTagsResponse)) dispatch(setPageMetaTags(pageMetaTagsResponse))
        else dispatch(setPageMetaTags([]))
      }
    }
  }
}
