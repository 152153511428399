import { adsTypes, globalOptions } from 'Core/constants'
import { CookiesHelper } from 'Core/cookiesHelper/CookiesHelper'

import citiesME from '../../../public/data/citiesME.json'
import citiesMEWithinRegions from '../../../public/data/citiesMEWithinRegions.json'
import parentCategories from '../../../public/data/parentCategories.json'

const cookies = new CookiesHelper()
const isAuthorized = Boolean(cookies.get('token') && cookies.get('userId'))
const initialState = {
  user: {
    auth: {
      isAuthorized: isAuthorized,
      isLoading: false,
      formErrors: {
        login: '',
        loginInPopup: ''
      },
      error: null
    },
    registration: {
      currentParentUserType: null,
      currentUserType: null,
      isLoading: false,
      smsCodeWasSent: false,
      activeTip: globalOptions.defaultTip,
      tempLogo: null,
      logo: null
    },
    profile: {
      currentPage: null,
      notifications: [],
      info: null,
      newLogo: null,
      newBanner: null,
      error: null,
      isLoading: false,
      packageInactiveMessage: '',
      photos: [],
      ads: {
        query: null,
        error: null
      },
      promo: {
        ad: null,
        error: null
      },
      favouriteAds: {
        Published: {
          isLoading: false,
          elements: []
        },
        Expired: {
          isLoading: false,
          elements: []
        },
        Sold: {
          isLoading: false,
          elements: []
        }
      },
      favouriteProfiles: {
        isLoading: false
      },
      savedFilters: {
        isLoading: false,
        elements: []
      },
      pagination: {
        params: {
          currentPage: 1,
          countPerPage: 10,
          count: 0
        }
      },
      addons: {
        elements: [],
        isLoading: false
      },
      agents: {
        elements: [],
        isLoading: false
      }
    },
    packages: {
      items: [],
      packageCategories: {
        isLoading: false,
        items: []
      },
      discounts: [],
      selectedPackage: null
    },
    list: {
      query: null,
      elements: [],
      isLoading: false,
      isAdditionalLoading: false,
      userType: null,
      pagination: {
        page: 1,
        countPerPage: globalOptions.usersCountPerPage,
        totalCount: 0
      },
      lastChangedField: null,
      jobFieldSpecification: null
    },
    listSidebar: {
      isLoading: false,
      elements: {}
    },
    detail: {
      info: null
    }
  },
  categories: {
    elements: [],
    withGroups: [],
    parentCategories,
    bySeo: {},
    currentParentCategory: null,
    currentSubcategory: null,
    isCategoryPage: false,
    specifications: [],
    activeCategories: null,
    similarWords: null,
    additionalDependencies: {},
    additionalDependenciesVisibility: {}
  },
  groups: {
    elements: [],
    currentGroup: null,
    isGroupPage: false
  },
  ads: {
    list: {
      elements: [],
      isLoading: false,
      paid: [],
      paidRequested: false,
      isSavedAdsButtonEnabled: true,
      infiniteScrollEnabled: false
    },
    detail: {
      currentAd: null,
      reportTypes: [],
      isServer: true,
      similarAds: {
        isLoading: false,
        elements: []
      },
      registrationFormulas: [],
      registrationFormulaDetails: null,
      registrationPremiumClasses: []
    },
    pagination: {
      params: {
        currentPage: 1,
        countPerPage: globalOptions.adsCountPerPage,
        adsType: adsTypes.paid,
        paidCount: 0,
        freeCount: 0,
        totalCount: 0
      }
    },
    brands: {
      elements: []
    },
    products: {
      elements: [],
      isLoading: false
    },
    equipment: {
      elements: [],
      dependencies: {},
      visibility: {}
    },
    add: {
      result: null,
      activeTab: 'register',
      wasSentSms: false
    },
    listings: [],
    services: {
      elements: []
    },
    serviceTypes: {
      elements: [],
      isLoading: false
    },
    edit: {
      ad: null,
      error: null,
      success: null
    },
    shared: {
      photos: [],
      resetPhotos: false,
      photosUploading: false
    },
    sms: {
      elements: [],
      isLoading: false,
      isAdditionalLoading: false,
      paid: [],
      query: null,
      pagination: {
        currentPage: 1,
        countPerPage: globalOptions.smsAdsCountPerPage,
        totalCount: 0
      },
      lastChangedField: null
    },
    adsWithPromos: {
      elements: [],
      isLoading: false,
      query: null
    },
    adsWithPromosPagination: {
      params: {
        currentPage: 1,
        countPerPage: 10,
        count: 0
      }
    }
  },
  common: {
    isPopupLoginBlockOpened: false,
    router: null,
    is404: false,
    transitions: {
      isLoading: false,
      internalTransitionFunc: null,
      isTransitioning: false
    },
    userTypes: [],
    selectableUserTypes: [],
    form: {
      fields: null,
      result: null,
      error: null,
      isLoading: false,
      smsCodeVerified: false,
      wasSmsSent: false
    },
    isMobileDevice: false,
    pageMetaTags: [],
    pageMetaTagsSingle: null
  },
  geoObjects: {
    countries: {
      isLoading: false,
      elements: []
    },
    regions: {
      elements: [],
      byCountry: {},
      isLoading: false
    },
    cities: {
      elements: [],
      byCountry: {
        1: citiesME
      },
      byCountryWithinRegions: {
        1: citiesMEWithinRegions
      },
      isLoading: false
    },
    locations: {
      elements: [],
      byCity: {},
      isLoading: false
    },
    closerLocations: {
      elements: [],
      isLoading: false
    }
  },
  cart: {
    list: {
      items: [],
      isLoading: false
    },
    cartItemLoading: false,
    initiallyFetched: false,
    buyCartNotification: '',
    history: {
      items: [],
      itemsLoading: false,
      initiallyFetched: false,
      pagination: {
        page: 1,
        limit: 10,
        count: 0
      },
      currentItem: null,
      currentItemLoading: false
    }
  },
  orders: {
    items: [],
    itemsLoading: false,
    boughtItemUpdateNotification: '',
    pagination: {
      page: 1,
      limit: 10,
      count: 0
    },
    currentItem: null
  }
}

export default initialState
