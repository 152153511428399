import actionTypes from 'SRC/modules/redux/actionTypes'
import initialState from 'SRC/modules/redux/initialState'

export const categoriesReducer = (state = initialState.categories, action) => {
  switch (action.type) {
    case actionTypes.SET_CATEGORIES:
      return {...state, elements: action.payload}
    case actionTypes.SET_CATEGORIES_WITH_GROUPS:
      return {...state, withGroups: action.payload}
    case actionTypes.SET_CATEGORY_BY_SEO:
      return {...state, bySeo: {...state.bySeo, [action.payload.seo]: action.payload.category}}
    case actionTypes.SET_PARENT_CATEGORY:
      return {...state, currentParentCategory: action.payload}
    case actionTypes.SET_PARENT_CATEGORIES:
      return {...state, parentCategories: action.payload}
    case actionTypes.SET_CURRENT_SUBCATEGORY:
      return {...state, currentSubcategory: action.payload}
    case actionTypes.SET_IS_CATEGORY_PAGE:
      return {...state, isCategoryPage: action.payload}
    case actionTypes.SET_CATEGORY_SPECIFICATIONS:
      return {...state, specifications: action.payload}
    case actionTypes.SET_ACTIVE_CATEGORIES:
      return {...state, activeCategories: action.payload}
    case actionTypes.SET_SIMILAR_WORDS:
      return {...state, similarWords: action.payload}
    case actionTypes.SET_CATEGORY_ADDITIONAL_DEPENDENCIES:
      return {...state, additionalDependencies: action.payload}
    case actionTypes.SET_CATEGORY_ADDITIONAL_DEPENDENCIES_VISIBILITY:
      return {...state, additionalDependenciesVisibility: action.payload}
    default:
      return state
  }
}
