import { getSortedSpecifications } from '../getSortedSpecifications'
import { getCurrentSubCategory } from 'SRC/modules/categories/selectors'
import { getIsMobileDevice } from 'SRC/modules/common/selectors'
import { createSelector } from 'reselect'

export const getSpecsIdForMainProps = createSelector(
  getSortedSpecifications,
  getCurrentSubCategory,
  getIsMobileDevice,
  (specs, cat, isMobileDevice) => {
    let iconsCount = cat ? cat.iconCountWeb : 5
    if (isMobileDevice) iconsCount = cat ? cat.iconCount : 5

    console.log('iconsCount', iconsCount)

    const specsForMainProps = specs.length > iconsCount ? specs.slice(0, iconsCount) : specs
    return specsForMainProps.map(item => {
      if (item.specification && item.specification.id) {
        return item.specification.id
      } else {
        return null
      }
    }).filter(item => Boolean(item))
  }
)
