import React from 'react'
import PropTypes from 'prop-types'
import { SubCategory } from '../../SubCategory'

const getHrefUrl = (baseHref, parentSeo, seo) => {
  let hrefUrl = {
    pathname: baseHref,
    query: {
      category: seo
    }
  }

  if (baseHref === '/') hrefUrl.query.parentCategory = parentSeo

  console.log('h', hrefUrl)

  return hrefUrl
}

const getAsUrl = (baseAs, parentSeo, seo) => {
  if (baseAs === '/dodaj') {
    return `/dodaj/${seo}`
  }

  return `/${parentSeo}/${seo}`
}

export const Body = ({items, parentSeo, baseHref, baseAs}) => {
  return <div className='kategorije-pretraga-mega-menu-content-item'>
    {
      items.map(item => [
        <SubCategory
          ico={item.icon}
          iconSvg={item.iconSvg}
          text={item.title}
          href={getHrefUrl(baseHref, parentSeo, item.seo)}
          as={getAsUrl(baseAs, parentSeo, item.seo)}
          key={item.id}
        />,
        '\n'
      ])
    }
  </div>
}

Body.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    icon: PropTypes.string,
    title: PropTypes.string.isRequired,
    seo: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired
  })).isRequired,
  parentSeo: PropTypes.string,
  baseHref: PropTypes.string,
  baseAs: PropTypes.string
}

Body.defaultProps = {
  items: [],
  baseHref: '/category',
  baseAs: '/'
}
