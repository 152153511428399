import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { flattenWithChildren } from 'src/utils'
import { Search, ItemHeader, ItemBody } from '../'
import { getCategories, getSimilarWords } from 'SRC/modules/categories/selectors'

export class List extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      filteredCategories: null
    }
  }

  onChange = (event) => {
    const { similarWords, flatCategories } = this.props
    const value = event.target.value

    const foundCategoriesBySimilarWords = similarWords.filter(wordCategoryPair => {
      const word = wordCategoryPair[0]
      return word.toLowerCase().indexOf(value.toLowerCase()) > -1
    }).map(wordCategoryPair => wordCategoryPair[1])

    const foundCategoriesByTitle = flatCategories.filter(category => {
      return category.title.toLowerCase().indexOf(value.toLowerCase()) > -1
    }).map(category => category.id)

    const foundCategories = foundCategoriesBySimilarWords.concat(foundCategoriesByTitle)

    const filteredCategories = value === '' ? null : Array.from(new Set(foundCategories))

    this.setState({
      filteredCategories
    })
  }

  onSubmit = (e) => {
    e.preventDefault()
  }

  render () {
    const { filteredCategories } = this.state
    return [
      <Search
        onChange={this.onChange}
        placeholder={this.props.placeholder}
        onSubmit={this.onSubmit}
        key='search'
      />,
      <div className='dodavanje-oglasa-kategorije' key='list'>
        {
          this.props.categories.map(item => {
            let items
            if (item.children && Array.isArray(item.children)) {
              if (filteredCategories) {
                items = item.children.filter(category => filteredCategories.includes(category.id))
              } else {
                items = item.children
              }
            } else {
              items = []
            }
            return (
              items.length
                ? [
                  <ItemHeader title={item.title} key={`header-${item.id}`} />,
                  '\n',
                  <ItemBody
                    baseHref={this.props.baseHref}
                    baseAs={this.props.baseAs}
                    items={items}
                    parentSeo={item.seo}
                    key={`body-${item.id}`}
                  />,
                  '\n'
                ]
                : null
            )
          })
        }
      </div>
    ]
  }
}

const mapStateToProps = (state, ownProps) => {
  const categories = getCategories(state)
  const similarWords = getSimilarWords(state)
  return {
    categories,
    flatCategories: flattenWithChildren(categories),
    similarWords
  }
}

List.propTypes = {
  categories: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    children: PropTypes.arrayOf(PropTypes.shape({
      title: PropTypes.string.isRequired
    }))
  })).isRequired,
  flatCategories: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired
  })).isRequired,
  similarWords: PropTypes.arrayOf(PropTypes.array).isRequired,
  placeholder: PropTypes.string.isRequired,
  baseHref: PropTypes.string,
  baseAs: PropTypes.string
}

List.defaultProps = {
  categories: [],
  flatCategories: [],
  similarWords: []
}

export default connect(mapStateToProps)(List)
