import { ShoppingCartApi } from '../../api'
import { setCartItemLoading } from '../setCartItemLoading'
import { setCartItems } from '../setCartItems'

export const addCartItem = payload => {
  return async (dispatch) => {
    dispatch(setCartItemLoading(true))
    const api = new ShoppingCartApi()

    const result = await api.addCartItem(payload)

    if (result.status === 'ok' && Array.isArray(result.items)) {
      dispatch(setCartItems(result.items))
    } else {
      dispatch(setCartItems([]))
    }

    dispatch(setCartItemLoading(false))
  }
}
