import React from 'react'
import config from 'SRC/config/config.yaml'

export const Weblab = () => (
  <div className='footer-regions'>
    <span className='footer-column-title'>WEBLAB D.O.O.</span>
    <p>{config.footer.weblab.address}</p>
    <p>{config.footer.weblab.contact}</p>
  </div>
)
