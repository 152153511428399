import { createSelector } from 'reselect'

export const getFavouritePublishedAds = createSelector(
  state => state && state.user && state.user.profile && state.user.profile.favouriteAds &&
    state.user.profile.favouriteAds.Published.elements.length
    ? state.user.profile.favouriteAds.Published.elements : [],
  (favouriteAds) => favouriteAds.map(ad => ({
    ...ad,
    isPaid: ad.listingStatus === 'Active'
  }))
)
