import { setAdditionalDependencies } from '../setAdditionalDependencies'
import { setAdditionalDependenciesVisibility } from '../setAdditionalDependenciesVisibility'
import { CategoriesApi } from '../../api'

export const fetchAdditionalDependencies = category => {
  return async dispatch => {
    const api = new CategoriesApi()
    const additionalDependencies = await api.fetchAdditionalDependenciesByCategory(category)

    dispatch(setAdditionalDependencies(additionalDependencies))

    const initialVisibility = {
      swapp: true,
      price: true,
      price_period: false
    }

    dispatch(setAdditionalDependenciesVisibility(initialVisibility))
  }
}
