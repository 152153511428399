import React from 'react'
import { Item } from '../Item'
import { projects } from '../data/projects'
import config from 'SRC/config/config.yaml'

export const List = () => (
  <div className='footer-projects'>
    <span className='footer-column-title'>{config.footer.projects.title}</span>
    <ul>
      {
        projects.map(project => [
          <Item key={project.id}{...project} />,
          '\n'
        ])
      }
    </ul>
  </div>
)
