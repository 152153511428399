import React from 'react'
import PropTypes from 'prop-types'

export class AppLink extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      visible: true,
      top: 0,
      left: 0,
      width: 1030,
      height: 1000,
      fontSize: 48,
      imgWidth: 500
    }

    this.interval = null
  }

  componentDidMount = () => {
    this.interval = setInterval(() => {
      const xOffset = window.visualViewport ? window.visualViewport.offsetLeft : window.pageXOffset
      const yOffset = window.visualViewport ? window.visualViewport.offsetTop : window.pageYOffset

      const vWidth = window.visualViewport.width
      const vHeight = window.visualViewport.height

      console.log('vWidth', vWidth)
      console.log('vHeight', vHeight)

      const updatedState = {}

      if (this.state.width !== vWidth) {
        updatedState.width = vWidth
      }

      if (this.state.top !== yOffset) {
        updatedState.top = yOffset
      }

      if (this.state.left !== xOffset) {
        updatedState.left = xOffset
      }

      const heightPercentage = vHeight > vWidth ? 0.35 : 0.7

      const popupHeight = Math.round(vHeight * heightPercentage)

      if (this.state.height !== popupHeight) {
        updatedState.height = popupHeight
      }

      const popupImageWidth = (vWidth > vHeight ? 375 : 500) * (vWidth / 1030)

      if (this.state.imgWidth !== popupImageWidth) {
        updatedState.imgWidth = popupImageWidth
      }

      const popupFontSize = (vWidth > vHeight ? 35 : 48) * (vWidth / 1030)

      if (this.state.fontSize !== popupFontSize) {
        updatedState.fontSize = popupFontSize
      }

      if (Object.keys(updatedState).length) {
        this.setState({ ...updatedState })
      }
    }, 500)
  }

  componentWillUnmount = () => {
    this.interval = null
  }

  onScroll = e => {
    console.log('onScroll', e)
  }

  render () {
    const { text, url, buttonText, onClose } = this.props
    const { top, left, width, height, fontSize, imgWidth } = this.state

    return (
      <div style={{ position: 'fixed', left, top, zIndex: 19, width, height, border: '1px solid black' }}>
        <section className='ios-modal' style={{ width: width - 2, height: height - 2 }}>
          <div onClick={onClose} className='ios-modal__close'>
            <img src='/icons/cancel.svg' width={fontSize * 1.5} />
          </div>
          <p style={{ fontSize }}>{text}</p>
          <img src='/img/logo.png' width={imgWidth} />
          <a href={url} style={{ fontSize }}>
            {buttonText}
          </a>
        </section>
      </div>
    )
  }
}

AppLink.propTypes = {
  text: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired
}
