import PropTypes from 'prop-types'
import React from 'react'
import { getFormattedAdPrice } from 'SRC/utils'

const renderPriceRange = ({ priceFrom, priceTo }) => {
  if (priceFrom && priceTo) {
    return (
      <div className='cena' key='priceRange'>
        <p>{getFormattedAdPrice(priceFrom)} do {getFormattedAdPrice(priceTo)} &euro;</p>
      </div>
    )
  }
  
  if (priceFrom && !priceTo) {
    return (
      <div className='cena' key='priceFrom'>
        <p>Već od {getFormattedAdPrice(priceFrom)} &euro;</p>
      </div>
    )
  }
  
  if (!priceFrom && priceTo) {
    return (
      <div className='cena' key='priceTo'>
        <p>Do {getFormattedAdPrice(priceTo)} &euro;</p>
      </div>
    )
  }

  return null
}

const renderRentPrice = ({
  type,
  priceDaily,
  priceMonthly
}) => {
  return (
    <React.Fragment>
      {priceMonthly ? (
        <div className='cena'>
          <p>{getFormattedAdPrice(priceMonthly)} &euro; mjesec</p>
        </div>
      ) : null}

      {priceDaily ? (
        <div className='cena'>
          <p>{getFormattedAdPrice(priceDaily)} &euro; dan</p>
        </div>
      ) : null}
    </React.Fragment>
  )
}

const renderRegularPrice = ({ newPrice, oldPrice, isBuying }) => {
  if (!newPrice) return <div className='cena'><p>Po dogovoru</p></div>
  return (
    <React.Fragment>
      <div className='cena'>
        <p>{`${isBuying ? 'do ' : ''}${getFormattedAdPrice(newPrice)}`}&euro;</p>
      </div>
      {oldPrice ? <div className='stara-cena'>
        {<span className={'stara-cena'}>{getFormattedAdPrice(oldPrice)}&euro;</span>}
      </div> : null}
    </React.Fragment>
  )
}

export const Price = ({
  newPrice,
  oldPrice,
  promotion,
  type,
  priceType,
  priceFrom,
  priceTo,
  priceOnRequest,
  priceDaily,
  priceMonthly,
  priceSpecification,
  isBuying
}) => {
  if (priceSpecification === 0) return null

  if (typeof priceSpecification === 'object' && priceSpecification !== null && typeof priceSpecification.value !== 'undefined') {
    return (
      <div className={'oglasi-items-' + type + '-cena'}>
        <div className='cena'>
          <p>{`${priceSpecification.value} ${priceSpecification.measure || ''}`}</p>
        </div>
      </div>
    )
  }

  const shouldRenderRangePrice = priceFrom || priceTo
  const shouldRenderRentPrice = !shouldRenderRangePrice && (priceDaily || priceMonthly)
  const shouldRenderRegularPrice = !shouldRenderRangePrice && !shouldRenderRentPrice

  const className = `oglasi-items-${type}-cena`
  const classNameRent = shouldRenderRentPrice ? `oglasi-items-${type}-cena-rent` : ''

  return (
    <div className={`${className} ${classNameRent || ''}`}>
      {!priceOnRequest
        ? (
          <React.Fragment>
            {shouldRenderRangePrice ? renderPriceRange({ priceFrom, priceTo }) : null}
            {shouldRenderRentPrice ? renderRentPrice({ type, priceDaily, priceMonthly }) : null}
            {shouldRenderRegularPrice ? renderRegularPrice({ newPrice, oldPrice, isBuying }) : null}
          </React.Fragment>
        ) : (
          <div className='cena'>
            <p>Cijena na upit</p>
          </div>
        )
      }
      {promotion && !priceOnRequest ? <div className='cena-akcija'><p>{promotion}&euro;</p></div> : null}
      {
        priceType && !priceOnRequest ? <span className='vrsta-cene'>
          {priceType === 1 ? 'Fiksno' : priceType === 2 ? 'Po dogovoru' : 'Hitno'}
        </span> : null
      }
    </div>
  )
}

Price.propTypes = {
  newPrice: PropTypes.number.isRequired,
  oldPrice: PropTypes.number,
  priceType: PropTypes.number,
  priceFrom: PropTypes.number,
  priceTo: PropTypes.number,
  promotion: PropTypes.number,
  priceOnRequest: PropTypes.bool,
  priceDaily: PropTypes.number,
  priceMonthly: PropTypes.number,
  type: PropTypes.string.isRequired,
  priceSpecification: PropTypes.shape({
    value: PropTypes.string,
    measure: PropTypes.string
  }),
  isBuying: PropTypes.bool
}
