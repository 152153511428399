import React from 'react'
import PropTypes from 'prop-types'
import { imagesCDN } from 'src/core/constants'
import { Header } from '../../../shared/components'
import Link from 'next/link'

class BusinessUsers extends React.Component {
  getUserBanner = user => {
    if (user.banner && user.banner.fd) {
      return `${imagesCDN}${user.banner.fd}`
    }
    return 'https://placehold.it/274x76'
  }

  render() {
    return (
      <>
        <Header title={this.props.title} />

        <div className='sidebar-content'>
          <div className='sidebar-info'>
            <p>{this.props.info}</p>
            <i className='ico-telefonska-podrska ico' />
          </div>
          {this.props.users.length ? <div className='sidebar-items'>
            {this.props.users.map(user => (
              <div className='sidebar-item' key={user.id}>
                <Link
                  href={`${user.url.href}`}
                  as={user.url.as}
                >
                  <a>
                    <div
                      style={{
                        width: '100%',
                        height: '100%',
                        position: 'relative'
                      }}
                    >
                      <img src={this.getUserBanner(user)} alt={user.username} style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                    </div>
                  </a>
                </Link>
              </div>
            ))}
          </div> : <div className='sidebar-items'><h3>Nema korisnika</h3></div>}
          <div className='sidebar-item-footer'>
            <div className='sidebar-footer-bg'>
              <Link href={{ pathname: '/users', query: { parentUserType: 'prodavac' } }} as='/prodavci'>
                <a>Prodavci</a>
              </Link>
            </div>
          </div>
        </div>
      </>
    )
  }
}

BusinessUsers.propTypes = {
  users: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
  info: PropTypes.string.isRequired
}

export default BusinessUsers
