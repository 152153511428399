import { BaseApi } from 'Core/api/BaseApi'
import { TypesHelper } from 'SRC/utils'

export class Api extends BaseApi {
  getLocationsByCity = async (cityId, categoryId = null) => {
    const defaultResult = []
    if (TypesHelper.isPositiveNumber(cityId)) {
      try {
        const params = { city: cityId }
        if (categoryId) params.category = categoryId

        const {body} = await Api.post(`/location/listByCity`, params)
        if (!body.error && body.locations && Array.isArray(body.locations)) {
          return body.locations.map(location => ({
            id: location.id,
            label: location.name,
            value: location.id,
            priority: location.priority
          }))
        } else {
          return defaultResult
        }
      } catch (error) {
        return defaultResult
      }
    } else {
      return defaultResult
    }
  }
}
