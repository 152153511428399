import PropTypes from 'prop-types'
import React from 'react'

export const ShowDetailBtn = ({onClick, disabled}) => (
  <button
    className={`filter-buttons-toggle-detail ${disabled ? 'filter-buttons-toggle-detail__disabled' : ''}`}
    onClick={!disabled ? e => { e.preventDefault(); onClick(); } : null}
  >
    DETALJNA PRETRAGA <i className='ico-pretraga-polja-lupa ico' />
  </button>
)

ShowDetailBtn.propTypes = {
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool
}
