import { createSelector } from 'reselect'
import { getUserTypes } from 'SRC/modules/common/selectors'
import { getUserInfo } from '../getUserInfo'

export const getIsAutoMotoUser = createSelector(
  getUserTypes,
  getUserInfo,
  (userTypes, user) => {
    if (!user || !user.type) return false
    if (!Array.isArray(userTypes)) return false

    const findAutoMotoType = userTypes.find(ut => ut.seo === 'auto-moto-nautika')

    if (findAutoMotoType) {
      const autoMotoId = findAutoMotoType.id

      return user.type.parent === autoMotoId
    }

    return false
  }
)
