import { CookiesHelper } from 'Core/cookiesHelper/CookiesHelper'
import { resetCart } from 'SRC/modules/shoppingCart/actions'
import { resetBoughtItems } from 'SRC/modules/orders/actions'
import { setIsAuthorized } from '../setIsAuthorized'
import { resetProfileInfo } from '../../../Profile/actions'
import { resetState } from '../../../../ads/edit/actions'

export const logout = () => {
  return (dispatch) => {
    try {
      const cookie = new CookiesHelper()
      cookie.remove('token')
      cookie.remove('userId')
      cookie.remove('userName')
      cookie.remove('userLogin')
      dispatch(resetProfileInfo())
      dispatch(resetState())
      dispatch(setIsAuthorized(false))
      dispatch(resetCart())
      dispatch(resetBoughtItems())
    } catch (error) {
      console.log('logout catch', error)
      dispatch(setIsAuthorized(false))
    }
  }
}
