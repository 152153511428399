import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { Formik, Form } from 'formik'
import { connect } from 'react-redux'
import Router from 'next/router'
import { Notification } from 'react-notification'
import { Preloader } from 'SRC/ui/Preloader'
import { CookiesHelper } from 'Core/cookiesHelper/CookiesHelper'
import { RegistrationApi } from 'SRC/modules/users/Registration/api'
import { ProfileApi } from 'SRC/modules/users/Profile/api'
import { string, object, number, ref, boolean, mixed } from 'yup'

import { smsWasSent, setRegistrationLoading } from 'SRC/modules/users/Registration/actions'

import config from 'SRC/config/config.yaml'

import { prepareFieldsForBusinessUser, prepareFieldsForFreeUser } from 'SRC/modules/users/Registration/functions'

import { NameSurname } from '../NameSurname'
import { Gender } from '../Gender'
import { Address } from '../Address'
import { Contact } from '../Contact'

const schema = object().shape({
  name: string().min(3, 'Ime mora biti dugačko minimalno 3 karaktera.').required('Popunite polje'),
  surname: string().min(3, 'Prezime mora biti dugačko minimalno 3 karaktera.'),
  gender: mixed().oneOf(['m', 'f'], 'Pol mora biti "m" ili "f"'),
  address: object().shape({
    country: number().test('country', 'Izaberi državu', value => Number(value) !== -1),
    region: number().test('region', 'Izaberi region', value => Number(value) !== -1),
    city: number().test('city', 'Izaberi grad', value => Number(value) !== -1),
    location: number().test('location', 'Izaberi lokaciju', value => Number(value) !== -1),
  }),
  phoneUpdate: object().shape({
    viber: boolean(),
    whatsapp: boolean()
  })
})

const getFormattedPhone = (dial, phone) => {
  return `${dial}${phone}`.trim().replace('+', '').replace(/ /g, '')
}

const UpdateForm = ({ userInfo, phoneNumber }) => {
  const [errorMessage, setErrorMessage] = React.useState('')

  const isFreeUser = userInfo?.type && userInfo.type === config.globalOptions.freeUserTypeId

  const getInitialValues = () => {
    const initialValues = { name: userInfo.name || '' }

    const mainAddress = Array.isArray(userInfo?.userAddresses) ? userInfo.userAddresses.find(userAddress => userAddress.main) : null

    if (mainAddress) {
      initialValues.address = {
        id: mainAddress.id,
        country: mainAddress.country ? `${mainAddress.country.id}` : -1,
        region: mainAddress.region ? `${mainAddress.region.id}` : -1,
        city: mainAddress.city ? `${mainAddress.city.id}` : -1,
        location: mainAddress.location ? `${mainAddress.location.id}` : -1
      }
    } else {
      initialValues.address = {}
    }

    const userPhone = getPhone()

    if (userPhone) {
      initialValues.phoneUpdate = {
        id: userPhone.id,
        viber: Boolean(userPhone.viber),
        whatsapp: Boolean(userPhone.whatsapp)
      }
    }

    if (isFreeUser) {
      initialValues.gender = userInfo.gender && ['m', 'f'].includes(userInfo.gender) ? userInfo.gender : 'm'
      initialValues.surname = userInfo.surname || ''
    }

    return initialValues
  }

  const prepareFields = (values) => {
    const address = {
      id: values.address.id,
      country: Number(values.address.country),
      region: Number(values.address.region),
      city: Number(values.address.city),
      location: Number(values.address.location)
    }

    const phone = {
      id: values.phoneUpdate.id,
      viber: values.phoneUpdate.viber,
      whatsapp: values.phoneUpdate.whatsapp
    }
  
    const preparedFields = {
      userId: userInfo.id,
      token: userInfo.token,
      user: userInfo.id,
      name: values.name,
      phonesUpdate: JSON.stringify([phone]),
      addressesUpdate: JSON.stringify([address])
    }

    if (values.gender) preparedFields.gender = values.gender
    if (typeof values.surname === 'string') preparedFields.surname = values.surname

    return preparedFields
  }

  const getPhone = () => {
    if (!Array.isArray(userInfo?.phones)) return null
    return userInfo.phones.find(userPhone => userPhone.phone === phoneNumber)
  }

  const submit = async (values, actions) => {
    await updateUser(values, userInfo)
  }

  const updateUser = async (values, userInfo) => {
    const profileApi = new ProfileApi()

    const payload = prepareFields(values)

    const needStringify = true

    const result = await profileApi.updateProfile(payload, needStringify)

    if (typeof result.error === 'string' && result.error) {
      return setErrorMessage(result.error)
    } else {
      if (result.data && result.data.user && result.data.token) {
        const cookie = new CookiesHelper()
        cookie.set('token', result.data.token, { maxAge: 315360000 })
        cookie.set('userId', result.data.user.id, { maxAge: 315360000 })
  
        return Router.push('/profile', '/profil')
      }

      setErrorMessage('Došlo je do greške. Provjerite opet sve podatke i pokušajte ponovo.')
    }
  }

  return (
    <Formik
      initialValues={getInitialValues()}
      validationSchema={schema}
      onSubmit={submit}
      enableReinitialize
    >
      {formikProps => (
        <Form className='registracija-korisnik-form'>
          <p style={{ marginBottom: 10 }}>Popunite podatke kako bi završili registraciju profila.</p>

          <div className='form-ime-prezime'>
            {/* Name and surname */}
            <NameSurname key='name-surname' showSurname={isFreeUser} />
          </div>

          {/* Gender */}
          {isFreeUser ? <Gender /> : null}

          {/* Address */}
          <Address />

          {/* Contact */}
          <Contact contact={getPhone()} />

          <button
            className='form-submit-btn'
            type='submit'
            key='button'
            onClick={formikProps.handleSubmit}
            disabled={formikProps.isSubmitting}
          >
            Potvrdi
          </button>

          <Notification
            isActive={Boolean(errorMessage)}
            message={errorMessage}
            action={'Sakrij'}
            onClick={() => { setErrorMessage('') }}
          />
          {formikProps.isSubmitting ? <Preloader /> : null}
        </Form>
      )}
    </Formik>
  )
}

UpdateForm.propTypes = {
  userInfo: PropTypes.shape({
    id: PropTypes.number,
    token: PropTypes.string,
    name: PropTypes.string,
    surname: PropTypes.string,
    username: PropTypes.string,
    type: PropTypes.number,
    gender: PropTypes.oneOf(['m', 'f']),
    phones: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      phone: PropTypes.string,
      visible: PropTypes.bool,
      viber: PropTypes.bool,
      whatsapp: PropTypes.bool
    })),
    userAddresses: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.id,
      main: boolean,
      country: PropTypes.shape({ id: PropTypes.number }),
      region: PropTypes.shape({ id: PropTypes.number }),
      city: PropTypes.shape({ id: PropTypes.number }),
      location: PropTypes.shape({ id: PropTypes.number }),
    }))
  }).isRequired,
  phoneNumber: PropTypes.string.isRequired
}

export default connect(null, { setRegistrationLoading })(UpdateForm)
