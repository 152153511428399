import { createSelector } from 'reselect'
import { getFlattenCategories } from 'SRC/modules/categories/selectors'
import { getPaidAds } from '../getPaidAds'

export const getAds = createSelector(
  (state) => state && state.ads && state.ads.list && state.ads.list.elements ? state.ads.list.elements : [],
  getPaidAds,
  getFlattenCategories,
  (ads, paidAds, categories) => {
    return ads.map(ad => {
      const categoryId = ad.category
      const newAd = {...ad, isPaid: paidAds.includes(ad.id)}
      const flattenCategories = []
      categories.forEach(item => {
        flattenCategories.push({
          id: item.id,
          title: item.title,
          seo: item.seo,
          shortTitle: item.shortTitle,
          specsShown: item.specsShown,
          specsShownWeb: item.specsShownWeb,
          group: item.group,
          parent: item.parent,
          specifications: item.categoryspecifications,
          requiredAdOptions: item.requiredAdOptions,
          titleSpecification1: item.titleSpecification1,
          titleSpecification2: item.titleSpecification2
        })
        if (item.children && Array.isArray(item.children)) {
          item.children.forEach(child => {
            flattenCategories.push({
              id: child.id,
              title: child.title,
              seo: child.seo,
              shortTitle: child.shortTitle,
              specsShown: child.specsShown,
              specsShownWeb: child.specsShownWeb,
              pricePeriod: child.pricePeriod,
              group: child.group,
              parent: child.parent,
              requiredAdOptions: child.requiredAdOptions,
              specifications: child.categoryspecifications,
              titleSpecification1: child.titleSpecification1,
              titleSpecification2: child.titleSpecification2
            })
          })
        }
      })
      const filteredCategories = flattenCategories.filter(category => category.id === categoryId)
      if (filteredCategories.length) {
        newAd.category = filteredCategories[0]
      }
      return newAd
    })
  }
)
